import React from "react";
import { Text } from "rebass/styled-components";

const HighlightedElement = ({ description }) => {
  return (
    <Text
      marginY="small"
      textAlign="left"
      color="secondary"
      fontSize="secondary"
      sx={{
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}>
      {description}
    </Text>
  );
};

export default HighlightedElement;
