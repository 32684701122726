import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { Box, Flex, Image } from "rebass/styled-components";
import qs from "qs";
import { OAuthCredentials } from "../../common/Deployment";
import { AuthorizationHandler } from "../../components/authorization";
import { SelfcareButton } from "../../components/base";
import Footer from "../../components/Footer";
import StyledModalMessage from "../../components/StyledModalMessage";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { BrandingContext } from "../../contexts/BrandingContext";

const Login = ({ location }) => {
  const { provider, logo } = useContext(BrandingContext);
  const { codeVerifier, loggedIn, logout } = useContext(AuthContext);
  const history = useHistory();
  const intl = useIntl();
  const { authorizationURL, clientID, scope, redirectURI } = OAuthCredentials;

  let { code } = qs.parse(location.search, { ignoreQueryPrefix: true });

  let url =
    authorizationURL +
    "?" +
    qs.stringify({
      response_type: "code",
      client_id: clientID,
      redirect_uri: window.location.origin + redirectURI,
      scope: scope,
      state: location.hash,
      provider: provider,
      code_challenge: codeVerifier,
    });

  if (!code && !loggedIn) {
    window.location.href = url;
    return null;
  }

  if (!code && loggedIn) {
    history.replace("/home");
  }

  const errorHandler = () => {
    window.location.href = url;
  };

  return (
    <Flex bg="windowBg" flexDirection="column" minHeight="100vh" flex={1}>
      <Box
        bg="contentBg"
        sx={{
          position: "sticky",
          top: 0,
          boxShadow: "light",
          zIndex: 1,
        }}>
        <Flex
          width="100%"
          maxWidth="headerContentMaxWidth"
          mx="0"
          px={["default", "default", "8.5rem"]}
          py="2.30rem"
          justifyContent="left"
          alignItems="left">
          <Image src={logo} width="10.375rem" height="3rem" />
        </Flex>
      </Box>
      <Flex
        width="100%"
        maxWidth="layoutContentMaxWidth"
        marginLeft="auto"
        marginRight="auto"
        px="default"
        py="large"
        flexDirection="column">
        <Box mb="default">
          <Flex justifyContent="center">
            {!loggedIn && <AuthorizationHandler hideSignIn={true} errorHandler={errorHandler} />}
            {loggedIn && (
              <StyledModalMessage
                isOpen={true}
                message={intl.formatMessage({ id: "lbl.session_exists" })}
                onRequestClose={() => {}}
                type="error">
                <SelfcareButton
                  variant="secondarySmall"
                  onClick={() => {
                    logout(history);
                  }}>
                  <FormattedMessage id="lbl.log_out" />
                </SelfcareButton>
              </StyledModalMessage>
            )}
          </Flex>
        </Box>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default Login;
