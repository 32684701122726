import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { SelfcareButton } from "../base";
import StyledModalMessage from "../../components/StyledModalMessage";

const LoadDocumentModal = ({ isOpen, onClose, url }) => {
  const intl = useIntl();
  return (
    <StyledModalMessage
      isOpen={isOpen}
      message={url ? null : intl.formatMessage({ id: "lbl.loading_document_wait" })}
      onRequestClose={onClose}
      isCloseDisabled={url === null}
      type={url ? "loading_success" : "loading"}>
      {url && (
        <SelfcareButton
          type="button"
          variant="secondarySmall"
          onClick={() => {
            window.open(url);
            onClose();
          }}>
          <FormattedMessage id="lbl.view" />
        </SelfcareButton>
      )}
    </StyledModalMessage>
  );
};

export default LoadDocumentModal;
