import React from "react";
export default ({ core = "#336eb4", button = "#318eff", jack = "#114878", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80.146"
    height="69.495"
    viewBox="0 0 80.146 69.495"
    {...props}>
    <g transform="translate(0 42.077)">
      <path
        fill={core}
        d="M77.721,39.9H2.425A2.386,2.386,0,0,0,0,42.325V64.893a2.386,2.386,0,0,0,2.425,2.425h75.3a2.386,2.386,0,0,0,2.425-2.425V42.325A2.386,2.386,0,0,0,77.721,39.9Z"
        transform="translate(0 -39.9)"
      />
      <circle fill={button} cx="5.273" cy="5.273" r="5.273" transform="translate(64.328 8.436)" />
      <path
        fill={jack}
        d="M29.695,51.469V49.887h-.738V48.2H25.687v1.687h-.844v1.582H24v6.644h6.538V51.469Z"
        transform="translate(1.309 -39.447)"
      />
      <path
        fill={jack}
        d="M38.295,51.469V49.887h-.738V48.2H34.182v1.687h-.738v1.582H32.6v6.644h6.538V51.469Z"
        transform="translate(1.779 -39.447)"
      />
      <path
        fill={jack}
        d="M46.895,51.469V49.887h-.738V48.2H42.782v1.687h-.738v1.582H41.2v6.644h6.538V51.469Z"
        transform="translate(2.248 -39.447)"
      />
      <path
        fill={jack}
        d="M55.495,51.469V49.887h-.844V48.2H51.382v1.687h-.738v1.582H49.8v6.644h6.538V51.469Z"
        transform="translate(2.717 -39.447)"
      />
      <path
        fill={jack}
        d="M19.764,50.455H17.655V49.4H7.109v1.055H5v4.218H7.109v1.055h3.164v1.055h4.218V55.727h3.164V54.673h2.109Z"
        transform="translate(0.273 -39.382)"
      />
    </g>
    <g transform="translate(23.728)">
      <path
        fill={button}
        d="M41.627,22.022l2.215,2.215a14.151,14.151,0,0,0,3.9-9.807A13.927,13.927,0,0,0,43.315,4.2L41.1,6.415a11.064,11.064,0,0,1,3.48,8.015A10.862,10.862,0,0,1,41.627,22.022Z"
        transform="translate(-21.485 0.229)"
      />
      <path
        fill={button}
        d="M45.922,26.786,48.136,29a20.454,20.454,0,0,0,5.8-14.237A20.8,20.8,0,0,0,47.715,0L45.5,2.215a17.434,17.434,0,0,1,5.378,12.549A18.236,18.236,0,0,1,45.922,26.786Z"
        transform="translate(-21.245)"
      />
      <path
        fill={core}
        d="M27.035,7.4h-3.48A1,1,0,0,0,22.5,8.455V20.371a1,1,0,0,0,1.055,1.055h3.48a1.5,1.5,0,0,1,.633.211l7.909,5.378a1.054,1.054,0,0,0,1.687-.844V2.655a1.054,1.054,0,0,0-1.687-.844L27.667,7.189A1.5,1.5,0,0,1,27.035,7.4Z"
        transform="translate(-22.5 0.087)"
      />
    </g>
  </svg>
);
