import React, { useState } from "react";
import { useIntl } from "react-intl";
import AutomaticPaymentSettingsSkeleton from "./AutomaticPaymentSettingsSkeleton";
import EditAutomaticPaymentSettingsModal from "./EditAutomaticPaymentSettingsModal";
import SummaryLayout from "../../containers/SummaryLayout";
import SummaryRow from "../../components/SummaryRow/SummaryRow";

const paymentMethodLabel = paymentMethod => {
  switch (paymentMethod) {
    case "CREDIT_CARD":
      return "lbl.automatic_payment_method_credit_card";

    case "DIRECT_DEBIT":
      return "lbl.automatic_payment_method_direct_debit";

    case "ECHECK":
      return "lbl.automatic_payment_method_echeck";

    default:
      return "lbl.automatic_payment_method_none";
  }
};

const AutomaticPaymentSettings = ({
  settings,
  isLoading,
  hasCreditCardDetails,
  hasBankAccountDetails,
  onEditSuccess,
}) => {
  const intl = useIntl();
  const [isEdit, setEdit] = useState(false);

  if (isLoading || !settings) {
    return <SummaryLayout isLoading skeleton={AutomaticPaymentSettingsSkeleton} />;
  }

  return (
    <SummaryLayout
      title="lbl.automatic_payment"
      showEdit={hasCreditCardDetails || hasBankAccountDetails}
      onEdit={() => setEdit(true)}>
      <SummaryRow
        tooltip={intl.formatMessage({
          id: settings.automaticPayment ? "tooltip.enabled" : "tooltip.disabled",
        })}
        icon={
          settings.automaticPayment ? "automatic-payment-enabled" : "automatic-payment-disabled"
        }
        value={intl.formatMessage({
          id: settings.automaticPayment
            ? "lbl.automatic_payment_enabled"
            : "lbl.automatic_payment_disabled",
        })}
      />
      {settings.automaticPayment && (
        <SummaryRow
          tooltip={intl.formatMessage({ id: "tooltip.automatic_payment_method" })}
          icon="automatic-payment-method"
          value={intl.formatMessage({ id: paymentMethodLabel(settings.automaticPaymentMethod) })}
        />
      )}

      {isEdit && (
        <EditAutomaticPaymentSettingsModal
          settings={settings}
          hasCreditCardDetails={hasCreditCardDetails}
          hasBankAccountDetails={hasBankAccountDetails}
          isOpen={isEdit}
          onClose={() => setEdit(false)}
          onEditSuccess={() => {
            setEdit(false);
            onEditSuccess();
          }}
        />
      )}
    </SummaryLayout>
  );
};

export default AutomaticPaymentSettings;
