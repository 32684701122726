const maskString = (value = "") => {
  let maskedPart = "*".repeat(4);
  if (!value) {
    return maskedPart;
  }

  let startOfVisiblePart = value.length > 1 ? value.length - 2 : 0;
  let lastPart = value.substring(startOfVisiblePart);

  return [maskedPart + lastPart];
};

function removeTimezoneFromDate(input) {
  var date = new Date(input);
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  var date2 = new Date(date.getTime() + userTimezoneOffset);
  return date2.getTime();
}

function getDateWithoutTimezone(inputDate) {
  if (!inputDate) {
    return inputDate;
  }

  return new Date(removeTimezoneFromDate(inputDate));
}

function getDaysUntil(toDateMilliseconds) {
  const timeDiffMiliseconds = removeTimezoneFromDate(toDateMilliseconds) - new Date().getTime();
  return Math.ceil(timeDiffMiliseconds / (1000 * 3600 * 24));
}

function getRemainingDaysPIA(piaExpirationDate) {
  return getDaysUntil(piaExpirationDate) + 1;
}

function getDaysUntilNextBP(billingPeriodEndDateString) {
  return getDaysUntil(Date.parse(billingPeriodEndDateString)) + 1;
}

function getFullName(contact) {
  if (!contact || !contact.name) {
    return "";
  }

  let result = contact.name.first ? contact.name.first + " " : "";
  result += contact.name.middle ? contact.name.middle + " " : "";
  result += contact.name.last ? contact.name.last + " " : "";

  return result;
}

function buildAddress(contact) {
  if (!contact) {
    return "";
  }

  return contact.addressType === "STRUCTURED"
    ? buildStructuredAddress(contact.structuredAddress)
    : buildFreeFormAddress(contact.address);
}

function buildStructuredAddress(address) {
  if (!address) {
    return "";
  }

  let result = address.floor ? address.floor + ", " : "";
  result += address.apartment ? address.apartment + ", " : "";
  result += address.entrance ? address.entrance + ", " : "";
  result += address.building ? address.building + ", " : "";
  result += address.street ? address.street + ", " : "";
  result += address.number ? address.number + ", " : "";
  result += address.city ? address.city + ", " : "";
  result += address.sector ? address.sector + ", " : "";
  result += address.commune ? address.commune + ", " : "";
  result += address.country ? address.country + ", " : "";
  result += address.state ? address.state : "";

  return result;
}

function buildFreeFormAddress(address) {
  if (!address) {
    return "";
  }

  let result;
  if (address.lines) {
    result = address.lines.filter(Boolean).join(", ");
  } else {
    result = "";
  }

  if (result !== "") {
    result += ", ";
  }

  result += address.city ? address.city + ", " : "";
  result += address.stateDescription ? address.stateDescription + ", " : "";
  result += address.zip ? address.zip + ", " : "";
  result += address.country ? address.country + ", " : "";

  return result.slice(0, -2);
}

function capitalize(s) {
  if (!s) {
    return "";
  }

  return s
    .toLowerCase()
    .split(" ")
    .map(e => e.charAt(0).toUpperCase() + e.slice(1))
    .join(" ");
}

const beautifyURL = url => url.split("://")[1];

/**
 * Check if the given string is empty.
 * @param {String} str The string.
 * @return {Boolean} True if the provided string is empty.
 */
const isEmpty = str => {
  return !str || 0 === str.length;
};

const getDateWithoutTime = date => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d;
};

export {
  maskString,
  getDaysUntil,
  getRemainingDaysPIA,
  getDaysUntilNextBP,
  buildAddress,
  getFullName,
  capitalize,
  beautifyURL,
  isEmpty,
  getDateWithoutTime,
  getDateWithoutTimezone,
};
