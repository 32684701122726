import React, { useState } from "react";

const showPasswordIcon = require(`../../resources/images/show_password.svg`);
const hidePasswordIcon = require(`../../resources/images/hide_password.svg`);

const ShowPassword = ({ refInput, mt = "-2rem" }) => {
  const [icon, setIcon] = useState(showPasswordIcon);

  function handleClick() {
    if (refInput.current && refInput.current.type) {
      if (refInput.current.type === "password") {
        refInput.current.type = "text";
        setIcon(hidePasswordIcon);
      } else {
        refInput.current.type = "password";
        setIcon(showPasswordIcon);
      }
    }
  }

  return (
    <span
      style={{
        float: "right",
        marginRight: "0.5rem",
        marginTop: mt,
        position: "relative",
        background: `url(${icon}) no-repeat top left`,
        display: "inline-block",
        width: "1.5rem",
        height: "1.5rem",
      }}
      onClick={handleClick}
    />
  );
};

export default ShowPassword;
