import React, { useEffect, useState } from "react";
import { Flex } from "rebass/styled-components";
import _ from "lodash";
import FeatureGroup from "./FeatureGroup";

const ServiceInstanceFeatures = ({
  path,
  originalService,
  serviceId,
  serviceInstance,
  _onServiceUpdate,
}) => {
  const [state, setState] = useState({ serviceInstance, service: _.cloneDeep(originalService) });

  useEffect(() => {
    function onPropsUpdate() {
      if (!_.isEqual(state.serviceInstance, serviceInstance)) {
        setState({ serviceInstance, service: _.cloneDeep(originalService) });
      }
    }

    onPropsUpdate();
  }, [state.serviceInstance, serviceInstance, originalService]);

  const onFeaturesUpdate = featureList => {
    let newFeatures = [];

    for (let oldFeature of state.serviceInstance.features) {
      let newFeature = featureList.find(feature => {
        return _.keys(feature.idStatusMap)[0] === _.keys(oldFeature.idStatusMap)[0];
      });
      if (newFeature) {
        oldFeature.selected = newFeature.selected;
      }

      newFeatures.push(oldFeature);
    }
    let newService = state.service;
    newService.instances[serviceId].features = newFeatures;

    if (_onServiceUpdate != null) {
      _onServiceUpdate(path, newService, originalService);
    }
    setState({ service: newService, serviceInstance: serviceInstance });
  };

  let rows = [];
  const featureGroups = _.groupBy(state.serviceInstance.features, feature => feature.group);
  _.each(featureGroups, (featureGroupItems, featureGroupCode) => {
    let featureGroupDescription = featureGroupItems[0].groupDescription;
    rows.push(
      <FeatureGroup
        serviceId={serviceId}
        key={featureGroupCode}
        featureGroupCode={featureGroupCode}
        featureGroupDescription={featureGroupDescription}
        _featureGroupItems={featureGroupItems}
        _onFeaturesUpdate={onFeaturesUpdate}
      />
    );
  });

  if (rows.length === 0) {
    return null;
  }

  return <Flex flexDirection={"column"}>{rows}</Flex>;
};

export default ServiceInstanceFeatures;
