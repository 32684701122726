import React, { useContext } from "react";
import { Input } from "@rebass/forms/styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import { Flex, Heading, Text } from "rebass/styled-components";
import qs from "qs";
import { SelfcareButton } from "../../components/base";
import StyledModalMessage from "../../components/StyledModalMessage";
import Tooltip from "../../components/Tooltip";
import LoginLayout from "../../containers/LoginLayout";
import { usePOST } from "../../hooks/restAPI";
import { FormErrorMessage } from "../../components/FormErrorMessage";
import GetErrorDescription from "../../components/GetErrorDescription";
import { AuthorizationHandler } from "../../components/authorization";
import { useForm } from "react-hook-form";
import { SelfcareIcon } from "../../components/icons";
import { BrandingContext } from "../../contexts/BrandingContext";

const Registration = props => {
  const intl = useIntl();
  const { provider, config } = useContext(BrandingContext);
  const [{ data, isError, error }, doPostRegister, resetError] = usePOST();

  const { register, handleSubmit, errors, watch, clearError } = useForm({
    mode: "onChange",
    validateCriteriaMode: "all",
  });

  const onRegister = values => {
    let params = {};
    if (values.accountCode) {
      params.method = "WITH_ACCOUNT_CODE";
      params.value = values.accountCode;
    } else if (values.phoneNumber) {
      params.method = "WITH_PHONE_NUMBER";
      params.value = values.phoneNumber;
    } else if (values.invoiceNumber) {
      params.method = "WITH_INVOICE_NUMBER";
      params.value = values.invoiceNumber;
    }

    if (config.limitAccessByProvider) {
      params.provider = provider;
    }

    let postData = {
      route: "/security/register?" + qs.stringify(params),
    };

    doPostRegister(postData);
  };

  const anyValueValidation = () => {
    return (
      watch("invoiceNumber") !== "" || watch("accountCode") !== "" || watch("phoneNumber") !== ""
    );
  };

  return data.accountCode ? (
    <Redirect
      push
      to={{
        pathname: "/signupStepTwo",
        state: {
          from: props.location,
          accountCode: data.accountCode,
          email: data.email,
          phoneNumber: data.phoneNumber,
        },
      }}
    />
  ) : (
    <LoginLayout>
      <>
        <form onSubmit={handleSubmit(onRegister)}>
          <Heading
            py="small"
            fontWeight={5}
            fontSize="title"
            color="primary"
            textAlign={["center", "center", "left"]}>
            <FormattedMessage id="lbl.sign_up" />
          </Heading>

          <Text color="textDark" fontSize="primary" paddingY="small" fontWeight={3}>
            <FormattedMessage id="lbl.sign_up_with_phone_number" />
          </Text>
          <Input
            onFocus={() => clearError(["invoiceNumber", "accountCode"])}
            onChange={() => clearError(["invoiceNumber", "accountCode"])}
            ref={register({
              validate: {
                anyValue: anyValueValidation,
              },
              pattern: { value: /^[0-9]+$/i, message: "err.invalid_phone_number" },
            })}
            disabled={watch("accountCode") || watch("invoiceNumber")}
            variant={errors.phoneNumber ? "inputError" : "input"}
            maxLength="30"
            name="phoneNumber"
            type="text"
          />
          {errors.phoneNumber && errors.phoneNumber.message !== "" && (
            <FormErrorMessage>
              <FormattedMessage id={errors.phoneNumber.message} />
            </FormErrorMessage>
          )}

          <Text color="textDark" fontSize="primary" paddingY="small" fontWeight={3}>
            <FormattedMessage id="lbl.or_sign_up_with_account_code" />
          </Text>

          <Flex flexDirection="column" mb="0.375rem">
            <Input
              onFocus={() => clearError(["invoiceNumber", "phoneNumber"])}
              onChange={() => clearError(["invoiceNumber", "phoneNumber"])}
              ref={register({
                validate: {
                  anyValue: anyValueValidation,
                },
                pattern: {
                  value: /^[a-zA-Z0-9]+$/i,
                  message: "lbl.account_code_should_be_alphanumerical",
                },
              })}
              disabled={watch("invoiceNumber") || watch("phoneNumber")}
              variant={errors.accountCode ? "inputError" : "input"}
              name="accountCode"
              type="text"
              maxLength="64"
              style={{ paddingRight: "2.2rem" }}
            />
            <Tooltip
              value={intl.formatMessage({ id: "lbl.account_code_hint" })}
              mt="-2.4rem"
              alignSelf="flex-end"
              sx={{ whiteSpace: "nowrap" }}
              childrenPY="small">
              <SelfcareIcon name="question-mark" width="2.75rem" height="1.125rem" />
            </Tooltip>
          </Flex>
          {errors.accountCode && errors.accountCode.message !== "" && (
            <FormErrorMessage>
              <FormattedMessage id={errors.accountCode.message} />
            </FormErrorMessage>
          )}

          <Text color="textDark" fontSize="primary" paddingY="small" fontWeight={3}>
            <FormattedMessage id="lbl.or_sign_up_with_invoice_number" />
          </Text>

          <Flex flexDirection="column" mb="0.375rem">
            <Input
              onChange={() => clearError(["phoneNumber", "accountCode"])}
              onFocus={() => clearError(["phoneNumber", "accountCode"])}
              ref={register({
                validate: {
                  anyValue: anyValueValidation,
                },
              })}
              disabled={watch("accountCode") || watch("phoneNumber")}
              variant={errors.invoiceNumber ? "inputError" : "input"}
              name="invoiceNumber"
              type="text"
              maxLength="24"
              style={{ paddingRight: "2.2rem" }}
            />
            <Tooltip
              value={intl.formatMessage({ id: "lbl.invoice_number_hint" })}
              mt="-2.4rem"
              alignSelf="flex-end"
              sx={{ whiteSpace: "nowrap" }}
              childrenPY="small">
              <SelfcareIcon name="question-mark" width="2.75rem" height="1.125rem" />
            </Tooltip>
          </Flex>
          {errors.invoiceNumber && errors.invoiceNumber.message !== "" && (
            <FormErrorMessage>
              <FormattedMessage id={errors.invoiceNumber.message} />
            </FormErrorMessage>
          )}

          <SelfcareButton type="submit" width="100%" mt="large" variant="primary">
            <FormattedMessage id="lbl.get_sms_with_verification_code" />
          </SelfcareButton>

          {isError && (
            <StyledModalMessage
              isOpen={isError}
              message={<GetErrorDescription error={error} />}
              onRequestClose={resetError}
              type="error">
              <SelfcareButton variant="secondarySmall" onClick={resetError}>
                <FormattedMessage id="lbl.Try_Again" />
              </SelfcareButton>
            </StyledModalMessage>
          )}
        </form>
      </>
      <>
        <Heading
          fontWeight={4}
          fontSize="presentationTitle"
          lineHeight="solid"
          color="textLight"
          textAlign={["center", "left", "left"]}
          paddingBottom="3rem">
          <FormattedMessage id="lbl.already_a_member" />
        </Heading>
        <AuthorizationHandler />
      </>
    </LoginLayout>
  );
};
export default Registration;
