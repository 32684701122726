import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Flex, Heading, Text } from "rebass/styled-components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import NavigationMenu from "../../components/NavigationMenu";
import MobileNavigationMenu from "../../components/MobileNavigationMenu";
import Breadcrumbs from "../../components/Breadcrumbs";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { useParams, matchPath, useLocation } from "react-router-dom";
import { getDateWithoutTime, isEmpty } from "../../common/Utilities";
import { useGET, usePOST } from "../../hooks/restAPI";
import { FormattedMessage, useIntl } from "react-intl";
import OpenTroubleTicket from "../../components/OpenTroubleTicket";
import { BrandingContext } from "../../contexts/BrandingContext";

const Layout = ({
  children,
  mobileTabSubMenu,
  disableNavMenuDefaultLocation,
  isUpdatePackage,
  isMasterAccount,
  displayOnlyBurgerMenu = false,
}) => {
  const {
    accountCode: rootAccountCode,
    billable: isBillable,
    serviceAgreement: isLoggedInAsServiceAgreement,
    isSubAccount,
    currentAccountDetails,
    masterAccount,
  } = useContext(AuthContext);

  const { accountCode: accountCodePath } = useParams();
  const accountCode = accountCodePath ? accountCodePath : rootAccountCode;
  const [isOpenTroubleTicketModal, setOpenTroubleTicketModal] = useState(false);
  const { config } = useContext(BrandingContext);
  const [, doPostAcctMessage] = usePOST({});
  const [accountMessageData, getAccountMessage] = useGET({});
  const intl = useIntl();
  const location = useLocation();

  const errorMatch = matchPath(location.pathname, {
    path: "/error",
  });

  /**
   * Commercial messages logic
   */
  const [acctMsgDismissed, setAcctMsgDismissed] = useState(false);

  const isValidForDisplay = (message, expirationDate) => {
    return (
      !isEmpty(message) &&
      getDateWithoutTime(new Date()).getTime() <= getDateWithoutTime(expirationDate).getTime()
    );
  };

  // Get the commercial message for root
  useEffect(() => {
    if (!displayOnlyBurgerMenu && !isSubAccount) {
      getAccountMessage({
        route: `/account/${rootAccountCode}/accountMessage`,
      });
    }
  }, [displayOnlyBurgerMenu, getAccountMessage, isSubAccount, rootAccountCode]);

  // Get the commercial message for descendant MAs
  useEffect(() => {
    if (!displayOnlyBurgerMenu && isSubAccount && isMasterAccount) {
      getAccountMessage({
        route: `/account/${accountCodePath}/accountMessage`,
      });
    }
  }, [displayOnlyBurgerMenu, isSubAccount, getAccountMessage, isMasterAccount, accountCodePath]);

  // Get the commercial message for SAs
  useEffect(() => {
    if (!displayOnlyBurgerMenu && isSubAccount && !isMasterAccount && currentAccountDetails) {
      getAccountMessage({
        route: `/account/${currentAccountDetails.parentAccountCode}/accountMessage`,
      });
    }
  }, [
    displayOnlyBurgerMenu,
    isSubAccount,
    currentAccountDetails,
    getAccountMessage,
    isMasterAccount,
  ]);

  // Dismiss displayed commercial message
  const onDismissAccountMessage = () => {
    const code = !isSubAccount
      ? rootAccountCode
      : isMasterAccount
      ? accountCode
      : currentAccountDetails.parentAccountCode;
    doPostAcctMessage({
      route: `/account/${code}/dismissAccountMessage`,
      expectedResponse: "none",
    });
    setAcctMsgDismissed(true);
  };

  const displayCommercialMessage =
    !acctMsgDismissed &&
    accountMessageData.isSuccess &&
    isValidForDisplay(
      accountMessageData.data.messageBody,
      accountMessageData.data.messageExpirationDate
    );

  const isChildOfRoot =
    currentAccountDetails && currentAccountDetails.parentAccountCode === rootAccountCode;

  const menuItems = isBillable
    ? [
        {
          title: "lbl.menu_home",
          icon: { desktop: "home", mobile: "home-mobile" },
          location: "/home",
        },
        {
          title: "lbl.menu_account_summary",
          icon: { desktop: "account-summary", mobile: "account-summary-mobile" },
          location: "/summary",
        },
        {
          title: "lbl.finance_transactions",
          icon: { desktop: "invoice-and-payments", mobile: "invoice-and-payments-mobile" },
          location: "/billing",
        },
      ]
    : [];

  const billableSubAccountMenuItems = currentAccountDetails && {
    masterAccount: [
      {
        title: "lbl.btn_back",
        icon: { desktop: "back", mobile: "back-mobile" },
        location: isChildOfRoot
          ? "/home"
          : `/account/${currentAccountDetails.parentAccountCode}/overview`,
      },
      {
        title: "lbl.menu_account_summary",
        icon: { desktop: "account-summary", mobile: null },
        location: `/account/${accountCode}/summary`,
      },
    ],
    serviceAgreement: [
      {
        title: "lbl.btn_back",
        icon: { desktop: "back", mobile: "back-mobile" },
        location: isChildOfRoot
          ? "/home"
          : `/account/${currentAccountDetails.parentAccountCode}/overview`,
      },
      {
        title: "lbl.service_details",
        icon: "overview",
        location: "/",
      },
      {
        title: "lbl.payments",
        icon: "make-payment",
        location: "/",
      },
      {
        title: "lbl.balance",
        icon: "balance",
        location: "/",
      },
      {
        title: "lbl.finance_transactions",
        icon: "invoice-and-payments",
        location: "/",
      },
    ],
  };

  const nonBillableSubAccountMenuItems = currentAccountDetails && {
    masterAccount: [
      {
        title: "lbl.btn_back",
        icon: { desktop: "back", mobile: "back-mobile" },
        location: isChildOfRoot
          ? "/home"
          : `/account/${currentAccountDetails.parentAccountCode}/overview`,
      },
      {
        title: "lbl.menu.overview",
        icon: { desktop: "overview", mobile: null },
        location: `/account/${accountCode}/overview`,
      },
    ],
    serviceAgreement: [
      {
        title: "lbl.btn_back",
        icon: { desktop: "back", mobile: "back-mobile" },
        location: isChildOfRoot
          ? "/home"
          : `/account/${currentAccountDetails.parentAccountCode}/overview`,
      },
    ],
  };

  const updatePackageMenuItems = [
    {
      title: "lbl.btn_back",
      icon: { desktop: "back", mobile: "back-mobile" },
      location: !isLoggedInAsServiceAgreement ? `/account/${accountCode}/overview` : "/home",
    },
  ];
  let items = [];
  let displayNavigationMenu = false;
  if (!displayOnlyBurgerMenu) {
    items = !isSubAccount
      ? menuItems
      : currentAccountDetails.billable && isMasterAccount
      ? billableSubAccountMenuItems.masterAccount
      : currentAccountDetails.billable && !isMasterAccount
      ? billableSubAccountMenuItems.serviceAgreement
      : !currentAccountDetails.billable && isMasterAccount
      ? nonBillableSubAccountMenuItems.masterAccount
      : nonBillableSubAccountMenuItems.serviceAgreement;

    if (isUpdatePackage) {
      items = updatePackageMenuItems;
    }
    displayNavigationMenu = isBillable || isUpdatePackage || isSubAccount;
  }

  return (
    <Flex bg="windowBg" flexDirection="column" minHeight="100vh" flex={1}>
      <Header />
      <Flex
        width="100%"
        maxWidth="layoutContentMaxWidth"
        marginLeft="auto"
        marginRight="auto"
        px="default"
        py="large"
        flexDirection="column">
        {displayCommercialMessage && (
          <Box
            mb="default"
            sx={{
              backgroundImage: `url(${require(`../../resources/images/banner.png`)})`,
              backgroundSize: "100%",
            }}>
            <Flex justifyContent="flex-end">
              <Text
                as="span"
                mx="default"
                mt="small"
                sx={{
                  color: "textLight",
                  float: "right",
                  fontSize: "title",
                  lineHeight: "solid",
                  fontWeight: "bold",
                  ":hover": {
                    cursor: "pointer",
                    opacity: "70%",
                  },
                }}
                onClick={onDismissAccountMessage}>
                &times;
              </Text>
            </Flex>
            <Heading mx="default" fontSize="title" color="textLight" textAlign="center">
              <FormattedMessage id="lbl.important_message" />
            </Heading>
            <Text mx="default" mt="default" color="textLight" fontSize="primary" textAlign="center">
              {accountMessageData.data.messageBody}
            </Text>
            {config.troubleTicket && config.troubleTicket.service && (
              <Flex justifyContent="center" my="default">
                <Button
                  type="button"
                  sx={{
                    outline: "none",
                    px: "default",
                    border: "1px solid",
                    borderRadius: "default",
                    borderColor: "textLight",
                    cursor: "pointer",
                    fontSize: "primary",
                    fontFamily: "roboto, sans-serif",
                    bg: "secondary",
                    "&:hover": {
                      color: "secondary",
                      bg: "windowBg",
                      borderColor: "inactive",
                    },
                  }}
                  onClick={() => {
                    setOpenTroubleTicketModal(true);
                  }}>
                  <FormattedMessage id="lbl.make_inquiry" />
                </Button>
                <OpenTroubleTicket
                  config={config.troubleTicket.service}
                  title={<FormattedMessage id="lbl.make_inquiry" />}
                  placeholderMessage={intl.formatMessage({
                    id: "lbl.trouble_ticket_inquiry_comments_placeholder",
                  })}
                  commercialMessage={accountMessageData.data.messageBody}
                  isOpenModal={isOpenTroubleTicketModal}
                  setOpenModal={setOpenTroubleTicketModal}
                />
              </Flex>
            )}
          </Box>
        )}
        {displayNavigationMenu && (
          <NavigationMenu
            menuItems={items}
            disableNavMenuDefaultLocation={disableNavMenuDefaultLocation}
            sx={{
              width: !isUpdatePackage
                ? !isSubAccount
                  ? "100%"
                  : !isMasterAccount && !currentAccountDetails.billable
                  ? "25%"
                  : !currentAccountDetails.billable
                  ? "50%"
                  : "50%"
                : "25%",
            }}
          />
        )}
        {isSubAccount && !isLoggedInAsServiceAgreement && !errorMatch && <Breadcrumbs />}
        {children}
      </Flex>
      <Footer />
      {mobileTabSubMenu}
      <MobileNavigationMenu menuItems={items} isLoading={!masterAccount} />
    </Flex>
  );
};

export default Layout;
