import React from "react";
export default ({ edge = "#346eb3", text = "#318eff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.724"
    height="28.024"
    viewBox="0 0 20.724 28.024"
    {...props}>
    <g transform="translate(-0.038 -0.038)">
      <path
        fill="none"
        stroke={edge}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.124px"
        d="M.6.6V25.4l1.9,2.1,2-2.1,2,2.1,1.9-2.1,2,2.1,2-2.1,1.9,2.1,2-2.1,2,2.1,1.9-2.1V8.3L12.5.6Z"
      />
      <path
        fill="none"
        stroke={edge}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.124px"
        d="M12.5.6V8.3h7.7Z"
      />
      <rect fill={text} width="15.2" height="1.4" transform="translate(2.8 12.6)" />
      <rect fill={text} width="15.2" height="1.4" transform="translate(2.8 15.3)" />
      <rect fill={text} width="15.2" height="1.4" transform="translate(2.8 18.1)" />
    </g>
  </svg>
);
