import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Flex, Text, Link } from "rebass/styled-components";
import { BrandingContext } from "../../contexts/BrandingContext";
import { beautifyURL } from "../../common/Utilities";

const MobileFooterSection = props => {
  return (
    <Flex
      {...props}
      height="5rem"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bg="primary"
      sx={{
        borderBottom: "1px solid",
        borderBottomColor: "contentBg",
      }}
    />
  );
};

const MobileFooter = () => {
  const { config } = useContext(BrandingContext);
  return (
    <Flex flexDirection="column" bg="primary">
      <MobileFooterSection>
        <Text fontWeight="bold" fontSize="subTitle" color="textLight" mb="tiny">
          <FormattedMessage id="lbl.give_us_a_call" />
        </Text>
        <Text fontSize="primary" color="textLight">
          {config.phoneNumber}
        </Text>
      </MobileFooterSection>
      <MobileFooterSection>
        <Text fontWeight="bold" fontSize="subTitle" color="textLight" mb="tiny">
          <FormattedMessage id="lbl.pay_us_a_visit" />
        </Text>
        <Link target="_blank" fontSize="primary" color="textLight" href={config.website}>
          {beautifyURL(config.website)}
        </Link>
      </MobileFooterSection>
      <MobileFooterSection>
        <Link
          href={config.terms_and_conditions}
          target="_blank"
          fontSize="primary"
          color="textLight"
          sx={{
            textDecoration: "none",
          }}>
          <FormattedMessage id="lbl.terms_of_use" />
        </Link>
      </MobileFooterSection>
    </Flex>
  );
};

export default MobileFooter;
