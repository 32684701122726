import React from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { Input } from "@rebass/forms/styled-components";
import ShowPassword from "../ShowPassword";
import * as ServiceUtils from "./ServiceUtils";

const ServiceDetails = ({ serviceDetails }) => {
  let accessKeyRef = React.useRef();

  if (!serviceDetails) {
    return null;
  }

  return (
    <Box marginX={"large"} marginY={"small"}>
      {ServiceUtils.isShowUserCode(serviceDetails) && (
        <Flex flexDirection={["column", "row", "row"]} width="100%">
          <Text color="textDark" fontSize="secondary" marginY="small" width="17rem" fontWeight={4}>
            {serviceDetails.keyLabel}
          </Text>
          <Box width="17rem !important">
            <Text color="textDark" sx={{ wordWrap: "break-word" }} maxLength="100" name="userCode">
              {serviceDetails.key}
            </Text>
          </Box>
        </Flex>
      )}

      {serviceDetails.basicType === "MAIL" && (
        <Flex flexDirection={["column", "row", "row"]} width="100%">
          <Text color="textDark" fontSize="secondary" marginY="small" width="17rem" fontWeight={4}>
            {serviceDetails.emailUsernameLabel}
          </Text>
          <Box width="17rem !important">
            <Text color="textDark" sx={{ wordWrap: "break-word" }} maxLength="100" name="userCode">
              {serviceDetails.emailUsername}
            </Text>
          </Box>
        </Flex>
      )}

      {ServiceUtils.isShowAccessKey(serviceDetails) && (
        <Flex flexDirection={["column", "row", "row"]} width="100%">
          <Text color="textDark" fontSize="secondary" marginY="small" width="17rem" fontWeight={4}>
            {serviceDetails.accessKeyLabel}
          </Text>

          <Box width="17rem !important">
            <Input
              defaultValue={serviceDetails.accessKey}
              ref={accessKeyRef}
              disabled={true}
              variant="greyInput"
              maxLength="100"
              name="accessKey"
              type="password"
            />
            <ShowPassword refInput={accessKeyRef} mt={"-1.6rem"} />
          </Box>
        </Flex>
      )}

      {serviceDetails.udfs && (
        <>
          {serviceDetails.udfs.map((udf, index) => {
            if (!udf.value) {
              return null;
            }
            return (
              <Flex key={udf.name + index} flexDirection={["column", "row", "row"]} width="100%">
                <Text
                  color="textDark"
                  fontSize="secondary"
                  marginY="small"
                  width="17rem"
                  fontWeight={4}>
                  {udf.label}
                </Text>
                <Box width="17rem !important">
                  <Text color="textDark" sx={{ wordWrap: "break-word" }}>
                    {udf.value}
                  </Text>
                </Box>
              </Flex>
            );
          })}
        </>
      )}
    </Box>
  );
};

export default ServiceDetails;
