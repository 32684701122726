import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { useGET, usePUT } from "../../hooks/restAPI";
import ModalPage from "../../components/ModalPage";
import { SelfcareButton } from "../../components/base";
import StyledModalMessage from "../../components/StyledModalMessage";
import GetErrorDescription from "../../components/GetErrorDescription";
import { Box, Heading, Text } from "rebass/styled-components";
import { Input, Select } from "@rebass/forms/styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const EditAccountBillingModal = ({
  shipmentContact,
  currentShipmentMethod,
  address,
  isEditBilling,
  onEditBillingSuccess,
  onEditBillingClose,
}) => {
  const intl = useIntl();
  const { accountCode: masterAccountCode } = useContext(AuthContext);
  const { accountCode: accountCodePath } = useParams();

  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });
  let [doPutShipmentMethodData, doPutShipmentMethod, resetPutShipmentMethodError] = usePUT({});
  const [shipmentMethodsData, getShipmentMethods] = useGET({});

  // possible options from select
  const [shipmentMethodsOptions, setShipmentMethodsOptions] = useState([]);
  // selected shipment method
  const [shipmentMethod, setShipmentMethod] = useState({});

  useEffect(() => {
    const loadInitialData = () => {
      getShipmentMethods({
        route: `/account/${accountCode}/shipmentMethods`,
      });
    };

    loadInitialData();
  }, [accountCode, getShipmentMethods]);

  useEffect(() => {
    const calculateShipmentMethodsList = () => {
      let options = [];
      if (shipmentMethodsData.data.invoiceShipmentOptions) {
        const mapAsFeatureToOption = elem => {
          return (
            <option key={elem.code} value={elem.code}>
              {elem.description}
            </option>
          );
        };

        let noShipOption = [];
        let otherShipOptions = [];
        for (let asFeature of shipmentMethodsData.data.invoiceShipmentOptions) {
          if (asFeature.code.toLocaleLowerCase().indexOf("noship") !== -1) {
            noShipOption.push(mapAsFeatureToOption(asFeature));
          } else {
            otherShipOptions.push(mapAsFeatureToOption(asFeature));
          }
        }

        options = options.concat(noShipOption);
        options = options.concat(otherShipOptions);
      }

      return options;
    };

    if (shipmentMethodsData.isSuccess) {
      setShipmentMethodsOptions(calculateShipmentMethodsList());
      setShipmentMethod(currentShipmentMethod);
    }
  }, [
    currentShipmentMethod,
    intl,
    shipmentMethodsData.data.invoiceShipmentOptions,
    shipmentMethodsData.isSuccess,
  ]);

  useEffect(() => {
    const doAfterSuccessfulSave = () => {
      if (doPutShipmentMethodData.isSuccess) {
        onEditBillingSuccess();
      }
    };

    doAfterSuccessfulSave();
  }, [doPutShipmentMethodData.isSuccess, onEditBillingSuccess]);

  const onSaveBilling = () => {
    let updateShipmentMethodRequest = {
      invoiceOption: shipmentMethod,
    };

    doPutShipmentMethod({
      route: `/account/${accountCode}/shipmentMethod`,
      expectedResponse: "none",
      body: updateShipmentMethodRequest,
    });
  };

  return (
    <ModalPage isOpen={isEditBilling} onRequestClose={onEditBillingClose}>
      <form onSubmit={handleSubmit(onSaveBilling)}>
        <Box px="larger" py="larger">
          <Heading
            as="h3"
            fontWeight={5}
            pb="medium"
            fontSize="title"
            color="textDark"
            textAlign="left">
            <FormattedMessage id="lbl.billing_information" />
          </Heading>

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.first_name" />
          </Text>

          <Input
            defaultValue={shipmentContact.name.first}
            ref={register({ required: true })}
            disabled={true}
            variant={errors.firstName ? "greyInputError" : "greyInput"}
            name="firstName"
            maxLength="100"
            type="text"
            marginBottom="medium"
          />

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.middle_name" />
          </Text>
          <Input
            defaultValue={shipmentContact.name.middle}
            ref={register}
            disabled={true}
            variant={errors.middleName ? "greyInputError" : "greyInput"}
            name="middleName"
            maxLength="60"
            type="text"
            marginBottom="medium"
          />

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.last_name" />
          </Text>
          <Input
            defaultValue={shipmentContact.name.last}
            ref={register({ required: true })}
            disabled={true}
            variant={errors.lastName ? "greyInputError" : "greyInput"}
            maxLength="100"
            name="lastName"
            type="text"
            marginBottom="medium"
          />

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.address" />
          </Text>
          <Input
            defaultValue={address}
            ref={register}
            disabled={true}
            variant={errors.address ? "greyInputError" : "greyInput"}
            maxLength="100"
            name="address"
            type="text"
            marginBottom="medium"
          />

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.inv_ship_met" />
          </Text>

          <Box marginBottom="medium">
            <Select
              name="shipmentMethod"
              width="100%"
              value={shipmentMethod}
              ref={register}
              onChange={event => {
                setShipmentMethod(event.target.value);
              }}
              variant={errors.shipmentMethod ? "selectError" : "select"}
              sx={{
                borderColor: "greyBorderInput",
                "&:focus": {
                  outline: "none",
                },
              }}>
              {shipmentMethodsOptions}
            </Select>
          </Box>

          <Box width="auto" mt="larger" textAlign="right" mb="huge">
            <SelfcareButton type="submit" variant="default" mr="spaceBetweenButtons">
              <FormattedMessage id="lbl.save" />
            </SelfcareButton>

            <SelfcareButton variant="default-inverted" onClick={onEditBillingClose}>
              <FormattedMessage id="lbl.cancel" />
            </SelfcareButton>
          </Box>

          {doPutShipmentMethodData.isError && (
            <StyledModalMessage
              isOpen={doPutShipmentMethodData.isError}
              message={<GetErrorDescription error={doPutShipmentMethodData.error} />}
              onRequestClose={resetPutShipmentMethodError}
              type="error">
              <SelfcareButton variant="secondarySmall" onClick={resetPutShipmentMethodError}>
                <FormattedMessage id="lbl.Try_Again" />
              </SelfcareButton>
            </StyledModalMessage>
          )}
        </Box>
      </form>
    </ModalPage>
  );
};

export default EditAccountBillingModal;
