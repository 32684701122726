export const FIRST_TIMELINE_EVENT_SIZE = 111;
export const TIMELINE_EVENT_SIZE = 186;

export const computeTimelineEventsPositions = billingSummary => {
  let timelinePositions = {
    invoice: -1,
    payment: -1,
    balance: -1,
    due: -1,
  };

  const hasInvoice =
    billingSummary.lastInvoiceFullNumber &&
    billingSummary.lastInvoiceFullNumber !== "" &&
    billingSummary.lastInvoiceDate != null &&
    billingSummary.lastInvoiceDate !== undefined;
  let hasPayment =
    billingSummary.lastPaymentReceiptNumber > 0 &&
    billingSummary.lastPaymentDate != null &&
    billingSummary.lastPaymentDate !== undefined;

  let currentFreePosition = 0;

  if (hasInvoice) {
    let dates = [
      { event: "invoice", date: new Date(billingSummary.lastInvoiceDate) },
      { event: "balance", date: new Date() },
      { event: "due", date: new Date(billingSummary.lastInvoiceDueDate) },
    ];
    if (hasPayment) {
      dates.push({ event: "payment", date: new Date(billingSummary.lastPaymentDate) });
    }

    dates.sort((a, b) => a.date - b.date);
    dates.forEach(date => (timelinePositions[date.event] = currentFreePosition++));
  } else {
    if (hasPayment) {
      timelinePositions.payment = currentFreePosition++;
    }
    timelinePositions.balance = currentFreePosition++;
  }

  return timelinePositions;
};
