import React from "react";

export default ({ color = "#336EB4", ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 42 31.9"
    {...props}>
    <path
      id="Path_3364"
      fill={color}
      d="M12.5,5.1V0L0,7.4l12.5,7.3V9.3h25.1v18.1H12.5v4.4H42V5.1H12.5z"
    />
  </svg>
);
