import React from "react";
import { Box, Heading } from "rebass/styled-components";

import FUBGroupTitleSkeleton from "./FUBGroupTitleSkeleton";
import { FUBCard, FUBCardSkeleton } from "../FUBCard";

const FUBGroup = ({
  title,
  titleSize = "title",
  fubs,
  isLoading,
  showExpirationDate = false,
  computeExpirationDateMessage,
  ...props
}) => {
  const itemsPerRow = () => {
    const browserFontSize = parseFloat(
      getComputedStyle(document.body, null).getPropertyValue("font-size")
    );
    if (window.innerWidth <= 40 * browserFontSize) {
      return 2;
    }
    if (window.innerWidth <= 64.1 * browserFontSize) {
      return 4;
    }
    return 6;
  };

  if (isLoading) {
    return (
      <Box {...props}>
        <Box my={["default", "default", "medium"]}>
          <FUBGroupTitleSkeleton />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridGap: ["large", "default", "default"],
            gridTemplateColumns: ["repeat(2, 1fr)", "repeat(4, 1fr)", "repeat(6, 1fr)"],
            gridAutoRows: "1fr",
          }}>
          {[...Array(itemsPerRow()).keys()].map(index => (
            <Box
              key={index}
              bg="contentBg"
              sx={{
                boxShadow: "light",
                borderRadius: "card",
              }}>
              <FUBCardSkeleton />
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  const displayTitle = !fubs || fubs.length > 0;

  return (
    <Box {...props}>
      {displayTitle && (
        <Heading
          my={["default", "default", "medium"]}
          color="textDark"
          fontSize={titleSize}
          textAlign={["center", "left", "left"]}>
          {title}
        </Heading>
      )}
      <Box
        sx={{
          display: "grid",
          gridGap: ["large", "default", "default"],
          gridTemplateColumns: ["repeat(2, 1fr)", "repeat(4, 1fr)", "repeat(6, 1fr)"],
          gridAutoRows: "1fr",
        }}>
        {fubs &&
          fubs.length > 0 &&
          fubs.map((fub, index) => (
            <FUBCard
              key={index}
              fub={fub}
              showExpirationDate={showExpirationDate}
              computeExpirationDateMessage={computeExpirationDateMessage}
            />
          ))}
      </Box>
    </Box>
  );
};

export default FUBGroup;
