import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Text, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { AuthContext } from "../../contexts/AuthorizationContext";

const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

const Breadcrumbs = () => {
  const hierarchy = JSON.parse(window.localStorage.getItem("hierarchy"));
  const { accountCode: masterAccountCode } = useContext(AuthContext);

  return (
    <Flex flexDirection="row" flexWrap="wrap" fontSize="primary" mt="default">
      {hierarchy.map((account, index) => {
        const isLast = index === hierarchy.length - 1;
        const divider = !isLast ? ">" : "";
        const url = `/account/${account.code}/overview`;
        return (
          <>
            <StyledLink
              key={url}
              to={account.code === masterAccountCode ? "/home" : url}
              replace
              style={{ pointerEvents: isLast ? "none" : "initial" }}>
              <Text color={isLast ? "textDark" : "secondary"} fontWeight={"5"}>{account.title}</Text>
            </StyledLink>
            <Text color="textDark" mx="small">
              {divider}
            </Text>
          </>
        );
      })}
    </Flex>
  );
};

export default Breadcrumbs;
