import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Flex, Text } from "rebass/styled-components";
import { useParams, useHistory } from "react-router-dom";
import { InvoiceCard, InvoiceSkeleton } from "../../components/InvoiceCard";
import { useGET } from "../../hooks/restAPI";
import { AuthContext } from "../../contexts/AuthorizationContext";
import StyledModalMessage from "../../components/StyledModalMessage";
import GetErrorDescription from "../../components/GetErrorDescription";
import { SelfcareButton } from "../../components/base";
import Billing from "../Billing";

const MAX_DISPLAYED_INVOICES = 6;

const Invoices = () => {
  const history = useHistory();
  const { accountCode: masterAccountCode } = useContext(AuthContext);
  const { accountCode: accountCodePath } = useParams();

  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;

  const [invoiceListData, doGetInvoiceList, resetGetInvoiceListError] = useGET({
    invoiceList: null,
  });
  const [displayAllInvoices, setdisplayAllInvoices] = useState(false);

  useEffect(() => {
    const getInvoicesListConfig = {
      route: `/account/${accountCode}/invoices`,
    };
    doGetInvoiceList(getInvoicesListConfig);
  }, [accountCode, doGetInvoiceList]);

  const tryAgain = () => {
    resetGetInvoiceListError();

    const getInvoicesListConfig = {
      route: `/account/${accountCode}/invoices`,
    };
    doGetInvoiceList(getInvoicesListConfig);
  };

  if (invoiceListData.isOffline) {
    history.push("/error");
  }

  const invoices = invoiceListData.data.invoiceList;

  const getRenderedInvoices = () => {
    return displayAllInvoices ? invoices : invoices.slice(0, MAX_DISPLAYED_INVOICES);
  };

  return (
    <Billing>
      {invoiceListData.isError && (
        <StyledModalMessage
          isOpen={invoiceListData.isError}
          message={<GetErrorDescription error={invoiceListData.error} />}
          onRequestClose={tryAgain}
          type="error">
          <SelfcareButton variant="secondarySmall" onClick={tryAgain}>
            <FormattedMessage id="lbl.Try_Again" />
          </SelfcareButton>
        </StyledModalMessage>
      )}
      {!invoiceListData.isError && invoices && invoices.length === 0 && (
        <Box mt={["0", "default", "default"]} sx={{ textAlign: "center" }}>
          <Text color="primary">
            <FormattedMessage id="lbl.no_invoices" />
          </Text>
          <Text color="note">
            <FormattedMessage id="lbl.no_invoices_subnote" />
          </Text>
        </Box>
      )}
      {!invoiceListData.isError && invoices && invoices.length > 0 && (
        <Flex flexDirection="column" width="100%">
          <Box
            mb="large"
            display="grid"
            sx={{
              gridGap: "large",
              gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"],
              gridAutoRows: "1fr",
            }}>
            {getRenderedInvoices().map(invoice => (
              <InvoiceCard
                key={invoice.invoiceId}
                id={invoice.invoiceId}
                number={invoice.invoiceNumber}
                fromDate={invoice.startDate}
                toDate={invoice.endDate}
                dueDate={invoice.dueDate}
                unpaidAmount={invoice.remainingAmount}
                value={invoice.value}
              />
            ))}
            {invoiceListData.isLoading &&
              [...Array(MAX_DISPLAYED_INVOICES).keys()].map(index => (
                <Box
                  key={index}
                  bg="contentBg"
                  sx={{
                    boxShadow: "light",
                    borderRadius: "card",
                  }}>
                  <InvoiceSkeleton />
                </Box>
              ))}
          </Box>
          {!invoiceListData.isError && invoices && invoices.length > MAX_DISPLAYED_INVOICES && (
            <SelfcareButton
              alignSelf="center"
              onClick={() => setdisplayAllInvoices(!displayAllInvoices)}
              px="0 !important"
              variant="tertiary">
              {displayAllInvoices ? (
                <FormattedMessage id="lbl.show_less" />
              ) : (
                <FormattedMessage id="lbl.show_more" />
              )}
            </SelfcareButton>
          )}
        </Flex>
      )}
    </Billing>
  );
};

export default Invoices;
