import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Flex, Text } from "rebass/styled-components";
import { useParams, useHistory } from "react-router-dom";
import { PaymentCard, PaymentSkeleton } from "../../components/PaymentCard";
import { useGET } from "../../hooks/restAPI";
import { AuthContext } from "../../contexts/AuthorizationContext";
import StyledModalMessage from "../../components/StyledModalMessage";
import GetErrorDescription from "../../components/GetErrorDescription";
import { SelfcareButton } from "../../components/base";

import Billing from "../Billing";

const MAX_DISPLAYED_PAYMENTS = 6;

const Payments = () => {
  const history = useHistory();
  const { accountCode: masterAccountCode } = useContext(AuthContext);
  const { accountCode: accountCodePath } = useParams();

  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;

  const [paymentsData, doGetPayments, resetGetPaymentsError] = useGET({ transactionList: null });
  const [displayAllPayments, setDisplayAllPayments] = useState(false);

  useEffect(() => {
    const getPaymentsConfig = {
      route: `/account/${accountCode}/transactions`,
      noTimeout: true,
    };
    doGetPayments(getPaymentsConfig);
  }, [accountCode, doGetPayments]);

  const tryAgain = () => {
    resetGetPaymentsError();

    const getPaymentsConfig = {
      route: `/account/${accountCode}/transactions`,
      noTimeout: true,
    };
    doGetPayments(getPaymentsConfig);
  };

  if (paymentsData.isOffline) {
    history.push("/error");
  }

  const payments = paymentsData.data.transactionList;

  const getRenderedPayments = () => {
    return displayAllPayments ? payments : payments.slice(0, MAX_DISPLAYED_PAYMENTS);
  };

  return (
    <Billing>
      {paymentsData.isError && (
        <StyledModalMessage
          isOpen={paymentsData.isError}
          message={<GetErrorDescription error={paymentsData.error} />}
          onRequestClose={tryAgain}
          type="error">
          <SelfcareButton variant="secondarySmall" onClick={tryAgain}>
            <FormattedMessage id="lbl.Try_Again" />
          </SelfcareButton>
        </StyledModalMessage>
      )}
      {!paymentsData.isError && payments && payments.length === 0 && (
        <Box mt={["0", "default", "default"]} sx={{ textAlign: "center" }}>
          <Text color="primary">
            <FormattedMessage id="lbl.no_payments" />
          </Text>
          <Text color="note">
            <FormattedMessage id="lbl.no_payments_subnote" />
          </Text>
        </Box>
      )}
      {!paymentsData.isError && payments && payments.length > 0 && (
        <Flex flexDirection="column" width="100%">
          <Box
            mb="large"
            display="grid"
            sx={{
              gridGap: "large",
              gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"],
              gridAutoRows: "1fr",
            }}>
            {getRenderedPayments().map(payment => (
              <PaymentCard key={payment.transactionNumber} payment={payment} />
            ))}
            {paymentsData.isLoading &&
              [...Array(MAX_DISPLAYED_PAYMENTS).keys()].map(index => (
                <Box
                  key={index}
                  bg="contentBg"
                  sx={{
                    boxShadow: "light",
                    borderRadius: "card",
                  }}>
                  <PaymentSkeleton />
                </Box>
              ))}
          </Box>
          {!paymentsData.isError && payments && payments.length > MAX_DISPLAYED_PAYMENTS && (
            <SelfcareButton
              alignSelf="center"
              onClick={() => setDisplayAllPayments(!displayAllPayments)}
              px="0 !important"
              variant="tertiary">
              {displayAllPayments ? (
                <FormattedMessage id="lbl.show_less" />
              ) : (
                <FormattedMessage id="lbl.show_more" />
              )}
            </SelfcareButton>
          )}
        </Flex>
      )}
    </Billing>
  );
};

export default Payments;
