import React, { useEffect, useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Text } from "rebass/styled-components";
import qs from "qs";

import { OAuthCredentials } from "../../common/Deployment";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { usePOST } from "../../hooks/restAPI";
import AuthorizationSender from "./AuthorizationSender";
import { SelfcareButton } from "../base";
import { FormattedMessage, useIntl } from "react-intl";
import StyledModalMessage from "../StyledModalMessage";

const AuthorizationHandler = ({
  signInVariant = "secondary",
  hideSignIn = false,
  errorHandler = () => {},
}) => {
  const intl = useIntl();
  const { search } = useLocation();
  const history = useHistory();
  const { login, codeVerifier } = useContext(AuthContext);
  let { code, error, error_description } = qs.parse(search, { ignoreQueryPrefix: true });

  const [errorDisplay, setErrorDisplay] = useState({ show: error, description: error_description });
  const [authCode, setAuthCode] = useState(code);

  const { tokenURL, clientID, clientSecret, redirectURI } = OAuthCredentials;
  const [tokenResponse, getToken] = usePOST(null);

  useEffect(() => {
    const tokenRequest = {
      route: tokenURL,
      isForm: true,
      authorization: { type: "Basic", token: btoa(clientID + ":" + clientSecret) },
      body: {
        grant_type: "authorization_code",
        code: authCode,
        redirect_uri: window.location.origin + redirectURI,
        client_id: clientID,
        code_verifier: codeVerifier,
      },
    };
    authCode && getToken(tokenRequest);
  }, [tokenURL, authCode, redirectURI, clientID, clientSecret, getToken, codeVerifier]);

  useEffect(() => {
    if (tokenResponse.isSuccess) {
      history.replace("/home");
    }
  }, [history, tokenResponse.isSuccess]);

  if (tokenResponse.isSuccess) {
    login({
      accessToken: tokenResponse.data.access_token,
      refreshToken: tokenResponse.data.refresh_token,
      expirationDate: new Date().getTime() + tokenResponse.data.expires_in * 1000,
    });
  }

  useEffect(() => {
    if (tokenResponse.isError) {
      setAuthCode(null);
      setErrorDisplay({ show: true, description: intl.formatMessage({ id: "err.error_page_generic" }) });
    }
  }, [tokenResponse.isError, tokenResponse.error, intl]);

  return (
    <>
      {!authCode && !hideSignIn && (
        <AuthorizationSender>
          <SelfcareButton variant={signInVariant}>
            <FormattedMessage id="lbl.sign_in" />
          </SelfcareButton>
        </AuthorizationSender>
      )}

      <StyledModalMessage
        isOpen={errorDisplay.show}
        message={errorDisplay.description}
        onRequestClose={() => setErrorDisplay({ show: false })}
        type="error">
        <SelfcareButton
          variant="secondarySmall"
          onClick={() => {
            setErrorDisplay({ show: false });
            errorHandler();
          }}>
          <FormattedMessage id="lbl.Try_Again" />
        </SelfcareButton>
      </StyledModalMessage>

      {tokenResponse.isLoading && (
        <Text color="textLight" fontSize="primary" paddingY="small" fontWeight={3}>
          <FormattedMessage id="lbl.please_wait" />
        </Text>
      )}
    </>
  );
};

export default AuthorizationHandler;
