import React from "react";
export default ({
  mediumBlue = "#336eb4",
  darkBlue = "#114878",
  lightBlue = "#318eff",
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" {...props}>
    <path
      id="medium_blue"
      fill={mediumBlue}
      d="M80,48.5V31.5h-7.1c-0.8-3-2-6-3.6-8.7l5-5L62.2,5.7l-5,5c-2.7-1.6-5.6-2.8-8.7-3.6V0H31.5
	v7.1c-3,0.8-6,2-8.7,3.6l-5-5L5.7,17.8l5,5c-1.6,2.7-2.8,5.6-3.6,8.7H0v17.1h7.1c0.8,3,2,6,3.6,8.7l-5,5l12.1,12.1l5-5
	c2.7,1.6,5.6,2.8,8.7,3.6V80h17.1v-7.1c3-0.8,6-2,8.7-3.6l5,5l12.1-12.1l-5-5c1.6-2.7,2.8-5.6,3.6-8.7L80,48.5z M49.5,55.4
	c0,0,0,0.3,0,0.7h-19c0-0.4,0-0.7,0-0.7c-0.1-4.6-8-6.8-7.6-19c0-0.1,0-0.2,0.1-0.3c0.9-9.4,9.2-16.3,18.5-15.5
	c8.2,0.7,14.7,7.2,15.5,15.5c0,0.1,0.1,0.2,0.1,0.3C57.5,48.6,49.6,50.8,49.5,55.4z"
    />
    <path
      id="light_blue"
      fill={lightBlue}
      d="M47,32.2h-0.8c-2.6,0-4.8,2.2-4.7,4.8c0,0,0,0,0,0v2.4h-3V37c0-2.6-2.1-4.8-4.8-4.8H33
	c-2.6,0-4.8,2.1-4.8,4.7c0,0,0,0,0,0v0.8c0,2.6,2.2,4.7,4.8,4.7h2.4v18.2h3.1V42.5h3l0,0l0,0l0.1,18.2h3.1l-0.1-18.1H47
	c2.6,0,4.8-2.1,4.8-4.8V37C51.8,34.4,49.7,32.2,47,32.2C47,32.2,47,32.2,47,32.2z M31.3,37.7V37c0-0.9,0.7-1.6,1.6-1.7
	c0,0,0,0,0.1,0h0.8c0.9,0,1.6,0.8,1.6,1.7c0,0,0,0,0,0v2.4H33C32.1,39.4,31.4,38.7,31.3,37.7C31.3,37.8,31.3,37.7,31.3,37.7z
	 M48.7,37.7c0,0.9-0.7,1.6-1.6,1.6c0,0,0,0,0,0h-2.3V37c0-0.9,0.7-1.6,1.6-1.6H47c0.9,0,1.6,0.7,1.7,1.6c0,0,0,0,0,0V37.7z"
    />
    <path
      id="dark_blue"
      fill={darkBlue}
      d="M30.5,56.2c0,1,0.1,2.5,0.1,2.5c0,5.2,4.2,9.4,9.4,9.4s9.4-4.2,9.4-9.4c0,0,0-1.5,0.1-2.5H30.5z
	"
    />
  </svg>
);
