import React from "react";
import ContentLoader from "react-content-loader";

const FUBCardSkeleton = () => (
  <ContentLoader
    speed={2}
    viewBox="0 0 154 154"
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">
    <path
      d="M77,20.5c-25.1,0-45.5,20.4-45.5,45.5c0,10.5,3.6,20.2,9.6,28l0,0c0.1,0.2,0.2,0.4,0.5,0.5
	c8.3,10.3,21.1,17,35.4,17c25.1,0,45.5-20.4,45.5-45.5S102.1,20.5,77,20.5z M51.3,97.3c-0.5-0.4-1-0.9-1.5-1.3l-0.1-0.1
	c-0.5-0.4-0.9-0.9-1.3-1.3c0,0-0.1-0.1-0.1-0.2c-0.4-0.4-0.8-0.9-1.2-1.3L47,93c-0.4-0.5-0.8-0.9-1.2-1.4l0,0
	c-1.7-2.1-3.2-4.3-4.4-6.7l0,0c-3-5.6-4.6-12-4.6-18.8c0-22.3,18.2-40.5,40.5-40.5s40.5,18.2,40.5,40.5c0,6.7-1.7,13.1-4.6,18.7
	c-1.5,2.9-3.3,5.5-5.4,7.9c0,0,0,0-0.1,0.1c-0.7,0.8-1.4,1.6-2.2,2.3c-5.3,5.2-12,8.9-19.4,10.5c-0.5,0.1-1,0.2-1.6,0.3h-0.1
	c-0.5,0.1-1.1,0.2-1.6,0.3l0,0c-1.8,0.3-3.7,0.4-5.6,0.4C67.2,106.5,58.3,103,51.3,97.3z"
    />
    <rect x="28.5" y="120.4" width="96.9" height="21.9" />
    <path
      d="M102.6,55.8c-5.6-5.6-14.9-5.6-20.5,0l-3,3l5.2,5.2l3-3l0.1-0.1l0,0c2.8-2.7,7.2-2.7,10,0.1l0,0
	c2.8,2.8,2.8,7.3,0,10.1c-2.8,2.8-7.3,2.8-10.1,0L82.2,66l0,0l0,0l0,0L77,60.8l0,0l0,0l0,0l-5.1-5.1c-5.6-5.6-14.9-5.6-20.5,0
	c-5.6,5.6-5.6,14.9,0,20.5c5.6,5.6,14.9,5.6,20.5,0l3-3l-5.2-5.2l-3.1,3.1l0,0c-2.8,2.7-7.2,2.7-10-0.1c-2.8-2.8-2.8-7.3,0-10.1
	c2.8-2.8,7.3-2.8,10.1,0l0,0l3,3l7.3,7.3l0,0l5.1,5.1c5.6,5.6,14.9,5.6,20.5,0C108.2,70.6,108.2,61.4,102.6,55.8z"
    />
  </ContentLoader>
);

export default FUBCardSkeleton;
