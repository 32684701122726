import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Flex, Heading, Text } from "rebass/styled-components";
import { useHistory } from "react-router-dom";
import PackageNameSkeleton from "./PackageNameSkeleton";
import { SelfcareBox, SelfcareButton } from "../base";
import FUBGroup from "../FUBGroup";
import SASummary from "../SASummary";
import ServiceAgreementPackage from "../ServiceAgreementPackage";
import SubscriberInformation from "../SubscriberInformation";
import { getDaysUntil } from "../../common/Utilities";
import { BrandingContext } from "../../contexts/BrandingContext";

const ServiceAgreementPrepaid = ({
  account,
  accountIsLoading,
  beneficiaryUnits,
  beneficiaryUnitsIsLoading,
  additionalSrv,
  configuration,
  ...props
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { config } = useContext(BrandingContext);

  const fubExpirationMessage = fub => {
    let remainingDays = getDaysUntil(account.prepaidExpirationDate);

    if (remainingDays === 0) {
      return intl.formatMessage({ id: "lbl.expires_today" });
    }
    if (remainingDays === 1) {
      return intl.formatMessage({ id: "lbl.expires_tomorrow" }, { days: remainingDays });
    }
    return intl.formatMessage({ id: "lbl.expires_in_future" }, { days: remainingDays });
  };

  return (
    <Box {...props}>
      <SelfcareBox variant="grid" mb="large">
        <SASummary account={account} isLoading={accountIsLoading || !account} />
        <SubscriberInformation account={account} isLoading={accountIsLoading || !account} />
      </SelfcareBox>

      {(accountIsLoading || !account) && <PackageNameSkeleton width="100%" mb="medium" />}
      {!accountIsLoading && account && (
        <Flex justifyContent="space-between" alignItems="center" mb="medium">
          <Heading color="textDark" fontSize="title">
            {account.planDescription}
          </Heading>
          {config.updatePackage && (
            <SelfcareButton
              variant="default"
              sx={{ ml: "default" }}
              onClick={() => {
                history.push(`/account/${account.accountCode}/updatePackage`, {
                  additionalSrv: additionalSrv,
                  account: account,
                });
              }}>
              <Text>
                <FormattedMessage id="lbl.update" />
              </Text>
            </SelfcareButton>
          )}
        </Flex>
      )}

      {(beneficiaryUnitsIsLoading || !beneficiaryUnits) && <FUBGroup isLoading />}
      {!beneficiaryUnitsIsLoading &&
        beneficiaryUnits &&
        account &&
        account.accountStatus === "ACTIVE" && (
          <>
            <FUBGroup
              title={intl.formatMessage({ id: "lbl.mandatory_and_optional_plans" })}
              titleSize="primary"
              fubs={beneficiaryUnits && beneficiaryUnits.filter(fub => !fub.shared)}
              showExpirationDate
              computeExpirationDateMessage={fubExpirationMessage}
            />
            <FUBGroup
              title={intl.formatMessage({ id: "lbl.shared_plans" })}
              titleSize="primary"
              fubs={beneficiaryUnits && beneficiaryUnits.filter(fub => fub.shared)}
              showExpirationDate
              computeExpirationDateMessage={fubExpirationMessage}
            />
          </>
        )}
      <ServiceAgreementPackage additionalSrv={additionalSrv} configuration={configuration} />
    </Box>
  );
};

export default ServiceAgreementPrepaid;
