import React from "react";
import Layout from "../../containers/Layout";
import { Box, Flex, Heading, Text } from "rebass/styled-components";
import { FormattedMessage } from "react-intl";

const EmptyUpdatePackage = ({accountName}) => {
  return (
    <Layout disableNavMenuDefaultLocation={true} isUpdatePackage={true}>
      <Flex width="100%" flexDirection="column">
        <Heading color="textDark" fontSize="title" mt={["0px", "default", "default"]}>
          {accountName}
        </Heading>
        <Box>
          <Heading
            my={["default", "default", "medium"]}
            color="textDark"
            fontSize="primary"
            textAlign={["center", "left", "left"]}>
            <FormattedMessage id="lbl.services_and_features" />
          </Heading>
          <Box sx={{ textAlign: ["center", "left", "left"] }}>
            <Text color="primary">
              <FormattedMessage id="lbl.no_visible_services" />
            </Text>
            <Text color="note">
              <FormattedMessage id="lbl.no_services_subnote" />
            </Text>
          </Box>
        </Box>
      </Flex>
    </Layout>
  );
};

export default EmptyUpdatePackage;
