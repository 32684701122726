import React from "react";
import ContentLoader from "react-content-loader";

const InvoiceSkeleton = () => (
  <ContentLoader
    speed={2}
    viewBox="0 0 317 254"
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">
    <path
      d="M30.9,162.2c0-0.8-0.3-1.4-0.8-2s-1.5-1-2.8-1.4c-1.8-0.5-3.1-1.3-3.9-2.2c-0.8-0.9-1.2-2-1.2-3.4
	c0-1.4,0.4-2.5,1.2-3.4c0.8-0.9,1.9-1.4,3.3-1.6v-2.8h1.9v2.8c1.4,0.2,2.5,0.8,3.3,1.8c0.8,1,1.2,2.4,1.2,4.1h-2.3
	c0-1.2-0.3-2.1-0.9-2.9c-0.6-0.7-1.3-1.1-2.3-1.1c-1,0-1.8,0.3-2.3,0.8s-0.8,1.2-0.8,2.2c0,0.9,0.3,1.6,0.8,2.1
	c0.6,0.5,1.5,1,2.8,1.4c1.3,0.4,2.3,0.9,3,1.4c0.7,0.5,1.3,1.1,1.6,1.8s0.5,1.5,0.5,2.3c0,1.4-0.4,2.6-1.3,3.5
	c-0.9,0.9-2.1,1.4-3.6,1.5v2.4h-1.9v-2.4c-1.6-0.1-2.8-0.7-3.7-1.7c-0.9-1-1.3-2.3-1.3-4h2.3c0,1.2,0.3,2.1,0.9,2.7
	c0.6,0.6,1.5,1,2.6,1c1.1,0,2-0.3,2.6-0.8S30.9,163.2,30.9,162.2z"
    />
    <rect x="16.6" y="19.3" width="178.7" height="26.6" />
    <rect x="16.6" y="66.3" width="280.9" height="26.6" />
    <rect x="16.6" y="95" width="178.7" height="26.6" />
    <rect x="37.4" y="144.6" width="80.1" height="26.6" />
    <path
      d="M210.8,162c0-0.8-0.3-1.4-0.8-2s-1.5-1-2.8-1.4c-1.8-0.5-3.1-1.3-3.9-2.2c-0.8-0.9-1.2-2-1.2-3.4
	c0-1.4,0.4-2.5,1.2-3.4c0.8-0.9,1.9-1.4,3.3-1.6v-2.8h1.9v2.8c1.4,0.2,2.5,0.8,3.3,1.8c0.8,1,1.2,2.4,1.2,4.1h-2.3
	c0-1.2-0.3-2.1-0.9-2.9c-0.6-0.7-1.3-1.1-2.3-1.1c-1,0-1.8,0.3-2.3,0.8s-0.8,1.2-0.8,2.2c0,0.9,0.3,1.6,0.8,2.1
	c0.6,0.5,1.5,1,2.8,1.4c1.3,0.4,2.3,0.9,3,1.4c0.7,0.5,1.3,1.1,1.6,1.8s0.5,1.5,0.5,2.3c0,1.4-0.4,2.6-1.3,3.5
	c-0.9,0.9-2.1,1.4-3.6,1.5v2.4h-1.9v-2.4c-1.6-0.1-2.8-0.7-3.7-1.7c-0.9-1-1.3-2.3-1.3-4h2.3c0,1.2,0.3,2.1,0.9,2.7
	c0.6,0.6,1.5,1,2.6,1c1.1,0,2-0.3,2.6-0.8S210.8,162.9,210.8,162z"
    />
    <rect x="217.4" y="144.3" width="80.1" height="26.6" />
    <rect x="16.6" y="208.5" width="105.1" height="26.6" />
    <rect x="19.5" y="191.5" width="278" height="0.9" />
  </ContentLoader>
);

export default InvoiceSkeleton;
