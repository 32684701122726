import React, { useContext } from "react";
import { useIntl } from "react-intl";
import SummaryLayout from "../../containers/SummaryLayout";
import SummaryRow from "../../components/SummaryRow/SummaryRow";
import EditAccountContactModal from "../../pages/AccountDetails/EditAccountContactModal";
import { buildAddress, getFullName } from "../../common/Utilities";
import ContactDetailsSkeleton from "./ContactDetailsSkeleton";
import { BrandingContext } from "../../contexts/BrandingContext";

const ContactDetails = ({ primaryContact, refreshContacts, isLoading }) => {
  const intl = useIntl();
  const { config } = useContext(BrandingContext);
  const [isEditContact, setEditContact] = React.useState(false);

  if (isLoading || !primaryContact) {
    return <SummaryLayout isLoading skeleton={ContactDetailsSkeleton} />;
  }

  return (
    <SummaryLayout title="lbl.contact_details" showEdit onEdit={() => setEditContact(true)}>
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.name" })}
        icon="name"
        value={getFullName(primaryContact)}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.address" })}
        icon="address"
        value={buildAddress(primaryContact)}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.email" })}
        icon="mail"
        value={primaryContact.email}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.mobile_phone" })}
        icon="mobile-phone"
        value={primaryContact.phone.business}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.home_phone" })}
        icon="home-phone"
        value={primaryContact.phone.home}
      />
      {config.mobilePhoneNumberVisible && (
        <SummaryRow
          tooltip={intl.formatMessage({ id: "lbl.mobile_phone" })}
          icon="mobile-phone"
          value={primaryContact.phone.mobile}
        />
      )}
      {config.identityTypeAndNoVisible && (
        <SummaryRow
          tooltip={intl.formatMessage({ id: "lbl.identity_type_number" })}
          icon="identity"
          value={primaryContact.identityType + " " + primaryContact.identityNo}
        />
      )}
      {isEditContact && (
        <EditAccountContactModal
          primaryContact={primaryContact}
          isEditContact={isEditContact}
          address={buildAddress(primaryContact)}
          onEditContactClose={() => {
            setEditContact(false);
          }}
          onEditContactSuccess={() => {
            setEditContact(false);
            refreshContacts();
          }}
        />
      )}
    </SummaryLayout>
  );
};

export default ContactDetails;
