import React, { useRef } from "react";
import { Flex, Text } from "rebass/styled-components";
import { useIntl } from "react-intl";

import Tooltip from "../Tooltip";
import { SelfcareIcon } from "../icons";
import { useOverflow } from "../../hooks/useOverflow";

const SummaryRow = ({ tooltip, icon, value, customTextProps, ...props }) => {
  const intl = useIntl();
  const valueRef = useRef();
  const [isOverflow] = useOverflow(valueRef);

  if (typeof value === "string" || value instanceof String) {
    value = value.trim();
  }
  if (typeof tooltip === "string" || tooltip instanceof String) {
    tooltip = tooltip.trim();
  }

  const showValue = value && value !== "";
  const showValuePlaceHolder = !showValue && tooltip && tooltip !== "";

  return (
    <Flex my="small" minHeight="2rem" alignItems="center" {...props}>
      <Flex minWidth="4rem" justifyContent="center" ml="-1rem">
        {tooltip && (
          <Tooltip position="top" value={tooltip}>
            <Flex alignItems="center">
              <SelfcareIcon name={icon} height="2rem" />
            </Flex>
          </Tooltip>
        )}
        {!tooltip && <SelfcareIcon name={icon} />}
      </Flex>

      {showValue && !isOverflow && (
        <Text
          ref={valueRef}
          textAlign="left"
          color="secondary"
          fontSize="secondary"
          sx={
            customTextProps
              ? { ...customTextProps }
              : {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }
          }>
          {value}
        </Text>
      )}
      {showValue && isOverflow && (
        <Tooltip position="top-noflex" value={value}>
          <Text
            textAlign="left"
            color="secondary"
            fontSize="secondary"
            sx={
              customTextProps
                ? { ...customTextProps }
                : {
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }
            }>
            {value}
          </Text>
        </Tooltip>
      )}

      {showValuePlaceHolder && (
        <Text textAlign="left" color="note" fontSize="secondary" fontStyle="italic">
          {intl.formatMessage(
            { id: "lbl.placeholder_info_not_specified" },
            { placeholder: tooltip }
          )}
        </Text>
      )}
    </Flex>
  );
};

export default SummaryRow;
