import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Flex, Heading, Link, Text } from "rebass/styled-components";
import { Input } from "@rebass/forms/styled-components";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import qs from "qs";
import LoginLayout from "../../containers/LoginLayout";
import { useGET, usePOST, usePUT } from "../../hooks/restAPI";
import { SelfcareButton } from "../../components/base";
import { FormErrorMessage } from "../../components/FormErrorMessage";
import StyledModalMessage from "../../components/StyledModalMessage";
import Tooltip from "../../components/Tooltip";
import ShowPassword from "../../components/ShowPassword";
import GetErrorDescription from "../../components/GetErrorDescription";
import { AuthorizationHandler } from "../../components/authorization";
import { SelfcareIcon } from "../../components/icons";
import { BrandingContext } from "../../contexts/BrandingContext";
import InputSkeleton from "./InputSkeleton";
import { AuthContext } from "../../contexts/AuthorizationContext";

let email, phoneNumber;

const ResetPassword = props => {
  const { provider, config } = useContext(BrandingContext);
  const { logout } = useContext(AuthContext);
  const history = useHistory();
  let { token } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const [accountCode, setAccountCode] = useState(
    props.location.state && props.location.state.accountCode ? props.location.state.accountCode : ""
  );
  const [username, setUsername] = useState("");
  const [invalidToken, setInvalidToken] = useState(false);

  const [
    { isError: isUpdateError, error: updateError, isSuccess: isUpdateSuccess },
    doUpdate,
    resetUpdateError,
  ] = usePUT();

  const [
    { isError: isResendError, error: resendError, isSuccess: isResendSuccess },
    doResend,
    resetResendError,
  ] = usePOST();

  const [securityPolicyData, doGetSecurityPolicy] = useGET();
  const [accountData, doGetAccount, resetGetAccountErr] = useGET();

  const { register, handleSubmit, errors, triggerValidation, watch, control } = useForm({
    mode: "onChange",
  });

  if (props.location.state && props.location.state.email) {
    email = props.location.state.email;
  }

  if (props.location.state && props.location.state.phoneNumber) {
    phoneNumber = props.location.state.phoneNumber;
  }

  useEffect(() => {
    const getSecurityPolicyConfig = {
      route: `/security/securityPolicy`,
    };
    doGetSecurityPolicy(getSecurityPolicyConfig);
  }, [doGetSecurityPolicy]);

  useEffect(() => {
    if (token) {
      logout();
      doGetAccount({
        route: `/security/accountByToken/${token.split("-").join("")}`,
      });
    }
  }, [doGetAccount, token, logout]);

  useEffect(() => {
    if (accountData.isSuccess) {
      setAccountCode(accountData.data.code);
      setUsername(accountData.data.subscriberUser);
    }
  }, [accountData]);

  let passwordRef = useRef();
  let confirmPasswordRef = useRef();

  const onResend = values => {
    let params = {
      method: "WITH_ACCOUNT_CODE",
      value: accountCode,
      isSecondStep: true,
    };

    if (config.limitAccessByProvider) {
      params.provider = provider;
    }

    const postData = {
      route: "/security/forgot?" + qs.stringify(params),
    };

    doResend(postData);
  };

  const onResetPassword = values => {
    let putData = {
      route: "/security/reset",
      expectedResponse: "none",
      body: {
        accountCode: accountCode,
        password: values.password,
        verificationCode: values.verificationCode,
        token: token,
      },
    };
    doUpdate(putData);
  };

  const handleAccountByTokenError = () => {
    setInvalidToken(true);
    resetGetAccountErr();
  };

  const goHome = () => {
    history.push("/");
  };

  return (
    <LoginLayout>
      <form onSubmit={handleSubmit(onResetPassword)} autoComplete="off">
        <Heading py="small" fontWeight={5} fontSize="title" color="primary" textAlign={"left"}>
          <FormattedMessage id="lbl.change_password" />
        </Heading>

        {token && (
          <Text
            color="textDark"
            fontSize="primary"
            paddingY="small"
            fontWeight={3}
            htmlFor="username">
            <FormattedMessage id="lbl.username" />
          </Text>
        )}

        {token && !username && !invalidToken && <InputSkeleton />}

        {token && (username || invalidToken) && (
          <Input
            disabled={true}
            defaultValue={username}
            maxLength="30"
            variant={errors.username ? "inputError" : "input"}
            name="username"
            type="text"
          />
        )}

        <Text
          color="textDark"
          fontSize="primary"
          paddingY="small"
          fontWeight={3}
          htmlFor="password">
          <FormattedMessage id="lbl.enter_password" />
        </Text>

        {securityPolicyData.isSuccess ? (
          <Box>
            <Controller
              name="password"
              as={
                <Input
                  ref={passwordRef}
                  variant={errors.password ? "inputError" : "input"}
                  style={{ paddingRight: "2.2rem" }}
                  maxLength="25"
                  type="password"
                  onChange={() => {
                    if (watch("confirmPassword")) triggerValidation("confirmPassword");
                  }}
                />
              }
              rules={{
                required: true,
                pattern: {
                  value: new RegExp(securityPolicyData.data.passwordRegEx),
                  message: securityPolicyData.data.passwordStructureHint,
                },
              }}
              control={control}
              defaultValue=""
              disabled={invalidToken}
            />
            <ShowPassword refInput={passwordRef} />
          </Box>
        ) : (
          <InputSkeleton />
        )}
        {errors.password && errors.password.message !== "" && (
          <FormErrorMessage>{errors.password.message}</FormErrorMessage>
        )}
        <Text
          color="textDark"
          fontSize="primary"
          paddingY="small"
          fontWeight={3}
          htmlFor="confirmPassword">
          <FormattedMessage id="lbl.confirm_password" />
        </Text>
        {securityPolicyData.isSuccess ? (
          <Box>
            <Controller
              name="confirmPassword"
              as={
                <Input
                  ref={confirmPasswordRef}
                  variant={errors.confirmPassword ? "inputError" : "input"}
                  style={{ paddingRight: "2.2rem" }}
                  maxLength="25"
                  type="password"
                />
              }
              rules={{
                required: true,
                validate: value => {
                  return value === watch("password") || "lbl.passwords_dont_match";
                },
              }}
              control={control}
              defaultValue=""
              disabled={invalidToken}
            />
            <ShowPassword refInput={confirmPasswordRef} />
          </Box>
        ) : (
          <InputSkeleton />
        )}

        {errors.confirmPassword && errors.confirmPassword.message !== "" && (
          <FormErrorMessage>
            <FormattedMessage id={errors.confirmPassword.message} />
          </FormErrorMessage>
        )}
        {!token && (
          <>
            <Text
              color="textDark"
              fontSize="primary"
              paddingY="small"
              fontWeight={3}
              htmlFor="verificationCode">
              <FormattedMessage id="lbl.enter_verification_code" />
            </Text>
            <Flex flexDirection="column">
              <Input
                maxLength="100"
                ref={register({
                  required: !token,
                  pattern: {
                    value: /^[0-9]+$/i,
                    message: "err.verification_code_should_contain_only_digits",
                  },
                })}
                variant={errors.verificationCode ? "inputError" : "input"}
                name="verificationCode"
                type="text"
                style={{ paddingRight: "2.2rem" }}
              />
              <Tooltip
                value={
                  <FormattedMessage
                    id="lbl.use_verification_code_sent_at"
                    values={{
                      emailOrPhone: email ? email : phoneNumber ? phoneNumber : "",
                    }}
                  />
                }
                mt="-2.4rem"
                alignSelf="flex-end"
                childrenPY="small">
                <SelfcareIcon name="question-mark" width="2.75rem" height="1.125rem" />
              </Tooltip>
            </Flex>
            {errors.verificationCode && errors.verificationCode.message !== "" && (
              <FormErrorMessage pt="0.6rem">
                <FormattedMessage id={errors.verificationCode.message} />
              </FormErrorMessage>
            )}
            <Box textAlign="right" mt="default" pt="small" width="100%">
              <Link
                href="#"
                onClick={onResend}
                color="secondary"
                fontSize="note"
                sx={{ textDecoration: "none" }}>
                <FormattedMessage id="lbl.Resend_SMS_code" />
              </Link>
            </Box>
          </>
        )}
        <SelfcareButton
          disabled={invalidToken}
          type="submit"
          mt="default"
          width="100%"
          variant="primary">
          <FormattedMessage id="lbl.change_password" />
        </SelfcareButton>

        {isUpdateError && (
          <StyledModalMessage
            isOpen={isUpdateError}
            message={<GetErrorDescription error={updateError} />}
            onRequestClose={resetUpdateError}
            type="error">
            <SelfcareButton variant="secondarySmall" onClick={resetUpdateError}>
              <FormattedMessage id="lbl.Try_Again" />
            </SelfcareButton>
          </StyledModalMessage>
        )}

        {accountData.isError && (
          <StyledModalMessage
            isOpen={accountData.isError}
            message={<GetErrorDescription error={accountData.error} />}
            onRequestClose={handleAccountByTokenError}
            type="error">
            <SelfcareButton variant="secondarySmall" onClick={handleAccountByTokenError}>
              <FormattedMessage id="lbl.ok" />
            </SelfcareButton>
          </StyledModalMessage>
        )}

        {isUpdateSuccess && (
          <StyledModalMessage
            isOpen={isUpdateSuccess}
            message={<FormattedMessage id="lbl.reset_password_success" />}
            onRequestClose={goHome}
            type="info">
            <AuthorizationHandler signInVariant="secondarySmall" />
          </StyledModalMessage>
        )}
        {isResendError && (
          <StyledModalMessage
            isOpen={isResendError}
            message={<GetErrorDescription error={resendError} />}
            onRequestClose={resetResendError}
            type="error">
            <SelfcareButton variant="secondarySmall" onClick={resetResendError}>
              <FormattedMessage id="lbl.Try_Again" />
            </SelfcareButton>
          </StyledModalMessage>
        )}
        {isResendSuccess && (
          <StyledModalMessage
            isOpen={isResendSuccess}
            message={
              <FormattedMessage
                id="lbl.notification_code_was_sent_again"
                values={{
                  phoneOrEmail: email ? email : phoneNumber ? phoneNumber : "",
                }}
              />
            }
            onRequestClose={resetResendError}
            type="info">
            <SelfcareButton variant="secondarySmall" onClick={resetResendError}>
              <FormattedMessage id="lbl.ok" />
            </SelfcareButton>
          </StyledModalMessage>
        )}
      </form>
      <>
        <Heading
          fontWeight={4}
          fontSize="presentationTitle"
          lineHeight="solid"
          color="textLight"
          textAlign={["center", "left", "left"]}
          paddingBottom="3rem">
          <FormattedMessage id="lbl.already_a_member" />
        </Heading>
        <AuthorizationHandler />
      </>
    </LoginLayout>
  );
};

export default ResetPassword;
