import React from "react";
import ContentLoader from "react-content-loader";

const InputSkeleton = ({ ...props }) => (
  <ContentLoader
    {...props}
    speed={2}
    viewBox="0 0 258 34"
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">
    <rect id="Rectangle_1209-2_1_" x="0" width="258" height="34" />
  </ContentLoader>
);

export default InputSkeleton;
