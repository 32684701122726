import React from "react";
import ContentLoader from "react-content-loader";

const ContactDetailsSkeleton = () => (
  <ContentLoader
    speed={2}
    viewBox="0 0 492 390"
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">

    <rect id="Rectangle_1209-2_1_" x="370" y="30" width="101" height="35" />

    <rect x="29.1" y="33.3" width="219.7" height="26.6" />
    <rect x="65.6" y="80.4" width="219.7" height="26.6" />
    <rect x="65.6" y="122.4" width="219.7" height="26.6" />
    <rect x="65.6" y="164.4" width="219.7" height="26.6" />

    <circle cx="46.5" cy="88.6" r="2.3" />
    <path
      d="M42.4,82.4c6.2,0,11.3,5.1,11.3,11.3c0,6.2-5.1,11.3-11.3,11.3S31.1,100,31.1,93.8
		C31.1,87.5,36.2,82.4,42.4,82.4 M42.4,80.4c-7.3,0-13.3,6-13.3,13.3s6,13.3,13.3,13.3s13.3-6,13.3-13.3S49.7,80.4,42.4,80.4
		L42.4,80.4z"
    />
    <path
      d="M45.1,95l-6-4.5c0,0-0.1,0-0.2,0c0,0-3.7,2.8-5.8,4.4c0.5,4.6,4.5,8.2,9.2,8.2c5.1,0,9.3-4.2,9.3-9.3
		c0-1-0.1-1.9-0.4-2.7l-6,4C45.3,95,45.2,95,45.1,95z"
    />

    <circle cx="46.5" cy="130.6" r="2.3" />
    <path
      d="M42.4,124.4c6.2,0,11.3,5.1,11.3,11.3c0,6.2-5.1,11.3-11.3,11.3s-11.3-5.1-11.3-11.3
		C31.1,129.5,36.2,124.4,42.4,124.4 M42.4,122.4c-7.3,0-13.3,6-13.3,13.3S35,149,42.4,149s13.3-6,13.3-13.3S49.7,122.4,42.4,122.4
		L42.4,122.4z"
    />
    <path
      d="M45.1,137l-6-4.5c0,0-0.1,0-0.2,0c0,0-3.7,2.8-5.8,4.4c0.5,4.6,4.5,8.2,9.2,8.2c5.1,0,9.3-4.2,9.3-9.3
		c0-1-0.1-1.9-0.4-2.7l-6,4C45.3,137,45.2,137,45.1,137z"
    />

    <circle cx="46.5" cy="172.6" r="2.3" />
    <path
      d="M42.4,166.4c6.2,0,11.3,5.1,11.3,11.3c0,6.2-5.1,11.3-11.3,11.3s-11.3-5.1-11.3-11.3
		C31.1,171.5,36.2,166.4,42.4,166.4 M42.4,164.4c-7.3,0-13.3,6-13.3,13.3S35,191,42.4,191s13.3-6,13.3-13.3S49.7,164.4,42.4,164.4
		L42.4,164.4z"
    />
    <path
      d="M45.1,178.9l-6-4.5c0,0-0.1,0-0.2,0c0,0-3.7,2.8-5.8,4.4c0.5,4.6,4.5,8.2,9.2,8.2c5.1,0,9.3-4.2,9.3-9.3
		c0-1-0.1-1.9-0.4-2.7l-6,4C45.3,179,45.2,179,45.1,178.9z"
    />

    <rect x="65.6" y="206.4" width="219.7" height="26.6" />
    <rect x="65.6" y="248.3" width="219.7" height="26.6" />
    <rect x="65.6" y="290.3" width="219.7" height="26.6" />

    <circle cx="46.5" cy="214.5" r="2.3" />
    <path
      d="M42.4,208.4c6.2,0,11.3,5.1,11.3,11.3c0,6.2-5.1,11.3-11.3,11.3s-11.3-5.1-11.3-11.3
		C31.1,213.4,36.2,208.4,42.4,208.4 M42.4,206.4c-7.3,0-13.3,6-13.3,13.3S35,233,42.4,233s13.3-6,13.3-13.3S49.7,206.4,42.4,206.4
		L42.4,206.4z"
    />
    <path
      d="M45.1,220.9l-6-4.5c0,0-0.1,0-0.2,0c0,0-3.7,2.8-5.8,4.4c0.5,4.6,4.5,8.2,9.2,8.2c5.1,0,9.3-4.2,9.3-9.3
		c0-1-0.1-1.9-0.4-2.7l-6,4C45.3,220.9,45.2,220.9,45.1,220.9z"
    />

    <circle cx="46.5" cy="256.5" r="2.3" />
    <path
      d="M42.4,250.3c6.2,0,11.3,5.1,11.3,11.3c0,6.2-5.1,11.3-11.3,11.3s-11.3-5.1-11.3-11.3
		C31.1,255.4,36.2,250.3,42.4,250.3 M42.4,248.3c-7.3,0-13.3,6-13.3,13.3c0,7.3,6,13.3,13.3,13.3s13.3-6,13.3-13.3
		C55.7,254.3,49.7,248.3,42.4,248.3L42.4,248.3z"
    />
    <path
      d="M45.1,262.9l-6-4.5c0,0-0.1,0-0.2,0c0,0-3.7,2.8-5.8,4.4c0.5,4.6,4.5,8.2,9.2,8.2c5.1,0,9.3-4.2,9.3-9.3
		c0-1-0.1-1.9-0.4-2.7l-6,4C45.3,262.9,45.2,262.9,45.1,262.9z"
    />

    <circle cx="46.5" cy="298.5" r="2.3" />
    <path
      d="M42.4,292.3c6.2,0,11.3,5.1,11.3,11.3s-5.1,11.3-11.3,11.3s-11.3-5.1-11.3-11.3S36.2,292.3,42.4,292.3
		 M42.4,290.3c-7.3,0-13.3,6-13.3,13.3c0,7.3,6,13.3,13.3,13.3s13.3-6,13.3-13.3C55.7,296.3,49.7,290.3,42.4,290.3L42.4,290.3z"
    />
    <path
      d="M45.1,304.8l-6-4.5c0,0-0.1,0-0.2,0c0,0-3.7,2.8-5.8,4.4c0.5,4.6,4.5,8.2,9.2,8.2c5.1,0,9.3-4.2,9.3-9.3
		c0-1-0.1-1.9-0.4-2.7l-6,4C45.3,304.9,45.2,304.9,45.1,304.8z"
    />

    <rect x="65.6" y="332.3" width="219.7" height="26.6" />

    <circle cx="46.5" cy="340.4" r="2.3" />
    <path
      d="M42.4,334.3c6.2,0,11.3,5.1,11.3,11.3c0,6.2-5.1,11.3-11.3,11.3s-11.3-5.1-11.3-11.3
		C31.1,339.3,36.2,334.3,42.4,334.3 M42.4,332.3c-7.3,0-13.3,6-13.3,13.3c0,7.3,6,13.3,13.3,13.3s13.3-6,13.3-13.3
		C55.7,338.2,49.7,332.3,42.4,332.3L42.4,332.3z"
    />
    <path
      d="M45.1,346.8l-6-4.5c0,0-0.1,0-0.2,0c0,0-3.7,2.8-5.8,4.4c0.5,4.6,4.5,8.2,9.2,8.2c5.1,0,9.3-4.2,9.3-9.3
		c0-1-0.1-1.9-0.4-2.7l-6,4C45.3,346.8,45.2,346.8,45.1,346.8z"
    />
  </ContentLoader>
);

export default ContactDetailsSkeleton;
