import React from "react";
export default ({ shackle = "#346eb3", body = "#318eff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.938"
    height="25.445"
    viewBox="0 0 23.938 25.445"
    {...props}>
    <g transform="translate(0 -0.022)">
      <path
        fill="none"
        stroke={shackle}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.557px"
        d="M5.5,10.9V7.809A7.012,7.012,0,0,1,12.509.8h0a7.012,7.012,0,0,1,7.009,7.009v2.731"
        transform="translate(-0.494)"
      />
      <path
        fill={body}
        d="M21.39,11.5H2.549A2.6,2.6,0,0,0,0,14.049v9.83a2.6,2.6,0,0,0,2.549,2.549H21.39a2.6,2.6,0,0,0,2.549-2.549v-9.83A2.489,2.489,0,0,0,21.39,11.5ZM5.279,21.148a2.185,2.185,0,1,1,0-4.369,2.185,2.185,0,1,1,0,4.369Zm6.736,0A2.185,2.185,0,1,1,14.2,18.964,2.215,2.215,0,0,1,12.015,21.148Zm6.645,0a2.185,2.185,0,1,1,2.185-2.185A2.215,2.215,0,0,1,18.659,21.148Z"
        transform="translate(0 -0.961)"
      />
    </g>
  </svg>
);
