import React from "react";
import { useIntl } from "react-intl";
import SummaryLayout from "../../containers/SummaryLayout";
import SummaryRow from "../../components/SummaryRow/SummaryRow";
import EditAccountBillingModal from "../../pages/AccountDetails/EditAccountBillingModal";
import { buildAddress, getFullName } from "../../common/Utilities";
import BillingDetailsSkeleton from "./BillingDetailsSkeleton";

const BillingDetails = ({
  shipmentContact,
  billingCycle,
  invoiceOptionFeature,
  refreshBilling,
  isLoading,
}) => {
  const intl = useIntl();
  const [isEditBilling, setEditBilling] = React.useState(false);
  if (isLoading || !shipmentContact || !billingCycle || !invoiceOptionFeature) {
    return <SummaryLayout isLoading skeleton={BillingDetailsSkeleton} />;
  }
  return (
    <SummaryLayout
      title="lbl.billing_information"
      showEdit
      onEdit={() => {
        setEditBilling(true);
      }}>
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.name" })}
        icon="name"
        value={getFullName(shipmentContact)}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.address" })}
        icon="address"
        value={buildAddress(shipmentContact)}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.billing_cycle" })}
        icon="billing-cycle"
        value={billingCycle}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.invoice_option" })}
        icon="invoice-shipment"
        value={
          invoiceOptionFeature && invoiceOptionFeature.data
            ? invoiceOptionFeature.data.description
            : ""
        }
      />
      {isEditBilling && (
        <EditAccountBillingModal
          shipmentContact={shipmentContact}
          currentShipmentMethod={
            invoiceOptionFeature && invoiceOptionFeature.data ? invoiceOptionFeature.data.code : ""
          }
          address={buildAddress(shipmentContact)}
          isEditBilling={isEditBilling}
          onEditBillingClose={() => {
            setEditBilling(false);
          }}
          onEditBillingSuccess={() => {
            setEditBilling(false);
            refreshBilling();
          }}
        />
      )}
    </SummaryLayout>
  );
};

export default BillingDetails;
