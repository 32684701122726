import React from "react";
export default ({ text = "#114878", edge = "#346eb3", photo = "#318eff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.2"
    height="18.222"
    viewBox="0 0 31.2 18.222"
    {...props}>
    <g transform="translate(14.673 4.055)">
      <rect fill={text} width="12.809" height="1.18" />
      <rect fill={text} width="12.809" height="1.18" transform="translate(0 2.949)" />
      <rect fill={text} width="12.809" height="1.18" transform="translate(0 5.983)" />
      <rect fill={text} width="12.809" height="1.18" transform="translate(0 9.017)" />
    </g>
    <path
      fill="none"
      stroke={edge}
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2px"
      d="M26.639,17.622H4.561A3.956,3.956,0,0,1,.6,13.662v-9.1A3.956,3.956,0,0,1,4.561.6H26.639A3.956,3.956,0,0,1,30.6,4.561v9.1A3.956,3.956,0,0,1,26.639,17.622Z"
    />
    <g transform="translate(3.297 4.055)">
      <circle fill={photo} cx="3.034" cy="3.034" r="3.034" transform="translate(1.601)" />
      <path
        fill={photo}
        d="M13.07,15.929A4.593,4.593,0,0,0,8.435,11.8,4.66,4.66,0,0,0,3.8,15.929Z"
        transform="translate(-3.8 -5.817)"
      />
    </g>
  </svg>
);
