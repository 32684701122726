import React from "react";
export default ({ ticket = "#114878", cogwheel = "#318eff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44.312"
    height="50.858"
    viewBox="0 0 44.312 50.858">
    <g transform="translate(-9.844 -6.571)">
      <path
        fill={cogwheel}
        d="M48.623,41.934l1.9-1.9-2.3-2.3-1.9,1.9a6.83,6.83,0,0,0-1.934-.8v-2.68H41.138v2.68a6.818,6.818,0,0,0-1.934.8l-1.9-1.9-2.3,2.3,1.9,1.9a6.83,6.83,0,0,0-.8,1.934h-2.68V47.12H36.1a6.818,6.818,0,0,0,.8,1.934l-1.9,1.9,2.3,2.3,1.9-1.9a6.818,6.818,0,0,0,1.934.8v2.68H44.39v-2.68a6.8,6.8,0,0,0,1.934-.8l1.9,1.9,2.3-2.3-1.9-1.9a6.791,6.791,0,0,0,.8-1.934h2.679V43.868H49.427A6.8,6.8,0,0,0,48.623,41.934Zm-5.859,7.941a4.382,4.382,0,1,1,4.382-4.381A4.381,4.381,0,0,1,42.764,49.875Z"
      />
      <path
        fill={ticket}
        d="M33.715,52.243,32.422,50.95l1.293-1.293.708-.708h-2.83v-6.91h2.83l-.708-.708-1.293-1.293,1.293-1.293,2.3-2.3,1.293-1.293L38.6,36.445l.708.708v-2.83h6.3L47.7,30.7l-.74-.427.914-1.584.741.427,5.544-9.6-4.65-2.685A7.894,7.894,0,0,1,36.383,9.255L31.732,6.571l-5.55,9.612.77.444-.913,1.584-.771-.444L9.844,44.483l4.65,2.685a7.9,7.9,0,0,1,13.124,7.577l4.65,2.684L34.7,53.224ZM44.653,26.838l1.609.928-.914,1.584-1.609-.928Zm-3.218-1.857,1.609.928-.914,1.584-1.61-.928Zm-3.219-1.856,1.61.928-.914,1.584-1.61-.928ZM35,21.269l1.61.928-.915,1.584-1.609-.928Zm-5.741-1.2-1.61-.928.915-1.584,1.609.928Zm2.523-.656,1.61.928-.915,1.584L30.866,21Z"
      />
    </g>
  </svg>
);
