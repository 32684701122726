import React, { useContext } from "react";
import { Input } from "@rebass/forms/styled-components";
import { FormattedMessage } from "react-intl";
import { Redirect, useLocation } from "react-router-dom";
import { Heading, Text } from "rebass/styled-components";
import { useForm } from "react-hook-form";
import qs from "qs";
import { SelfcareButton } from "../../components/base";
import StyledModalMessage from "../../components/StyledModalMessage";
import LoginLayout from "../../containers/LoginLayout";
import { usePOST } from "../../hooks/restAPI";
import { FormErrorMessage } from "../../components/FormErrorMessage";
import GetErrorDescription from "../../components/GetErrorDescription";
import { AuthorizationHandler } from "../../components/authorization";
import { BrandingContext } from "../../contexts/BrandingContext";

const ForgotPassword = props => {
  const { provider, config } = useContext(BrandingContext);
  const { search } = useLocation();
  const { u } = qs.parse(search, { ignoreQueryPrefix: true });
  let decodedUsername;
  try {
    decodedUsername = atob(u);
    if (!/^[0-9a-zA-Z]+$/g.test(decodedUsername)) {
      decodedUsername = "";
    }
  } catch (error) {
    decodedUsername = "";
  }

  const [{ data, isError, error }, doPostForgot, resetError] = usePOST();

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    defaultValues: {
      username: decodedUsername,
    },
  });

  const onForgot = values => {
    let params = {
      method: "WITH_USERNAME",
      value: values.username,
      isSecondStep: false,
    };

    if (config.limitAccessByProvider) {
      params.provider = provider;
    }

    let postData = {
      route: "/security/forgot?" + qs.stringify(params),
    };

    doPostForgot(postData);
  };

  return data.accountCode ? (
    <Redirect
      push
      to={{
        pathname: "/resetPassword",
        state: {
          from: props.location,
          accountCode: data.accountCode,
          email: data.email,
          phoneNumber: data.phoneNumber,
        },
      }}
    />
  ) : (
    <LoginLayout>
      <>
        <form onSubmit={handleSubmit(onForgot)}>
          <Heading py="small" fontWeight={5} fontSize="title" color="primary">
            <FormattedMessage id="lbl.reset_password" />
          </Heading>

          <Text color="textDark" fontSize="primary" paddingY="small" fontWeight={3}>
            <FormattedMessage id="lbl.enter_username" />
          </Text>
          <Input
            ref={register({
              required: true,
            })}
            variant={errors.username ? "inputError" : "input"}
            maxLength="30"
            name="username"
            type="text"
          />
          {errors.username && errors.username.message !== "" && (
            <FormErrorMessage>
              <FormattedMessage id={errors.username.message} />
            </FormErrorMessage>
          )}

          <SelfcareButton type="submit" width="100%" mt="large" variant="primary">
            <FormattedMessage id="lbl.get_sms_with_verification_code" />
          </SelfcareButton>

          {isError && (
            <StyledModalMessage
              isOpen={isError}
              message={<GetErrorDescription error={error} />}
              onRequestClose={resetError}
              type="error">
              <SelfcareButton variant="secondarySmall" onClick={resetError}>
                <FormattedMessage id="lbl.Try_Again" />
              </SelfcareButton>
            </StyledModalMessage>
          )}
        </form>
      </>
      <>
        <Heading
          fontWeight={4}
          fontSize="presentationTitle"
          lineHeight="solid"
          color="textLight"
          textAlign={["center", "left", "left"]}
          paddingBottom="3rem">
          <FormattedMessage id="lbl.already_a_member" />
        </Heading>
        <AuthorizationHandler />
      </>
    </LoginLayout>
  );
};
export default ForgotPassword;
