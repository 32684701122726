import React from "react";
import { useIntl } from "react-intl";

import { Circle, Stroke } from "../Lines";
import { FIRST_TIMELINE_EVENT_SIZE, TIMELINE_EVENT_SIZE } from "../Common";
import { SelfcareAmount } from "../../base";
import { BaseIcon } from "../../icons";

const TimelineInvoiceDesktop = ({
  strokeColor,
  circleColor,
  textColor,
  amountColor,
  isFirstInTimeline,
  invoiceDate,
  invoiceNumber,
  invoiceBalance,
  ...props
}) => {
  const { transform, svgProps } = props;
  const strokeSize = isFirstInTimeline ? "small" : "large";
  const textTranslateX = isFirstInTimeline ? "70%" : "82%";
  const iconTranslateX = isFirstInTimeline ? 68 : 143;
  const circleTranslateX = isFirstInTimeline ? 46.3 : 121.2;
  const svgSize = isFirstInTimeline ? FIRST_TIMELINE_EVENT_SIZE : TIMELINE_EVENT_SIZE;

  const intl = useIntl();

  return (
    <g transform={transform}>
      <svg
        {...svgProps}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${svgSize} 200`}
        width={`${svgSize}px`}
        height="200px"
        overflow="visible">
        <g>
          <text x={textTranslateX} y="16" textAnchor="middle" fill={textColor}>
            <tspan fontSize="16px">{intl.formatDate(invoiceDate)}</tspan>
          </text>

          <Stroke color={strokeColor} size={strokeSize} transform="translate(0 26)" />
          <Circle color={circleColor} transform={`translate(${circleTranslateX} 84)`} />
          <g transform={`translate(${iconTranslateX} 103)`}>
            <BaseIcon name="invoice" />
          </g>

          <text x={textTranslateX} y="170" textAnchor="middle" fill={textColor}>
            <tspan fontSize="16px">
              {intl.formatMessage({ id: "lbl.invoice_number" }, { number: invoiceNumber })}
            </tspan>
          </text>
          <text x={textTranslateX} y="195" textAnchor="middle" fill={amountColor}>
            <tspan fontSize="20px">
              <SelfcareAmount amount={invoiceBalance} />
            </tspan>
          </text>
        </g>
      </svg>
    </g>
  );
};

export default TimelineInvoiceDesktop;
