import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { Flex, Text } from "rebass/styled-components";

import AccountDetailsSkeleton from "../Header/AccountDetailsSkeleton";
import { AuthContext } from "../../contexts/AuthorizationContext";

const SubscriberIconDetails = ({ ...props }) => {
  const intl = useIntl();
  const { masterAccount } = useContext(AuthContext);
  const isLoading = masterAccount === null;

  return (
    <Flex {...props} alignItems="center" justifyContent="center">
      {isLoading ? (
        <AccountDetailsSkeleton height="3.9rem" />
      ) : (
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          <Text
            fontSize="primary"
            color="primary"
            maxWidth="10.375rem"
            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {masterAccount.accountName}
          </Text>
          <Text textAlign="center" fontSize="secondary" color="primary">
            {masterAccount.phoneNumber !== ""
              ? masterAccount.phoneNumber
              : masterAccount.accountCode}
          </Text>
          <Text textAlign="center" fontSize="note" color="primary">
            {intl.formatMessage(
              { id: "lbl.member_since" },
              {
                date: intl.formatDate(masterAccount.registrationDate),
              }
            )}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default SubscriberIconDetails;
