import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { usePUT } from "../../hooks/restAPI";
import ModalPage from "../../components/ModalPage";
import { FormErrorMessage } from "../../components/FormErrorMessage";
import { SelfcareButton } from "../../components/base";
import StyledModalMessage from "../../components/StyledModalMessage";
import GetErrorDescription from "../../components/GetErrorDescription";
import { Box, Heading, Text } from "rebass/styled-components";
import { Input } from "@rebass/forms/styled-components";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { BrandingContext } from "../../contexts/BrandingContext";

const EditAccountContactModal = ({
  primaryContact,
  address,
  isEditContact,
  onEditContactSuccess,
  onEditContactClose,
}) => {
  const { accountCode: masterAccountCode } = useContext(AuthContext);
  const { accountCode: accountCodePath } = useParams();
  const { config } = useContext(BrandingContext);

  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  let [doPutContactData, doPutContact, resetPutContactError] = usePUT({});

  useEffect(() => {
    const doAfterSuccessfulSave = () => {
      if (doPutContactData.isSuccess) {
        onEditContactSuccess();
      }
    };
    doAfterSuccessfulSave();
  }, [doPutContactData.isSuccess, onEditContactSuccess, accountCode]);

  const onSaveContact = values => {
    let contact = {
      ...primaryContact,
      name: {
        ...primaryContact.name,
        first: values.firstName,
        middle: values.middleName,
        last: values.lastName,
      },
      email: values.email,
      phone: {
        ...primaryContact.phone,
        home: values.homePhone,
        business: values.businessPhone,
        mobile: values.mobilePhone,
      },
    };

    let putData = {
      route: `/account/${accountCode}/contacts/${primaryContact.id}`,
      expectedResponse: "none",
      body: contact,
    };

    doPutContact(putData);
  };

  return (
    <ModalPage isOpen={isEditContact} onRequestClose={onEditContactClose}>
      <form onSubmit={handleSubmit(onSaveContact)}>
        <Box px="larger" py="larger">
          <Heading
            as="h3"
            fontWeight={5}
            pb="medium"
            fontSize="title"
            color="textDark"
            textAlign="left">
            <FormattedMessage id="lbl.contact_details" />
          </Heading>

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.first_name" />
          </Text>

          <Input
            defaultValue={primaryContact.name.first}
            ref={register}
            disabled={true}
            variant={errors.firstName ? "greyInputError" : "greyInput"}
            maxLength="100"
            name="firstName"
            type="text"
            marginBottom="medium"
          />

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.middle_name" />
          </Text>
          <Input
            defaultValue={primaryContact.name.middle}
            ref={register}
            disabled={true}
            variant={errors.middleName ? "greyInputError" : "greyInput"}
            maxLength="60"
            name="middleName"
            type="text"
            marginBottom="medium"
          />

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.last_name" />
          </Text>
          <Input
            defaultValue={primaryContact.name.last}
            ref={register}
            disabled={true}
            variant={errors.lastName ? "greyInputError" : "greyInput"}
            maxLength="100"
            name="lastName"
            type="text"
            marginBottom="medium"
          />

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.address" />
          </Text>
          <Input
            defaultValue={address}
            ref={register}
            disabled={true}
            variant={errors.address ? "greyInputError" : "greyInput"}
            maxLength="100"
            name="address"
            type="text"
            marginBottom="medium"
          />

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.email_address" />
          </Text>
          <Box marginBottom="medium">
            <Input
              defaultValue={primaryContact.email}
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "err.email_address_is_invalid",
                },
              })}
              variant={errors.email ? "greyInputError" : "greyInput"}
              maxLength="80"
              name="email"
              type="text"
              paddingY="small"
            />

            {errors.email && errors.email.message && (
              <FormErrorMessage>
                <FormattedMessage id={errors.email.message} />
              </FormErrorMessage>
            )}
          </Box>

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.mobile_phone" />
          </Text>
          <Box marginBottom="medium">
            <Input
              defaultValue={primaryContact.phone.business}
              ref={register({
                pattern: {
                  value: /^[0-9]+$/i,
                  message: "lbl.phone_number_should_contain_digits",
                },
              })}
              variant={errors.businessPhone ? "greyInputError" : "greyInput"}
              maxLength="20"
              name="businessPhone"
              type="text"
            />
            {errors.businessPhone && (
              <FormErrorMessage>
                <FormattedMessage id={errors.businessPhone.message} />
              </FormErrorMessage>
            )}
          </Box>

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.home_phone" />
          </Text>
          <Box marginBottom="medium">
            <Input
              defaultValue={primaryContact.phone.home}
              ref={register({
                pattern: {
                  value: /^[0-9]+$/i,
                  message: "lbl.phone_number_should_contain_digits",
                },
              })}
              variant={errors.homePhone ? "greyInputError" : "greyInput"}
              maxLength="20"
              name="homePhone"
              type="text"
            />
            {errors.homePhone && (
              <FormErrorMessage>
                <FormattedMessage id={errors.homePhone.message} />
              </FormErrorMessage>
            )}
          </Box>

          {config.mobilePhoneNumberVisible && (
            <React.Fragment>
              <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
                <FormattedMessage id="lbl.mobile_phone" />
              </Text>
              <Box marginBottom="medium">
                <Input
                  defaultValue={primaryContact.phone.mobile}
                  ref={register({
                    pattern: {
                      value: /^[0-9]+$/i,
                      message: "lbl.phone_number_should_contain_digits",
                    },
                  })}
                  variant={errors.mobilePhone ? "greyInputError" : "greyInput"}
                  maxLength="20"
                  name="mobilePhone"
                  type="text"
                />
                {errors.mobilePhone && (
                  <FormErrorMessage>
                    <FormattedMessage id={errors.mobilePhone.message} />
                  </FormErrorMessage>
                )}
              </Box>
            </React.Fragment>
          )}

          {config.identityTypeAndNoVisible && (
            <>
              <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
                <FormattedMessage id="lbl.identity_type_number" />
              </Text>
              <Input
                defaultValue={primaryContact.identityType + " " + primaryContact.identityNo}
                ref={register}
                disabled={true}
                variant="greyInput"
                maxLength="100"
                name="identityTypeAndNo"
                type="text"
                marginBottom="medium"
              />
            </>
          )}

          <Box
            width="auto"
            mt="larger"
            textAlign="right"
            mb={["mobileModalExtraOffset", "huge", "huge"]}>
            <SelfcareButton type="submit" variant="default" mr="spaceBetweenButtons">
              <FormattedMessage id="lbl.save" />
            </SelfcareButton>

            <SelfcareButton variant="default-inverted" onClick={onEditContactClose}>
              <FormattedMessage id="lbl.cancel" />
            </SelfcareButton>
          </Box>

          {doPutContactData.isError && (
            <StyledModalMessage
              isOpen={doPutContactData.isError}
              message={<GetErrorDescription error={doPutContactData.error} />}
              onRequestClose={resetPutContactError}
              type="error">
              <SelfcareButton variant="secondarySmall" onClick={resetPutContactError}>
                <FormattedMessage id="lbl.Try_Again" />
              </SelfcareButton>
            </StyledModalMessage>
          )}
        </Box>
      </form>
    </ModalPage>
  );
};

export default EditAccountContactModal;
