import { baseTheme } from "./theme";

export const invertedTheme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    primary: baseTheme.colors.inactive,
    secondary: baseTheme.colors.secondary,
    windowBg: baseTheme.colors.primary,
    contentBg: baseTheme.colors.textDark,
    textDark: baseTheme.colors.textLight,
    textLight: baseTheme.colors.contentBg,
  },
  shadows: {
    ...baseTheme.shadows,
    light: baseTheme.shadows.dark,
    dark: baseTheme.shadows.light,
  },
};
