import React from "react";
import { Box } from "rebass/styled-components";

const FormErrorMessage = props => {
  return (
    <Box
      fontSize="note"
      fontWeight={3}
      p="0.2rem"
      {...props}
      textAlign="right"
      color="textError"
      sx={{
        wordWrap: "break-word",
      }}>
      {props.children}
    </Box>
  );
};

export default FormErrorMessage;
