import styled from "styled-components";
import { Box } from "rebass/styled-components";
import { variant } from "styled-system";

const SelfcareBox = styled(Box)`
  ${variant({
    variants: {
      card: {
        bg: "contentBg",
        px: "2rem",
        py: "1.75rem",
        boxShadow: "light",
        borderRadius: "card",
      },
      grid: {
        display: "grid",
        gridGap: "large",
        gridTemplateColumns: ["1fr", "0.5fr 0.5fr"],
        flex: 1,
      },
    },
  })}
`;

export default SelfcareBox;
