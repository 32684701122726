import React, { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Flex, Text } from "rebass/styled-components";

import SubscriberIconDetails from "../SubscriberIconDetails";
import { SelfcareMobileWrapper } from "../base";
import { useHistory } from "react-router-dom";

import { AuthConsumer } from "../../contexts/AuthorizationContext";
import SubscriberAvatar from "../SubscriberAvatar";
import { BrandingContext } from "../../contexts/BrandingContext";
import { Providers } from "../../common/Providers";
import { SelfcareIcon } from "../icons";
import ChangePasswordModal from "../ChangePasswordModal/ChangePasswordModal";

const MobileNavigationOption = props => {
  return (
    <Box
      {...props}
      p="default"
      sx={{
        display: "inline-block",
        cursor: "pointer",
        "&:hover": {
          opacity: "70%",
        },
      }}
    />
  );
};

const ContextMenuOption = ({ onOptionSelected, ...props }) => {
  return (
    <Flex
      {...props}
      alignItems="center"
      onClick={() => onOptionSelected()}
      px="default"
      py="default"
      sx={{ cursor: "pointer" }}
    />
  );
};

const Separator = props => {
  return (
    <Flex
      {...props}
      as="hr"
      width="100%"
      sx={{
        bg: "inactive",
        border: 0,
        height: 1,
      }}
    />
  );
};

const MobileNavigationMenu = ({ menuItems, isLoading }) => {
  const history = useHistory();
  const mobileNavigationContainer = useRef();
  const contextMenuContainer = useRef();
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleOutsideClick = e => {
    if (contextMenuContainer.current.contains(e.target)) {
      return;
    }

    setShowContextMenu(false);
  };

  useEffect(() => {
    if (showContextMenu) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showContextMenu]);

  const { provider, config, switchTheme } = useContext(BrandingContext);
  const providerKeys = Object.keys(Providers);
  const currentThemeIndex = providerKeys.indexOf(provider);
  const nextTheme =
    currentThemeIndex === providerKeys.length - 1
      ? providerKeys[0]
      : providerKeys[currentThemeIndex + 1];

  return (
    <SelfcareMobileWrapper sx={{ position: "sticky", bottom: 0, boxShadow: "dark" }}>
      <Flex
        ref={mobileNavigationContainer}
        bg="secondary"
        alignItems="center"
        justifyContent={
          menuItems.length > 2 ? "space-between" : menuItems.length === 0 ? "flex-end" : "center"
        }
        px="large"
        py="default"
        height="mobileNavigationMenuHeight">
        {menuItems.map(item => {
          if (item.icon.mobile === null) {
            return null;
          }
          return (
            <MobileNavigationOption
              key={item.icon.mobile}
              mr={menuItems.length <= 2 ? "huge" : "0"}
              onClick={e => history.push(item.location)}>
              <SelfcareIcon name={item.icon.mobile} />
            </MobileNavigationOption>
          );
        })}
        <MobileNavigationOption onClick={() => setShowContextMenu(value => !value)}>
          <SelfcareIcon name="menu-burger-mobile" />
        </MobileNavigationOption>
      </Flex>

      {showContextMenu && (
        <Flex
          ref={contextMenuContainer}
          bg="contentBg"
          flexDirection="column"
          sx={{
            position: "absolute",
            bottom: mobileNavigationContainer.current.clientHeight,
            right: 0,
            boxShadow: "light",
          }}>
          <Flex alignItems="center">
            <SubscriberAvatar />
            <SubscriberIconDetails pr="default" py="default" sx={{ cursor: "default" }} />
          </Flex>

          <Separator />

          {!isLoading && (
            <>
              <ContextMenuOption
                onOptionSelected={() => {
                  setShowContextMenu(false);
                  setShowChangePassword(true);
                }}>
                <Text fontSize="default" color="primary">
                  <FormattedMessage id="lbl.change_password" />
                </Text>
              </ContextMenuOption>

              <Separator />
            </>
          )}
          {config.themeSwitch && (
            <ContextMenuOption onOptionSelected={() => switchTheme(nextTheme)}>
              <Text fontSize="default" color="primary">
                <FormattedMessage id="lbl.switch_theme" />
              </Text>
            </ContextMenuOption>
          )}

          <Separator />

          <AuthConsumer>
            {({ logout }) => {
              return (
                <ContextMenuOption onOptionSelected={() => logout(history)}>
                  <Text fontSize="default" color="primary">
                    <FormattedMessage id="lbl.log_out" />
                  </Text>
                </ContextMenuOption>
              );
            }}
          </AuthConsumer>
        </Flex>
      )}

      {showChangePassword && (
        <ChangePasswordModal
          showChangePassword={showChangePassword}
          onChangePasswordClose={() => {
            setShowChangePassword(false);
          }}
          onChangePasswordSuccess={() => {
            setShowChangePassword(false);
          }}
        />
      )}
    </SelfcareMobileWrapper>
  );
};

export default MobileNavigationMenu;
