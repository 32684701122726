import messages_en from "../resources/translations/en";
import messages_es from "../resources/translations/es";
import { IntlMessageFormat } from "intl-messageformat";

const MESSAGES = {
  en: messages_en,
  es: messages_es,
};

const INITIAL_LOCALE = "en";

class LocalizationApi {
  constructor() {
    this.locale = INITIAL_LOCALE;
  }

  setLocale(locale) {
    this.locale = locale;
  }

  /**
   * To be used only where useIntl() hook is not available. Gets corresponding formatted string for the specified key, using the provided variables.
   * @param {String} key The key of the message you want to format. Can be passed as a string ("lbl_hello_name") where "lbl_hello_name": "Hello {name}"
   * @param {Object} values Variables for replacement/formatting - an object containing keys, e.g. {name: "World"}.
   * @return {String} Corresponding formatted string for the specified key.
   */
  getString = (key, values = {}) => {
    return new IntlMessageFormat(MESSAGES[this.locale][key]).format(values);
  };
}

const Localization = new LocalizationApi();

export { MESSAGES, INITIAL_LOCALE, Localization };
