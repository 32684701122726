import React from "react";
export default ({ a_fill = "#cfed64", b_stroke = "#fff", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" {...props}>
    <circle fill={a_fill} cx="7" cy="7" r="7" />
    <path
      fill="none"
      stroke={b_stroke}
      strokeMiterlimit="10"
      strokeWidth="2.0px"
      d="M10.33,4.63,6.97,9.37,3.67,7.03"
    />
  </svg>
);
