import React from "react";
import { FormattedMessage } from "react-intl";
import { Flex, Text } from "rebass/styled-components";
import { SelfcareIcon } from "../icons";

const NavigationMenuItem = ({
  icon,
  titleLanguageID,
  isActive,
  textAlign,
  activeBorderPosition = "bottom",
  items,
  ...props
}) => (
  <Flex
    {...props}
    height="100%"
    alignItems="center"
    justifyContent="center"
    sx={{
      cursor: isActive ? "default" : "pointer",
      borderBottom: activeBorderPosition === "bottom" ? 3 : 0,
      borderBottomColor: isActive ? "secondary" : "transparent",
      borderTop: activeBorderPosition === "top" ? 3 : 0,
      borderTopColor: isActive ? "secondary" : "transparent",
      "&:hover": {
        opacity: isActive ? "100%" : "70%",
      },
    }}>
    {icon && <SelfcareIcon name={icon} width="2.875rem" height="3.375‬rem" />}
    <Text
      color="primary"
      fontSize="primary"
      ml={icon && icon !== "" ? "large" : "0"}
      textAlign={textAlign}
      width={items < 3 ? "min-content" : "max-content"}>
      <FormattedMessage id={titleLanguageID} />
    </Text>
  </Flex>
);

export default NavigationMenuItem;
