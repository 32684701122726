import React from "react";
import { Box, Heading, Image, Text } from "rebass/styled-components";
import { FormattedMessage } from "react-intl";
import StyledModal from "../base/StyledModal";

const StyledModalMessage = ({
  children,
  type,
  isOpen,
  onRequestClose,
  isCloseDisabled,
  message,
  ...props
}) => {
  function _mapTypeToIconAndHeadingMessage(type) {
    switch (type) {
      case "error":
        return {
        };
      case "warn":
        return {
        };
      case "loading":
        return {
          headingId: "lbl.loading_document",
        };
      case "loading_success":
        return {
          headingId: "lbl.loading_document_success",
        };
      default:
        return {
        };
    }
  }

  return (
    <StyledModal
      {...props}
      isOpen={isOpen}
      onBackgroundClick={onRequestClose}
      onEscapeKeydown={onRequestClose}
      opacity={1}
      width={[300, 500, 610]}>
      <Text
        as="span"
        sx={{
          color: "primary",
          float: "right",
          fontSize: "title",
          lineHeight: "solid",
          fontWeight: "bold",
          marginTop: "tiny",
          marginRight: "small",
          opacity: isCloseDisabled ? "70%" : "100%",
          ":hover": {
            cursor: isCloseDisabled ? "default" : "pointer",
            opacity: "70%",
          },
        }}
        onClick={onRequestClose}>
        &times;
      </Text>

      <Box px="default" py={["default", "large", "huge"]} textAlign="center">
        {_mapTypeToIconAndHeadingMessage(type).icon ? <Image
          src={_mapTypeToIconAndHeadingMessage(type).icon}
          width={["7rem", "9rem", "11rem"]}
          height={["7rem", "9rem", "11rem"]}
          textAlign="center"
        /> : <></>}
        {_mapTypeToIconAndHeadingMessage(type).headingId ?
        <Heading pt="medium" fontWeight={5} fontSize="title" lineHeight={1} color="primary">
          <FormattedMessage id={_mapTypeToIconAndHeadingMessage(type).headingId} />
        </Heading> : <></>}
        {message && (
          <Text color="primary" fontSize="primary" lineHeight={1} fontWeight={3} pt="default">
            {message}
          </Text>
        )}
        {children && (
          <Box pt="large" textAlign="center">
            {children}
          </Box>
        )}
      </Box>
    </StyledModal>
  );
};

export default StyledModalMessage;
