import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Flex, Box, Image, Text, Link } from "rebass/styled-components";

import { BrandingContext } from "../../contexts/BrandingContext";
import { beautifyURL } from "../../common/Utilities";

const DesktopFooter = () => {
  const { config, logoAlt } = useContext(BrandingContext);

  return (
    <Box bg="primary">
      <Box
        width="100%"
        maxWidth="footerContentMaxWidth"
        mx="auto"
        px={["default", "default", "8.5rem"]}
        py={["large", "large", "huge"]}
        display="grid"
        sx={{
          gridGap: "0",
          gridTemplateColumns: ["0.4fr 0.2fr 0.4fr"],
          gridAutoRows: "1fr",
          flexDirection: "row",
          justifyContent: "center",
        }}>
        <Flex
          alignItems={["flex-start", "flex-start", "flex-end"]}
          justifyContent={["flex-end", "flex-end", "flex-start"]}
          flexDirection={["column", "column", "row"]}>
          <Flex flexDirection="column" mr={["0px", "0px", "10%"]} mb={["0px", "default", "0px"]}>
            <Text fontWeight={5} fontSize="title" color="textLight" mb="tiny">
              <FormattedMessage id="lbl.give_us_a_call" />
            </Text>
            <Text fontWeight={2} fontSize="primary" color="textLight">
              {config.phoneNumber}
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <Text fontWeight={5} fontSize="title" color="textLight" mb="tiny">
              <FormattedMessage id="lbl.pay_us_a_visit" />
            </Text>
            <Link
              target="_blank"
              fontWeight={2}
              fontSize="primary"
              color="textLight"
              href={config.website}>
              {beautifyURL(config.website)}
            </Link>
          </Flex>
        </Flex>
        <Flex alignItems="center" justifyContent="center" px="default">
          <Image src={logoAlt} />
        </Flex>
        <Flex alignItems="center" justifyContent="flex-end">
          <Link
            href={config.terms_and_conditions}
            target="_blank"
            fontWeight={2}
            fontSize="primary"
            color="textLight">
            <FormattedMessage id="lbl.terms_of_use" />
          </Link>
        </Flex>
      </Box>
    </Box>
  );
};

export default DesktopFooter;
