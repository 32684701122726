const OAuthIssuerURL = new URL(
  window._env_ ? window._env_.OAUTH2_TOKEN_ISSUER : "https://newselfcare.mindsoft.com:8400"
).toString();

const OAuthCredentials = {
  authorizationURL: OAuthIssuerURL + "oauth/authorize",
  tokenURL: OAuthIssuerURL + "oauth/token",
  clientID: "SELFCARE",
  scope: "subscriber",
  redirectURI: "/",
};

export { OAuthCredentials };
