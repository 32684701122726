import React from "react";
import ContentLoader from "react-content-loader";

const WelcomeSkeleton = ({ ...props }) => (
  <ContentLoader
    {...props}
    speed={2}
    viewBox="0 0 237.2 33.2"
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">
    <rect width="237.2" height="33.2" />
  </ContentLoader>
);

export default WelcomeSkeleton;
