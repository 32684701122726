import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Flex, Heading } from "rebass/styled-components";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { useParams } from "react-router-dom";

import Layout from "../../containers/Layout";
import NavigationSecondaryMenu from "../../components/NavigationSecondaryMenu";

const Summary = ({ children }) => {
  const { accountCode: masterAccountCode, isSubAccount, currentAccountDetails } = useContext(
    AuthContext
  );
  const { accountCode: accountCodePath } = useParams();

  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;

  const menuItems = [
    {
      title: "lbl.account_details",
      icon: "",
      location: isSubAccount ? `/account/${accountCode}/summary/account` : "/summary/account",
    },
    {
      title: "lbl.payment_information",
      icon: "",
      location: isSubAccount ? `/account/${accountCode}/summary/payment` : "/summary/payment",
    },
  ];

  return (
    <Layout
      mobileTabSubMenu={
        <NavigationSecondaryMenu.Mobile menuItems={menuItems} isSubAccount={isSubAccount} />
      }
      isMasterAccount={true}>
      <Heading
        mt={isSubAccount ? ["default", "default", "medium"] : ["default", "default", "largeish"]}
        color="textDark"
        fontSize="title"
        fontWeight={5}>
        {isSubAccount && currentAccountDetails ? (
          currentAccountDetails.accountName
        ) : (
          <FormattedMessage id="lbl.menu_account_summary" />
        )}
      </Heading>
      {!isSubAccount && (
        <NavigationSecondaryMenu.Desktop
          menuItems={menuItems}
          mt={["default", "default", "largeish"]}
        />
      )}
      <Flex py={isSubAccount ? "default" : "large"}>{children}</Flex>
    </Layout>
  );
};

export default Summary;
