import React, { useContext } from "react";
import { Flex } from "rebass/styled-components";
import { useIntl } from "react-intl";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { capitalize } from "../../common/Utilities";
import Tooltip from "../Tooltip";
import { SelfcareIcon } from "../icons";
import { BUSINESS_CATEGORIES } from "../../common/Constants";

const SubscriberAvatar = () => {
  const intl = useIntl();
  const { masterAccount } = useContext(AuthContext);

  return (
    <Flex flexDirection="row" alignItems="flex-end" mx="default" height="100%">
      {masterAccount && (
        <SelfcareIcon
          name={
            masterAccount.businessCategory === BUSINESS_CATEGORIES.RESIDENTIAL
              ? "user-residential"
              : "user-commercial"
          }
          width="3.125rem"
          height="3.1875rem"
        />
      )}
      {masterAccount && (
        <>
          {masterAccount.accountStatus === "ACTIVE" && (
            <Tooltip
              value={intl.formatMessage(
                { id: "lbl.status" },
                { status: capitalize(masterAccount.accountStatus) }
              )}
              position="bottom">
              <SelfcareIcon name="user-active-status" ml="-0.8rem" />
            </Tooltip>
          )}
          {masterAccount.accountStatus === "SUSPENDED" && (
            <Tooltip
              value={intl.formatMessage(
                { id: "lbl.status" },
                { status: capitalize(masterAccount.accountStatus) }
              )}
              position="bottom">
              <SelfcareIcon name="user-suspended-status" ml="-0.8rem" />
            </Tooltip>
          )}
          {masterAccount.accountStatus === "CLOSED" && (
            <Tooltip
              value={intl.formatMessage(
                { id: "lbl.status" },
                { status: capitalize(masterAccount.accountStatus) }
              )}
              position="bottom">
              <SelfcareIcon name="user-closed-status" ml="-0.8rem" />
            </Tooltip>
          )}
        </>
      )}
    </Flex>
  );
};

export default SubscriberAvatar;
