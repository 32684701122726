import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { BUSINESS_CATEGORIES } from "../../common/Constants";
import SummaryRow from "../../components/SummaryRow/SummaryRow";
import SummaryLayout from "../../containers/SummaryLayout";
import { AuthContext } from "../../contexts/AuthorizationContext";
import SubscriberInformationSkeleton from "./SubscriberInformationSkeleton";

const SubscriberInformation = ({ account, isLoading }) => {
  const intl = useIntl();
  const { identificationNumber: masterIdentificationNumber } = useContext(AuthContext);

  const address = accountAddress => {
    if (!accountAddress) {
      return "";
    }

    let address = "";
    address += accountAddress.street ? accountAddress.street.join(", ") + ", " : "";
    address += accountAddress.city ? accountAddress.city + ", " : "";
    address += accountAddress.state ? accountAddress.state + ", " : "";
    address += accountAddress.zip ? accountAddress.zip + ", " : "";
    address += accountAddress.country ? accountAddress.country : "";

    return address;
  };

  const servedAddress = address => {
    if (!address) {
      return "";
    }

    let servedAddress = [];
    servedAddress.push(address.street);
    servedAddress.push(address.building);
    servedAddress.push(address.apartment);
    servedAddress.push(address.city);
    servedAddress.push(address.county);
    servedAddress.push(address.zip);
    servedAddress.push(address.country);

    return servedAddress.filter(el => el !== "").join(", ");
  };

  const maskedIdentificationNumber = num => {
    if (num) {
      return num.replace(num.slice(0, 5), "*".repeat(5));
    }
  };

  if (isLoading) {
    return <SummaryLayout isLoading skeleton={SubscriberInformationSkeleton} />;
  }

  return (
    <SummaryLayout title="lbl.sa.subscriber_information">
      <SummaryRow
        tooltip={intl.formatMessage({ id: "tooltip.account_holder_name" })}
        icon="name"
        value={
          account.businessCategory === BUSINESS_CATEGORIES.COMMERCIAL
            ? account.companyName
            : `${account.firstName} ${account.lastName}`
        }
      />

      {servedAddress(account.accountServedAddress) !== "" && (
        <SummaryRow
          tooltip={intl.formatMessage({ id: "tooltip.service_address" })}
          icon="service-address"
          value={servedAddress(account.accountServedAddress)}
          customTextProps={{ whiteSpace: "normal" }}
        />
      )}
      {servedAddress(account.accountServedAddress) === "" && (
        <SummaryRow
          tooltip={intl.formatMessage({ id: "tooltip.contact_address" })}
          icon="contact-address"
          value={address(account.billingAddress)}
          customTextProps={{ whiteSpace: "normal" }}
        />
      )}
      {account.businessCategory === BUSINESS_CATEGORIES.COMMERCIAL && (
        <SummaryRow
          tooltip={intl.formatMessage({ id: "tooltip.federal_id" })}
          icon="identity"
          value={maskedIdentificationNumber(
            account.identificationNumber !== ""
              ? account.identificationNumber
              : masterIdentificationNumber
          )}
        />
      )}
    </SummaryLayout>
  );
};

export default SubscriberInformation;
