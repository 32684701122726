import React from "react";

import { SelfcareDesktopAndTabletWrapper, SelfcareMobileWrapper } from "../base";
import BillingTimelineDesktop from "./BillingTimelineDesktop";
import BillingTimelineMobile from "./BillingTimelineMobile";

const BillingTimeline = ({ billingSummary, ...props }) => {
  return (
    <>
      <SelfcareDesktopAndTabletWrapper>
        <BillingTimelineDesktop billingSummary={billingSummary} {...props} />
      </SelfcareDesktopAndTabletWrapper>

      <SelfcareMobileWrapper>
        <BillingTimelineMobile billingSummary={billingSummary} {...props} />
      </SelfcareMobileWrapper>
    </>
  );
};

export default BillingTimeline;
