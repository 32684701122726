import React, { useContext } from "react";
import qs from "qs";

import { OAuthCredentials } from "../../common/Deployment";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { BrandingContext } from "../../contexts/BrandingContext";

const AuthorizationSender = ({ location, children }) => {
  const { provider } = useContext(BrandingContext);
  const { codeVerifier } = useContext(AuthContext);
  const { authorizationURL, clientID, scope, redirectURI } = OAuthCredentials;

  let url =
    authorizationURL +
    "?" +
    qs.stringify({
      response_type: "code",
      client_id: clientID,
      redirect_uri: window.location.origin + redirectURI,
      scope: scope,
      state: location,
      provider: provider,
      code_challenge: codeVerifier,
    });

  return <a href={url}>{children}</a>;
};

export default AuthorizationSender;
