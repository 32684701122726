import React from "react";
import ERROR_CODES from "../../common/Constants";
import { FormattedMessage } from "react-intl";

const GetErrorDescription = ({ error }) => {
  let errorDescription;

  // messages from core/api (ApiError.description) are rarely translated, define custom when needed
  switch (error.errorCode) {
    case ERROR_CODES.ALREADY_REGISTERED_TO_SELFCARE:
      errorDescription = <FormattedMessage id="err.already_registered" />;
      break;
    case ERROR_CODES.BLOCKED_SELFCARE_ACCESS:
      errorDescription = <FormattedMessage id="err.access_disabled" />;
      break;
    case ERROR_CODES.BLOCKED_SELFCARE_REGISTRATION:
      errorDescription = <FormattedMessage id="err.selfcare_registration_blocked" />;
      break;
    case ERROR_CODES.ACCOUNT_NOT_FOUND:
    case ERROR_CODES.INVOICE_NOT_FOUND:
      errorDescription = <FormattedMessage id="err.account_not_found" />;
      break;
    case ERROR_CODES.INVALID_VERIFICATION_CODE:
      errorDescription = <FormattedMessage id="err.invalid_verification_code" />;
      break;
    case ERROR_CODES.ACCOUNT_CONTACT_NOT_FOUND:
      errorDescription = <FormattedMessage id="err.account_contact_not_found" />;
      break;
    case ERROR_CODES.AT_LEAST_ONE_SIGN_UP_METHOD_MUST_BE_ENTERED:
      errorDescription = <FormattedMessage id="err.at_least_one_sign_up_method_must_be_entered" />;
      break;
    case ERROR_CODES.NOTIFICATION_LETTER_DESTINATION_NOT_FOUND:
      errorDescription = (
        <FormattedMessage id="err.details_that_you_have_provided_are_not_available_contact_call_center" />
      );
      break;
    case ERROR_CODES.SECURITY_QUESTION_REPEAT_NOT_ALLOWED:
      errorDescription = <FormattedMessage id="err.you_cannot_repeat_secret_questions" />;
      break;
    case ERROR_CODES.ACCOUNT_WEB_LOGIN_NAME_INVALID:
      errorDescription = <FormattedMessage id="err.web_login_name_already_exists" />;
      break;
    case ERROR_CODES.CHANGE_PASSWORD_ERROR:
    case ERROR_CODES.MISSING_SECURITY_RIGHT:
    case ERROR_CODES.DIRECT_DEBIT_PAYMENTS_NOT_ALLOWED:
      errorDescription = error.description;
      break;
    case ERROR_CODES.NO_ACCOUNT_STATEMENT_BANK_HOLDER_NAME:
      errorDescription = (
        <FormattedMessage id="err.bank_details_must_be_defined_fist_missing_account_holder_name" />
      );
      break;
    case ERROR_CODES.NO_ACCOUNT_STATEMENT_BANK_ACCOUNT_TYPE:
      errorDescription = (
        <FormattedMessage id="err.bank_details_must_be_defined_fist_missing_account_bank_type" />
      );
      break;
    case ERROR_CODES.ITEM_IS_BLACKLISTED:
      errorDescription = <FormattedMessage id="err.bank_account_blacklisted" />;
      break;
    case ERROR_CODES.BANK_ACCOUNT_IS_INVALID:
      errorDescription = <FormattedMessage id="err.bank_account_number_invalid" />;
      break;
    case ERROR_CODES.TOKEN_NO_LONGER_VALID:
      errorDescription = <FormattedMessage id="err.link_no_longer_valid" />;
      break;
    case ERROR_CODES.USER_NOT_FOUND:
      errorDescription = <FormattedMessage id="err.user_not_found" />;
      break;
    case ERROR_CODES.FORGOT_PASSWORD_ERROR:
      errorDescription = (
        <FormattedMessage
          id="err.please_wait_until_another_forgot_action"
          values={{
            difference:
              error.additionalInfo && error.additionalInfo.difference
                ? error.additionalInfo.difference
                : 1,
          }}
        />
      );
      break;
    case ERROR_CODES.CLOSED_ACCOUNT:
      errorDescription = <FormattedMessage id="err.closed_account" />;
      break;
    default:
      errorDescription = <FormattedMessage id="err.generic" />;
      break;
  }

  return errorDescription;
};

export default GetErrorDescription;
