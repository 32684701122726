import React from "react";
import { Box } from "rebass/styled-components";
import { props as systemProps } from "@styled-system/should-forward-prop";

const rebassProps = [...systemProps, "sx", "variant"];

const PRE = new RegExp(`^(${rebassProps.join("|")})$`);

const getProps = test => props => {
  const next = {};
  for (const key in props) {
    if (test(key || "")) next[key] = props[key];
  }
  return next;
};
const getSystemProps = getProps(k => PRE.test(k));

const SVG = ({ size = 24, ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentcolor"
    {...props}
  />
);

export { getSystemProps, SVG };
