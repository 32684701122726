import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useGET } from "../../hooks/restAPI";
import { AuthContext } from "../../contexts/AuthorizationContext";
import Summary from "../Summary";
import ContactDetails from "../../components/ContactDetails";
import CpniDetails from "../../components/CpniDetails";
import BillingDetails from "../../components/BillingDetails";
import { SelfcareBox, SelfcareButton } from "../../components/base";
import StyledModalMessage from "../../components/StyledModalMessage";
import GetErrorDescription from "../../components/GetErrorDescription";

const AccountDetails = () => {
  const history = useHistory();
  const { accountCode: masterAccountCode, setCurrentAccount } = useContext(AuthContext);
  const { accountCode: accountCodePath } = useParams();

  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;

  const [accountData, getAccountData, resetGetAccountData] = useGET();
  const [contactsData, getContacts, resetGetContacts] = useGET();
  const [invoiceOptionFeature, getInvoiceOptionFeature, resetGetInvoiceOptionFeature] = useGET();

  useEffect(() => {
    const loadInitialData = () => {
      getAccountData({
        route: `/account/${accountCode}`,
      });

      getContacts({
        route: `/account/${accountCode}/contacts`,
      });

      getInvoiceOptionFeature({
        route: `/account/${accountCode}/invoiceOption`,
      });
    };

    loadInitialData();
  }, [accountCode, getInvoiceOptionFeature, getAccountData, getContacts]);

  useEffect(() => {
    if (accountData.isSuccess && accountCodePath !== undefined) {
      setCurrentAccount(Boolean(accountData.data.parentAccountCode), accountData.data);
    }
  }, [accountData.data, accountData.isSuccess, accountCodePath, setCurrentAccount]);

  const refreshCPNI = () => {
    getAccountData({
      route: `/account/${accountCode}`,
    });
  };

  const refreshContacts = () => {
    getContacts({
      route: `/account/${accountCode}/contacts`,
    });
  };

  const refreshBilling = () => {
    getInvoiceOptionFeature({
      route: `/account/${accountCode}/invoiceOption`,
    });
  };

  const tryAgain = () => {
    resetGetAccountData();
    resetGetContacts();
    resetGetInvoiceOptionFeature();

    getAccountData({
      route: `/account/${accountCode}`,
    });

    getContacts({
      route: `/account/${accountCode}/contacts`,
    });

    getInvoiceOptionFeature({
      route: `/account/${accountCode}/invoiceOption`,
    });
  };

  if (accountData.isOffline || contactsData.isOffline || invoiceOptionFeature.isOffline) {
    history.push("/error");
  }

  const accountInfo = accountData.data;

  let primaryContact = { name: {}, phone: {} };
  let shipmentContact = { name: {}, phone: {} };

  if (contactsData.data && contactsData.data.accountContacts) {
    primaryContact = contactsData.data.accountContacts.find(element => element.primary === true);
    shipmentContact = contactsData.data.accountContacts.find(element => element.shipment === true);
  }

  const isError = accountData.isError || contactsData.isError || invoiceOptionFeature.isError;
  const error = accountData.error || contactsData.error || invoiceOptionFeature.error;

  return (
    <Summary>
      {isError && (
        <StyledModalMessage
          isOpen={isError}
          message={<GetErrorDescription error={error} />}
          onRequestClose={tryAgain}
          type="error">
          <SelfcareButton variant="secondarySmall" onClick={tryAgain}>
            <FormattedMessage id="lbl.Try_Again" />
          </SelfcareButton>
        </StyledModalMessage>
      )}
      <SelfcareBox variant="grid">
        <ContactDetails
          isLoading={contactsData.isLoading}
          primaryContact={primaryContact}
          refreshContacts={refreshContacts}
        />

        <CpniDetails
          isLoading={accountData.isLoading}
          cpniDetails={accountInfo.cpniDetails}
          refreshCPNI={refreshCPNI}
        />

        <BillingDetails
          isLoading={
            accountData.isLoading || invoiceOptionFeature.isLoading || contactsData.isLoading
          }
          shipmentContact={shipmentContact}
          billingCycle={
            accountInfo.billingDetails ? accountInfo.billingDetails.billingCycleDescription : null
          }
          invoiceOptionFeature={invoiceOptionFeature}
          refreshBilling={refreshBilling}
        />
      </SelfcareBox>
    </Summary>
  );
};

export default AccountDetails;
