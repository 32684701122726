import React, { useState, createContext } from "react";
import { ThemeProvider } from "styled-components";
import { Providers, defaultProvider } from "../common/Providers";

const BrandingContext = createContext();

const BrandingProvider = ({ children }) => {
  const doSwitchTheme = provider => {
    setBranding({
      ...branding,
      provider: provider,
      theme: Providers[provider].theme,
      logo: Providers[provider].logo,
      logoAlt: Providers[provider].logoAlt,
      config: Providers[provider].config,
    });
  };

  const [branding, setBranding] = useState({
    provider: defaultProvider,
    theme: Providers[defaultProvider].theme,
    logo: Providers[defaultProvider].logo,
    logoAlt: Providers[defaultProvider].logoAlt,
    config: Providers[defaultProvider].config,
    switchTheme: doSwitchTheme,
  });

  return (
    <BrandingContext.Provider value={branding}>
      <ThemeProvider theme={branding.theme}>{children}</ThemeProvider>
    </BrandingContext.Provider>
  );
};

export { BrandingProvider, BrandingContext };
