import React from "react";
export default ({ color = "#fff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.2"
    height="22.025"
    viewBox="0 0 20.2 22.025"
    {...props}>
    <path
      fill={color}
      d="M19.7,9.4,10.5.2A.785.785,0,0,0,9.4.2L.2,9.4A1.421,1.421,0,0,0,0,10V21.2a.789.789,0,0,0,.8.8H7.3a.789.789,0,0,0,.8-.8V15.6h4v5.6a.789.789,0,0,0,.8.8h6.5a.789.789,0,0,0,.8-.8V10C19.9,9.8,19.9,9.6,19.7,9.4Zm-1.3,11h-5V14.8a.789.789,0,0,0-.8-.8H7.2a.789.789,0,0,0-.8.8v5.6h-5V10.3L10,1.8l8.5,8.5V20.4Z"
      transform="translate(0 0.025)"
    />
  </svg>
);
