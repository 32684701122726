import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../contexts/AuthorizationContext";

/**
 * Public route.
 *
 * @param {*} param0.component The component to display for this route.
 * @param {*} param0.restricted True if the page is restricted, false otherwise.
 * @param {*} param0.options The rest of props to send for Route.
 *
 * @returns The specified component or a redirect to Home if the user is
 *          authenticated and the specified route is restricted.
 *          Useful behavior when the user is authenticated and access
 *          one of the Registration or Login page again.
 */
const PublicRoute = ({ component: Component, restricted, ...options }) => {
  const { loggedIn } = useContext(AuthContext);
  return (
    <Route
      {...options}
      render={props =>
        loggedIn && restricted ? (
          <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
