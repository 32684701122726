import React, { useContext, useEffect, useState } from "react";
import MasterAccountLayout from "../../components/MasterAccountLayout";
import ServiceAgreementLayout from "../../components/ServiceAgreementLayout";
import Layout from "../../containers/Layout";
import { AuthContext } from "../../contexts/AuthorizationContext";
import HostedCcOperationRequest from "../../pages/HostedCcOperationRequest";
import { useGET } from "../../hooks/restAPI";
import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();
  const { accountCode, billable, serviceAgreement, accountType, setCurrentAccount } = useContext(
    AuthContext
  );
  const [initiatePayment, setInitiatePayment] = useState(false);
  const [payment, setPayment] = useState({ amount: 0, card: 0 });
  const [internetSasWithoutTC, doGetInternetSasWithoutTC] = useGET();

  useEffect(() => {
    doGetInternetSasWithoutTC({
      route: `/account/${accountCode}/termsAndConditionsAccounts`,
    });
  }, [accountCode, doGetInternetSasWithoutTC]);

  useEffect(() => {
    setCurrentAccount(false);
    window.localStorage.setItem(
      "hierarchy",
      JSON.stringify([{ code: accountCode, title: "Home" }])
    );
  }, [accountCode, setCurrentAccount]);

  useEffect(() => {
    if (internetSasWithoutTC && internetSasWithoutTC.data && internetSasWithoutTC.data.length > 0) {
      history.push("/internetTermsAndConditions", {
        internetSasWithoutTC: internetSasWithoutTC.data,
      });
    }
    if (internetSasWithoutTC && internetSasWithoutTC.isOffline) {
      history.push("/error");
    }
  }, [history, internetSasWithoutTC]);

  return (
    <>
      {!initiatePayment && (
        <Layout disableNavMenuDefaultLocation={true}>
          {!serviceAgreement && billable !== undefined && (
            <MasterAccountLayout
              accountCode={accountCode}
              isBillable={billable}
              setPayment={setPayment}
              setInitiatePayment={setInitiatePayment}
            />
          )}
          {serviceAgreement && accountType !== undefined && (
            <ServiceAgreementLayout accountCode={accountCode} accountType={accountType} />
          )}
        </Layout>
      )}
      {initiatePayment && (
        <HostedCcOperationRequest
          amount={payment.amount}
          card={payment.card}
          responseUrl={encodeURIComponent(window.location.pathname)}
        />
      )}
    </>
  );
};

export default Home;
