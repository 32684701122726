import React, { useContext, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Flex, Heading, Text } from "rebass/styled-components";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import ModalPage from "../../components/ModalPage";
import StyledModalMessage from "../../components/StyledModalMessage";
import GetErrorDescription from "../../components/GetErrorDescription";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { SelfcareButton } from "../../components/base";
import { usePUT } from "../../hooks/restAPI";

const EditAutomaticPaymentSettingsModal = ({
  settings,
  hasCreditCardDetails,
  hasBankAccountDetails,
  isOpen,
  onClose,
  onEditSuccess,
}) => {
  const { accountCode: masterAccountCode } = useContext(AuthContext);
  const { accountCode: accountCodePath } = useParams();

  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;

  const { handleSubmit } = useForm({ mode: "onChange" });
  const [doPutPaymentSettingsData, doPutPaymentSettings, resetPutPaymentSettingsError] = usePUT({});
  const [automaticPaymentMethod, setAutomaticPaymentMethod] = useState(
    settings.automaticPaymentMethod
  );

  useEffect(() => {
    if (doPutPaymentSettingsData.isSuccess) {
      onEditSuccess();
    }
  }, [doPutPaymentSettingsData.isSuccess, onEditSuccess]);

  const onSave = () => {
    const putData = {
      route: `/account/${accountCode}/payment/settings`,
      expectedResponse: "none",
      body: {
        automaticPayment: automaticPaymentMethod !== "NONE",
        automaticPaymentMethod: automaticPaymentMethod,
      },
    };

    doPutPaymentSettings(putData);
  };

  return (
    <ModalPage isOpen={isOpen} onRequestClose={onClose}>
      <form onSubmit={handleSubmit(onSave)}>
        <Box px="larger" py="larger">
          <Heading
            as="h3"
            fontWeight={5}
            pb="medium"
            fontSize="title"
            color="textDark"
            textAlign={["center", "center", "left"]}>
            <FormattedMessage id="lbl.automatic_payment" />
          </Heading>

          <Box
            my="small"
            flexDirection={["column", "row"]}
            alignItems={["center", "center", "left"]}
            textAlign="center">
            <Text
              color="textDark"
              fontWeight={4}
              fontSize="secondary"
              textAlign={["center", "center", "left"]}
              mr={[0, "small"]}
              mb="default">
              <FormattedMessage id="lbl.automatic_payment" />
            </Text>
            <Flex
              flexDirection={["column", "column", "row"]}
              mt={["default", 0]}
              alignItems="center">
              <SelfcareButton
                px="0 !important"
                variant={
                  automaticPaymentMethod === "NONE" ? "tertiaryNoWidth" : "tertiaryWhiteNoWidth"
                }
                onClick={event => {
                  event.preventDefault();
                  setAutomaticPaymentMethod("NONE");
                }}
                sx={{
                  boxShadow: "none !important",
                }}
                width="17rem">
                <FormattedMessage id="lbl.automatic_payment_method_off" />
              </SelfcareButton>
              {hasCreditCardDetails && (
                <SelfcareButton
                  px="0 !important"
                  variant={
                    automaticPaymentMethod === "CREDIT_CARD"
                      ? "tertiaryNoWidth"
                      : "tertiaryWhiteNoWidth"
                  }
                  onClick={event => {
                    event.preventDefault();
                    setAutomaticPaymentMethod("CREDIT_CARD");
                  }}
                  sx={{
                    boxShadow: "none !important",
                  }}
                  width="17rem">
                  <FormattedMessage id="lbl.automatic_payment_method_credit_card" />
                </SelfcareButton>
              )}
              {hasBankAccountDetails && (
                <SelfcareButton
                  px="0 !important"
                  variant={
                    automaticPaymentMethod === "DIRECT_DEBIT"
                      ? "tertiaryNoWidth"
                      : "tertiaryWhiteNoWidth"
                  }
                  onClick={event => {
                    event.preventDefault();
                    setAutomaticPaymentMethod("DIRECT_DEBIT");
                  }}
                  sx={{
                    boxShadow: "none !important",
                  }}
                  width="17rem">
                  <FormattedMessage id="lbl.automatic_payment_method_direct_debit" />
                </SelfcareButton>
              )}
            </Flex>
          </Box>

          <Flex
            width="auto"
            mt="larger"
            mb="huge"
            alignItems="center"
            justifyContent={["center", "center", "flex-end"]}>
            <SelfcareButton type="submit" variant="default" mr="spaceBetweenButtons">
              <FormattedMessage id="lbl.save" />
            </SelfcareButton>

            <SelfcareButton
              variant="default-inverted"
              onClick={event => {
                event.preventDefault();
                onClose();
              }}>
              <FormattedMessage id="lbl.cancel" />
            </SelfcareButton>
          </Flex>

          {doPutPaymentSettingsData.isError && (
            <StyledModalMessage
              isOpen={doPutPaymentSettingsData.isError}
              message={<GetErrorDescription error={doPutPaymentSettingsData.error} />}
              onRequestClose={resetPutPaymentSettingsError}
              type="error">
              <SelfcareButton variant="secondarySmall" onClick={resetPutPaymentSettingsError}>
                <FormattedMessage id="lbl.Try_Again" />
              </SelfcareButton>
            </StyledModalMessage>
          )}
        </Box>
      </form>
    </ModalPage>
  );
};

export default EditAutomaticPaymentSettingsModal;
