import React from "react";
import { useIntl } from "react-intl";

import { Circle, Stroke } from "../Lines";
import { TIMELINE_EVENT_SIZE } from "../Common";
import { BaseIcon } from "../../icons";

const TimelineDueMobile = ({
  strokeColor,
  circleColor,
  textColor,
  invoiceDueDate,
  isDueDateInFuture,
  ...props
}) => {
  const { transform, svgProps } = props;
  const intl = useIntl();

  return (
    <g transform={transform}>
      <svg
        {...svgProps}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 400 ${TIMELINE_EVENT_SIZE}`}
        width="400px"
        height={`${TIMELINE_EVENT_SIZE}px`}
        overflow="visible">
        <g>
          <text x="300" y="85%" textAnchor="start" fill={textColor}>
            <tspan fontSize="16px">{intl.formatDate(invoiceDueDate)}</tspan>
          </text>

          <Stroke
            color={strokeColor}
            size="large"
            dashed={isDueDateInFuture}
            transform="translate(292 0) rotate(90)"
          />
          <Circle color={circleColor} transform="translate(168.5 121.2)" />
          <g transform="translate(190.5 140)">
            <BaseIcon name="due" />
          </g>

          <text x="155" y="85%" textAnchor="end" fill={textColor}>
            <tspan fontSize="16px">{intl.formatMessage({ id: "lbl.invoice_due" })}</tspan>
          </text>
        </g>
      </svg>
    </g>
  );
};

export default TimelineDueMobile;
