import React from "react";
export default ({ color = "#b5b5b5", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.67"
    height="26"
    viewBox="0 0 21.67 26"
    {...props}>
    <path
      fill={color}
      d="M17.33,11.38V6.5a6.5,6.5,0,0,0-13,0v4.88H0V26H21.67V11.38ZM5.42,6.5a5.42,5.42,0,0,1,10.84,0v4.88H5.42ZM20.58,24.92H1.08V12.46h19.5Z"
    />
    <path
      fill={color}
      d="M10.29,21.05v2.24h1.08V21.05a3.36,3.36,0,1,0-1.08,0Zm.54-5.64A2.33,2.33,0,1,1,8.5,17.74,2.332,2.332,0,0,1,10.83,15.41Z"
    />
  </svg>
);
