import React from "react";

export default ({ screen = "#114878", bezel = "#336eb4", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40.593"
    height="79.177"
    viewBox="0 0 40.593 79.177"
    {...props}>
    <g transform="translate(-1337 -272)">
      <rect fill={screen} width="36.543" height="71.564" transform="translate(1339.04 276.568)" />
      <path
        fill={bezel}
        d="M40.593,73.086v-67A6.108,6.108,0,0,0,34.5,0H6.091A6.108,6.108,0,0,0,0,6.091v67a6.108,6.108,0,0,0,6.091,6.091H34.5A6.108,6.108,0,0,0,40.593,73.086ZM23.357,76.132H17.236a1.972,1.972,0,0,1-1.766-1,2.053,2.053,0,0,1,1.8-3.045h6.121a1.972,1.972,0,0,1,1.766,1A2.074,2.074,0,0,1,23.357,76.132Zm14.191-7.1H2.984v-58.9H37.548Z"
        transform="translate(1337 272)"
      />
    </g>
  </svg>
);
