import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Flex, Heading, Text } from "rebass/styled-components";
import { SelfcareButton, SelfcareBox } from "../../components/base";

const SummaryLayout = ({
  children,
  title,
  showEdit,
  onEdit,
  showAdd,
  onAdd,
  isLoading,
  skeleton: Skeleton,
}) => {
  if (isLoading) {
    return (
      <SelfcareBox variant="card" px="0 !important" py="0 !important">
        <Skeleton />
      </SelfcareBox>
    );
  }

  return (
    <SelfcareBox variant="card">
      <Flex justifyContent="space-between" alignItems="center">
        <Heading color="textDark" fontSize="primary" mr="small">
          <FormattedMessage id={title} />
        </Heading>

        {showEdit && (
          <SelfcareButton
            onClick={onEdit}
            variant="default"
            sx={{ width: "6.3125rem", height: "2.1875rem" }}>
            <Text>
              <FormattedMessage id="lbl.edit" />
            </Text>
          </SelfcareButton>
        )}

        {showAdd && (
          <SelfcareButton
            onClick={onAdd}
            variant="default"
            sx={{ width: "6.3125rem", height: "2.1875rem" }}>
            <Text>
              <FormattedMessage id="lbl.add" />
            </Text>
          </SelfcareButton>
        )}
      </Flex>
      <Box>{children}</Box>
    </SelfcareBox>
  );
};

export default SummaryLayout;
