import Modal from "styled-react-modal";
import { color, layout, space } from "styled-system";

const StyledModal = Modal.styled`
  height: fit-content;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #F8F8F8;
  border-radius: 0;
  width: 610px;
  box-shadow: 0px 6px 6px #02257528;
  overflow: auto;
  transition: opacity ease 500ms;
  ${space}
  ${layout}
  ${color}
`;

export default StyledModal;
