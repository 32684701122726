import React from "react";
export default ({ pin = "#114878", circle = "#346eb3", icon = "#318eff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.013"
    height="30.875"
    viewBox="0 0 29.013 30.875"
    {...props}>
    <g transform="translate(0.013)">
      <path
        fill={pin}
        d="M12.1,0A8.173,8.173,0,0,0,3.9,8.2c0,6.6,8.2,16,8.2,16s8.2-9.3,8.2-16A8.3,8.3,0,0,0,12.1,0Zm0,11.7A3.691,3.691,0,0,1,8.4,8a3.7,3.7,0,0,1,7.4,0A3.757,3.757,0,0,1,12.1,11.7Z"
      />
      <path
        fill="none"
        stroke={circle}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.026px"
        d="M15,26.9a28.124,28.124,0,0,1-3,.1C5.6,27,.5,25.3.5,23.2c0-1.5,2.5-2.8,6.3-3.4"
      />
      <path
        fill="none"
        stroke={circle}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.026px"
        d="M17.4,19.8a15.511,15.511,0,0,1,3.6,1"
      />
      <path
        fill={icon}
        d="M18.9,24.5a2.234,2.234,0,0,0,2.6-1.2A16.542,16.542,0,0,1,23,20.8a2.41,2.41,0,0,0-.1-3.1.3.3,0,0,1,0-.4L24,15.5a23.924,23.924,0,0,1,1.3-2.1c.1-.2.3-.2.5-.1l.2.1,1.7,1c.4.2.7.4,1.1.6.2.1.2.2.2.4a29.371,29.371,0,0,1-3.5,8.2,36.023,36.023,0,0,1-5.6,7.2.3.3,0,0,1-.4,0h0a10.54,10.54,0,0,1-1.1-.7L16.5,29c-.2-.1-.2-.3-.1-.5.4-.7.8-1.3,1.2-2l1.2-2Z"
      />
    </g>
  </svg>
);
