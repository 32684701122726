import React from "react";
export default ({ handset = "#318eff", base = "#336eb4", cable = "#114878", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.1"
    height="25.529"
    viewBox="0 0 29.1 25.529"
    {...props}>
    <g transform="translate(0.011)">
      <path
        fill={handset}
        d="M6.265,15.933a2.874,2.874,0,0,1-2.059-2.864c0-1.074.09-2.327,0-3.491A2.909,2.909,0,0,1,6.265,6.355c.179-.09.269-.179.269-.358V.448A.423.423,0,0,0,6.085,0H1.879a.414.414,0,0,0-.448.269A40.109,40.109,0,0,0,0,11.1a39.848,39.848,0,0,0,1.432,10.92.6.6,0,0,0,.448.358h4.3a.423.423,0,0,0,.448-.448V16.38A.63.63,0,0,0,6.265,15.933Z"
        transform="translate(0)"
      />
      <path
        fill={base}
        d="M27.9.627A1.214,1.214,0,0,0,26.912,0H10.085A1.016,1.016,0,0,0,9.1,1.074v20.14a1.088,1.088,0,0,0,1.074,1.074H26.912a1.1,1.1,0,0,0,.985-.627C28.882,19.155,32.193,9.936,27.9.627ZM14.739,18.439H12.68V16.38h2.059Zm0-4.028H12.68V12.352h2.059Zm0-4.028H12.68V8.324h2.059Zm4.475,8.056H17.156V16.38h2.059Zm0-4.028H17.156V12.352h2.059Zm0-4.028H17.156V8.324h2.059Zm4.565,8.056H21.721V16.38H23.78Zm0-4.028H21.721V12.352H23.78Zm0-4.028H21.721V8.324H23.78Zm0-4.028H12.68V3.4H23.69V6.355Z"
        transform="translate(-0.956)"
      />
      <path
        fill="none"
        stroke={cable}
        strokeMiterlimit="10"
        strokeWidth="1.112px"
        d="M3.9,24.9v.9a1.8,1.8,0,0,0,1.79,1.79h5.639"
        transform="translate(-0.41 -2.612)"
      />
    </g>
  </svg>
);
