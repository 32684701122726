import React, { useContext, useEffect, useState } from "react";
import Layout from "../../containers/Layout";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { Box, Flex, Heading } from "rebass/styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import EmptyUpdatePackage from "./EmptyUpdatePackage";
import ProductCard from "./ProductCard";
import * as JSMethods from "./JSMethods";
import _ from "lodash";
import { SelfcareButton } from "../../components/base";
import StyledModalMessage from "../../components/StyledModalMessage";
import { ACCOUNT_TYPES } from "../../common/Constants";
import { useGET, usePUT } from "../../hooks/restAPI";
import SelfcareAmount from "../../components/base/SelfcareAmount";
import GetErrorDescription from "../../components/GetErrorDescription";

const UpdatePackage = props => {
  const { currentAccountDetails, setCurrentAccount, serviceAgreement: isLoggedInAsSA } = useContext(
    AuthContext
  );
  const history = useHistory();
  const intl = useIntl();
  const [configuration, doGetConfiguration] = useGET({});
  const [additionalServicesData, doPutAdditionalServices, resetPutAdditionalServicesError] = usePUT(
    {}
  );

  let account;
  if (props.location.state) {
    account = props.location.state.account;
  } else {
    account = currentAccountDetails;
  }

  useEffect(() => {
    setCurrentAccount(true, account);
  }, [account, setCurrentAccount]);

  const [prepaidCharges, setPrepaidCharges] = useState({
    servicesCharges: 0,
    servicesTaxes: 0,
    servicesTotalCharges: 0,
  });

  // Map<String, ServiceObject>
  // Key::String the path inside the root product object.
  // Value::ServiceObject the service object with the updated fields.
  const [diffMap, setDiffMap] = useState(new Map());
  const [productsDiffMap, setProductsDiffMap] = useState(new Map());
  const [productCards, setProductCards] = useState(null);

  const [product, setProduct] = useState(
    _.cloneDeep(props.location.state.additionalSrv.data.product)
  );

  const noConfirmation = {
    isOpen: false,
    message: "",
    onRequestClose: () => {},
    isCloseDisabled: false,
    type: "",
    buttons: [],
  };
  let [confirmation, setConfirmation] = useState({
    ...noConfirmation,
  });

  useEffect(() => {
    const loadInitialData = () => {
      doGetConfiguration({
        route: `/configuration/SCREEN_VISIBILITY`,
      });
    };

    loadInitialData();
  }, [doGetConfiguration]);

  const redirectToViewPlan = () => {
    if (!isLoggedInAsSA) {
      history.push(`/account/${account.accountCode}/overview`, {
        account: account,
      });
    } else {
      history.push(`/home`);
    }
  };

  const isCreditCardPaymentEnabled = () => {
    if (
      !configuration ||
      configuration.isError ||
      !configuration.data ||
      !configuration.data.options
    ) {
      return false;
    }
    return configuration.data.options.find(
      element => element.key === "CREDIT_CARD_PAYMENT" && element.value === "Y"
    );
  };

  const confirm = () => {
    if (diffMap.size === 0 && productsDiffMap.size === 0) {
      let onClose = () => {
        setConfirmation(noConfirmation);
      };

      setConfirmation({
        isOpen: true,
        message: intl.formatMessage({ id: "lbl.no_changes_performed" }),
        type: "info",
        onRequestClose: onClose,
        buttons: [
          <SelfcareButton key="btnOk" variant="secondarySmall" onClick={onClose}>
            <FormattedMessage id="lbl.ok" />
          </SelfcareButton>,
        ],
      });
    } else {
      const selectedAccount = account;
      const isCCPaymentEnabled = isCreditCardPaymentEnabled();
      const isDebitAndNeedsRecharge =
        selectedAccount.accountRemaining < prepaidCharges.servicesTotalCharges &&
        [ACCOUNT_TYPES.PIA, ACCOUNT_TYPES.PREPAID].indexOf(selectedAccount.accountType) > -1;
      if (isDebitAndNeedsRecharge === true && isCCPaymentEnabled === false) {
        const rechargeAmount =
          prepaidCharges.servicesTotalCharges - selectedAccount.accountRemaining;

        let onClose = () => {
          setConfirmation(noConfirmation);
        };

        setConfirmation({
          isOpen: true,
          message: intl.formatMessage(
            { id: "lbl.insufficient_credit" },
            { amount: SelfcareAmount({ amount: rechargeAmount }) }
          ),
          type: "info",
          onRequestClose: onClose,
          buttons: [
            <SelfcareButton key="btnOk" variant="secondarySmall" onClick={onClose}>
              <FormattedMessage id="lbl.ok" />
            </SelfcareButton>,
          ],
        });
      } else {
        let onClose = () => {
          setConfirmation(noConfirmation);
        };

        setConfirmation({
          isOpen: true,
          message: intl.formatMessage({ id: "lbl.add_services_confirmation" }),
          type: "warn",
          onRequestClose: onClose,
          buttons: [
            <SelfcareButton
              key="btnOk"
              mr="spaceBetweenButtons"
              variant="secondarySmall"
              onClick={() => {
                afterConfirmation();
              }}>
              <FormattedMessage id="lbl.ok" />
            </SelfcareButton>,
            <SelfcareButton
              key="btnCancel"
              variant="secondarySmallInverted"
              onClick={() => {
                onClose();
              }}>
              <FormattedMessage id="lbl.cancel" />
            </SelfcareButton>,
          ],
        });
      }
    }
  };

  const afterConfirmation = () => {
    const diffProduct = JSMethods.getDiffProduct(
      _.cloneDeep(product),
      _.cloneDeep(diffMap),
      _.cloneDeep(productsDiffMap)
    );
    const selectedAccount = account;
    const isDebitAndNeedsRecharge =
      selectedAccount.accountRemaining < prepaidCharges.servicesTotalCharges &&
      [ACCOUNT_TYPES.PIA, ACCOUNT_TYPES.PREPAID].indexOf(selectedAccount.accountType) > -1;
    if (isDebitAndNeedsRecharge) {
      // Recharge needed. Redirect to RechargeWithCard screen with a predefined amount.
      const rechargeAmount = prepaidCharges.servicesTotalCharges - selectedAccount.accountRemaining;
      let onClose = () => {
        setConfirmation(noConfirmation);
      };

      setConfirmation({
        isOpen: true,
        message: intl.formatMessage(
          { id: "lbl.insufficient_credit" },
          { amount: SelfcareAmount({ amount: rechargeAmount }) }
        ),
        type: "error",
        onRequestClose: onClose,
        buttons: [
          <SelfcareButton
            key="btnDismiss"
            variant="secondarySmall"
            mr="spaceBetweenButtons"
            onClick={onClose}>
            <FormattedMessage id="lbl.dismiss" />
          </SelfcareButton>,
          <SelfcareButton
            key="btnRecharge"
            variant="secondarySmall"
            onClick={() => {
              onClose();
              // todo: marius do redirect after recharge page is implemented
            }}>
            <FormattedMessage id="lbl.recharge" />
          </SelfcareButton>,
        ],
      });
    } else {
      setConfirmation({
        isOpen: true,
        message: intl.formatMessage({ id: "lbl.add_services_confirmation" }),
        type: "warn",
        onRequestClose: () => {},
        isCloseDisabled: true,
        buttons: [
          <SelfcareButton
            key="btnOk"
            disabled={true}
            mr="spaceBetweenButtons"
            variant="secondarySmall">
            <FormattedMessage id="lbl.ok" />
          </SelfcareButton>,
          <SelfcareButton key="btnCancel" disabled={true} variant="secondarySmallInverted">
            <FormattedMessage id="lbl.cancel" />
          </SelfcareButton>,
        ],
      });
      doPutAdditionalServices({
        route: `/account/${account.accountCode}/plan/additionalServices`,
        expectedResponse: "none",
        body: { product: diffProduct },
      });
    }
  };

  useEffect(() => {
    /**
     * Render product/service entries containing the product services/services features, recursively, through the entire product hierarchy.
     * @param {Object} product The current processed product.
     * @param {String} path The hierarchy path of the currently processed product.
     * @returns {Array<ProductCard>}
     **/
    const renderProductCards = (product, path = "product") => {
      let productServicesCards = [];
      if (
        JSMethods.canDisplayProduct(product) &&
        product.serviceList &&
        product.serviceList.length > 0
      ) {
        const serviceGroups = _.groupBy(product.serviceList, service => service.exclusivityGroup);
        let serviceGroupsMap = new Map();
        _.each(serviceGroups, (value, key) => {
          const services = JSMethods.getServicesForDisplay(value);
          if (services.length > 0) {
            serviceGroupsMap.set(key, services);
          }
        });
        let servicesWithEditableFeatures = JSMethods.getServicesWithEditableFeatures(
          product.serviceList
        );
        const subproductGroups = _.groupBy(
          product.subProductList,
          subproduct => subproduct.exclusivityGroup
        );
        let subproductGroupsMap = new Map();
        _.each(subproductGroups, (value, key) => {
          subproductGroupsMap.set(key, value);
        });

        if (serviceGroupsMap.size > 0 || servicesWithEditableFeatures.length > 0) {
          productServicesCards.push(
            <ProductCard
              key={product.code}
              path={path}
              productDescription={product.description}
              mutualExclusiveServices={product.mutualExclusiveServices}
              serviceGroupsMap={_.cloneDeep(serviceGroupsMap)}
              subproductGroupsMap={_.cloneDeep(subproductGroupsMap)}
              servicesWithEditableFeatures={_.cloneDeep(servicesWithEditableFeatures)}
              onServiceUpdate={onServiceUpdateHandler}
              onMEServicesUpdate={onMEServicesUpdateHandler}
              onSubProductsUpdate={onSubProductsUpdate}
            />
          );
        }
      }

      if (product.subProductList && product.subProductList.length > 0) {
        for (let index = 0; index < product.subProductList.length; index++) {
          const subProductCard = renderProductCards(
            product.subProductList[index],
            path + ".subProductList[" + index + "]"
          );
          if (subProductCard.length > 0) productServicesCards.push(subProductCard);
        }
      }

      return productServicesCards;
    };

    /**
     * Update the diff map and live charges when a service is updated in UI.
     **/
    const onServiceUpdateHandler = (path, service, originalService) => {
      const clonedService = _.cloneDeep(service);
      let updatedCharges = {
        servicesCharges: prepaidCharges.servicesCharges,
        servicesTaxes: prepaidCharges.servicesTaxes,
        servicesTotalCharges: prepaidCharges.servicesTotalCharges,
      };

      updateServiceChanges(path, clonedService, updatedCharges, originalService);
      updateRealTimeChargesState(updatedCharges);
    };

    /**
     * Update the diff map and live charges when a list of mutual exclusive services is updated in UI.
     **/
    const onMEServicesUpdateHandler = (path, serviceList) => {
      const clonedServiceList = _.cloneDeep(serviceList);
      let updatedCharges = {
        servicesCharges: prepaidCharges.servicesCharges,
        servicesTaxes: prepaidCharges.servicesTaxes,
        servicesTotalCharges: prepaidCharges.servicesTotalCharges,
      };

      _.each(clonedServiceList, (service, index) =>
        updateServiceChanges(path, service, updatedCharges)
      );
      updateRealTimeChargesState(updatedCharges);
    };

    /**
     * Update the diff map and live charges when a list of mutual exclusive products is updated in UI.
     **/
    const onSubProductsUpdate = (path, productList) => {
      const clonedProductList = _.cloneDeep(productList);
      _.each(clonedProductList, (product, index) => updateProductChanges(path, product));
    };

    const updateProductChanges = (path, product) => {
      let productList = productsDiffMap.get(path);

      if (productList) {
        const filteredProductList = _.filter(productList, prod => prod.code === product.code);
        productList = _.filter(productList, prod => prod.code !== product.code);
        // new product added or updated
        if (
          filteredProductList.length === 0 ||
          filteredProductList.some(prod => prod.status !== product.status)
        ) {
          productList.push(_.cloneDeep(product));
        }

        if (productList.length > 0) {
          productsDiffMap.set(path, productList);
        } else {
          productsDiffMap.delete(path);
        }
        setProductsDiffMap(productsDiffMap);
      } else {
        const productsArray = [];
        productsArray.push(_.cloneDeep(product));
        productsDiffMap.set(path, productsArray);
        setProductsDiffMap(productsDiffMap);
      }
    };

    /**
     * Calculate the new real time charges when the state for a service is updated,
     * based on the last known values.
     * Also, update the diff map based on the latest service changes.
     **/
    const updateServiceChanges = (path, service, charges, originalService = null) => {
      const setupFee = parseFloat(service.setupFee),
        rcFee = parseFloat(service.rcFee),
        taxAmount = parseFloat(service.taxAmount);

      const isMultipleInstanceService = JSMethods.isMultipleInstanceService(service);
      let newInstances = service.instances;
      const shouldUpdateCharges =
        account.accountType !== ACCOUNT_TYPES.POSTPAID &&
        (isMultipleInstanceService || _.toNumber(_.keys(newInstances)[0]) === 0);

      let serviceList = diffMap.get(path);

      if (serviceList) {
        const filteredServiceList = _.filter(serviceList, srv => srv.code === service.code);
        if (filteredServiceList.length > 0) {
          serviceList = _.filter(serviceList, srv => srv.code !== service.code);

          if (isMultipleInstanceService) {
            let previousInstances = filteredServiceList[0].instances;
            const previousInstancesNr = _.filter(
              _.keys(previousInstances),
              srvId => _.toNumber(srvId) < 0
            ).length;

            const newInstancesNr = _.filter(_.keys(newInstances), srvId => _.toNumber(srvId) < 0)
              .length;

            let instancesWereAddedOrRemoved = newInstancesNr > 0;
            let originalInstances = originalService.instances;
            let featuresWereUpdated =
              !_.isEqual(newInstances, previousInstances) &&
              !_.isEqual(newInstances, originalInstances);

            if (instancesWereAddedOrRemoved || featuresWereUpdated) {
              serviceList.push(_.cloneDeep(service));
            } else {
              // do not push service <=> remove it from updated list
            }

            if (shouldUpdateCharges && newInstancesNr > previousInstancesNr) {
              addCharges(charges, setupFee, rcFee, taxAmount);
            } else if (shouldUpdateCharges && newInstancesNr < previousInstancesNr) {
              removeCharges(charges, setupFee, rcFee, taxAmount);
            }
          } else if (shouldUpdateCharges) {
            removeCharges(charges, setupFee, rcFee, taxAmount);
          }
        } else {
          serviceList.push(_.cloneDeep(service));
          if (shouldUpdateCharges) {
            addCharges(charges, setupFee, rcFee, taxAmount);
          }
        }

        if (serviceList.length > 0) {
          diffMap.set(path, serviceList);
        } else {
          diffMap.delete(path);
        }
        setDiffMap(diffMap);
      } else {
        const serviceArray = [];
        serviceArray.push(_.cloneDeep(service));
        diffMap.set(path, serviceArray);
        setDiffMap(diffMap);
        if (shouldUpdateCharges) {
          addCharges(charges, setupFee, rcFee, taxAmount);
        }
      }
    };

    const addCharges = (charges, setupFee, rcFee, taxAmount) => {
      charges.servicesCharges = charges.servicesCharges + setupFee + rcFee;
      charges.servicesTaxes = charges.servicesTaxes + taxAmount;
      charges.servicesTotalCharges = charges.servicesTotalCharges + setupFee + rcFee + taxAmount;
    };

    const removeCharges = (charges, setupFee, rcFee, taxAmount) => {
      charges.servicesCharges = charges.servicesCharges - setupFee - rcFee;
      charges.servicesTaxes = charges.servicesTaxes - taxAmount;
      charges.servicesTotalCharges = charges.servicesTotalCharges - setupFee - rcFee - taxAmount;
    };

    /**
     * Set states for real time charges values.
     **/
    const updateRealTimeChargesState = updatedCharges => {
      if (account.accountType !== ACCOUNT_TYPES.POSTPAID) {
        setPrepaidCharges({
          servicesCharges: updatedCharges.servicesCharges,
          servicesTaxes: updatedCharges.servicesTaxes,
          servicesTotalCharges: updatedCharges.servicesTotalCharges,
        });
      }
    };

    const isProductSet = !!product;
    if (isProductSet) {
      setProductCards(renderProductCards(product, "product"));
    }
  }, [product, prepaidCharges, diffMap, productsDiffMap, account.accountType]);

  useEffect(() => {
    const resetProductState = () => {
      setDiffMap(new Map());
      setProductsDiffMap(new Map());
      setProduct(_.cloneDeep(props.location.state.additionalSrv.data.product));
      setPrepaidCharges({
        servicesCharges: 0,
        servicesTaxes: 0,
        servicesTotalCharges: 0,
      });
    };

    const redirectToViewPlan = () => {
      if (!isLoggedInAsSA) {
        history.push(`/account/${account.accountCode}/overview`, {
          account: account,
        });
      } else {
        history.push(`/home`);
      }
    };

    if (additionalServicesData.isSuccess === true) {
      redirectToViewPlan();
    }

    if (additionalServicesData.isError === true) {
      let modalButtonName = intl.formatMessage({ id: "lbl.ok" });
      let modalButtonAction = () => {
        resetProductState();
        setConfirmation(noConfirmation);
      };

      if (
        additionalServicesData.error instanceof TypeError ||
        additionalServicesData.error.name === "AbortError"
      ) {
        modalButtonName = intl.formatMessage({ id: "lbl.take_me_back" });
        modalButtonAction = () => {
          setConfirmation(noConfirmation);
          redirectToViewPlan();
        };
      }
      resetPutAdditionalServicesError();
      setConfirmation({
        isOpen: true,
        message: <GetErrorDescription error={additionalServicesData.error} />,
        type: "error",
        onRequestClose: modalButtonAction,
        buttons: [
          <SelfcareButton key="btnOk" variant="secondarySmall" onClick={modalButtonAction}>
            {modalButtonName}
          </SelfcareButton>,
        ],
      });
    }
  }, [
    account,
    additionalServicesData.error,
    additionalServicesData.isError,
    additionalServicesData.isSuccess,
    history,
    intl,
    isLoggedInAsSA,
    noConfirmation,
    props.location.state.additionalSrv.data.product,
    resetPutAdditionalServicesError,
  ]);

  if (!productCards || productCards.length === 0) {
    return <EmptyUpdatePackage accountName={account.accountName} />;
  }

  return (
    <Layout disableNavMenuDefaultLocation={true} isUpdatePackage={true}>
      <Flex width="100%" flexDirection="column">
        <Heading color="textDark" fontSize="title" mt={["0px", "default", "default"]}>
          {account.accountName}
        </Heading>

        {confirmation.isOpen && (
          <StyledModalMessage
            isOpen={confirmation.isOpen}
            message={confirmation.message}
            onRequestClose={confirmation.onRequestClose}
            isCloseDisabled={confirmation.isCloseDisabled}
            type={confirmation.type}>
            {confirmation.buttons}
          </StyledModalMessage>
        )}

        {productCards}

        <Box width="auto" mt="larger" textAlign="right">
          <SelfcareButton
            type="submit"
            variant="default"
            mr="spaceBetweenButtons"
            onClick={confirm}>
            <FormattedMessage id="lbl.confirm" />
          </SelfcareButton>

          <SelfcareButton variant="default-inverted" onClick={redirectToViewPlan}>
            <FormattedMessage id="lbl.cancel" />
          </SelfcareButton>
        </Box>
      </Flex>
    </Layout>
  );
};

export default UpdatePackage;
