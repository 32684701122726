import React from "react";
export default ({ color = "#336eb4", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.146"
    height="18.2"
    viewBox="0 0 12.146 18.2"
    {...props}>
    <g transform="translate(-0.2 -0.2)">
      <path
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2.0px"
        d="M1.2,6.273A5.108,5.108,0,0,1,6.273,1.2a5.108,5.108,0,0,1,5.073,5.073,5.108,5.108,0,0,1-5.073,5.073v3.091"
      />
      <line
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2.0px"
        y2="1.585"
        transform="translate(6.273 16.815)"
      />
    </g>
  </svg>
);
