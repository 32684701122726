const ERROR_CODES = {
  MINDBILL_ERROR: "MINDBILL_ERROR",
  INTERNAL_ERROR: "INTERNAL_ERROR",
  ACCOUNT_NOT_FOUND: "ACCOUNT_NOT_FOUND",
  ALREADY_REGISTERED_TO_SELFCARE: "ALREADY_REGISTERED_TO_SELFCARE",
  BLOCKED_SELFCARE_ACCESS: "BLOCKED_SELFCARE_ACCESS",
  BLOCKED_SELFCARE_REGISTRATION: "BLOCKED_SELFCARE_REGISTRATION",
  INVOICE_NOT_FOUND: "INVOICE_NOT_FOUND",
  AT_LEAST_ONE_SIGN_UP_METHOD_MUST_BE_ENTERED: "AT_LEAST_ONE_SIGN_UP_METHOD_MUST_BE_ENTERED",
  NOTIFICATION_LETTER_DESTINATION_NOT_FOUND: "NOTIFICATION_LETTER_DESTINATION_NOT_FOUND",
  SECURITY_QUESTION_REPEAT_NOT_ALLOWED: "SECURITY_QUESTION_REPEAT_NOT_ALLOWED",
  INVALID_VERIFICATION_CODE: "INVALID_VERIFICATION_CODE",
  ACCOUNT_WEB_LOGIN_NAME_INVALID: "ACCOUNT_WEB_LOGIN_NAME_INVALID",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
  MISSING_SECURITY_RIGHT: "MISSING_SECURITY_RIGHT",
  DIRECT_DEBIT_PAYMENTS_NOT_ALLOWED: "DIRECT_DEBIT_PAYMENTS_NOT_ALLOWED",
  ACCOUNT_CONTACT_NOT_FOUND: "ACCOUNT_CONTACT_NOT_FOUND",
  NO_ACCOUNT_STATEMENT_BANK_HOLDER_NAME: "NO_ACCOUNT_STATEMENT_BANK_HOLDER_NAME",
  NO_ACCOUNT_STATEMENT_BANK_ACCOUNT_TYPE: "NO_ACCOUNT_STATEMENT_BANK_ACCOUNT_TYPE",
  BANK_ACCOUNT_IS_INVALID: "BANK_ACCOUNT_IS_INVALID",
  ITEM_IS_BLACKLISTED: "ITEM_IS_BLACKLISTED",
  TOKEN_NO_LONGER_VALID: "TOKEN_NO_LONGER_VALID",
  USER_NOT_FOUND: "USER_NOT_FOUND",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",
  CLOSED_ACCOUNT: "CLOSED_ACCOUNT",
};

export default ERROR_CODES;

export const BUSINESS_CATEGORIES = {
  RESIDENTIAL: 0,
  COMMERCIAL: 1,
};

export const ACCOUNT_TYPES = {
  POSTPAID: "Postpaid",
  PREPAID: "Prepaid",
  PIA: "PIA",
};

export const PAYMENT_METHOD = {
  NEW: 0,
  SAVED: 1,
  E_CHECK: 2,
};

export const RECURRING_PERIOD = {
  DAILY: 0,
  WEEKLY: 1,
  BI_WEEKLY: 2,
  MONTHLY: 3,
  BI_MONTHLY: 4,
  QUARTERLY: 5,
  FOUR_MONTH: 6,
  SEMI_ANNUAL: 7,
  ANNUAL: 8,
  BIENNIAL: 9,
  TRIENNIAL: 10,
  UNLIMITED: 11,
  BASED_ON_BP: 12,
};

export const WIRELINE_TECHNOLOGIES = {
  FIXEDINTERNET: "FIXEDINTERNET",
  DSL: "DSL",
  VOFW: "VOFW",
  VOOM: "VOOM",
  CATVDSL: "CATVDSL",
  INTERNET: "INTERNET",
  VOBB: "VOBB",
  CATVFIBER: "CATVFIBER",
};
