function AuthorizationException(errorCode, description) {
  this.name = "AuthorizationException";
  this.errorCode = errorCode;
  this.description = description;
  this.stack = new Error().stack;
}

AuthorizationException.prototype = Object.create(Error.prototype);
AuthorizationException.prototype.constructor = AuthorizationException;
export default AuthorizationException;
