import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Flex, Heading } from "rebass/styled-components";
import { ACCOUNT_TYPES } from "../../common/Constants";
import { useGET } from "../../hooks/restAPI";
import { HostedCcOperationResponse } from "../../pages";
import { SelfcareButton } from "../base";
import GetErrorDescription from "../GetErrorDescription";
import ServiceAgreementPostpaid from "../ServiceAgreementPostpaid";
import ServiceAgreementPrepaid from "../ServiceAgreementPrepaid";
import StyledModalMessage from "../StyledModalMessage";

const ServiceAgreementLayout = ({ accountName, accountType, accountCode }) => {
  const history = useHistory();
  const [accountData, doGetAccount, resetGetAccountError] = useGET();
  const [beneficiaryUnitsData, doGetBeneficiaryUnits, resetGetBeneficiaryUnits] = useGET();
  const [additionalSrv, doGetAdditionalSrv, resetGetAdditionalSrv] = useGET();
  const [configuration, doGetConfiguration, resetGetConfiguration] = useGET();

  useEffect(() => {
    doGetAccount({
      route: `/account/${accountCode}`,
    });
    doGetBeneficiaryUnits({
      route: `/account/${accountCode}/plan/beneficiaryUnits?show=ALL`,
      noTimeout: true,
    });
    doGetAdditionalSrv({
      route: `/account/${accountCode}/plan/additionalServices`,
    });
    doGetConfiguration({
      route: `/configuration/global`,
    });
  }, [accountCode, doGetAccount, doGetAdditionalSrv, doGetBeneficiaryUnits, doGetConfiguration]);

  const tryAgain = () => {
    resetGetAccountError();
    resetGetBeneficiaryUnits();
    resetGetAdditionalSrv();
    resetGetConfiguration();

    doGetAccount({
      route: `/account/${accountCode}`,
    });

    doGetBeneficiaryUnits({
      route: `/account/${accountCode}/plan/beneficiaryUnits?show=ALL`,
      noTimeout: true,
    });

    doGetAdditionalSrv({
      route: `/account/${accountCode}/plan/additionalServices`,
    });

    doGetConfiguration({
      route: `/configuration/global`,
    });
  };

  if (
    accountData.isOffline ||
    beneficiaryUnitsData.isOffline ||
    additionalSrv.isOffline ||
    configuration.isOffline
  ) {
    history.push("/error");
  }

  const isError =
    accountData.isError ||
    beneficiaryUnitsData.isError ||
    additionalSrv.isError ||
    configuration.isError;
  const error = accountData.isError
    ? accountData.error
    : beneficiaryUnitsData.isError
    ? beneficiaryUnitsData.error
    : additionalSrv.isError
    ? additionalSrv.error
    : configuration.error;
  return (
    <Flex width="100%" flexDirection="column">
      <Heading color="textDark" fontSize="title" my={["default", "default", "medium"]}>
        {accountName}
      </Heading>

      {isError && (
        <StyledModalMessage
          isOpen={isError}
          message={<GetErrorDescription error={error} />}
          onRequestClose={tryAgain}
          type="error">
          <SelfcareButton variant="secondarySmall" onClick={tryAgain}>
            <FormattedMessage id="lbl.Try_Again" />
          </SelfcareButton>
        </StyledModalMessage>
      )}

      {!isError && accountType === ACCOUNT_TYPES.POSTPAID && (
        <ServiceAgreementPostpaid
          account={accountData.data}
          accountIsLoading={accountData.isLoading}
          beneficiaryUnits={beneficiaryUnitsData.data.beneficiaryUnits}
          beneficiaryUnitsIsLoading={beneficiaryUnitsData.isLoading}
          additionalSrv={additionalSrv}
          configuration={configuration}
        />
      )}
      {!isError && accountType === "Prepaid" && (
        <ServiceAgreementPrepaid
          account={accountData.data}
          accountIsLoading={accountData.isLoading}
          beneficiaryUnits={beneficiaryUnitsData.data.beneficiaryUnits}
          beneficiaryUnitsIsLoading={beneficiaryUnitsData.isLoading}
          additionalSrv={additionalSrv}
          configuration={configuration}
        />
      )}
      {window.location.search && <HostedCcOperationResponse params={window.location.search} />}
    </Flex>
  );
};

export default ServiceAgreementLayout;
