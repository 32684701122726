import React from "react";
export default ({ hands = "#114878", questionMark = "#318eff", globe = "#114878", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52.382"
    height="48.502"
    viewBox="0 0 52.382 48.502">
    <g transform="translate(-5.809 -7.749)">
      <path
        fill={questionMark}
        d="M32,7.749A11.766,11.766,0,1,0,43.766,19.516,11.766,11.766,0,0,0,32,7.749Z"
      />
      <path
        fill={globe}
        d="M30.847,22.986c0-2.5.19-2.876,2.092-4.374,1.283-1.022,1.593-1.45,1.593-2.258a2.218,2.218,0,0,0-2.449-2.14,2.4,2.4,0,0,0-2.71,2.663H26.307c.1-3.328,2.4-5.444,5.872-5.444,3.3,0,5.514,1.925,5.514,4.8a4.255,4.255,0,0,1-.95,2.782A9.724,9.724,0,0,1,34.817,20.7a2.41,2.41,0,0,0-.975,2.282ZM34.1,27.6H30.776V24.27H34.1Z"
      />
      <path
        fill={hands}
        d="M45.029,49.793s7.827-7.063,10.739-9.868a3.967,3.967,0,0,0,1.367-1.958q.7-6.13,1.056-12.3c-.2-2.349-2.8-1.64-3.367-1.106a4.886,4.886,0,0,0-1.165,2.347,77.216,77.216,0,0,0-1.007,7.86l-4.907,4.367a49.42,49.42,0,0,0-5.229,1.278c1.087-1.473,2.125-2.819,3.1-4.212.792-1.136,1.549-2.425.261-3.621s-2.378-.131-3.3.722q-4.287,3.966-8.471,8.043a2.739,2.739,0,0,0-.852,1.707c-.006,4.292.08,8.6.149,13.2H44.414C44.536,54.787,45.029,49.793,45.029,49.793Z"
      />
      <path
        fill={hands}
        d="M29.9,41.348q-4.179-4.078-8.471-8.043c-.922-.853-2-1.924-3.3-.722s-.531,2.485.261,3.621c.97,1.393,2.008,2.739,3.095,4.212a49.42,49.42,0,0,0-5.229-1.278l-4.907-4.367a77.215,77.215,0,0,0-1.007-7.86,4.886,4.886,0,0,0-1.165-2.347c-.57-.534-3.164-1.243-3.367,1.106q.363,6.159,1.056,12.3a3.967,3.967,0,0,0,1.367,1.958c2.912,2.805,10.739,9.868,10.739,9.868s.493,4.994.615,6.458H30.6c.069-4.6.155-8.9.149-13.2A2.739,2.739,0,0,0,29.9,41.348Z"
      />
    </g>
  </svg>
);
