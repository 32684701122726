import React, { useEffect, useState } from "react";
import { Flex, Text } from "rebass/styled-components";
import _ from "lodash";
import * as JSMethods from "./JSMethods";
import { PRODUCT_STATUS } from "./JSMethods";
import SelfcareSwitch from "../../components/base/SelfcareSwitch";

const MutualExclusiveServiceGroup = ({
  path,
  exclusivityGroup,
  exclusivityGroupDescription,
  mandatoryExclusiveItem,
  _services,
  _subProducts,
  _onMEServicesUpdate,
  _onMEProductsUpdate,
}) => {
  const [state, setState] = useState({
    services: _services,
    subProducts: _subProducts,
  });

  useEffect(() => {
    function onPropsUpdate() {
      if (!_.isEqual(state.services, _services) || !_.isEqual(state.subProducts, _subProducts)) {
        setState({
          services: _services,
          subProducts: _subProducts,
        });
      }
    }

    onPropsUpdate();
  }, [state.services, _services, state.subProducts, _subProducts]);

  /**
   * Render a service row, containing the service name and the switch/checkbox component.
   * @param {Number} index The service index.
   * @param {Object} service The service object.
   * @returns {Flex}
   **/
  const renderMEServiceRow = (index, service) => {
    return (
      <Flex key={service.code + index} marginY="small" flexDirection="row" width="100%">
        <Text
          textAlign="left"
          color="textDark"
          fontSize="secondary"
          marginLeft="default"
          flex={1}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}>
          {JSMethods.getServiceLabel(
            service.description,
            service.rcFee,
            service.recurringPeriod,
            service.setupFee
          )}
        </Text>

        <SelfcareSwitch
          alignSelf="flex-end"
          id={"id" + service.code + index}
          name={"name" + service.code + index}
          checked={service.instances[_.keys(service.instances)[0]].status}
          onClick={() => onServiceOrSubProductUpdate(index, true)}
          ml="auto"
        />
      </Flex>
    );
  };

  /**
   * Render a subProduct row, containing the subProduct name and the switch/checkbox component.
   * @param {Number} index The subProduct index.
   * @param {Object} subProduct The subProduct object.
   * @returns {Flex}
   **/
  const renderMESubProductRow = (index, subProduct) => {
    return (
      <Flex key={subProduct.code + index} marginY="small" flexDirection="row" width="100%">
        <Text
          textAlign="left"
          color="textDark"
          fontSize="secondary"
          marginLeft="default"
          flex={1}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}>
          {subProduct.description}
        </Text>

        <SelfcareSwitch
          alignSelf="flex-end"
          id={"id" + subProduct.code + index}
          name={"name" + subProduct.code + index}
          checked={
            subProduct.status === PRODUCT_STATUS.ENABLED ||
            subProduct.status === PRODUCT_STATUS.ADDED ||
            subProduct.status === PRODUCT_STATUS.ACTIVATED
          }
          onClick={() => onServiceOrSubProductUpdate(index, false)}
          ml="auto"
        />
      </Flex>
    );
  };

  /**
   * Update service or subProduct state at service or subProduct update event.
   * @param {Number} serviceIndex New selected service index from the service list.
   * @param {Boolean} isService true if crt updated entity is a service
   **/
  const onServiceOrSubProductUpdate = (serviceIndex, isService) => {
    let serviceList = [];
    let services = state.services;
    let subProducts = state.subProducts;
    if (isService) {
      _.each(services, (service, index) => {
        let serviceId = _.keys(service.instances)[0];
        const isActive = service.instances[serviceId] && service.instances[serviceId].status;

        if (serviceId === "0" && index === serviceIndex) {
          delete service.instances[serviceId];
          serviceId = "1";
        }
        service.instances[serviceId] = {
          status: index === serviceIndex && (mandatoryExclusiveItem || !isActive),
        };
        if (isActive !== (service.instances[serviceId] && service.instances[serviceId].status)) {
          serviceList.push(service);
        }
      });

      // unassign subProduct if any
      _.each(subProducts, (subProduct, index) => {
        JSMethods.changeToRemovedStatus(subProduct);
      });
    }
    // is a product
    else {
      _.each(services, (service, index) => {
        let serviceId = _.keys(service.instances)[0];
        const isActive = service.instances[serviceId] && service.instances[serviceId].status;

        if (isActive) {
          service.instances[serviceId] = { status: false };
          serviceList.push(service);
        }
      });

      // add selected/remove unselected subProducts
      _.each(subProducts, (subProduct, index) => {
        // add
        if (
          serviceIndex === index &&
          (mandatoryExclusiveItem ||
            !(
              subProduct.status === PRODUCT_STATUS.ENABLED ||
              subProduct.status === PRODUCT_STATUS.ADDED ||
              subProduct.status === PRODUCT_STATUS.ACTIVATED
            ))
        ) {
          JSMethods.changeToAddedStatus(subProduct);
        }
        // remove
        else {
          JSMethods.changeToRemovedStatus(subProduct);
        }
      });
    }

    if (_onMEServicesUpdate != null) {
      _onMEServicesUpdate(path, serviceList);
    }

    if (_onMEProductsUpdate != null) {
      _onMEProductsUpdate(path, subProducts);
    }

    setState({ ...state, services, subProducts });
  };

  let rows = [];

  _.each(state.services, (service, index) => {
    rows.push(renderMEServiceRow(index, state.services[index]));
  });

  _.each(state.subProducts, (subProduct, index) => {
    rows.push(renderMESubProductRow(index, state.subProducts[index]));
  });

  return (
    <Flex key={exclusivityGroup} marginY="small" flexDirection="column" width="100%">
      <Flex marginBottom="small" flexDirection="row" width="100%">
        <Text
          textAlign="left"
          color="textDark"
          fontSize="secondary"
          flex={1}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}>
          {exclusivityGroupDescription}
        </Text>
      </Flex>
      {rows}
    </Flex>
  );
};

export default MutualExclusiveServiceGroup;
