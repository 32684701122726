import React, { useState, useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Text } from "rebass/styled-components";
import { SelfcareBox, SelfcareButton } from "../../components/base";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { useGET } from "../../hooks/restAPI";
import { SelfcareIcon } from "../icons";
import StyledModalMessage from "../StyledModalMessage";
import LoadDocumentModal from "../../components/LoadDocumentModal";

const ViewPostpaidUnbilledSummary = ({ saAccountCode }) => {
  const [summaryData, doGetSummary, resetGetError] = useGET({});
  const { accountCode } = useContext(AuthContext);
  const [documentUrl, setDocumentUrl] = useState(null);

  const getSummary = () => {
    const getSummaryConfig = {
      route: saAccountCode
        ? `/account/${saAccountCode}/viewPostpaidUnbilledSummary`
        : `/account/${accountCode}/viewPostpaidUnbilledSummary`,
      noTimeout: true,
      expectedResponse: "blob",
    };
    doGetSummary(getSummaryConfig);
  };

  useEffect(() => {
    if (summaryData.isSuccess) {
      var file = new Blob([summaryData.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      setDocumentUrl(fileURL);
    }
  }, [summaryData.data, summaryData.isSuccess]);

  return (
    <>
      <Box>
        <SelfcareBox
          display="flex"
          height="4.875rem"
          width={["100%", "100%", "21.125rem"]}
          my={["", "", "default"]}
          mr={["0rem", "0rem", "2rem"]}
          variant="card"
          alignItems="center"
          justifyContent="center"
          sx={{
            cursor: "pointer",
            "&:hover": {
              opacity: "70%",
            },
          }}
          onClick={getSummary}>
          <Box ml="1rem" mr="2rem" display={["none", "none", "inline"]}>
            <SelfcareIcon name="view-postpaid-unbilled-summary" />
          </Box>
          <Text color="primary" textAlign="center" justifyContent="center" fontSize="primary">
            <FormattedMessage id="lbl.view_postpaid_unbilled_summary" />
          </Text>
        </SelfcareBox>
      </Box>

      <LoadDocumentModal
        isOpen={summaryData.isLoading || summaryData.isSuccess}
        onClose={() => {
          setDocumentUrl(null);
          resetGetError();
        }}
        url={documentUrl}
      />

      {summaryData.isError && (
        <StyledModalMessage
          isOpen={summaryData.isError}
          message={<FormattedMessage id="lbl.view_postpaid_unbilled_summary_error" />}
          onRequestClose={resetGetError}
          type="error">
          <SelfcareButton variant="secondarySmall" onClick={resetGetError}>
            <FormattedMessage id="lbl.ok" />
          </SelfcareButton>
        </StyledModalMessage>
      )}
    </>
  );
};

export default ViewPostpaidUnbilledSummary;
