import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Flex, Heading } from "rebass/styled-components";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { useParams } from "react-router-dom";

import Layout from "../../containers/Layout";
import NavigationSecondaryMenu from "../../components/NavigationSecondaryMenu";

const Billing = ({ children }) => {
  const { accountCode: masterAccountCode, isSubAccount } = useContext(AuthContext);
  const { accountCode: accountCodePath } = useParams();

  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;

  const menuItems = [
    {
      title: "lbl.invoices",
      icon: "",
      location: isSubAccount ? `/account/${accountCode}/billing/invoices` : "/billing/invoices",
    },
    {
      title: "lbl.payments",
      icon: "",
      location: isSubAccount ? `/account/${accountCode}/billing/payments` : "/billing/payments",
    },
  ];

  return (
    <Layout mobileTabSubMenu={<NavigationSecondaryMenu.Mobile menuItems={menuItems} />}>
      <Flex width="100%" flexDirection="column">
        <Heading
          py={["default", "default", "largeish"]}
          color="textDark"
          fontSize="title"
          fontWeight={5}>
          <FormattedMessage id="lbl.finance_transactions" />
        </Heading>
        <NavigationSecondaryMenu.Desktop menuItems={menuItems} />
        <Flex py="large" justifyContent="center">
          {children}
        </Flex>
      </Flex>
    </Layout>
  );
};

export default Billing;
