import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { Heading } from "rebass/styled-components";
import { PAYMENT_METHOD } from "../../common/Constants";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { useGET, usePOST } from "../../hooks/restAPI";
import StyledModalMessage from "../../components/StyledModalMessage";
import SelfcareButton from "../../components/base/SelfcareButton";

const HostedCcOperationRequest = ({ amount, card, responseUrl }) => {
  const { accountCode } = useContext(AuthContext);
  const history = useHistory();

  const [makePaymentData, makePayment] = usePOST({});
  const [requestData, getRequest] = useGET({});

  useEffect(() => {
    if (card === PAYMENT_METHOD.SAVED) {
      makePayment({
        route: `/ippay/makePayment`,
        body: { accountCode, amount, responseUrl },
        noTimeout: true,
      });
    } else if (card === PAYMENT_METHOD.NEW) {
      getRequest({
        route: `/ippay/initiateHostedCcOperation`,
        body: { accountCode, amount, responseUrl: window.location.origin + responseUrl },
        noTimeout: true,
      });
    }
  }, [accountCode, amount, card, responseUrl, getRequest, makePayment]);

  useEffect(() => {
    if (makePaymentData.isSuccess) {
      history.push(makePaymentData.data.responseUrl, { paymentResponse: makePaymentData.data });
      window.location.reload();
    }
  }, [makePaymentData, history]);

  useEffect(() => {
    if (requestData.isSuccess) {
      postToIPPay(requestData.data.hostedURL, {
        CustomerID: requestData.data.customerID,
        MID: requestData.data.merchantID,
        TerminalID: requestData.data.terminalID,
        SaveForFuture: requestData.data.saveForFuture,
        ReferenceID: requestData.data.referenceID,
        Amount: requestData.data.amount,
        CallbackURL: window.location.origin + requestData.data.callbackURL,
        Token: requestData.data.token,
        TransactionType: requestData.data.transactionType,
        PaymentType: "CC",
      });
    }
  }, [requestData, history]);

  function postToIPPay(path, params) {
    var form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", path);

    for (var key in params) {
      if (params.hasOwnProperty(key) && params[key] !== "") {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  }

  const goBack = () => {
    history.push(decodeURIComponent(responseUrl));
    window.location.reload();
  };

  return (
    <>
      {/*we can't know for sure if payment was done*/}
      {makePaymentData.isError && (
        <StyledModalMessage
          isOpen={makePaymentData.isError}
          message={<FormattedMessage id="err.make_payment" />}
          onRequestClose={goBack}
          type="error">
          <SelfcareButton variant="secondarySmall" onClick={goBack}>
            <FormattedMessage id="lbl.ok" />
          </SelfcareButton>
        </StyledModalMessage>
      )}

      {/*we know for sure that only initiation of ippay communication failed*/}
      {requestData.isError && (
        <StyledModalMessage
          isOpen={requestData.isError}
          message={
            <FormattedMessage
              id={
                card === PAYMENT_METHOD.NEW && amount
                  ? "err.make_payment_not_initiated"
                  : "err.cc_update_not_initiated"
              }
            />
          }
          onRequestClose={goBack}
          type="error">
          <SelfcareButton variant="secondarySmall" onClick={goBack}>
            <FormattedMessage id="lbl.ok" />
          </SelfcareButton>
        </StyledModalMessage>
      )}
      <Heading
        as="h1"
        fontWeight={5}
        mt="4rem"
        fontSize="title"
        color="textDark"
        textAlign="center">
        <FormattedMessage id="lbl.please_wait" />
      </Heading>
    </>
  );
};

export default HostedCcOperationRequest;
