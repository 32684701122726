import React from "react";
import ContentLoader from "react-content-loader";

const FUBGroupTitleSkeleton = ({ ...props }) => (
  <ContentLoader
    {...props}
    speed={2}
    viewBox="0 0 928 33"
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">
    <rect width="448" height="33" x="0" />
  </ContentLoader>
);

export default FUBGroupTitleSkeleton;
