import { Textarea } from "@rebass/forms/styled-components";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Box, Flex, Heading, Text } from "rebass/styled-components";
import { SelfcareButton } from "../../components/base";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { usePOST } from "../../hooks/restAPI";
import ModalPage from "../ModalPage";
import StyledModalMessage from "../StyledModalMessage";

const OpenTroubleTicket = ({
  config,
  title,
  placeholderMessage,
  commercialMessage,
  isOpenModal,
  setOpenModal,
}) => {
  const [openTroubleTicketResponse, openTroubleTicket, resetGetError] = usePOST();
  const { accountCode } = useContext(AuthContext);
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  const initiateOpenTroubleTicket = values => {
    let comments = values.comments;
    const openTroubleTicketConfig = {
      route: `/troubleticket/openTroubleTicket`,
      body: {
        accountCode: accountCode,
        area: config.area,
        role: config.role,
        type: config.type,
        commercialMessage: commercialMessage,
        comments: comments,
      },
      expectedResponse: "none",
    };
    openTroubleTicket(openTroubleTicketConfig);
    setOpenModal(false);
  };

  return (
    <>
      {isOpenModal && (
        <ModalPage
          isOpen={isOpenModal}
          onRequestClose={() => {
            setOpenModal(false);
          }}>
          <form onSubmit={handleSubmit(initiateOpenTroubleTicket)}>
            <Box px="medium" pt="large" pb="large" mx={["2rem", "2rem", "1rem"]}>
              <Heading
                as="h1"
                fontWeight={5}
                pb="medium"
                mb="medium"
                fontSize="title"
                color="textDark"
                textAlign="left">
                {title}
              </Heading>

              {commercialMessage && (
                <Text
                  color="secondary"
                  textAlign="left"
                  justifyContent="center"
                  fontSize={["primary", "primary", "secondary"]}
                  mb="1rem">
                  {commercialMessage}
                </Text>
              )}

              <Text
                color="primary"
                textAlign="left"
                justifyContent="center"
                fontSize={["primary", "primary", "secondary"]}
                mb="1rem">
                <FormattedMessage id="lbl.comments" />
              </Text>

              <Textarea
                ref={register({
                  required: true,
                })}
                width="100%"
                height="10rem"
                variant={errors.comments ? "greyInputError" : "greyInput"}
                maxLength="500"
                name="comments"
                marginBottom="medium"
                color="textDark"
                placeholder={placeholderMessage}
                sx={{
                  "::placeholder": {
                    opacity: 0.5,
                  },
                }}
              />

              <Flex
                width="auto"
                mt="larger"
                textAlign="right"
                justifyContent={["space-between", "space-around", "flex-end"]}>
                <SelfcareButton type="submit" variant="default" mr="spaceBetweenButtons">
                  <FormattedMessage id="lbl.proceed" />
                </SelfcareButton>

                <SelfcareButton
                  variant="default-inverted"
                  onClick={() => {
                    setOpenModal(false);
                  }}>
                  <FormattedMessage id="lbl.cancel" />
                </SelfcareButton>
              </Flex>
            </Box>
          </form>
        </ModalPage>
      )}
      {openTroubleTicketResponse.isSuccess && (
        <StyledModalMessage
          isOpen={openTroubleTicketResponse.isSuccess}
          message={<FormattedMessage id="lbl.open_trouble_ticket_success" />}
          onRequestClose={resetGetError}>
          <SelfcareButton variant="secondarySmall" onClick={resetGetError}>
            <FormattedMessage id="lbl.ok" />
          </SelfcareButton>
        </StyledModalMessage>
      )}
      {openTroubleTicketResponse.isError && (
        <StyledModalMessage
          isOpen={openTroubleTicketResponse.isError}
          message={<FormattedMessage id="err.open_trouble_ticket_error" />}
          onRequestClose={resetGetError}
          type="error">
          <SelfcareButton variant="secondarySmall" onClick={resetGetError}>
            <FormattedMessage id="lbl.ok" />
          </SelfcareButton>
        </StyledModalMessage>
      )}
    </>
  );
};

export default OpenTroubleTicket;
