import React from "react";
export default ({ paper = "#346eb3", text = "#318eff", signature = "#114878", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.2"
    height="29.9"
    viewBox="0 0 22.2 29.9"
    {...props}>
    <path
      fill="none"
      stroke={paper}
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2px"
      d="M.6.6V27.1l2.1,2.2,2.1-2.2,2.1,2.2L9,27.1l2.1,2.2,2.1-2.2,2.1,2.2,2.1-2.2,2.1,2.2,2.1-2.2V8.9L13.3.6Z"
    />
    <path
      fill="none"
      stroke={paper}
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2px"
      d="M13.3.6V8.9h8.3Z"
    />
    <rect fill={text} width="3.3" height="1.1" transform="translate(4.5 11.7)" />
    <rect fill={text} width="7.7" height="1.1" transform="translate(10 11.7)" />
    <rect fill={text} width="3.3" height="1.1" transform="translate(4.5 13.9)" />
    <rect fill={text} width="7.7" height="1.1" transform="translate(10 13.9)" />
    <rect fill={text} width="3.3" height="1.1" transform="translate(4.5 16.1)" />
    <rect fill={signature} width="3.3" height="1.1" transform="translate(14.4 18.3)" />
    <rect fill={text} width="7.7" height="1.1" transform="translate(10 16.1)" />
  </svg>
);
