import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { useGET, usePUT } from "../../hooks/restAPI";
import ModalPage from "../ModalPage";
import { SelfcareButton } from "../base";
import StyledModalMessage from "../StyledModalMessage";
import GetErrorDescription from "../GetErrorDescription";
import { Box, Heading, Text } from "rebass/styled-components";
import { Input } from "@rebass/forms/styled-components";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import ShowPassword from "../ShowPassword";
import { FormErrorMessage } from "../FormErrorMessage";
import { FormattedMessage, useIntl } from "react-intl";

const ChangePasswordModal = ({
  showChangePassword,
  onChangePasswordSuccess,
  onChangePasswordClose,
}) => {
  const { accountCode: masterAccountCode } = useContext(AuthContext);
  const { accountCode: accountCodePath } = useParams();
  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;

  const intl = useIntl();
  const { handleSubmit, watch, errors, control, triggerValidation } = useForm({
    mode: "onChange",
  });

  let [changePasswordData, doPutChangePassword, resetChangePasswordError] = usePUT({});
  const [securityPolicyData, doGetSecurityPolicy] = useGET({});

  useEffect(() => {
    doGetSecurityPolicy({
      route: `/security/securityPolicy`,
    });
  }, [doGetSecurityPolicy]);

  const doAfterSuccessfulSave = () => {
    resetChangePasswordError();
    onChangePasswordSuccess();
  };

  const onChangePassword = values => {
    doPutChangePassword({
      route: `/account/${accountCode}/changePassword`,
      body: {
        password: values.oldPassword,
        newPassword: values.password,
        newPasswordHint: values.newPasswordHint,
      },
      expectedResponse: "none",
    });
  };
  let oldPasswordRef = React.useRef();
  let passwordRef = React.useRef();
  let confirmPasswordRef = React.useRef();

  return (
    <ModalPage isOpen={showChangePassword} onRequestClose={onChangePasswordClose}>
      <form onSubmit={handleSubmit(onChangePassword)}>
        <Box px="larger" py="larger">
          <Heading
            as="h3"
            fontWeight={5}
            pb="medium"
            fontSize="title"
            color="textDark"
            textAlign="left">
            <FormattedMessage id="lbl.change_password" />
          </Heading>

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.old_password" />
          </Text>
          <Box marginBottom="medium">
            <Controller
              name="oldPassword"
              as={
                <Input
                  ref={oldPasswordRef}
                  variant={errors.oldPassword ? "greyInputError" : "greyInput"}
                  style={{ paddingRight: "2.2rem" }}
                  maxLength="25"
                  type="password"
                />
              }
              rules={{
                required: true,
              }}
              control={control}
              defaultValue=""
            />
            <ShowPassword refInput={oldPasswordRef} mt="-1.6rem" />
          </Box>

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.new_password" />
          </Text>
          <Box marginBottom="medium">
            <Box>
              {securityPolicyData.isSuccess && (
                <Controller
                  name="password"
                  as={
                    <Input
                      ref={passwordRef}
                      variant={errors.password ? "greyInputError" : "greyInput"}
                      style={{ paddingRight: "2.2rem" }}
                      maxLength="25"
                      type="password"
                      onChange={() => {
                        if (watch("confirmPassword")) triggerValidation("confirmPassword");
                      }}
                    />
                  }
                  rules={{
                    required: true,
                    pattern: {
                      value: new RegExp(securityPolicyData.data.passwordRegEx),
                      message: securityPolicyData.data.passwordStructureHint,
                    },
                    validate: value => {
                      return (
                        (value && value !== watch("oldPassword")) ||
                        intl.formatMessage({ id: "lbl.old_new_password_cannot_be_the_same" })
                      );
                    },
                  }}
                  control={control}
                  defaultValue=""
                />
              )}
              <ShowPassword refInput={passwordRef} mt="-1.6rem" />
            </Box>
            {errors.password && errors.password.message && (
              <FormErrorMessage>{errors.password.message}</FormErrorMessage>
            )}
          </Box>

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.confirm_password" />
          </Text>
          <Box marginBottom="medium">
            <Box>
              <Controller
                name="confirmPassword"
                as={
                  <Input
                    ref={confirmPasswordRef}
                    variant={errors.confirmPassword ? "greyInputError" : "greyInput"}
                    style={{ paddingRight: "2.2rem" }}
                    maxLength="25"
                    type="password"
                  />
                }
                rules={{
                  required: true,
                  validate: value => {
                    return value === watch("password") || "lbl.passwords_dont_match";
                  },
                }}
                control={control}
                defaultValue=""
              />
              <ShowPassword refInput={confirmPasswordRef} mt="-1.6rem" />
            </Box>
            {errors.confirmPassword && errors.confirmPassword.message && (
              <FormErrorMessage>
                <FormattedMessage id={errors.confirmPassword.message} />
              </FormErrorMessage>
            )}
          </Box>

          <Box width="auto" mt="larger" textAlign="right" mb="huge">
            <SelfcareButton
              type="submit"
              disabled={changePasswordData.isLoading}
              variant="default"
              mr="spaceBetweenButtons">
              <FormattedMessage id="lbl.save" />
            </SelfcareButton>
            <SelfcareButton
              variant="default-inverted"
              disabled={changePasswordData.isLoading}
              onClick={onChangePasswordClose}>
              <FormattedMessage id="lbl.cancel" />
            </SelfcareButton>
          </Box>

          {changePasswordData.isSuccess && (
            <StyledModalMessage
              isOpen={changePasswordData.isSuccess}
              message={<FormattedMessage id="lbl.change_password_success" />}
              onRequestClose={doAfterSuccessfulSave}
              type="info">
              <SelfcareButton variant="secondarySmall" onClick={doAfterSuccessfulSave}>
                <FormattedMessage id="lbl.ok" />
              </SelfcareButton>
            </StyledModalMessage>
          )}

          {changePasswordData.isError && (
            <StyledModalMessage
              isOpen={changePasswordData.isError}
              message={<GetErrorDescription error={changePasswordData.error} />}
              onRequestClose={resetChangePasswordError}
              type="error">
              <SelfcareButton variant="secondarySmall" onClick={resetChangePasswordError}>
                <FormattedMessage id="lbl.Try_Again" />
              </SelfcareButton>
            </StyledModalMessage>
          )}
        </Box>
      </form>
    </ModalPage>
  );
};

export default ChangePasswordModal;
