import React from "react";
export default ({
  eye = "#114878",
  background = "#e6e6e6",
  billText = "#114878",
  billClipper = "#318eff",
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44.04" height="55.38" viewBox="0 0 44.04 55.38">
    <g transform="translate(-70.02 -5.65)">
      <path
        fill={background}
        d="M90.416,53.9l.74-1.11c.219-.328,5.428-8.015,12.284-8.015a10.561,10.561,0,0,1,4.5,1.082V10.132H70.02V55.06H91.187l-.031-.045Z"
      />
      <path fill={billClipper} d="M93.929,7.937a6.5,6.5,0,0,0-9.9,0H80.859v6H97.1v-6Z" />
      <rect fill={billText} width="23.04" height="1.891" transform="translate(77.46 22.65)" />
      <rect fill={billText} width="23.04" height="1.891" transform="translate(77.46 28.65)" />
      <rect fill={billText} width="23.04" height="1.891" transform="translate(77.46 34.65)" />
      <rect fill={billText} width="23.04" height="1.891" transform="translate(77.46 40.65)" />
      <path
        fill={eye}
        d="M107.94,48.106a9.134,9.134,0,0,0-4.5-1.326c-5.865,0-10.62,7.125-10.62,7.125s.312.465.869,1.155c1.63,2.016,5.381,5.97,9.751,5.97,5.865,0,10.62-7.125,10.62-7.125A21.09,21.09,0,0,0,107.94,48.106Z"
      />
      <path
        fill={background}
        d="M103.44,48.865a5.04,5.04,0,1,0,5.04,5.04A5.039,5.039,0,0,0,103.44,48.865Zm0,8.152a3.112,3.112,0,1,1,3.112-3.112A3.112,3.112,0,0,1,103.44,57.017Z"
      />
    </g>
  </svg>
);
