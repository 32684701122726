import React, { useContext, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthorizationContext";

import { FormattedDate, FormattedMessage } from "react-intl";
import { Box, Button, Flex, Heading, Text } from "rebass/styled-components";
import Layout from "../../containers/Layout";
import NavigationSecondaryMenu from "../../components/NavigationSecondaryMenu";
import { SelfcareAmount } from "../../components/base";
import Tooltip from "../../components/Tooltip";
import { useOverflow } from "../../hooks/useOverflow";
import { getDateWithoutTimezone } from "../../common/Utilities";

const PaymentDetails = props => {
  const payment = props.location.state;
  const history = useHistory();

  const { accountCode: masterAccountCode, isSubAccount } = useContext(AuthContext);
  const { accountCode: accountCodePath } = useParams();

  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  const menuItems = [
    {
      title: "lbl.invoices",
      icon: "",
      location: isSubAccount ? `/account/${accountCode}/billing/invoices` : "/billing/invoices",
    },
    {
      title: "lbl.payments",
      icon: "",
      location: isSubAccount ? `/account/${accountCode}/billing/payments` : "/billing/payments",
    },
  ];

  const _getTransactionTypeDescription = transactionType => {
    switch (transactionType) {
      case "P":
        return "Payment";
      case "R":
        return "Refund";
      case "RP":
        return "Reserved Payment";
      case "RR":
        return "Reserved Refund";
      default:
        return "N/A";
    }
  };

  const _getFormattedDate = date => {
    let formattedDate;
    if (date == null) {
      formattedDate = <FormattedMessage id="lbl.not_available" />;
    } else {
      formattedDate = <FormattedDate value={getDateWithoutTimezone(date)} />;
    }

    return formattedDate;
  };

  const _getFormattedNumber = value => {
    let formattedValue;
    if (value == null) {
      formattedValue = <FormattedMessage id="lbl.not_available" />;
    } else {
      formattedValue = <SelfcareAmount amount={value} />;
    }

    return formattedValue;
  };

  const getPaymentMethod = payment => {
    if (payment.paymentMethod === 4) {
      return <FormattedMessage id="lbl.e_check" />;
    }
    return payment.description;
  };

  const Row = ({ labelId, value }) => {
    const valueRef = useRef();
    const [isOverflow] = useOverflow(valueRef);

    return (
      <Flex width="100%">
        <Text color="textDark" fontSize="secondary" mt="default" width={1 / 2}>
          <FormattedMessage id={labelId} />
        </Text>
        {!isOverflow && (
          <Text
            ref={valueRef}
            color="secondary"
            fontSize="secondary"
            mt="default"
            width={1 / 2}
            sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
            {value}
          </Text>
        )}
        {isOverflow && (
          <Box mt="default" width={1 / 2}>
            <Tooltip position="top-noflex" value={value}>
              <Text
                color="secondary"
                fontSize="secondary"
                textAlign="left"
                sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                {value}
              </Text>
            </Tooltip>
          </Box>
        )}
      </Flex>
    );
  };

  return (
    <Layout mobileTabSubMenu={<NavigationSecondaryMenu.Mobile menuItems={menuItems} />}>
      <Flex width="100%" flexDirection="column" mb="huge">
        <Heading color="textDark" fontSize="title" my={["default", "default", "medium"]}>
          <FormattedMessage id="lbl.finance_transactions" />
        </Heading>
        <NavigationSecondaryMenu.Desktop menuItems={menuItems} />
        <Heading color="textDark" fontSize="title" my={["default", "default", "medium"]}>
          <FormattedMessage id="lbl.payment_details_heading" />
        </Heading>
        <Box
          sx={{
            display: "grid",
            gridGap: "large",
            gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)"],
          }}>
          <Box
            bg="contentBg"
            p="large"
            sx={{
              boxShadow: "light",
              borderRadius: "card",
            }}>
            <Text color="textDark" fontSize="primary" fontWeight="bold">
              <FormattedMessage id="lbl.payment.transaction_details" />
            </Text>
            <Row labelId="lbl.payment.transaction_id" value={payment.transactionNumber} />
            <Row
              labelId="lbl.payment.transaction_type"
              value={_getTransactionTypeDescription(payment.transactionType)}
            />
            <Row labelId="lbl.payment.amount" value={_getFormattedNumber(payment.value)} />
            <Row
              labelId="lbl.payment.payment_date"
              value={_getFormattedDate(payment.transactionDate)}
            />
            <Row labelId="lbl.payment.payment_method" value={getPaymentMethod(payment)} />
            <Row labelId="lbl.payment.remark" value={payment.remark} />
          </Box>

          {payment.creditCard && (
            <Box
              bg="contentBg"
              p="large"
              sx={{
                boxShadow: "light",
                borderRadius: "card",
              }}>
              <Text color="textDark" fontSize="primary" fontWeight="bold">
                <FormattedMessage id="lbl.cc.credit_card_information" />
              </Text>
              <Row labelId="lbl.cc.card_number" value={payment.creditCard.number} />
              <Row labelId="lbl.cc.cardholdername" value={payment.creditCard.holderName} />
              <Row labelId="lbl.cc.card_type" value={payment.creditCard.descr} />
              <Row
                labelId="lbl.cc.expiration_date"
                value={`${payment.creditCard.expMonth}/${payment.creditCard.expYear}`}
              />
              <Row labelId="lbl.cc.reference_number" value={payment.referenceId} />
              <Row labelId="lbl.cc.authorization_number" value={payment.authCode} />
            </Box>
          )}
        </Box>
        <Button
          mt="large"
          p="small"
          width="6rem"
          backgroundColor="secondary"
          color="textLight"
          bg="secondary"
          alignSelf="flex-end"
          onClick={e => history.goBack()}
          sx={{
            borderRadius: "default",
            boxShadow: "light",
            "&:hover": {
              cursor: "pointer",
              opacity: "95%",
            },
          }}>
          <FormattedMessage id="lbl.btn_back" />
        </Button>
      </Flex>
    </Layout>
  );
};

export default PaymentDetails;
