import React from "react";
import * as Utilities from "../../common/Utilities";
import ServiceGroup from "./ServiceGroup";
import MutualExclusiveServiceGroup from "./MutualExclusiveServiceGroup";
import { SelfcareBox } from "../../components/base";
import { Box, Text } from "rebass/styled-components";
import _ from "lodash";
import ServiceInstanceFeatures from "./ServiceInstanceFeatures";

const ProductCard = ({
  path,
  productDescription,
  mutualExclusiveServices,
  serviceGroupsMap,
  subproductGroupsMap,
  servicesWithEditableFeatures,
  onServiceUpdate,
  onMEServicesUpdate,
  onSubProductsUpdate,
}) => {
  const renderServiceGroups = () => {
    let serviceGroupsContainers = [];
    for (let [key, value] of serviceGroupsMap.entries()) {
      let subProductsFromSameGroup = subproductGroupsMap.get(key);
      if (Utilities.isEmpty(key)) {
        serviceGroupsContainers.push(
          <ServiceGroup
            key={key}
            path={path}
            _services={value}
            _subProducts={subProductsFromSameGroup}
            _onServiceUpdate={onServiceUpdate}
            _onProductUpdate={onSubProductsUpdate}
          />
        );
      } else {
        serviceGroupsContainers.push(
          <MutualExclusiveServiceGroup
            key={key}
            path={path}
            exclusivityGroup={key}
            exclusivityGroupDescription={productDescription}
            mandatoryExclusiveItem={mutualExclusiveServices}
            _services={value}
            _subProducts={subProductsFromSameGroup}
            _onMEServicesUpdate={onMEServicesUpdate}
            _onMEProductsUpdate={onSubProductsUpdate}
          />
        );
      }
    }
    return serviceGroupsContainers;
  };

  const renderServiceInstancesFeatures = () => {
    let serviceInstancesFeatures = [];
    for (let service of servicesWithEditableFeatures) {
      _.each(service.instances, (srvInstanceValue, srvIdKey) => {
        if (
          srvInstanceValue.status &&
          srvInstanceValue.features.find(feature => {
            return feature.editPermission;
          })
        ) {
          serviceInstancesFeatures.push(
            <Text
              textAlign="left"
              color="secondary"
              key={"featuresFor" + srvIdKey + "title"}
              fontSize="secondary"
              marginY={["default", "default"]}
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}>
              {service.description}
            </Text>
          );

          serviceInstancesFeatures.push(
            <SelfcareBox variant="card" key={"featuresFor" + srvIdKey + "values"}>
              <ServiceInstanceFeatures
                path={path}
                originalService={_.cloneDeep(service)}
                serviceId={srvIdKey}
                serviceInstance={srvInstanceValue}
                _onServiceUpdate={onServiceUpdate}
              />
            </SelfcareBox>
          );
        }
      });
    }
    return serviceInstancesFeatures;
  };

  return (
    <Box>
      {serviceGroupsMap.size > 0 && (
        <>
          <Text
            textAlign="left"
            color="secondary"
            fontSize="secondary"
            marginY={["default", "default"]}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}>
            {productDescription}
          </Text>
          <SelfcareBox variant="card">{renderServiceGroups()}</SelfcareBox>
        </>
      )}

      {renderServiceInstancesFeatures()}
    </Box>
  );
};
export default ProductCard;
