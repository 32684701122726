import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Box, Flex, Text } from "rebass/styled-components";

import { AuthConsumer } from "../../contexts/AuthorizationContext";
import { Providers } from "../../common/Providers";
import { BrandingContext } from "../../contexts/BrandingContext";
import { SelfcareIcon } from "../icons";
import ChangePasswordModal from "../ChangePasswordModal";

const MenuOption = ({ icon, textId, onOptionSelected }) => {
  return (
    <Flex
      alignItems="center"
      pb="default"
      px="default"
      mr="default"
      onClick={() => onOptionSelected()}>
      <SelfcareIcon name={icon} pr="small" />
      <Text fontSize="primary" color="primary">
        <FormattedMessage id={textId} />
      </Text>
    </Flex>
  );
};

const PopupMenu = ({ children, isLoading }) => {
  const container = useRef();
  const history = useHistory();
  const [showPopupMenu, setShowPopupMenu] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleOutsideClick = e => {
    if (container.current && !container.current.contains(e.target)) {
      setShowPopupMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  });

  const { provider, config, switchTheme } = useContext(BrandingContext);
  const providerKeys = Object.keys(Providers);
  const currentThemeIndex = providerKeys.indexOf(provider);
  const nextTheme =
    currentThemeIndex === providerKeys.length - 1
      ? providerKeys[0]
      : providerKeys[currentThemeIndex + 1];

  return (
    <Box>
      <Box
        display="inline-block"
        sx={{
          position: "relative",
          cursor: "pointer",
        }}
        onClick={() => setShowPopupMenu(showPopupMenu => !showPopupMenu)}
        ref={container}>
        {children}
        {showPopupMenu && (
          <Box
            pt="default"
            bg="contentBg"
            sx={{
              position: "absolute",
              top: "100%",
              right: 0,
              boxShadow: "light",
              borderRadius: "card",
              whiteSpace: "nowrap",
            }}>
            {!isLoading && (
              <MenuOption
                icon="change-password"
                textId="lbl.change_password"
                onOptionSelected={() => {
                  setShowChangePassword(true);
                }}
              />
            )}
            {config.themeSwitch && (
              <MenuOption
                icon="question-mark"
                textId="lbl.switch_theme"
                onOptionSelected={() => switchTheme(nextTheme)}
              />
            )}
            <AuthConsumer>
              {({ logout }) => {
                return (
                  <MenuOption
                    icon="logout"
                    textId="lbl.log_out"
                    onOptionSelected={() => logout(history)}
                  />
                );
              }}
            </AuthConsumer>
          </Box>
        )}
      </Box>
      {showChangePassword && (
        <ChangePasswordModal
          showChangePassword={showChangePassword}
          onChangePasswordClose={() => {
            setShowChangePassword(false);
          }}
          onChangePasswordSuccess={() => {
            setShowChangePassword(false);
          }}
        />
      )}
    </Box>
  );
};

export default PopupMenu;
