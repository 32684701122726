import React from "react";
export default ({ color = "#fff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.4"
    height="19.7"
    viewBox="0 0 16.4 19.7"
    {...props}>
    <rect fill={color} width="16.4" height="1.5" transform="translate(0 6.1)" />
    <rect fill={color} width="16.4" height="1.5" />
    <rect fill={color} width="16.4" height="1.5" transform="translate(0 18.2)" />
    <rect fill={color} width="16.4" height="1.5" transform="translate(0 12.1)" />
  </svg>
);
