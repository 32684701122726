import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import {
  AccountBasePage,
  AccountDetails,
  Billing,
  Error,
  ForgotPassword,
  Home,
  InternetTermsAndConditions,
  Invoices,
  Login,
  NotFound,
  PaymentDetails,
  PaymentInformation,
  Payments,
  Registration,
  RegistrationStepTwo,
  ResetPassword,
  Summary,
  UpdatePackage,
} from "../pages";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const SelfcareRouter = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/home" component={Home} />
        <PrivateRoute
          exact
          path={["/billing", "/account/:accountCode/billing"]}
          component={Billing}
        />
        <PrivateRoute
          exact
          path={["/billing/payments", "/account/:accountCode/billing/payments"]}
          component={Payments}
        />
        <PrivateRoute
          exact
          path={["/billing/payments/details", "/account/:accountCode/billing/payments/details"]}
          component={PaymentDetails}
        />
        <PrivateRoute
          exact
          path={["/billing/invoices", "/account/:accountCode/billing/invoices"]}
          component={Invoices}
        />
        <PrivateRoute
          exact
          path={["/summary", "/account/:accountCode/summary"]}
          component={Summary}
        />
        <PrivateRoute
          exact
          path={["/summary/account", "/account/:accountCode/summary/account"]}
          component={AccountDetails}
        />
        <PrivateRoute
          exact
          path={["/summary/payment", "/account/:accountCode/summary/payment"]}
          component={PaymentInformation}
        />
        <PrivateRoute exact path={["/account/:accountCode/overview"]} component={AccountBasePage} />
        <PrivateRoute
          exact
          path={["/account/:accountCode/updatePackage"]}
          component={UpdatePackage}
        />
        <PrivateRoute
          exact
          path="/internetTermsAndConditions"
          component={InternetTermsAndConditions}
        />

        <PublicRoute exact path="/" component={Login} />
        <PublicRoute exact path="/signup" component={Registration} />
        <PublicRoute exact path="/signupStepTwo" component={RegistrationStepTwo} />
        <PublicRoute exact path="/forgotPassword" component={ForgotPassword} />
        <PublicRoute exact path="/resetPassword" component={ResetPassword} />
        <PublicRoute exact path="/error" component={Error} />
        <PublicRoute component={NotFound} />
      </Switch>
    </Router>
  );
};

export default SelfcareRouter;
