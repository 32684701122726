import React from "react";
export default ({ color = "#336eb4", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.4"
    height="16.4"
    viewBox="0 0 16.4 16.4"
    {...props}>
    <path
      fill={color}
      d="M8.2,0a8.2,8.2,0,1,0,8.2,8.2A8.3,8.3,0,0,0,8.2,0Zm0,14.3A6.211,6.211,0,0,1,2,8.2,6.3,6.3,0,0,1,8.2,2a6.231,6.231,0,0,1,6.2,6.2A6.211,6.211,0,0,1,8.2,14.3Z"
    />
    <path fill={color} d="M9.3,3.6h-2V9.1h4.4v-2H9.3Z" />
  </svg>
);
