import React, { useContext } from "react";
import { Flex } from "rebass/styled-components";
import { ThemeContext } from "styled-components";

import { TimelineInvoiceDesktop } from "./TimelineInvoice";
import { TimelinePaymentDesktop } from "./TimelinePayment";
import { TimelineBalanceDesktop } from "./TimelineBalance";
import { TimelineDueDesktop } from "./TimelineDue";
import {
  computeTimelineEventsPositions,
  FIRST_TIMELINE_EVENT_SIZE,
  TIMELINE_EVENT_SIZE,
} from "./Common";
import { getDateWithoutTimezone } from "../../common/Utilities";

const BillingTimelineDesktop = ({ billingSummary, ...props }) => {
  const theme = useContext(ThemeContext);

  const colors = [
    { stroke: theme.colors.primary, circle: theme.colors.textDark },
    { stroke: theme.colors.textDark, circle: theme.colors.secondary },
    { stroke: theme.colors.secondary, circle: theme.colors.note },
    { stroke: theme.colors.note, circle: theme.colors.primary },
  ];
  let timelineEvents = [];

  const timelineEventsPositions = computeTimelineEventsPositions(billingSummary);

  if (timelineEventsPositions.invoice !== -1) {
    const translateX =
      timelineEventsPositions.invoice === 0
        ? 0
        : FIRST_TIMELINE_EVENT_SIZE + (timelineEventsPositions.invoice - 1) * TIMELINE_EVENT_SIZE;
    timelineEvents.push(
      <TimelineInvoiceDesktop
        key={timelineEventsPositions.invoice}
        strokeColor={colors[timelineEventsPositions.invoice].stroke}
        circleColor={colors[timelineEventsPositions.invoice].circle}
        textColor={theme.colors.textDark}
        amountColor={theme.colors.secondary}
        isFirstInTimeline={timelineEventsPositions.invoice === 0}
        invoiceDate={getDateWithoutTimezone(billingSummary.lastInvoiceDate)}
        invoiceNumber={billingSummary.lastInvoiceFullNumber}
        invoiceBalance={billingSummary.lastInvoiceBalance}
        transform={`translate(${translateX} 0)`}
      />
    );
  }
  if (timelineEventsPositions.payment !== -1) {
    const translateX =
      timelineEventsPositions.payment === 0
        ? 0
        : FIRST_TIMELINE_EVENT_SIZE + (timelineEventsPositions.payment - 1) * TIMELINE_EVENT_SIZE;
    timelineEvents.push(
      <TimelinePaymentDesktop
        key={timelineEventsPositions.payment}
        strokeColor={colors[timelineEventsPositions.payment].stroke}
        circleColor={colors[timelineEventsPositions.payment].circle}
        textColor={theme.colors.textDark}
        amountColor={theme.colors.secondary}
        isFirstInTimeline={timelineEventsPositions.payment === 0}
        paymentDate={getDateWithoutTimezone(billingSummary.lastPaymentDate)}
        paymentNumber={billingSummary.lastPaymentReceiptNumber}
        paymentAmount={billingSummary.lastPayment}
        transform={`translate(${translateX} 0)`}
      />
    );
  }
  if (timelineEventsPositions.balance !== -1) {
    const translateX =
      timelineEventsPositions.balance === 0
        ? 0
        : FIRST_TIMELINE_EVENT_SIZE + (timelineEventsPositions.balance - 1) * TIMELINE_EVENT_SIZE;
    timelineEvents.push(
      <TimelineBalanceDesktop
        key={timelineEventsPositions.balance}
        strokeColor={colors[timelineEventsPositions.balance].stroke}
        circleColor={colors[timelineEventsPositions.balance].circle}
        textColor={theme.colors.textDark}
        amountColor={theme.colors.secondary}
        isFirstInTimeline={timelineEventsPositions.balance === 0}
        balance={billingSummary.balance}
        transform={`translate(${translateX} 0)`}
      />
    );
  }
  if (timelineEventsPositions.due !== -1) {
    const translateX =
      timelineEventsPositions.due === 0
        ? 0
        : FIRST_TIMELINE_EVENT_SIZE + (timelineEventsPositions.due - 1) * TIMELINE_EVENT_SIZE;
    timelineEvents.push(
      <TimelineDueDesktop
        key={timelineEventsPositions.due}
        strokeColor={colors[timelineEventsPositions.due].stroke}
        circleColor={colors[timelineEventsPositions.due].circle}
        textColor={theme.colors.textDark}
        invoiceDueDate={getDateWithoutTimezone(billingSummary.lastInvoiceDueDate)}
        isDueDateInFuture={timelineEventsPositions.due > timelineEventsPositions.balance}
        transform={`translate(${translateX} 0)`}
      />
    );
  }

  const SVG_WIDTH =
    FIRST_TIMELINE_EVENT_SIZE + (timelineEvents.length - 1) * TIMELINE_EVENT_SIZE + 20;

  return (
    <Flex
      {...props}
      padding="default"
      justifyContent="center"
      alignItems="center"
      sx={{ backgroundColor: "contentBg", borderRadius: "card", boxShadow: "light" }}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${SVG_WIDTH} 200`}
        width={`${SVG_WIDTH}px`}
        height="200px">
        {timelineEvents}
      </svg>
    </Flex>
  );
};

export default BillingTimelineDesktop;
