import React from "react";
export default ({ edge = "#346eb3", text = "#114878", accent = "#318eff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.055"
    height="13.966"
    viewBox="0 0 24.055 13.966"
    {...props}>
    <g transform="translate(0.033 0.033)">
      <rect
        fill="none"
        stroke={edge}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.066px"
        width="22.989"
        height="12.9"
        transform="translate(0.5 0.5)"
      />
      <g transform="translate(2.878 3.166)">
        <rect fill={text} width="18.305" height="1.658" transform="translate(0 2.739)" />
        <rect fill={text} width="18.305" height="1.658" />
        <rect fill={accent} width="4.612" height="2.162" transform="translate(0 5.477)" />
        <rect fill={accent} width="4.612" height="2.162" transform="translate(5.333 5.477)" />
        <rect fill={accent} width="2.306" height="2.162" transform="translate(10.594 5.477)" />
      </g>
    </g>
  </svg>
);
