import React, { useState } from "react";
import Storage from "store2";
import jwt from "jwt-decode";
import createPersistedState from "use-persisted-state";

const loggedInState = createPersistedState("logged_in");
const codeVerifierState = createPersistedState("code_verifier");

const decodeToken = token => {
  let accountCode, error;
  try {
    const claims = jwt(token);
    accountCode = claims.account_code;
  } catch (err) {
    error = err;
  }
  return { accountCode, error };
};

const generateCodeVerifier = () => {
  let salt = new Uint8Array(32);
  window.crypto.getRandomValues(salt);

  const codec = "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-._~";

  return salt.reduce((code, i) => code + codec[i % 66]);
};

const defaultState = {
  loggedIn: false,
  isLoading: false,
  error: null,
  accountCode: null,
  login: () => true,
  logout: () => true,
};

const AuthContext = React.createContext(defaultState);
const { Provider, Consumer } = AuthContext;

function AuthProvider({ children }) {
  const accessToken = Storage.get("access_token");

  let accountCode, error;
  if (Storage.get("logged_in") && accessToken) {
    ({ accountCode, error } = decodeToken(accessToken));
  }

  const doLogin = token => {
    Storage.set("access_token", token.accessToken, true);
    Storage.set("expiration_date", token.expirationDate, true);
    Storage.set("refresh_token", token.refreshToken, true);

    setLoggedIn(true);
    setCodeVerifier(null);

    const { accountCode, error } = decodeToken(token.accessToken);
    setAuth({
      ...auth,
      error: error,
      accountCode: accountCode,
    });
  };

  const doLogout = history => {
    setLoggedIn(false);
    setCodeVerifier(generateCodeVerifier());

    Storage.remove("access_token");
    Storage.remove("expiration_date");
    Storage.remove("refresh_token");

    if (history) {
      history.replace("/");
    }
  };

  const doSetMasterAccount = accountDetails => {
    setAccountDetails({
      ...accountDetails,
      masterAccount: accountDetails,
    });
  };

  const doSetCurrentAccount = (isSubAccount, details) => {
    setCurrentAccount({
      ...currentAccount,
      isSubAccount: isSubAccount,
      currentAccountDetails: details,
    });
  };

  const doSetAccountsList = list => {
    setAccountsListData({
      ...accountsListData,
      accountsList: list,
    });
  };

  const [auth, setAuth] = useState({
    ...defaultState,
    error: error,
    accountCode: accountCode,
    login: doLogin,
    logout: doLogout,
  });

  const [accountDetails, setAccountDetails] = useState({
    masterAccount: null,
    setMasterAccount: doSetMasterAccount,
  });

  const [currentAccount, setCurrentAccount] = useState({
    isSubAccount: false,
    currentAccountDetails: null,
    setCurrentAccount: doSetCurrentAccount,
  });

  const [accountsListData, setAccountsListData] = useState({
    accountsList: [],
    setAccountsList: doSetAccountsList,
  });

  const [loggedIn, setLoggedIn] = loggedInState(false);
  const [codeVerifier, setCodeVerifier] = codeVerifierState(generateCodeVerifier());

  return (
    <Provider
      value={{
        ...auth,
        loggedIn,
        ...accountDetails,
        ...currentAccount,
        ...accountsListData,
        codeVerifier,
      }}>
      {children}
    </Provider>
  );
}

export { AuthContext, AuthProvider, Consumer as AuthConsumer };
