import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Flex, Heading, Text } from "rebass/styled-components";
import { SelfcareAmount, SelfcareButton } from "../../components/base";
import ModalPage from "../../components/ModalPage";
import StyledModalMessage from "../../components/StyledModalMessage";

const PaymentResponseModal = ({
  isDisplayPaymentResponse,
  onPaymentResponseClose,
  response,
  successMessageId = "lbl.payment_successful",
}) => {
  if (!response || (!response.errorMessage && (!response.amount || response.amount === "0.0"))) {
    return <></>;
  }

  return (
    <ModalPage isOpen={isDisplayPaymentResponse} onRequestClose={onPaymentResponseClose}>
      <Box alignItems="center" justifyContent="center">
        {response && response.errorMessage && (
          <>
            <StyledModalMessage
              isOpen={true}
              message={response.errorMessage}
              onRequestClose={onPaymentResponseClose}
              type="error">
              <SelfcareButton variant="secondarySmall" onClick={onPaymentResponseClose}>
                <FormattedMessage id="lbl.Try_Again" />
              </SelfcareButton>
            </StyledModalMessage>
          </>
        )}

        {response && !response.errorMessage && (
          <>
            <Box>
              <Heading
                as="h1"
                mt="2.5rem" mb="1rem"
                fontWeight={5}
                fontSize="title"
                color="primary"
                textAlign="center">
                <FormattedMessage id={successMessageId} />
              </Heading>
            </Box>

            <Box>
              <Box px="huge" py="large">
                <Heading
                  as="h3"
                  fontWeight={5}
                  pb="medium"
                  fontSize="title"
                  color="textDark"
                  textAlign={["center", "center", "left"]}>
                  <FormattedMessage id="lbl.payment_details_heading" />
                </Heading>

                <Box>
                  <Box
                    pb="medium"
                    sx={{
                      borderBottomColor: "#cccccc",
                      borderBottomWidth: response && response.referenceId ? "0.01rem" : "0rem",
                      borderBottomStyle: "solid",
                    }}>
                    {response && response.paymentNumber && (
                      <Flex width={1}>
                        <Box width={1 / 2}>
                          <Text
                            color="textDark"
                            fontSize="secondary"
                            marginY="small"
                            fontWeight={4}>
                            <FormattedMessage id="lbl.payment_amount" />
                          </Text>
                        </Box>
                        <Box width={1 / 2}>
                          <Text
                            color="secondary"
                            fontSize="secondary"
                            marginY="small"
                            fontWeight={4}>
                            <SelfcareAmount amount={response.amount} />
                          </Text>
                        </Box>
                      </Flex>
                    )}

                    {response && response.paymentMethod && (
                      <Flex width={1}>
                        <Box width={1 / 2}>
                          <Text
                            color="textDark"
                            fontSize="secondary"
                            marginY="small"
                            fontWeight={4}>
                            <FormattedMessage id="lbl.payment_method" />
                          </Text>
                        </Box>
                        <Box width={1 / 2}>
                          <Text
                            color="secondary"
                            fontSize="secondary"
                            marginY="small"
                            fontWeight={4}>
                            {response.paymentMethod}
                          </Text>
                        </Box>
                      </Flex>
                    )}

                    {response && response.paymentNumber && (
                      <Flex width={1}>
                        <Box width={1 / 2}>
                          <Text
                            color="textDark"
                            fontSize="secondary"
                            marginY="small"
                            fontWeight={4}>
                            <FormattedMessage id="lbl.payment_number" />
                          </Text>
                        </Box>
                        <Box width={1 / 2}>
                          <Text
                            color="secondary"
                            fontSize="secondary"
                            marginY="small"
                            fontWeight={4}>
                            {response.paymentNumber}
                          </Text>
                        </Box>
                      </Flex>
                    )}

                    {response && response.authCode && (
                      <Flex width={1}>
                        <Box width={1 / 2}>
                          <Text
                            color="textDark"
                            fontSize="secondary"
                            marginY="small"
                            fontWeight={4}>
                            <FormattedMessage id="lbl.authorization_number" />
                          </Text>
                        </Box>
                        <Box width={1 / 2}>
                          <Text
                            color="secondary"
                            fontSize="secondary"
                            marginY="small"
                            fontWeight={4}>
                            {response.authCode}
                          </Text>
                        </Box>
                      </Flex>
                    )}

                    {response && response.referenceId && (
                      <Flex width={1}>
                        <Box width={1 / 2}>
                          <Text
                            color="textDark"
                            fontSize="secondary"
                            marginY="small"
                            fontWeight={4}>
                            <FormattedMessage id="lbl.reference_number" />
                          </Text>
                        </Box>
                        <Box width={1 / 2}>
                          <Text
                            color="secondary"
                            fontSize="secondary"
                            marginY="small"
                            fontWeight={4}>
                            {response.referenceId}
                          </Text>
                        </Box>
                      </Flex>
                    )}
                  </Box>

                  {/** eCheck doesn't have a reference id because payment is not processed instantly,
                     therefore no post successfully message should be displayed */
                  response && response.referenceId && (
                    <Box pt="medium" textAlign={["center", "center", "left"]}>
                      <Text
                        color="primary"
                        fontSize="note"
                        marginY="small"
                        fontWeight={4}
                        display={["none", "none", "inline"]}>
                        <FormattedMessage id="msg.post_payment_thank_you" />
                      </Text>
                      <Text
                        color="primary"
                        fontSize="note"
                        marginY="small"
                        fontWeight={4}
                        display={["inline", "inline", "none"]}>
                        <FormattedMessage id="msg.post_payment_thank_you_mobile" />
                      </Text>
                    </Box>
                  )}
                </Box>

                <Box width="auto" mt="larger" textAlign="center">
                  <SelfcareButton variant="default" onClick={onPaymentResponseClose}>
                    <FormattedMessage id="lbl.ok" />
                  </SelfcareButton>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </ModalPage>
  );
};

export default PaymentResponseModal;
