import React from "react";
import { useIntl } from "react-intl";
import SASummarySkeleton from "./SASummarySkeleton";
import SummaryLayout from "../../containers/SummaryLayout";
import SummaryRow from "../../components/SummaryRow/SummaryRow";
import { capitalize } from "../../common/Utilities";

const SASummary = ({ account, isLoading }) => {
  const intl = useIntl();

  function _mapAccountStatusToIcon(status) {
    if (status === "ACTIVE") {
      return "active";
    } else if (status === "SUSPENDED") {
      return "paused";
    } else {
      return "disabled";
    }
  }

  if (isLoading) {
    return <SummaryLayout isLoading skeleton={SASummarySkeleton} />;
  }

  return (
    <SummaryLayout title="lbl.sa.summary">
      <SummaryRow
        icon="service-identifier"
        value={account.phoneNumber ? account.phoneNumber : account.accountCode}
        tooltip={intl.formatMessage({ id: "tooltip.service_identifier" })}
      />
      <SummaryRow
        icon={"service-agreement"}
        value={`${account.accountType} / ${account.technologyDescription}`}
        tooltip={intl.formatMessage({ id: "tooltip.type_description" })}
      />
      <SummaryRow
        icon={_mapAccountStatusToIcon(account.accountStatus)}
        value={capitalize(account.accountStatus)}
        tooltip={intl.formatMessage({ id: "tooltip.status" })}
      />
    </SummaryLayout>
  );
};

export default SASummary;
