import React from "react";
export default ({ color = "#346EB3", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    y="0px"
    viewBox="0 0 24 23.6"
    {...props}>
    <path
      fill={color}
      d="M24,11.8l-0.3-0.4c-0.2-0.3-5.3-6.9-11.7-6.9S0.5,11.1,0.3,11.3L0,11.8l0.3,0.5c1.3,1.6,2.8,3.1,4.5,4.3l-3,3  l0.9,0.9l2.2-2.2l0,0l0,0l1-1l0,0l0,0c1.6,1,3.5,1.7,5.4,1.8v4.5h1.3v-4.4h0v0c1.9-0.2,3.8-0.8,5.4-1.8l0.8,0.8v0l2.4,2.4l0.9-0.9  l-3-3c1.7-1.2,3.2-2.7,4.5-4.3L24,11.8z M22.1,11.8c-1.2,1.4-5.3,5.9-10.1,5.9s-8.9-4.4-10.1-5.9C3.1,10.3,7.3,5.9,12,5.9  S20.9,10.3,22.1,11.8z"
    />
  </svg>
);
