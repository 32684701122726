export const isShowUserCode = serviceDetails => {
  return isShowServiceCredentialField(serviceDetails, "U");
};

export const isShowAccessKey = serviceDetails => {
  return isShowServiceCredentialField(serviceDetails, "A");
};

const isShowServiceCredentialField = (serviceDetails, fieldType) => {
  if (!serviceDetails.mediationFieldsServiceTypes) {
    return false;
  }

  if (serviceDetails.type === "EMAIL") {
    return true;
  }

  let paramList = serviceDetails.mediationFieldsServiceTypes.split(",");
  for (let param of paramList) {
    let serviceTypePair = param.split("=");
    if (
      serviceTypePair[0] === serviceDetails.type &&
      (serviceTypePair.length === 1 || serviceTypePair[1] === fieldType)
    ) {
      return true;
    }
  }

  return false;
};

export const isMediationVisible = serviceDetails => {
  if (!serviceDetails) {
    return false;
  }

  return isShowUserCode(serviceDetails) || isShowAccessKey(serviceDetails);
};

export const isAnyUdfSet = udfs => {
  if (!udfs || udfs.length <= 0) {
    return false;
  }
  return udfs.find(udf => udf.value);
};
