import React from "react";
import { Box, Heading, Text } from "rebass/styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { SelfcareBox } from "../base";
import _ from "lodash";
import { PRODUCT_STATUS } from "../../pages/UpdatePackage/JSMethods";
import RegularElement from "./RegularElement";
import HighlightedElement from "./HighlightedElement";
import * as JSMethods from "../../pages/UpdatePackage/JSMethods";

const ServiceAgreementPackage = ({ additionalSrv, configuration }) => {
  const intl = useIntl();
  let mediationFieldsServiceTypes = "";

  if (configuration && configuration.data && configuration.data.options) {
    let mediationFieldsServiceTypesField = configuration.data.options.find(
      element => element.key === "MEDIATION_FIELDS_SERVICE_TYPES"
    );
    mediationFieldsServiceTypes = mediationFieldsServiceTypesField
      ? mediationFieldsServiceTypesField.value
      : "";
  }

  const getServices = additionalSrv => {
    const mapServiceToHtmlElement = (key, service) => {
      // do not display services not assigned
      if (
        _.size(service.instances) === 0 ||
        !service.visible ||
        (_.size(service.instances) === 1 &&
          service.instances[0] &&
          service.instances[0].status === false)
      ) {
        return null;
      }

      let serviceInstancesHtml = [];
      _.each(service.instances, (serviceInstance, serviceId) => {
        let activationDate =
          !service.mandatory && serviceInstance.activationDate
            ? intl.formatDate(serviceInstance.activationDate)
            : "";
        let expirationDate =
          !service.mandatory && serviceInstance.expirationDate
            ? intl.formatDate(serviceInstance.expirationDate)
            : "";

        serviceInstancesHtml.push(
          <RegularElement
            key={key + serviceId}
            description={service.description}
            activationDate={activationDate}
            expirationDate={expirationDate}
            serviceDetails={{
              key: serviceInstance.key,
              accessKey: serviceInstance.accessKey,
              emailUsername: serviceInstance.emailUsername,
              keyLabel: serviceInstance.keyLabel,
              accessKeyLabel: serviceInstance.accessKeyLabel,
              emailUsernameLabel: serviceInstance.emailUsernameLabel,
              type: service.type,
              basicType: service.basicType,
              udfs: serviceInstance.udfs,
              mediationFieldsServiceTypes: mediationFieldsServiceTypes,
            }}
          />
        );
      });
      return serviceInstancesHtml;
    };

    const mapFeatureToHtmlElement = (key, feature) => {
      // do not display features not assigned
      if (feature.idStatusMap[0] === false || !feature.selected) {
        return null;
      }

      return <RegularElement key={key} description={feature.description} />;
    };

    const mapSubProductWithNoVisibleServicesToHtml = subproduct => {
      let subproductElements = [];
      // do not display subProducts without services assigned
      let hasNoVisibleServices = !JSMethods.subProductHasVisibleServicesOrFeatures(subproduct);

      if (
        hasNoVisibleServices &&
        subproduct.status === PRODUCT_STATUS.ENABLED &&
        subproduct.visible &&
        !subproduct.exclusivityGroup
      ) {
        subproductElements.push(
          <RegularElement key={"key" + subproduct.code} description={subproduct.description} />
        );
      }

      return subproductElements;
    };

    const mapSubProductAndSubEntitiesToHtml = (subproduct, index, isLeaf) => {
      let subproductElements = [];

      // do not display subProducts UNNASIGNED
      if (PRODUCT_STATUS.UNASSIGNED === subproduct.status) {
        return [];
      }

      // do not display subProducts without services assigned
      if (!JSMethods.subProductHasVisibleServicesOrFeatures(subproduct)) {
        return [];
      }

      if (subproduct.visible) {
        subproductElements.push(
          <HighlightedElement key={subproduct.code + index} description={subproduct.description} />
        );
      }
      subproductElements.push(
        ...subproduct.subProductList
          .filter(product => product.status === PRODUCT_STATUS.ENABLED && product.exclusivityGroup)
          .map(product => {
            return <RegularElement key={"key" + product.code} description={product.description} />;
          })
      );

      subproductElements.push(
        ...subproduct.serviceList.map((service, index) => {
          return mapServiceToHtmlElement(subproduct.code + service.code + index, service);
        })
      );

      subproductElements.push(
        ...subproduct.subProductList.map(subSubProduct =>
          mapSubProductWithNoVisibleServicesToHtml(subSubProduct)
        )
      );

      subproductElements.push(
        ...subproduct.serviceList.map((service, index) => {
          return mapServiceWithFeatures(service, index);
        })
      );

      if (!isLeaf) {
        subproductElements.push(
          ...subproduct.subProductList.map((subSubProduct, index) => {
            return mapSubProductAndSubEntitiesToHtml(subSubProduct, index, true);
          })
        );
      }
      let filteredSubproductElements = subproductElements.filter(
        el => !!el && (!Array.isArray(el) || el.length)
      );
      if (filteredSubproductElements.length) {
        return (
          <Box key={subproduct.code + index} marginY="medium">
            {subproductElements}
          </Box>
        );
      } else {
        return [];
      }
    };

    const mapServiceWithFeatures = (service, index) => {
      if (!service.instances[_.keys(service.instances)[0]].features.length) {
        return [];
      }

      let serviceElements = [];
      _.each(service.instances, (serviceInstance, serviceId) => {
        let featuresByGroupCode = new Map();
        for (let feature of serviceInstance.features) {
          if (
            Object.keys(feature.idStatusMap).length === 0 ||
            feature.idStatusMap[0] === false ||
            !feature.selected
          ) {
            continue;
          }

          let crtValue = featuresByGroupCode.get(feature.group);
          if (crtValue) {
            crtValue.groupFeatures.push(feature);
          } else {
            featuresByGroupCode.set(feature.group, {
              groupDescription: feature.groupDescription,
              groupFeatures: [feature],
            });
          }
        }

        featuresByGroupCode.forEach((value, key) => {
          let serviceFeatureElements = [];

          serviceFeatureElements.push(
            <HighlightedElement
              key={service.code + serviceId + index}
              description={value.groupDescription}
            />
          );

          serviceFeatureElements.push(
            ...value.groupFeatures.map((feature, index) => {
              return mapFeatureToHtmlElement(
                service.code + serviceId + feature.code + index,
                feature
              );
            })
          );

          serviceElements.push(
            <Box key={service.code + serviceId + key + index} marginY="medium">
              {serviceFeatureElements}
            </Box>
          );
        });
      });

      return serviceElements;
    };

    if (!additionalSrv || !additionalSrv.data.product || additionalSrv.isLoading) {
      return (
        // todo: marius replace with proper loader
        <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
          Loading.....
        </Text>
      );
    }

    let elements = [];
    elements.push(
      ...additionalSrv.data.product.subProductList
        .filter(product => product.status === PRODUCT_STATUS.ENABLED && product.exclusivityGroup)
        .map(product => {
          return <RegularElement key={"key" + product.code} description={product.description} />;
        })
    );

    let serviceList = additionalSrv.data.product.serviceList;
    elements.push(
      ...serviceList.map((service, index) => {
        return mapServiceToHtmlElement(service.code + index, service);
      })
    );

    elements.push(
      ...additionalSrv.data.product.subProductList.map(aSubProduct =>
        mapSubProductWithNoVisibleServicesToHtml(aSubProduct)
      )
    );

    elements.push(
      ...serviceList.map((service, index) => {
        return mapServiceWithFeatures(service, index);
      })
    );

    elements.push(
      ...additionalSrv.data.product.subProductList.map((subproduct, index) => {
        return mapSubProductAndSubEntitiesToHtml(subproduct, index, false);
      })
    );

    return elements;
  };

  let productServices = getServices(additionalSrv);

  // filter out empty elements
  let actualProductServices =
    !productServices || !(productServices instanceof Array)
      ? []
      : productServices.filter(
          e => (e instanceof Array && e.length) || (!(e instanceof Array) && e)
        );

  return (
    <Box>
      <Heading
        my={["default", "default", "medium"]}
        color="textDark"
        fontSize="primary"
        textAlign={["center", "left", "left"]}>
        <FormattedMessage id="lbl.services_and_features" />
      </Heading>
      {additionalSrv.isSuccess && additionalSrv.data.product && !actualProductServices.length && (
        <Box sx={{ textAlign: ["center", "left", "left"] }}>
          <Text color="primary">
            <FormattedMessage id="lbl.no_visible_services" />
          </Text>
          <Text color="note">
            <FormattedMessage id="lbl.no_services_subnote" />
          </Text>
        </Box>
      )}

      {actualProductServices.length > 0 && (
        <SelfcareBox variant="card">{actualProductServices}</SelfcareBox>
      )}
    </Box>
  );
};

export default ServiceAgreementPackage;
