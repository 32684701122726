import React from "react";
export default ({ circle = "#114878", letter = "#114878", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
    <g transform="translate(-1369 -581)">
      <g fill="none" stroke={circle} transform="translate(1369 581)">
        <circle stroke="none" cx="8.5" cy="8.5" r="8.5" />
        <circle fill="none" cx="8.5" cy="8.5" r="8" />
      </g>
      <g transform="translate(-2.5)">
        <rect fill={letter} width="2" height="7" transform="translate(1379 588)" />
        <rect fill={letter} width="2" height="1.5" transform="translate(1379 585)" />
      </g>
    </g>
  </svg>
);
