import React, { useEffect } from "react";
import { Box, Flex } from "rebass/styled-components";
import { useHistory, useLocation } from "react-router-dom";

import NavigationMenuItem from "../NavigationMenuItem";

const Separator = props => (
  <Box
    {...props}
    height="100%"
    sx={{
      borderRight: 1,
      borderRightColor: "inactive",
    }}
  />
);

const NavigationSecondaryMenuDesktop = ({ menuItems, ...props }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let defaultLocation;
    for (let i = 0; i < menuItems.length; i++) {
      if (!defaultLocation) {
        defaultLocation = menuItems[i].location;
      }
      if (location.pathname.includes(menuItems[i].location)) {
        return;
      }
    }
    history.replace(defaultLocation);
  }, [history, location.pathname, menuItems]);

  return (
    <Box display={["none", "inline", "inline"]}>
      <Flex
        {...props}
        bg="contentBg"
        sx={{
          boxShadow: "light",
          borderRadius: "card",
        }}>
        <Flex width="100%" alignItems="center" justifyContent="center" mx="auto">
          <Separator />
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <NavigationMenuItem
                p="default"
                width={1 / (menuItems.length + 2)}
                titleLanguageID={item.title}
                isActive={location.pathname.includes(item.location)}
                textAlign="center"
                onClick={() =>
                  !location.pathname.includes(item.location) && history.push(item.location)
                }
              />
              {index < menuItems.length - 1 && <Separator />}
            </React.Fragment>
          ))}
          <Separator />
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavigationSecondaryMenuDesktop;
