import React from "react";

const SmallStroke = ({ color, dashed = false, ...props }) => {
  const { transform, svgProps } = props;

  return (
    <g transform={transform}>
      <svg
        {...svgProps}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 86.3 94.3"
        width="86.3"
        height="94.3">
        <path
          fill={color}
          d="M78.3 0a8.024 8.024 0 0 0-8 8 8.014 8.014 0 0 0 6 7.7V50A42.475 42.475 0 0 0 37.7 90.3h0v4h3.9v-2A38.581 38.581 0 0 1 80.1 53.8v-.1h.2v-38a8.014 8.014 0 0 0 6-7.7A8.024 8.024 0 0 0 78.3 0Zm0 12a4 4 0 1 1 4-4A4.012 4.012 0 0 1 78.3 12Z"
        />
        <line
          stroke={color}
          strokeWidth="4px"
          strokeDasharray={dashed ? "2 4" : "0"}
          x1="0"
          x2="38"
          transform="translate(0 92.3)"
        />
      </svg>
    </g>
  );
};

const LargeStroke = ({ color, dashed = false, ...props }) => {
  const { transform, svgProps } = props;

  return (
    <g transform={transform}>
      <svg
        {...svgProps}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 161.2 94.3"
        width="161.2"
        height="94.3">
        <path
          fill={color}
          d="M78.3 0a8.024 8.024 0 0 0-8 8 8.014 8.014 0 0 0 6 7.7V50A42.475 42.475 0 0 0 37.7 90.3h0v4h3.9v-2A38.581 38.581 0 0 1 80.1 53.8v-.1h.2v-38a8.014 8.014 0 0 0 6-7.7A8.024 8.024 0 0 0 78.3 0Zm0 12a4 4 0 1 1 4-4A4.012 4.012 0 0 1 78.3 12Z"
          transform="translate(74.9 0)"
        />
        <line
          stroke={color}
          strokeWidth="4px"
          strokeDasharray={dashed ? "2 4" : "0"}
          x1="0"
          x2="112.9"
          transform="translate(0 92.3)"
        />
      </svg>
    </g>
  );
};

const Stroke = ({ color, size = "large", dashed = false, ...props }) => {
  return size === "large" ? (
    <LargeStroke color={color} dashed={dashed} {...props} />
  ) : (
    <SmallStroke color={color} dashed={dashed} {...props} />
  );
};

export default Stroke;
