import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import SelfcareRouter from "./routing";
import { SelfcareIntlProvider } from "./contexts/SelfcareIntlContext";
import { AuthProvider } from "./contexts/AuthorizationContext";
import { BaseModalBackground, ModalProvider } from "styled-react-modal";
import { BrandingProvider } from "./contexts/BrandingContext";

const GlobalStyle = createGlobalStyle`
  @media screen and (max-width: 320px) {
    html {
      font-size: calc(10px * ${window.devicePixelRatio});
    }
  };
  @media screen and (min-width: 320px) and (max-width: 1600px) {
    html {
      font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1600 - 320)) * ${window.devicePixelRatio});
    }
  };
  @media screen and (min-width: 1600px) {
    html {
      font-size: calc(16px * ${window.devicePixelRatio});
    }
  };
  body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  };

  /* iOS Safari - fix for issues with zooming form controls when font-size is < 16px */ 
  @media screen and (max-width: 40em) {
    select:active, input:active, textarea:active {
      font-size: 16px !important;
    }
  };

  /* Safari - hide autofill icon */
  input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    position: absolute;
    right: 0;
  };
`;

const FadingBackground = styled(BaseModalBackground)`
  background-color: rgba(255, 255, 255, 0.8);
  overflow-y: auto;
  @supports ((-webkit-backdrop-filter: blur(6px)) or (backdrop-filter: blur(6px))) {
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
  }
`;

function App() {
  return (
    <SelfcareIntlProvider>
      <BrandingProvider>
        <ModalProvider backgroundComponent={FadingBackground}>
          <AuthProvider>
            <SelfcareRouter />
            <GlobalStyle />
          </AuthProvider>
        </ModalProvider>
      </BrandingProvider>
    </SelfcareIntlProvider>
  );
}

export default App;
