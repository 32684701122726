import React from "react";
import { Flex, Text } from "rebass/styled-components";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { SelfcareIcon } from "../icons";

const MakePayment = ({ navigationOutcome, onClick, ...props }) => {
  const history = useHistory();

  const handleClick = () => {
    if (navigationOutcome) {
      history.push(navigationOutcome);
    }
    onClick();
  };

  return (
    <Flex
      {...props}
      px={["default", "default", "large"]}
      py={["default", "default", "large"]}
      backgroundColor="contentBg"
      alignItems="center"
      justifyContent="center"
      flexDirection={["row", "row", "column"]}
      sx={{
        boxShadow: "light",
        borderRadius: "card",
        cursor: "pointer",
        "&:hover": {
          opacity: "70%",
        },
      }}
      onClick={() => handleClick()}>
      <SelfcareIcon name="make-payment" display={["none", "none", "inline"]} mb="default" />

      <Text
        textAlign="center"
        color="primary"
        fontSize={["primary", "primary", "secondary"]}
        fontWeight={4}
        mt="1rem"
        mb={["1rem", "1rem", "0rem"]}
        maxWidth={["100%", "100%", "5rem"]}>
        <FormattedMessage id="lbl.make_payment" />
      </Text>
    </Flex>
  );
};

export default MakePayment;
