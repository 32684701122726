import React from "react";

const Circle = ({ color, ...props }) => {
  const { transform, svgProps } = props;

  return (
    <g transform={transform}>
      <svg
        {...svgProps}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 65 65"
        width="65px"
        height="65px">
        <circle
          cx="30.5"
          cy="30.5"
          r="30.5"
          strokeWidth="4px"
          stroke={color}
          fill="none"
          transform="translate(2 2)"
        />
      </svg>
    </g>
  );
};

export default Circle;
