import Storage from "store2";
import { FacadeApi } from "./FacadeRestClient";
import { OAuthCredentials } from "../common/Deployment";
import AuthorizationException from "../exceptions/AuthorizationException";

const { tokenURL, clientID, clientSecret, redirectURI } = OAuthCredentials;

const getToken = async (requestType, value) => {
  let requestBody;
  if (requestType === "code") {
    requestBody = {
      grant_type: "authorization_code",
      code: value,
      redirect_uri: window.location.origin + redirectURI,
      client_id: clientID,
    };
  } else if (requestType === "refresh") {
    requestBody = {
      grant_type: "refresh_token",
      refresh_token: value,
      client_id: clientID,
    };
  }

  try {
    const result = await FacadeApi._fetch(
      tokenURL,
      "POST",
      requestBody,
      false,
      true,
      "json",
      true,
      null,
      {
        type: "Basic",
        token: btoa(clientID + ":" + clientSecret),
      }
    );
    return result;
  } catch (error) {
    throw error;
  }
};

let mustWait = false;

let waiter = () => {
  return new Promise(function(resolve) {
    setTimeout(resolve, 25);
  });
};

const retrieveAccessToken = async () => {
  const expirationDate = new Date(Storage.get("expiration_date"));
  //token is 1 minute before expiration, perform refresh
  if (expirationDate - 60000 < new Date()) {
    while (mustWait) {
      await waiter();
      if (Storage.get("expiration_date") > expirationDate.getTime()) {
        return Storage.get("access_token");
      }
    }

    mustWait = true;

    const refreshToken = Storage.get("refresh_token");

    // get a new one using the refresh token
    try {
      const response = await getToken("refresh", refreshToken);

      //store it
      Storage.set("access_token", response.access_token, true);
      Storage.set("expiration_date", new Date().getTime() + response.expires_in * 1000, true);
      Storage.set("refresh_token", response.refresh_token, true);
    } catch (error) {
      throw new AuthorizationException("REFRESH_FAILED", error.error_description);
    }

    mustWait = false;
  }

  return Storage.get("access_token");
};

export { getToken, retrieveAccessToken };
