import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { space, layout } from "styled-system";

import Home from "./Home";
import HomeMobile from "./HomeMobile";
import UserResidential from "./UserResidential";
import AccountSummary from "./AccountSummary";
import AccountSummaryMobile from "./AccountSummaryMobile";
import InvoiceAndPayments from "./InvoiceAndPayments";
import InvoiceAndPaymentsMobile from "./InvoiceAndPaymentsMobile";
import MenuBurgerMobile from "./MenuBurgerMobile";
import Mobile from "./Mobile";
import Prepaid from "./Prepaid";
import Active from "./Active";
import Paused from "./Paused";
import Disabled from "./Disabled";
import DslInternet from "./DslInternet";
import FiberInternet from "./FiberInternet";
import VoiceOverFiber from "./VoiceOverFiber";
import Wirelines from "./Wirelines";
import Balance from "./Balance";
import Due from "./Due";
import Invoice from "./Invoice";
import Payment from "./Payment";
import MakePayment from "./MakePayment";
import Name from "./Name";
import Address from "./Address";
import Mail from "./Mail";
import BusinessPhone from "./BusinessPhone";
import HomePhone from "./HomePhone";
import MobilePhone from "./MobilePhone";
import Identity from "./Identity";
import PhoneNumber from "./PhoneNumber";
import Password from "./Password";
import CheckMark from "./CheckMark";
import CheckBox from "./CheckBox";
import SecurityQuestion from "./SecurityQuestion";
import BillingCycle from "./BillingCycle";
import InvoiceShipment from "./InvoiceShipment";
import CreditCard from "./CreditCard";
import CreditCardExpiration from "./CreditCardExpiration";
import AutomaticPaymentMethod from "./AutomaticPaymentMethod";
import AutomaticPaymentEnabled from "./AutomaticPaymentEnabled";
import AutomaticPaymentDisabled from "./AutomaticPaymentDisabled";
import BankAccountNumber from "./BankAccountNumber";
import BankAccountRoutingNumber from "./BankAccountRoutingNumber";
import BankAccountType from "./BankAccountType";
import ServiceIdentifier from "./ServiceIdentifier";
import ServiceAgreement from "./ServiceAgreement";
import ContactAddress from "./ContactAddress";
import ServiceAddress from "./ServiceAddress";
import ChangePassword from "./ChangePassword";
import QuestionMark from "./QuestionMark";
import Logout from "./Logout";
import Unlimited from "./Unlimited";
import Overview from "./Overview";
import Back from "./Back";
import ShowPassword from "../ShowPassword";
import HidePassword from "./HidePassword";
import UserActiveStatus from "./UserActiveStatus";
import UserSuspendedStatus from "./UserSuspendedStatus";
import UserClosedStatus from "./UserClosedStatus";
import MABillable from "./MABillable";
import MANonBillable from "./MANonBillable";
import BackMobile from "./BackMobile";
import Info from "./Info";
import ViewPostpaidUnbilledSummary from "./ViewPostpaidUnbilledSummary";
import UsageDetails from "./UsageDetails";
import OpenTroubleTicket from "./OpenTroubleTicket";
import RequestAssistance from "./RequestAssistance";
import VoiceOverFixedWireless from "./VoiceOverFixedWireless";
import VoiceOverOtherMedia from "./VoiceOverOtherMedia";
import AdvancedServices from "./AdvancedServices";
import FixedWirelessInternet from "./FixedWirelessInternet";
import UserCommercial from "./UserCommercial";

const BaseIcon = ({ name, ...props }) => {
  const theme = useContext(ThemeContext);

  switch (name) {
    case "home":
      return (
        <Home
          wall={theme.colors.greyBorderInput}
          door={theme.colors.primary}
          roof={theme.colors.textDark}
          {...props}
        />
      );
    case "overview":
      return (
        <Overview
          page={theme.colors.greyBorderInput}
          shadow={theme.colors.inactive}
          text={theme.colors.textDark}
          icon={theme.colors.primary}
          {...props}
        />
      );
    case "back":
      return <Back color={theme.colors.primary} {...props} />;
    case "home-mobile":
      return <HomeMobile color={theme.colors.textLight} {...props} />;
    case "user-residential":
      return (
        <UserResidential
          grey={theme.colors.greyBorderInput}
          mediumBlue={theme.colors.primary}
          lightBlue={theme.colors.secondary}
          {...props}
        />
      );
    case "account-summary":
      return (
        <AccountSummary
          face={theme.colors.greyBorderInput}
          body={theme.colors.primary}
          hair={theme.colors.textDark}
          other={theme.colors.secondary}
          {...props}
        />
      );
    case "invoice-and-payments":
      return (
        <InvoiceAndPayments
          page={theme.colors.greyBorderInput}
          shadow={theme.colors.inactive}
          text={theme.colors.textDark}
          silver={theme.colors.secondary}
          {...props}
        />
      );
    case "account-summary-mobile":
      return <AccountSummaryMobile color={theme.colors.textLight} {...props} />;
    case "invoice-and-payments-mobile":
      return <InvoiceAndPaymentsMobile color={theme.colors.textLight} {...props} />;
    case "back-mobile":
      return <BackMobile color={theme.colors.textLight} {...props} />;
    case "menu-burger-mobile":
      return <MenuBurgerMobile color={theme.colors.textLight} {...props} />;
    case "mobile":
      return <Mobile bezel={theme.colors.primary} screen={theme.colors.textDark} {...props} />;
    case "prepaid":
      return (
        <Prepaid
          bezel={theme.colors.primary}
          screen={theme.colors.textDark}
          icon={theme.colors.secondary}
          {...props}
        />
      );
    case "dsl-internet":
      return (
        <DslInternet
          tip={theme.colors.secondary}
          core={theme.colors.primary}
          cable={theme.colors.textDark}
          {...props}
        />
      );
    case "fiber-internet":
      return (
        <FiberInternet
          tip={theme.colors.secondary}
          core={theme.colors.primary}
          sleeve={theme.colors.textDark}
          {...props}
        />
      );
    case "voice-over-fiber":
      return (
        <VoiceOverFiber
          button={theme.colors.secondary}
          core={theme.colors.primary}
          jack={theme.colors.textDark}
          {...props}
        />
      );
    case "wireline":
      return (
        <Wirelines
          tip={theme.colors.secondary}
          core={theme.colors.primary}
          cable={theme.colors.textDark}
          {...props}
        />
      );
    case "active":
      return <Active color={theme.colors.secondary} {...props} />;
    case "paused":
      return <Paused color={theme.colors.primary} {...props} />;
    case "disabled":
      return <Disabled color={theme.colors.textDark} {...props} />;
    case "balance":
      return (
        <Balance
          pan={theme.colors.secondary}
          beam={theme.colors.primary}
          chains={theme.colors.textDark}
          {...props}
        />
      );
    case "due":
      return (
        <Due
          sand={theme.colors.secondary}
          glass={theme.colors.primary}
          base={theme.colors.textDark}
          {...props}
        />
      );
    case "invoice":
      return (
        <Invoice
          text={theme.colors.secondary}
          paper={theme.colors.primary}
          signature={theme.colors.textDark}
          {...props}
        />
      );
    case "payment":
      return (
        <Payment
          field={theme.colors.secondary}
          edge={theme.colors.primary}
          text={theme.colors.textDark}
          {...props}
        />
      );
    case "make-payment":
      return (
        <MakePayment
          dollarCorners={theme.colors.textDark}
          dollarCircle={theme.colors.secondary}
          dollarBackground={theme.colors.primary}
          billBackground={theme.colors.greyBorderInput}
          billCorner={theme.colors.inactive}
          billUpperText={theme.colors.textDark}
          billLowerText={theme.colors.secondary}
          arrow={theme.colors.primary}
          {...props}
        />
      );
    case "name":
      return <Name body={theme.colors.secondary} head={theme.colors.textDark} {...props} />;
    case "address":
      return <Address circle={theme.colors.secondary} pin={theme.colors.textDark} {...props} />;
    case "mail":
      return <Mail envelope={theme.colors.secondary} line={theme.colors.textDark} {...props} />;
    case "home-phone":
      return <HomePhone handset={theme.colors.secondary} base={theme.colors.primary} {...props} />;
    case "business-phone":
      return (
        <BusinessPhone
          handset={theme.colors.secondary}
          base={theme.colors.primary}
          cable={theme.colors.textDark}
          {...props}
        />
      );
    case "mobile-phone":
      return <MobilePhone color={theme.colors.primary} {...props} />;
    case "phone-number":
      return (
        <PhoneNumber handset={theme.colors.secondary} cable={theme.colors.textDark} {...props} />
      );
    case "identity":
      return (
        <Identity
          photo={theme.colors.secondary}
          edge={theme.colors.primary}
          text={theme.colors.textDark}
          {...props}
        />
      );
    case "password":
      return <Password body={theme.colors.secondary} shackle={theme.colors.primary} {...props} />;
    case "check-mark":
      return <CheckMark color={theme.colors.primary} {...props} />;
    case "check-box":
      return <CheckBox color={theme.colors.note} {...props} />;
    case "security-question":
      return (
        <SecurityQuestion body={theme.colors.secondary} shackle={theme.colors.primary} {...props} />
      );
    case "invoice-shipment":
      return (
        <InvoiceShipment text={theme.colors.secondary} edge={theme.colors.primary} {...props} />
      );
    case "billing-cycle":
      return (
        <BillingCycle
          coin={theme.colors.secondary}
          arrows={theme.colors.primary}
          denomination={theme.colors.textDark}
          {...props}
        />
      );
    case "credit-card":
      return (
        <CreditCard
          stripe={theme.colors.secondary}
          edge={theme.colors.primary}
          brand={theme.colors.textDark}
          {...props}
        />
      );
    case "credit-card-expiration":
      return (
        <CreditCardExpiration
          icon={theme.colors.secondary}
          clock={theme.colors.textDark}
          {...props}
        />
      );
    case "automatic-payment-method":
      return (
        <AutomaticPaymentMethod
          field={theme.colors.secondary}
          edge={theme.colors.primary}
          text={theme.colors.textDark}
          {...props}
        />
      );
    case "automatic-payment-enabled":
      return (
        <AutomaticPaymentEnabled
          mark={theme.colors.secondary}
          circle={theme.colors.textDark}
          {...props}
        />
      );
    case "automatic-payment-disabled":
      return (
        <AutomaticPaymentDisabled
          mark={theme.colors.secondary}
          circle={theme.colors.textDark}
          {...props}
        />
      );
    case "bank-account-number":
      return (
        <BankAccountNumber
          brick={theme.colors.secondary}
          stone={theme.colors.textDark}
          hash={theme.colors.primary}
          {...props}
        />
      );
    case "bank-account-routing-number":
      return (
        <BankAccountRoutingNumber
          accent={theme.colors.secondary}
          text={theme.colors.textDark}
          edge={theme.colors.primary}
          {...props}
        />
      );
    case "bank-account-type":
      return (
        <BankAccountType brick={theme.colors.secondary} stone={theme.colors.textDark} {...props} />
      );
    case "service-agreement":
      return (
        <ServiceAgreement edge={theme.colors.primary} text={theme.colors.secondary} {...props} />
      );
    case "service-identifier":
      return (
        <ServiceIdentifier
          frame={theme.colors.primary}
          icon={theme.colors.secondary}
          handle={theme.colors.textDark}
          {...props}
        />
      );
    case "contact-address":
      return (
        <ContactAddress
          circle={theme.colors.primary}
          pin={theme.colors.textDark}
          icon={theme.colors.secondary}
          {...props}
        />
      );
    case "service-address":
      return (
        <ServiceAddress
          circle={theme.colors.primary}
          pin={theme.colors.textDark}
          icon={theme.colors.secondary}
          {...props}
        />
      );
    case "change-password":
      return <ChangePassword color={theme.colors.primary} {...props} />;
    case "question-mark":
      return <QuestionMark color={theme.colors.primary} {...props} />;
    case "logout":
      return <Logout color={theme.colors.primary} {...props} />;
    case "unlimited":
      return <Unlimited color={theme.colors.primary} {...props} />;
    case "show-password":
      return <ShowPassword color={theme.colors.primary} {...props} />;
    case "hide-password":
      return <HidePassword color={theme.colors.primary} {...props} />;
    case "user-active-status":
      return <UserActiveStatus {...props} />;
    case "user-suspended-status":
      return <UserSuspendedStatus {...props} />;
    case "user-closed-status":
      return <UserClosedStatus {...props} />;
    case "ma-billable":
      return (
        <MABillable
          accent={theme.colors.secondary}
          paper={theme.colors.primary}
          shadow={theme.colors.textDark}
          {...props}
        />
      );
    case "ma-non-billable":
      return (
        <MANonBillable
          accent={theme.colors.secondary}
          paper={theme.colors.primary}
          shadow={theme.colors.textDark}
          {...props}
        />
      );
    case "info":
      return <Info circle={theme.colors.textDark} letter={theme.colors.textDark} {...props} />;
    case "usage-details":
      return (
        <UsageDetails
          consumed={theme.colors.textDark}
          remaining={theme.colors.secondary}
          eye={theme.colors.primary}
          eyeCenter={theme.colors.inactive}
          {...props}
        />
      );
    case "view-postpaid-unbilled-summary":
      return (
        <ViewPostpaidUnbilledSummary
          eye={theme.colors.primary}
          background={theme.colors.greyBorderInput}
          billText={theme.colors.textDark}
          billClipper={theme.colors.secondary}
          {...props}
        />
      );
    case "open-trouble-ticket":
      return (
        <OpenTroubleTicket
          ticket={theme.colors.textDark}
          cogwheel={theme.colors.secondary}
          {...props}
        />
      );
    case "request-assistance":
      return (
        <RequestAssistance
          hands={theme.colors.primary}
          questionMark={theme.colors.secondary}
          globe={theme.colors.textDark}
          {...props}
        />
      );
    case "voice-over-fixed-wireless":
      return (
        <VoiceOverFixedWireless
          mediumBlue={theme.colors.primary}
          darkBlue={theme.colors.textDark}
          lightBlue={theme.colors.secondary}
          {...props}
        />
      );
    case "voice-over-other-media":
      return (
        <VoiceOverOtherMedia
          mediumBlue={theme.colors.primary}
          darkBlue={theme.colors.textDark}
          lightBlue={theme.colors.secondary}
          {...props}
        />
      );
    case "advanced-services":
      return (
        <AdvancedServices
          mediumBlue={theme.colors.primary}
          darkBlue={theme.colors.textDark}
          lightBlue={theme.colors.secondary}
          {...props}
        />
      );
    case "fixed-wireless-internet":
      return (
        <FixedWirelessInternet
          mediumBlue={theme.colors.primary}
          darkBlue={theme.colors.textDark}
          lightBlue={theme.colors.secondary}
          {...props}
        />
      );
    case "user-commercial":
      return (
        <UserCommercial
          grey={theme.colors.greyBorderInput}
          mediumBlue={theme.colors.primary}
          {...props}
        />
      );
    default:
      return null;
  }
};

const SelfcareIcon = styled(BaseIcon)(space, layout);
export { BaseIcon, SelfcareIcon };
