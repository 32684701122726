import React, { useContext } from "react";
import { useIntl } from "react-intl";
import SummaryLayout from "../../containers/SummaryLayout";
import SummaryRow from "../../components/SummaryRow/SummaryRow";
import { maskString } from "../../common/Utilities";
import EditAccountCPNIModal from "../../pages/AccountDetails/EditAccountCPNIModal";
import CpniDetailsSkeleton from "./CpniDetailsSkeleton";
import { BrandingContext } from "../../contexts/BrandingContext";

const CpniDetails = ({ cpniDetails, refreshCPNI, isLoading, ...props }) => {
  const intl = useIntl();
  const { config } = useContext(BrandingContext);
  const [isEditCPNI, setEditCPNI] = React.useState(false);

  if (isLoading || !cpniDetails) {
    return <SummaryLayout isLoading skeleton={CpniDetailsSkeleton} />;
  }

  const getQuestions = questions => {
    if (!questions || !questions.length) {
      return [];
    }

    return questions.map(q => {
      return (
        <SummaryRow
          key={q.id}
          tooltip={intl.formatMessage({ id: "lbl.security_question" })}
          icon="security-question"
          value={q.description + " " + maskString(q.answer)}
        />
      );
    });
  };

  return (
    <SummaryLayout
      title="lbl.cpni"
      showEdit
      onEdit={() => {
        setEditCPNI(true);
      }}
      {...props}>
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.phone_number" })}
        icon="phone-number"
        value={cpniDetails.phoneNumber}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.email" })}
        icon="mail"
        value={cpniDetails.email}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.password" })}
        icon="password"
        value={cpniDetails.password !== "" ? maskString(cpniDetails.password) : ""}
      />

      {getQuestions(cpniDetails.securityQuestions)}

      {config.receiveMarketingDetailsVisible && (
        <SummaryRow
          tooltip={intl.formatMessage({
            id: cpniDetails.receiveMarketingMaterials ? "tooltip.enabled" : "tooltip.disabled",
          })}
          icon={
            cpniDetails.receiveMarketingMaterials
              ? "automatic-payment-enabled"
              : "automatic-payment-disabled"
          }
          value={intl.formatMessage({ id: "lbl.receive_marketing_materials" })}
        />
      )}

      {isEditCPNI && (
        <EditAccountCPNIModal
          isEditCPNI={isEditCPNI}
          cpniDetails={cpniDetails}
          onEditCPNIClose={() => {
            setEditCPNI(false);
          }}
          onEditCPNISuccess={() => {
            setEditCPNI(false);
            refreshCPNI();
          }}
        />
      )}
    </SummaryLayout>
  );
};

export default CpniDetails;
