import React from "react";
import ContentLoader from "react-content-loader";

const SACardSkeleton = () => (
  <ContentLoader
    speed={2}
    viewBox="0 0 232 232"
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">
    <path
      d="M144.5,49.8H88c-1.4,0-2.5,1.1-2.5,2.5v56.5c0,1.4,1.1,2.5,2.5,2.5h56.5c1.4,0,2.5-1.1,2.5-2.5V52.3
		C147,51,145.9,49.8,144.5,49.8z M142,106.3H90.5V54.8H142V106.3z"
    />
    <path
      d="M97,100.3h38.7c0.2,0,0.4-0.2,0.4-0.4V76.6c0-0.3-0.4-0.5-0.6-0.3L124,84c-0.1,0.1-0.3,0.1-0.5,0l-15.8-11.8
		c-0.1-0.1-0.3-0.1-0.5,0L96.7,80c-0.1,0.1-0.2,0.2-0.2,0.3v19.6C96.6,100.1,96.7,100.3,97,100.3z"
    />
    <circle cx="127" cy="67.3" r="6.1" />
    <rect x="67.8" y="147.8" width="96.9" height="21.9" />
    <rect x="67.8" y="173.9" width="96.9" height="21.9" />
  </ContentLoader>
);

export default SACardSkeleton;
