import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { Box, Flex, Image, Text } from "rebass/styled-components";
import SubscriberIconDetails from "../SubscriberIconDetails";
import WelcomeSkeleton from "./WelcomeSkeleton";
import PopupMenu from "../PopupMenu/PopupMenu";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { useGET } from "../../hooks/restAPI";
import SubscriberAvatar from "../SubscriberAvatar";
import { BrandingContext } from "../../contexts/BrandingContext";
import StyledModalMessage from "../../components/StyledModalMessage";
import GetErrorDescription from "../../components/GetErrorDescription";
import { SelfcareButton } from "../../components/base";
import ERROR_CODES from "../../common/Constants";

const Header = props => {
  const history = useHistory();
  const intl = useIntl();
  const { logo } = useContext(BrandingContext);
  const { logout } = useContext(AuthContext);
  const { accountCode, masterAccount, setMasterAccount } = useContext(AuthContext);
  const [accountData, doGetAccount] = useGET(null);
  const [displayErrorModal, setDisplayErrorModal] = useState(false);

  useEffect(() => {
    if (masterAccount === null || masterAccount === undefined) {
      doGetAccount({
        route: `/account/${accountCode}`,
      });
    }
  }, [accountCode, doGetAccount, masterAccount]);
  const isLoading = masterAccount === null;

  useEffect(() => {
    if (accountData && accountData.isSuccess && !masterAccount) {
      setMasterAccount(accountData.data);
    }

    if (accountData && accountData.isError) {
      if (accountData.error.errorCode === ERROR_CODES.CLOSED_ACCOUNT) {
        setDisplayErrorModal(true);
      } else {
        history.push("/error");
      }
    }
  }, [accountData, masterAccount, history, setMasterAccount]);

  return (
    <Box
      {...props}
      bg="contentBg"
      sx={{
        position: "sticky",
        top: 0,
        boxShadow: "light",
        zIndex: 1,
      }}>
      <Flex
        width="100%"
        maxWidth="headerContentMaxWidth"
        mx="auto"
        px={["default", "default", "8.5rem"]}
        py="1.75rem"
        justifyContent="center"
        alignItems="center">
        <Image src={logo} width="10.375rem" height="3rem" />

        <Box
          display={["none", "flex", "flex"]}
          flex={1}
          mx="auto"
          maxWidth="layoutContentMaxWidth"
          px="huge"
          alignItems="center">
          {isLoading ? (
            <WelcomeSkeleton width="15rem" />
          ) : (
            <Text
              color="secondary"
              fontSize={[0, "primary", "title"]}
              fontWeight={5}
              textAlign="center">
              {masterAccount.firstName === ""
                ? intl.formatMessage({ id: "lbl.welcome" })
                : intl.formatMessage({ id: "lbl.welcome_user" }, { name: masterAccount.firstName })}
            </Text>
          )}
        </Box>

        <Box display={["none", "flex", "flex"]} alignItems="center">
          <SubscriberAvatar />
          <PopupMenu isLoading={isLoading}>
            <SubscriberIconDetails accountDetails={masterAccount} />
          </PopupMenu>
        </Box>
      </Flex>

      {displayErrorModal && (
        <StyledModalMessage
          isOpen={accountData.isError}
          message={<GetErrorDescription error={accountData.error} />}
          onRequestClose={() => logout(history)}
          type="error">
          <SelfcareButton variant="secondarySmall" onClick={() => logout(history)}>
            <FormattedMessage id="lbl.log_out" />
          </SelfcareButton>
        </StyledModalMessage>
      )}
    </Box>
  );
};

export default Header;
