import React from "react";
import { Text } from "rebass/styled-components";
import StyledModal from "../base/StyledModal";

const ModalPage = ({ children, isOpen, onRequestClose, ...props }) => {
  return (
    <StyledModal
      {...props}
      isOpen={isOpen}
      onEscapeKeydown={onRequestClose}
      maxHeight="95%"
      width={["95%", "40rem", "54.82rem"]}>
      <Text
        as="span"
        sx={{
          color: "primary",
          float: "right",
          fontSize: "title",
          lineHeight: "solid",
          fontWeight: "bold",
          marginTop: "tiny",
          marginRight: "small",
          ":hover": {
            cursor: "pointer",
            opacity: "70%",
          },
        }}
        onClick={onRequestClose}>
        &times;
      </Text>

      {children}
    </StyledModal>
  );
};

export default ModalPage;
