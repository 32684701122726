import React from "react";
import { useIntl } from "react-intl";
import { Flex, Text } from "rebass/styled-components";
import SummaryRow from "../../components/SummaryRow/SummaryRow";
import SummaryLayout from "../../containers/SummaryLayout";
import CreditCardDetailsSkeleton from "./CreditCardDetailsSkeleton";

const CreditCardDetails = ({ details, isLoading, setEditCard }) => {
  const intl = useIntl();

  function onEditCard() {
    setEditCard(true);
  }

  if (isLoading || !details) {
    return <SummaryLayout isLoading skeleton={CreditCardDetailsSkeleton} />;
  }

  if (
    details.cardNumber === null ||
    details.cardNumber === undefined ||
    details.cardNumber === ""
  ) {
    return (
      <SummaryLayout title="lbl.credit_card_information" showAdd onAdd={onEditCard}>
        <Flex my="small" minHeight="2rem" alignItems="center">
          <Text textAlign="left" color="note" fontSize="secondary">
            {intl.formatMessage({ id: "lbl.no_credit_card" })}
          </Text>
        </Flex>
      </SummaryLayout>
    );
  }

  return (
    <SummaryLayout title="lbl.credit_card_information" showEdit onEdit={onEditCard}>
      <SummaryRow
        tooltip={intl.formatMessage({ id: "tooltip.cardholder_name" })}
        icon="name"
        value={details.cardHolderName}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "tooltip.card_type_and_number" })}
        icon="credit-card"
        value={details.cardType + " " + details.cardNumber}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "tooltip.expiration_date" })}
        icon="credit-card-expiration"
        value={details.cardExpirationMonth + "/" + details.cardExpirationYear}
      />
    </SummaryLayout>
  );
};

export default CreditCardDetails;
