import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Flex, Heading, Link, Text } from "rebass/styled-components";
import { SelfcareButton } from "../../components/base";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { BrandingContext } from "../../contexts/BrandingContext";
import SelfcareCheckbox from "../../components/base/SelfcareCheckbox";
import { usePUT } from "../../hooks/restAPI";
import StyledModalMessage from "../../components/StyledModalMessage";
import GetErrorDescription from "../../components/GetErrorDescription";
import Layout from "../../containers/Layout";

const InternetTermsAndConditions = props => {
  const history = useHistory();
  const { config } = useContext(BrandingContext);
  const { accountCode, logout } = useContext(AuthContext);
  const [agree, setAgree] = useState(false);
  const [acceptedTCData, doPutAcceptedTC, resetPutAcceptedTC] = usePUT({});

  const saveTermsAndConditionsAccepted = () => {
    doPutAcceptedTC({
      route: `/account/${accountCode}/termsAndConditionsAccounts`,
      expectedResponse: "none",
      body: { accounts: props.location.state.internetSasWithoutTC },
    });
  };

  useEffect(() => {
    const doAfterSuccessfulSave = () => {
      if (acceptedTCData.isSuccess) {
        history.goBack();
      }
    };

    doAfterSuccessfulSave();
  }, [acceptedTCData.isSuccess, history]);

  return (
    <Layout displayOnlyBurgerMenu={true} disableNavMenuDefaultLocation={true}>
      <Flex justifyContent="center" alignItems="center" height="100%" my="large">
        <Flex
          bg="contentBg"
          px="large"
          py="large"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{
            boxShadow: "light",
            borderRadius: "card",
          }}>
          <Heading
            my="default"
            fontWeight={5}
            fontSize="title"
            lineHeight={1}
            color="primary"
            textAlign="center">
            <FormattedMessage id="lbl.important" />
          </Heading>
          <Flex flexDirection="row">
            <Text
              color="primary"
              fontSize="primary"
              lineHeight={1}
              fontWeight={3}
              mb="default"
              textAlign="center">
              <FormattedMessage id="lbl.you_are_seeing_this_because_internet" />
            </Text>
          </Flex>
          <Flex flexDirection="row">
            <SelfcareCheckbox
              checked={agree}
              onClick={() => {
                setAgree(!agree);
              }}
              onChange={() => {}}
              mr={["", "", "tiny"]}
            />
            <Text
              color="primary"
              fontSize="primary"
              lineHeight={1}
              fontWeight={3}
              mr="tiny"
              textAlign="center">
              <FormattedMessage id="lbl.i_agree_to_the" />
              <Link
                target="_blank"
                href={config.terms_and_conditions}
                color="secondary"
                lineHeight={1}
                fontWeight={3}
                fontSize="primary"
                sx={{ textDecoration: "none" }}>
                <FormattedMessage id="lbl.terms_and_conditions_for_internet" />
              </Link>
            </Text>
          </Flex>

          <Flex mt="default" justifyContent="space-around" flexWrap="wrap">
            <SelfcareButton
              variant="secondarySmall"
              disabled={!agree || acceptedTCData.isLoading}
              onClick={() => {
                saveTermsAndConditionsAccepted();
              }}
              mr="spaceBetweenButtons"
              mb="spaceBetweenButtons">
              <FormattedMessage id="lbl.continue" />
            </SelfcareButton>

            <SelfcareButton
              variant="secondarySmall"
              mr="spaceBetweenButtons"
              mb="spaceBetweenButtons"
              onClick={() => logout(history)}>
              <FormattedMessage id="lbl.log_out" />
            </SelfcareButton>
          </Flex>
        </Flex>
      </Flex>

      {acceptedTCData.isError && (
        <StyledModalMessage
          isOpen={acceptedTCData.isError}
          message={<GetErrorDescription error={acceptedTCData.error} />}
          onRequestClose={resetPutAcceptedTC}
          type="error">
          <SelfcareButton variant="secondarySmall" onClick={resetPutAcceptedTC}>
            <FormattedMessage id="lbl.Try_Again" />
          </SelfcareButton>
        </StyledModalMessage>
      )}
    </Layout>
  );
};

export default InternetTermsAndConditions;
