import React from "react";
import { Box, Text } from "rebass/styled-components";
import { SelfcareBox } from "../../components/base";
import { SelfcareIcon } from "../icons";
import { FormattedMessage } from "react-intl";

const ViewUsageDetailsButton = ({ onClick, ...props }) => {
  return (
    <Box {...props}>
      <SelfcareBox
        display="flex"
        height="4.875rem"
        width={["100%", "100%", "21.125rem"]}
        my="default"
        variant="card"
        alignItems="center"
        justifyContent="center"
        sx={{
          cursor: "pointer",
          "&:hover": {
            opacity: "70%",
          },
        }}
        onClick={onClick}>
        <Box ml="1rem" mr="2rem" display={["none", "none", "inline"]}>
          <SelfcareIcon name="usage-details" />
        </Box>
        <Text color="primary" textAlign="center" justifyContent="center" fontSize="primary">
          <FormattedMessage id="lbl.view_usage_details" />
        </Text>
      </SelfcareBox>
    </Box>
  );
};

export default ViewUsageDetailsButton;
