import React, { useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Text } from "rebass/styled-components";
import { SelfcareBox } from "../../components/base";
import { SelfcareIcon } from "../icons";
import OpenTroubleTicket from "../OpenTroubleTicket";
import { BrandingContext } from "../../contexts/BrandingContext";

const RequestAssistance = () => {
  const intl = useIntl();
  const [isOpenModal, setOpenModal] = useState(false);
  const { config } = useContext(BrandingContext);

  return (
    <>
      <Box mr={["0rem", "0rem", "2rem"]}>
        <Box>
          <SelfcareBox
            display="flex"
            height="4.875rem"
            width={["100%", "100%", "21.125rem"]}
            my="default"
            variant="card"
            alignItems="center"
            justifyContent="center"
            sx={{
              cursor: "pointer",
              "&:hover": {
                opacity: "70%",
              },
            }}
            onClick={() => {
              setOpenModal(true);
            }}>
            <Box mr={["", "", "2rem"]} display={["none", "none", "inline"]}>
              <SelfcareIcon name="request-assistance" />
            </Box>
            <Text color="primary" textAlign="center" justifyContent="center" fontSize="primary">
              <FormattedMessage id="lbl.request_assistance" />
            </Text>
          </SelfcareBox>
        </Box>

        <OpenTroubleTicket
          config={config.troubleTicket.assistance}
          title={<FormattedMessage id="lbl.request_assistance" />}
          placeholderMessage={intl.formatMessage({
            id: "lbl.trouble_ticket_assist_comments_placeholder",
          })}
          isOpenModal={isOpenModal}
          setOpenModal={setOpenModal}
        />
      </Box>
    </>
  );
};

export default RequestAssistance;
