import React from "react";

import MobileFooter from "./MobileFooter";
import DesktopFooter from "./DesktopFooter";
import { SelfcareDesktopAndTabletWrapper, SelfcareMobileWrapper } from "../base";

const Footer = () => {
  return (
    <>
      <SelfcareDesktopAndTabletWrapper marginTop="auto">
        <DesktopFooter />
      </SelfcareDesktopAndTabletWrapper>

      <SelfcareMobileWrapper marginTop="auto">
        <MobileFooter />
      </SelfcareMobileWrapper>
    </>
  );
};

export default Footer;
