import React from "react";
import { useIntl } from "react-intl";

import { Circle, Stroke } from "../Lines";
import { FIRST_TIMELINE_EVENT_SIZE, TIMELINE_EVENT_SIZE } from "../Common";
import { SelfcareAmount } from "../../base";
import { BaseIcon } from "../../icons";

const TimelineBalanceMobile = ({
  strokeColor,
  circleColor,
  textColor,
  amountColor,
  isFirstInTimeline,
  balance,
  ...props
}) => {
  const { transform, svgProps } = props;
  const strokeSize = isFirstInTimeline ? "small" : "large";
  const titleTranslateY = isFirstInTimeline ? "67%" : "80%";
  const amountTranslateY = isFirstInTimeline ? "87%" : "92%";
  const dateTranslateY = isFirstInTimeline ? "75%" : "85%";
  const iconTranslateY = isFirstInTimeline ? 66 : 141;
  const circleTranslateY = isFirstInTimeline ? 46.3 : 121.2;
  const svgSize = isFirstInTimeline ? FIRST_TIMELINE_EVENT_SIZE : TIMELINE_EVENT_SIZE;

  const intl = useIntl();

  return (
    <g transform={transform}>
      <svg
        {...svgProps}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 400 ${svgSize}`}
        width="400px"
        height={`${svgSize}px`}
        overflow="visible">
        <g>
          <text x="300" y={dateTranslateY} textAnchor="start" fill={textColor}>
            <tspan fontSize="16px">{intl.formatDate(new Date())}</tspan>
          </text>

          <Stroke color={strokeColor} size={strokeSize} transform="translate(292 0) rotate(90)" />
          <Circle color={circleColor} transform={`translate(168.5 ${circleTranslateY})`} />
          <g transform={`translate(184.5 ${iconTranslateY})`}>
            <BaseIcon name="balance" />
          </g>

          <text x="155" y={titleTranslateY} textAnchor="end" fill={textColor}>
            <tspan fontSize="16px">{intl.formatMessage({ id: "lbl.balance" })}</tspan>
          </text>
          <text x="155" y={amountTranslateY} textAnchor="end" fill={amountColor}>
            <tspan fontSize="20px">
              <SelfcareAmount amount={balance !== 0 ? balance * -1 : balance} />
            </tspan>
          </text>
        </g>
      </svg>
    </g>
  );
};

export default TimelineBalanceMobile;
