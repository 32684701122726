import React from "react";
import { useIntl } from "react-intl";
import { Text, Flex, Box } from "rebass/styled-components";
import Tooltip from "../Tooltip";
import { SelfcareIcon } from "../icons";
import { useHistory } from "react-router-dom";
import { capitalize } from "../../common/Utilities";
import { ACCOUNT_TYPES, WIRELINE_TECHNOLOGIES } from "../../common/Constants";

const SACard = ({ account }) => {
  const intl = useIntl();
  const history = useHistory();

  function _mapAccountToIcon(type, technologyCode, isMA, isBillable) {
    if (isMA) {
      return isBillable ? "ma-billable" : "ma-non-billable";
    }

    if (Object.values(WIRELINE_TECHNOLOGIES).includes(technologyCode)) {
      switch (technologyCode) {
        case WIRELINE_TECHNOLOGIES.FIXEDINTERNET:
          return "fixed-wireless-internet";
        case WIRELINE_TECHNOLOGIES.DSL:
          return "dsl-internet";
        case WIRELINE_TECHNOLOGIES.VOFW:
          return "voice-over-fixed-wireless";
        case WIRELINE_TECHNOLOGIES.VOOM:
          return "voice-over-other-media";
        case WIRELINE_TECHNOLOGIES.INTERNET:
          return "fiber-internet";
        case WIRELINE_TECHNOLOGIES.VOBB:
          return "voice-over-fiber";
        default:
          return "wireline";
      }
    }

    if (technologyCode === "ADVSERVICESW") {
      return "advanced-services";
    }

    return type === ACCOUNT_TYPES.POSTPAID ? "mobile" : "prepaid";
  }

  function _mapAccountStatusToIcon(status) {
    if (status === "ACTIVE") {
      return "active";
    } else if (status === "SUSPENDED") {
      return "paused";
    } else {
      return "disabled";
    }
  }

  return (
    <Box
      bg="contentBg"
      sx={{
        boxShadow: "light",
        borderRadius: "card",
        cursor: "pointer",
        "&:hover": {
          opacity: "90%",
        },
      }}
      onClick={e => {
        history.push(`/account/${account.accountCode}/overview`, {
          account: account,
        });
      }}>
      <Flex p="default" flexDirection="column" height="100%" justifyContent="space-between">
        <Box width="100%" textAlign="center">
          <SelfcareIcon
            name={_mapAccountToIcon(
              account.accountType,
              account.technologyCode,
              account.masterAccount,
              account.billable
            )}
            mb="default"
          />
        </Box>
        <Text
          color="primary"
          textAlign="center"
          py="small"
          fontSize="primary"
          sx={{ overflowWrap: "break-word" }}>
          {account.accountName}
        </Text>
        <Flex alignItems="center" justifyContent="center">
          <Text color="primary" textAlign="center" fontSize="secondary" fontWeight={3}>
            {account.phoneNumber !== "" ? account.phoneNumber : account.accountCode}
          </Text>
          <Tooltip
            value={intl.formatMessage(
              { id: "lbl.status" },
              { status: account.accountStatus && capitalize(account.accountStatus) }
            )}
            position="top">
            <SelfcareIcon name={_mapAccountStatusToIcon(account.accountStatus)} ml="small" />
          </Tooltip>
        </Flex>
      </Flex>
    </Box>
  );
};

export default SACard;
