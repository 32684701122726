import React, { useRef, useState } from "react";
import { Flex, Text } from "rebass/styled-components";
import * as ServiceUtils from "./ServiceUtils";
import ServiceDetails from "./ServiceDetails";
import { FormattedMessage } from "react-intl";
import Tooltip from "../Tooltip";
import { useOverflow } from "../../hooks/useOverflow";

const RegularElement = ({
  description,
  activationDate = "",
  expirationDate = "",
  serviceDetails = null,
}) => {
  const [showServiceDetails, setShowServiceDetails] = useState(false);
  const valueRef = useRef();
  const [isOverflow] = useOverflow(valueRef);
  let isDetailsVisible =
    serviceDetails &&
    (ServiceUtils.isMediationVisible(serviceDetails) ||
      ServiceUtils.isAnyUdfSet(serviceDetails.udfs));
  return (
    <Flex marginY="small" flexDirection="column" width="100%">
      <Flex flexDirection="row" width="100%">
        <Flex flex={1} flexDirection="row">
          {!isOverflow && (
            <Text
              ref={valueRef}
              textAlign="left"
              color="textDark"
              fontSize="secondary"
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}>
              {description}
            </Text>
          )}
          {isOverflow && (
            <Tooltip position="top-noflex" value={description}>
              <Text
                ref={valueRef}
                textAlign="left"
                color="textDark"
                fontSize="secondary"
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}>
                {description}
              </Text>
            </Tooltip>
          )}
          {isDetailsVisible && (
            <Text
              color="textDark"
              ml="small"
              fontSize="secondary"
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                setShowServiceDetails(!showServiceDetails);
              }}>
              <FormattedMessage id={"lbl.details"} />
            </Text>
          )}
        </Flex>

        {activationDate && expirationDate && (
          <Text
            alignSelf="flex-end"
            width={isDetailsVisible ? "15rem" : "13rem"}
            textAlign="right"
            color="textDark"
            fontSize="secondary">
            {activationDate + " - " + expirationDate}
          </Text>
        )}
      </Flex>

      {showServiceDetails && <ServiceDetails serviceDetails={serviceDetails} />}
    </Flex>
  );
};

export default RegularElement;
