import React from "react";
import { Box, Flex, Text } from "rebass/styled-components";

const AddButton = ({ ...props }) => {
  return (
    <Flex
      flexDirection="row"
      width="3.5rem"
      height="1.5rem"
      {...props}
      sx={{
        backgroundColor: "secondary",
        borderRadius: "circle",
        ":hover": {
          cursor: "pointer",
        },
      }}>
      <Box flex={1} alignItems="flex-end" justifyContent="center" textAlign="center">
        <Text
          as="span"
          sx={{
            position: "relative",
            top: "-0.125rem",
            color: "windowBg",
            fontSize: "1.75rem",
            lineHeight: "solid",
            userSelect: "none",
            fontWeight: "bold",
          }}>
          +
        </Text>
      </Box>
    </Flex>
  );
};
export default AddButton;
