import React, { useEffect, useState } from "react";
import { Flex, Text } from "rebass/styled-components";
import { useIntl } from "react-intl";
import _ from "lodash";
import SelfcareSwitch from "../../components/base/SelfcareSwitch";

const FeatureGroup = ({
  serviceId,
  featureGroupCode,
  featureGroupDescription,
  _featureGroupItems,
  _onFeaturesUpdate,
}) => {
  const intl = useIntl();

  const [state, setState] = useState({
    featureGroupItems: [..._featureGroupItems],
  });

  useEffect(() => {
    function onPropsUpdate() {
      if (!_.isEqual(state.featureGroupItems, _featureGroupItems)) {
        setState({ featureGroupItems: _featureGroupItems });
      }
    }

    onPropsUpdate();
  }, [_featureGroupItems, state.featureGroupItems, intl]);

  const onFeatureUpdate = (index, isMutuallyExclusive, mandatoryToSelectOne) => {
    let featGrpItems = state.featureGroupItems;

    if (isMutuallyExclusive) {
      if (mandatoryToSelectOne) {
        _.each(featGrpItems, (feature, localIndex) => {
          feature.selected = index === localIndex;
        });
      } else {
        _.each(featGrpItems, (feature, localIndex) => {
          if (index === localIndex) {
            feature.selected = !feature.selected;
          } else {
            feature.selected = false;
          }
        });
      }
    } else {
      featGrpItems[index].selected = !featGrpItems[index].selected;
    }
    if (_onFeaturesUpdate) {
      _onFeaturesUpdate(featGrpItems);
    }
    setState({ featureGroupItems: featGrpItems });
  };

  return (
    <Flex key={featureGroupCode} flexDirection="column">
      {/*      {state.featureGroupItems && <pre>{JSON.stringify(state.featureGroupItems, null, 1)}</pre>}*/}
      <Text
        textAlign="left"
        color="textDark"
        fontSize="secondary"
        marginTop="small"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}>
        {featureGroupDescription}
      </Text>

      <Flex marginY="small" flexDirection="column" width="100%">
        {state.featureGroupItems.map((feature, index) => (
          <Flex key={feature.code + index} marginBottom="small" flexDirection="row" width="100%">
            <Text
              textAlign="left"
              color="textDark"
              fontSize="secondary"
              marginLeft="default"
              flex={1}
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}>
              {feature.description}
            </Text>

            <SelfcareSwitch
              alignSelf="flex-end"
              id={"id" + serviceId + feature.code + index}
              name={"name" + +serviceId + feature.code + index}
              checked={feature.idStatusMap[_.keys(feature.idStatusMap)[0]] && feature.selected}
              onClick={() =>
                onFeatureUpdate(index, feature.mutualExclusive, feature.mandatoryToSelectOne)
              }
              ml="auto"
            />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default FeatureGroup;
