import React, { useContext, useEffect, useState } from "react";
import ModalPage from "../ModalPage";
import GetErrorDescription from "../GetErrorDescription";
import StyledModalMessage from "../StyledModalMessage";
import { SelfcareButton, SelfcareCheckbox, SelfcareRadio } from "../base";
import { Box, Heading, Text } from "rebass/styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { Label } from "@rebass/forms/styled-components";
import { useGET } from "../../hooks/restAPI";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { DateRangeInput } from "@datepicker-react/styled";
import { isEmpty } from "../../common/Utilities";
import { BrandingContext } from "../../contexts/BrandingContext";
import LoadDocumentModal from "../../components/LoadDocumentModal";

const UsageDetailsModal = ({
  displayUsageDetailsModal,
  onModalClose,
  setUsageDetailsModalVisibility,
}) => {
  const { config } = useContext(BrandingContext);
  const intl = useIntl();
  const [usageDetailsData, doGetUsageDetails, resetGetError] = useGET({});

  const { currentAccountDetails, masterAccount } = useContext(AuthContext);
  const account = currentAccountDetails ? currentAccountDetails : masterAccount;

  const { accountCode: accountCodePath } = useParams();
  const accountCode = accountCodePath ? accountCodePath : masterAccount.accountCode;

  const [usageDisplayOption, setUsageDisplayOption] = useState("NEW_USAGE_ONLY");
  const [newUsageOnly, setNewUsageOnly] = useState(true);
  const [includeNBCalls, setIncludeNBCalls] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [dateFieldsError, setDateFieldsError] = useState(false);
  const [documentUrl, setDocumentUrl] = useState(null);

  useEffect(() => {
    if (usageDisplayOption === "DATE_RANGE") {
      document.getElementById("startDate").setAttribute("readonly", "readonly");
      document.getElementById("endDate").setAttribute("readonly", "readonly");
    }
  }, [focusedInput, fromDate, usageDisplayOption]);

  useEffect(() => {
    if (usageDetailsData.isSuccess) {
      let fileURL = URL.createObjectURL(usageDetailsData.data);
      setDocumentUrl(fileURL);
      setUsageDetailsModalVisibility(false);
      reset();
    }
  }, [setUsageDetailsModalVisibility, usageDetailsData]);

  const getUsageDetails = () => {
    if (!newUsageOnly && (fromDate === null || toDate === null)) {
      setDateFieldsError(true);
    } else {
      let route = `/account/${accountCode}/usageDetails?`;

      if (newUsageOnly) {
        route += "newUsageOnly=true";
      } else {
        const updatedFromDate = new Date(
          fromDate.getTime() - fromDate.getTimezoneOffset() * 60000
        ).toISOString();
        const updatedToDate = new Date(
          toDate.getTime() - toDate.getTimezoneOffset() * 60000
        ).toISOString();
        route += `from=${updatedFromDate}&to=${updatedToDate}`;
      }

      if (includeNBCalls) {
        route += "&includeNBCalls=true";
      }

      const getUsageDetailsConfig = {
        route: route,
        noTimeout: true,
        expectedResponse: "blob",
      };
      doGetUsageDetails(getUsageDetailsConfig);
    }
  };

  const reset = () => {
    setUsageDisplayOption("NEW_USAGE_ONLY");
    setNewUsageOnly(true);
    setIncludeNBCalls(false);
    setFromDate(null);
    setToDate(null);
    setDateFieldsError(false);
    setFocusedInput(null);
  };

  return (
    <>
      <ModalPage
        isOpen={displayUsageDetailsModal}
        onRequestClose={() => {
          onModalClose();
          reset();
        }}>
        <Box px={["medium", "large", "huge"]} py="huge">
          <Heading
            as="h3"
            fontWeight={5}
            mb="large"
            fontSize="title"
            color="textDark"
            textAlign="left">
            {intl.formatMessage(
              { id: "lbl.usage_details" },
              {
                phoneNumber: isEmpty(account.phoneNumber)
                  ? account.accountCode
                  : account.phoneNumber,
              }
            )}
          </Heading>
          <Label mb="default">
            <SelfcareRadio
              name="usageDisplay"
              id="newUsageOnly"
              value="NEW_USAGE_ONLY"
              onChange={event => {
                setNewUsageOnly(true);
                setUsageDisplayOption("NEW_USAGE_ONLY");
              }}
              checked={usageDisplayOption === "NEW_USAGE_ONLY"}
            />
            <Text color="textDark" fontSize="secondary" ml="small">
              <FormattedMessage id="lbl.usage_display.new_usage_only" />
            </Text>
          </Label>
          <Label mb="default">
            <SelfcareRadio
              name="usageDisplay"
              id="dateRange"
              value="DATE_RANGE"
              onChange={event => {
                setNewUsageOnly(false);
                setUsageDisplayOption("DATE_RANGE");
                setDateFieldsError(false);
              }}
              checked={usageDisplayOption === "DATE_RANGE"}
            />
            <Text color="textDark" fontSize="secondary" ml="small">
              <FormattedMessage id="lbl.usage_display.date_range" />
            </Text>
          </Label>
          {usageDisplayOption === "DATE_RANGE" && (
            <Box mx={["small", "large", "large"]} mb="default">
              <DateRangeInput
                onDatesChange={data => {
                  setFromDate(data.startDate);
                  setToDate(data.endDate);
                  setFocusedInput(data.focusedInput);
                  setDateFieldsError(false);
                }}
                onFocusChange={focusedInput => {
                  setFocusedInput(focusedInput);
                }}
                startDate={fromDate}
                endDate={toDate}
                focusedInput={focusedInput}
                showResetDates={false}
                showSelectedDates={false}
                showClose={false}
                minBookingDate={new Date(account.registrationDate)}
                maxBookingDate={new Date()}
                numberOfMonths={1}
                firstDayOfWeek={0}
              />
              {dateFieldsError && (
                <Text color="textError" mt="tiny">
                  <FormattedMessage id="err.usage_details.date_fields_mandatory" />
                </Text>
              )}
            </Box>
          )}
          {config.includeNBCallsVisible && (
            <Label>
              <SelfcareCheckbox
                onChange={event => setIncludeNBCalls(!includeNBCalls)}
                checked={includeNBCalls}
              />
              <Text color="textDark" fontSize="secondary" ml="small">
                <FormattedMessage id="lbl.usage_details.include_non-billable_calls" />
              </Text>
            </Label>
          )}

          {focusedInput && <Box height="17rem"></Box>}

          <Box width="auto" mt="larger" textAlign="right">
            <SelfcareButton
              type="button"
              variant="default"
              mr="spaceBetweenButtons"
              onClick={getUsageDetails}>
              <FormattedMessage id="lbl.search" />
            </SelfcareButton>
            <SelfcareButton type="button" variant="default-inverted" onClick={() => reset()}>
              <FormattedMessage id="lbl.reset" />
            </SelfcareButton>
          </Box>
        </Box>
      </ModalPage>

      <LoadDocumentModal
        isOpen={usageDetailsData.isLoading || usageDetailsData.isSuccess}
        onClose={() => {
          setDocumentUrl(null);
          resetGetError();
        }}
        url={documentUrl}
      />

      {usageDetailsData.isError && (
        <StyledModalMessage
          isOpen={usageDetailsData.isError}
          message={<GetErrorDescription error={usageDetailsData.error} />}
          onRequestClose={resetGetError}
          type="error">
          <SelfcareButton variant="secondarySmall" onClick={resetGetError}>
            <FormattedMessage id="lbl.Try_Again" />
          </SelfcareButton>
        </StyledModalMessage>
      )}
    </>
  );
};

export default UsageDetailsModal;
