export const baseTheme = {
  breakpoints: ["40em", "64.1em", "80em"],

  fontWeights: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900],

  borders: [0, "1px solid", "2px solid", "4px solid", "8px solid", "16px solid", "32px solid"],

  space: {
    tiny: "0.25rem", // 4px
    small: "0.5rem", // 8px
    default: "1rem", // 16px
    medium: "1.25rem", // 20px
    largeish: "1.875rem", //30px
    large: "2rem", // 32px
    larger: "2.5rem", // 40px
    huge: "4rem", // 64px
    mobileNavigationMenuOffset: "4.375rem", // 70px
    tooltipLeft: "-5rem",
    tooltipTop: "-2.25rem",
    spaceBetweenButtons: "0.625rem", // 10px
    mobileModalExtraOffset: "6.5rem", //
  },
  sizes: {
    inputHeightSmall: "2rem", // 32px
    inputHeightMedium: "2.1875rem", //35px
    inputHeightDefault: "2.5rem", //40px
    headerContentMaxWidth: "120rem", // 1920px
    footerContentMaxWidth: "120rem", // 1920px
    layoutContentMaxWidth: "65rem", // 1040px
    mobileNavigationMenuHeight: "4.375rem", // 70px
  },
  fontSizes: {
    primary: "1.25rem", // 20px
    secondary: "1.125rem", // 18px
    default: "1rem", // 16px
    note: "0.875rem", // 14px
    subnote: "0.625rem", // 10px
    title: "1.875rem", // 30px
    subTitle: "1.625rem", // 26px
    amountTitle: "1.625rem", // 26px
    presentationTitle: "3.75rem", // 60px
  },
  lineHeights: {
    solid: 1,
    title: 1.25,
  },
  fonts: {
    heading: "roboto, sans-serif",
  },
  radii: {
    default: 0,
    card: 10,
    circle: 99999,
    spinner: 12,
  },
  shadows: {
    light: "rgba(161, 161, 161, 0.16) 0px 5px 6px",
    dark: "rgba(2, 37, 117, 0.28) 0px 5px 6px",
    tooltip: "rgba(2, 37, 117, 0.28) 0px 0px 6px",
  },
  colors: {
    windowBg: "#F8F8F8",
    contentBg: "#FFFFFF",
    primary: "#336EB4",
    secondary: "#318EFF",
    textDark: "#114878",
    textLight: "#FFFFFF",
    note: "#B5B5B5",
    inactive: "#DCDCDC",
    textError: "#D92160",
    unpaidSum: "#FC508B",
    greyBorderInput: "#E6E6E6",
    inputOutlineError: "#DF4D7F",
  },

  forms: {
    text: {
      ":disabled": {
        color: "note",
      },
    },
    input: {
      height: "inputHeightDefault",
      borderColor: "textDark",
      padding: "small",
      color: "primary",
      fontSize: "primary",
      fontFamily: "roboto, sans-serif",
      fontWeight: "3",
      ":focus": { outline: "2px solid", outlineColor: "textDark", border: 0 },
      ":disabled": {
        backgroundColor: "inactive",
        borderColor: "inactive",
      },
      "::-ms-clear": {
        display: "none",
      },
      "::-ms-reveal": {
        display: "none",
      },
    },
    inputError: {
      height: "inputHeightDefault",
      padding: "small",
      background: "#F9EFF3",
      color: "inputOutlineError",
      fontSize: "primary",
      fontFamily: "roboto, sans-serif",
      fontWeight: "3",
      border: "1px solid",
      borderColor: "inputOutlineError",
      ":focus": { outline: "2px solid", outlineColor: "inputOutlineError", border: 0 },
      "::-ms-clear": {
        display: "none",
      },
      "::-ms-reveal": {
        display: "none",
      },
    },
    greyInput: {
      height: "inputHeightSmall",
      borderColor: "greyBorderInput",
      paddingX: "small",
      color: "secondary",
      fontSize: "secondary", // secondary, primary
      fontFamily: "roboto, sans-serif",
      paddingLeft: "small",
      flex: 1,
      ":focus": { outline: "2px solid", outlineColor: "secondary", border: 0 },
      ":disabled": {
        backgroundColor: "windowBg",
        borderColor: "windowBg",
        opacity: "1",
      },
      "::-ms-clear": {
        display: "none",
      },
      "::-ms-reveal": {
        display: "none",
      },
    },
    greyInputError: {
      height: "inputHeightSmall",
      background: "#FBECF2",
      paddingX: "small",
      color: "inputOutlineError",
      fontFamily: "roboto, sans-serif",
      fontSize: "secondary",
      border: "1px solid",
      borderColor: "inputOutlineError",
      paddingLeft: "small",
      flex: 1,
      ":focus": { outline: "2px solid", outlineColor: "inputOutlineError", border: 0 },
      "::-ms-clear": {
        display: "none",
      },
      "::-ms-reveal": {
        display: "none",
      },
    },
    select: {
      height: "inputHeightSmall",
      borderColor: "textDark",
      paddingLeft: "small",
      paddingRight: "large",
      marginRight: "tiny",
      color: "secondary",
      fontFamily: "roboto, sans-serif",
      fontSize: "secondary",
      fontWeight: 4,
      flex: 1,
      ":disabled": {
        backgroundColor: "windowBg",
        borderColor: "windowBg",
      },
      "::-ms-clear": {
        display: "none",
      },
      "::-ms-reveal": {
        display: "none",
      },
    },
    selectError: {
      height: "inputHeightSmall",
      paddingLeft: "small",
      paddingRight: "large",
      marginRight: "tiny",
      background: "#F9EFF3",
      color: "inputOutlineError",
      fontFamily: "roboto, sans-serif",
      fontSize: "secondary",
      fontWeight: 4,
      border: "1px solid",
      borderColor: "inputOutlineError",
      flex: 1,
      ":focus": { outline: "2px solid", outlineColor: "inputOutlineError", border: 0 },
      "::-ms-clear": {
        display: "none",
      },
      "::-ms-reveal": {
        display: "none",
      },
    },
  },

  variants: {
    link: {
      textDecoration: "none",
      ":hover": {
        textDecoration: "underline",
      },
    },
  },
  reactDatepicker: {
    daySize: [28, 30, 32],
    fontFamily: "roboto, sans-serif",
    colors: {
      accessibility: "#336EB4",
      selectedDay: "#318EFF",
      selectedDayHover: "#318EFF",
      primaryColor: "#336EB4",
    },
    monthLabelColor: "primary",
    inputColor: "primary",
    inputFontSize: "default",
    dateRangeGridTemplateColumns: ["10rem 2rem 10rem", "15rem 3rem 15rem", "15rem 3rem 15rem"],
    dateRangeStartDateInputPadding: "0 1rem 0 3rem",
    dateRangeEndDateInputPadding: "0 1rem 0 3rem",
    inputCalendarWrapperWidth: "100%",
  },
};
