import React, { useContext, useEffect, useState } from "react";
import { Input, Select } from "@rebass/forms/styled-components";
import { Box, Heading, Text } from "rebass/styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller, useForm } from "react-hook-form";

import ModalPage from "../../components/ModalPage";
import StyledModalMessage from "../../components/StyledModalMessage";
import GetErrorDescription from "../../components/GetErrorDescription";
import ShowPassword from "../../components/ShowPassword";
import { FormErrorMessage } from "../../components/FormErrorMessage";
import { SelfcareButton } from "../../components/base";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { useGET, usePUT } from "../../hooks/restAPI";
import { useParams } from "react-router-dom";
import { isEmpty } from "../../common/Utilities";

const EditBankAccountDetailsModal = ({ details, isOpen, onClose, onEditSuccess }) => {
  const intl = useIntl();
  const { accountCode: masterAccountCode } = useContext(AuthContext);
  const { accountCode: accountCodePath } = useParams();

  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;

  const { register, handleSubmit, errors, control, clearError } = useForm({ mode: "onChange" });

  const [doPutBankDetailsData, doPutBankDetails, resetPutBankDetailsError] = usePUT({});
  const [
    allowOverrideBankNumberOnAccountLevelData,
    doGetAllowOverrideBankNumberOnAccountLevel,
  ] = useGET(false);
  const [bankAccountHolderNameMandatoryData, doGetBankAccountHolderNameMandatory] = useGET(true);
  const [bankAccountTypeMandatoryData, doGetBankAccountTypeMandatory] = useGET(true);
  const [bankAccountTypesData, doGetBankAccountTypes] = useGET({});

  const [bankAccountType, setBankAccountType] = useState(
    details.bankAccountDetails.bankAccountType
  );

  /**
   * After the changes were saved, perform onEditSuccess callback.
   */
  useEffect(() => {
    if (doPutBankDetailsData.isSuccess) {
      onEditSuccess();
    }
  }, [doPutBankDetailsData.isSuccess, onEditSuccess]);

  /**
   * Load initial configurations.
   */
  useEffect(() => {
    const loadInitialData = () => {
      doGetAllowOverrideBankNumberOnAccountLevel({
        route: `/configuration/allowOverrideBankNumberOnAccountLevel`,
      });
      doGetBankAccountHolderNameMandatory({
        route: `/configuration/bankAccountHolderNameMandatory`,
      });
      doGetBankAccountTypeMandatory({
        route: `/configuration/bankAccountTypeMandatory`,
      });
    };

    loadInitialData();
  }, [
    accountCode,
    doGetAllowOverrideBankNumberOnAccountLevel,
    doGetBankAccountHolderNameMandatory,
    doGetBankAccountTypeMandatory,
  ]);

  /**
   * Load bank account types.
   */
  useEffect(() => {
    doGetBankAccountTypes({
      route: `/configuration/bankAccountTypes`,
    });
  }, [accountCode, doGetBankAccountTypes]);

  useEffect(() => {
    if (
      bankAccountTypesData.isSuccess &&
      bankAccountTypeMandatoryData.isSuccess &&
      isEmpty(bankAccountType) &&
      bankAccountTypeMandatoryData.data
    ) {
      setBankAccountType(bankAccountTypesData.data.banks[0].code);
    }
  }, [bankAccountType, bankAccountTypeMandatoryData, bankAccountTypesData]);

  /**
   * On save action.
   * @param {*} values Form values.
   */
  const onSave = values => {
    const newDetails = {
      ...details,
      bankAccountDetails: {
        ...details.bankAccountDetails,
        bankHolderName: values.accountHolderName,
        bankAccountNumber: values.bankAccountNumber,
        bankAccountType: bankAccountType,
        bankRoutingNumber: allowOverrideBankNumberOnAccountLevelData.data
          ? values.bankRoutingNumber
          : details.bankAccountDetails.bankRoutingNumber,
      },
    };
    doPutBankDetails({
      route: `/account/${accountCode}/payment/details`,
      expectedResponse: "none",
      body: newDetails,
    });
  };

  const bankAccountNumberRef = React.useRef();

  return (
    <ModalPage isOpen={isOpen} onRequestClose={onClose}>
      <form onSubmit={handleSubmit(onSave)}>
        <Box px="larger" py="larger">
          <Heading
            as="h3"
            fontWeight={5}
            pb="medium"
            fontSize="title"
            color="textDark"
            textAlign="left">
            <FormattedMessage id="lbl.bank_account_information" />
          </Heading>

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.account_holder_name" />
          </Text>
          <Input
            defaultValue={details.bankAccountDetails.bankHolderName}
            variant={errors.accountHolderName ? "greyInputError" : "greyInput"}
            ref={register({
              required: bankAccountHolderNameMandatoryData.data,
            })}
            maxLength="80"
            name="accountHolderName"
            type="text"
            marginBottom="medium"
          />

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.bank_account_type" />
          </Text>
          <Box marginBottom="medium">
            <Select
              name="bankAccountType"
              width="100%"
              value={bankAccountType}
              ref={register}
              onClick={() => {
                clearError();
              }}
              onChange={event => {
                setBankAccountType(event.target.value);
              }}
              variant={errors.bankAccountType ? "selectError" : "select"}
              sx={{
                borderColor: "greyBorderInput",
                "&:focus": {
                  outline: "none",
                },
              }}>
              {!bankAccountTypeMandatoryData.data && (
                <option key="" value="">
                  {intl.formatMessage({ id: "lbl.none_with_parenthesis" })}
                </option>
              )}
              {bankAccountTypesData.isSuccess &&
                bankAccountTypesData.data.banks &&
                bankAccountTypesData.data.banks.map(bank => (
                  <option key={bank.code} value={bank.code}>
                    {bank.description}
                  </option>
                ))}
            </Select>
          </Box>

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.bank_account_number" />
          </Text>
          <Box marginBottom="medium">
            <Controller
              name="bankAccountNumber"
              as={
                <Input
                  ref={bankAccountNumberRef}
                  variant={errors.bankAccountNumber ? "greyInputError" : "greyInput"}
                  style={{ paddingRight: "2.2rem" }}
                  maxLength="25"
                  type="password"
                />
              }
              rules={{
                required: true,
                pattern: {
                  value: /^[0-9]+$/i,
                  message: "err.bank_account_number_should_contain_digits",
                },
              }}
              control={control}
              defaultValue={details.bankAccountDetails.bankAccountNumber}
            />
            <ShowPassword refInput={bankAccountNumberRef} mt="-1.6rem" />
            {errors.bankAccountNumber && errors.bankAccountNumber.message && (
              <FormErrorMessage>
                <FormattedMessage id={errors.bankAccountNumber.message} />
              </FormErrorMessage>
            )}
          </Box>

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.bank_routing_number" />
          </Text>
          <Box marginBottom="medium">
            <Input
              ref={register({
                required: allowOverrideBankNumberOnAccountLevelData.data,
                pattern: {
                  value: /^[0-9]{9}$/i,
                  message: "err.bank_routing_number_should_contain_9_digits",
                },
              })}
              variant={errors.bankRoutingNumber ? "greyInputError" : "greyInput"}
              disabled={!allowOverrideBankNumberOnAccountLevelData.data}
              defaultValue={details.bankAccountDetails.bankRoutingNumber}
              maxLength="9"
              name="bankRoutingNumber"
              type="text"
              paddingY="small"
            />
            {errors.bankRoutingNumber && errors.bankRoutingNumber.message && (
              <FormErrorMessage>
                <FormattedMessage id={errors.bankRoutingNumber.message} />
              </FormErrorMessage>
            )}
          </Box>

          <Box width="auto" mt="larger" textAlign="right" mb="huge">
            <SelfcareButton type="submit" variant="default" mr="spaceBetweenButtons">
              <FormattedMessage id="lbl.save" />
            </SelfcareButton>

            <SelfcareButton
              variant="default-inverted"
              onClick={event => {
                event.preventDefault();
                onClose();
              }}>
              <FormattedMessage id="lbl.cancel" />
            </SelfcareButton>
          </Box>

          {doPutBankDetailsData.isError && (
            <StyledModalMessage
              isOpen={doPutBankDetailsData.isError}
              message={<GetErrorDescription error={doPutBankDetailsData.error} />}
              onRequestClose={resetPutBankDetailsError}
              type="error">
              <SelfcareButton variant="secondarySmall" onClick={resetPutBankDetailsError}>
                <FormattedMessage id="lbl.Try_Again" />
              </SelfcareButton>
            </StyledModalMessage>
          )}
        </Box>
      </form>
    </ModalPage>
  );
};

export default EditBankAccountDetailsModal;
