import React from "react";

export default ({ wall = "#e6e6e6", door = "#336eb4", roof = "#114878", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47.9"
    height="52.45"
    viewBox="0 0 47.9 52.45"
    {...props}>
    <g transform="translate(0 -0.05)">
      <path fill={wall} d="M24.9,15,4.4,25.2V52.5h39V25.1Z" />
      <rect fill={door} width="13" height="22" transform="translate(17.4 30.5)" />
      <path fill={roof} d="M43,20.6V12H35.9v4.7l-11-6L0,23.2l1.8,3.6L24.9,15.2l21,11.5,2-3.5Z" />
    </g>
  </svg>
);
