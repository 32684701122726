import React from "react";
export default ({ beam = "#336eb4", chains = "#114878", pan = "#318eff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34.2"
    height="24.602"
    viewBox="0 0 34.2 24.602"
    {...props}>
    <g transform="translate(15612 -563)">
      <rect fill={beam} width="19.4" height="1.3" transform="translate(-15604.7 566.6)" />
      <rect fill={chains} width="1.3" height="8.7" transform="translate(-15595.6 563)" />
      <path
        fill={pan}
        d="M14,17.1H1.2l.1-.1H.1l-.1.1H0a7.6,7.6,0,0,0,15.2,0h0L15,17H13.9Z"
        transform="translate(-15612 563)"
      />
      <path
        fill={chains}
        d="M.1,17H1.3L7.6,6.1,13.9,17H15L7.6,4.1Z"
        transform="translate(-15612 563)"
      />
      <path
        fill={pan}
        d="M34.2,17.1h0l-.1-.1H33l.1.1H20.2l.1-.1H19.2l-.1.1h0a7.576,7.576,0,0,0,7.6,7.5,7.427,7.427,0,0,0,7.5-7.5Z"
        transform="translate(-15612 563)"
      />
      <path
        fill={chains}
        d="M26.6,6,33,17h1.1L26.6,4,19.2,17h1.1Z"
        transform="translate(-15612 563)"
      />
    </g>
  </svg>
);
