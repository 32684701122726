import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import { INITIAL_LOCALE, MESSAGES, Localization } from "../common/Localization";

const SelfcareIntlContext = React.createContext();

const formats = {
  number: {
    USD: {
      style: "currency",
      currency: "USD",
    },
  },
};

function SelfcareIntlProvider({ children }) {
  // Method to dynamically change the language
  const switchLanguageTo = language => {
    setIntlParams({
      ...intlParams,
      locale: language,
      messages: MESSAGES[language],
    });
    Localization.setLocale(language);
  };

  const [intlParams, setIntlParams] = useState({
    locale: INITIAL_LOCALE,
    messages: MESSAGES[INITIAL_LOCALE],
    switchLanguageTo: switchLanguageTo,
  });

  return (
    <SelfcareIntlContext.Provider value={intlParams}>
      <IntlProvider
        key={intlParams.locale}
        locale={intlParams.locale}
        messages={MESSAGES[intlParams.locale]}
        defaultLocale={INITIAL_LOCALE}
        formats={formats}>
        {children}
      </IntlProvider>
    </SelfcareIntlContext.Provider>
  );
}

export { SelfcareIntlProvider, SelfcareIntlContext, INITIAL_LOCALE };
