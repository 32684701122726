import qs from "qs";
import AuthorizationException from "../../exceptions/AuthorizationException";
import OfflineException from "../../exceptions/OfflineException";

export default class BaseRestClient {
  constructor(apiUrl = "", { headers = {} } = {}) {
    if (!apiUrl) {
      throw new Error("Missing apiUrl!");
    }

    this.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    Object.assign(this.headers, headers);
    this.apiUrl = apiUrl;

    this.requestTimeout =
      window._env_ && window._env_.REQUEST_TIMEOUT ? window._env_.REQUEST_TIMEOUT : 15000;
  }

  _fullRoute(url) {
    return `${this.apiUrl}${url}`;
  }

  async _fetch(
    route,
    method,
    body,
    isQuery = false,
    isForm = false,
    expectedResponse = "json",
    noTimeout = false,
    abortHandle = null,
    authorization = null
  ) {
    if (!route) {
      throw new Error("Route is undefined!");
    }

    if (authorization) {
      this.headers.Authorization = authorization.type + " " + authorization.token;
    } else {
      delete this.headers.Authorization;
    }

    var fullRoute = route.startsWith("http") ? route : this._fullRoute(route);

    if (isQuery && body) {
      const query = qs.stringify(body);
      fullRoute = `${fullRoute}?${query}`;
      body = undefined;
    }
    let localAbortController = new AbortController();
    // client requested an abort handle
    if (abortHandle) {
      abortHandle.abort = () => localAbortController.abort();
    }
    let timeoutId;
    if (!noTimeout) {
      let triggerAbort = abortControllerObj => {
        abortControllerObj.signal.source = "TIMEOUT";
        abortControllerObj.abort();
      };
      timeoutId = setTimeout(() => triggerAbort(localAbortController), this.requestTimeout);
    }

    if (isForm) {
      this.headers["Content-Type"] = "application/x-www-form-urlencoded";
    } else {
      this.headers["Content-Type"] = "application/json";
    }

    let opts = {
      method,
      headers: this.headers,
      signal: localAbortController.signal,
    };
    if (body) {
      Object.assign(opts, { body: isForm ? qs.stringify(body) : JSON.stringify(body) });
    }

    console.log("FacadeAPI Call: " + JSON.stringify(fullRoute));
    console.log("REQUEST: " + JSON.stringify(opts));

    try {
      // Perform fetch
      const response = await fetch(fullRoute, opts);
      const isOK = response.ok;

      if (isOK) {
        if ("json" === expectedResponse) {
          const responseJson = await response.json();
          if (response.headers.has("Authorization")) {
            responseJson.newAccessToken = response.headers.get("Authorization");
          }
          console.log("RESPONSE: " + JSON.stringify(responseJson));
          return responseJson;
        } else if ("blob" === expectedResponse) {
          const responseBlob = await response.blob();
          console.log("RESPONSE: " + JSON.stringify(responseBlob));
          return responseBlob;
        } else {
          // no response body
        }
      } else {
        return response.json().then(
          err => {
            console.log("ERROR: " + JSON.stringify(err));
            if (response.status === 403) {
              throw new AuthorizationException(err.errorCode, err.description);
            } else {
              throw err;
            }
          },
          () => {
            console.log("NO RESPONSE ERROR");
            throw new OfflineException();
          }
        );
      }
    } catch (error) {
      console.log("FETCH ERROR: " + error);
      if (error.name === "AbortError" && localAbortController.signal.source === "TIMEOUT") {
        error.timeout = true;
        throw error;
      }
      throw error;
    } finally {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  }

  async GET(route, query, expectedResponse = "json", noTimeout = false, abortHandle = null) {
    return await this._fetch(route, "GET", query, true, expectedResponse, noTimeout, abortHandle);
  }

  async POST(route, body, expectedResponse = "json") {
    return await this._fetch(route, "POST", body, false, expectedResponse);
  }

  async PUT(route, body, expectedResponse = "json") {
    return await this._fetch(route, "PUT", body, false, expectedResponse);
  }

  async DELETE(route, body, expectedResponse = "json") {
    return await this._fetch(route, "DELETE", body, false, expectedResponse);
  }
}
