import { useIntl } from "react-intl";
import _ from "lodash";

const SelfcareAmount = ({ amount, currency = "USD" }) => {
  const intl = useIntl();
  const preciseAmount = _.round(amount, 2);

  if (preciseAmount < 0) {
    return `(${intl.formatNumber(preciseAmount * -1, { format: currency })})`;
  }

  return intl.formatNumber(Math.abs(preciseAmount), { format: currency });
};

export default SelfcareAmount;
