import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PaymentResponseModal from "../../pages/Home/PaymentResponseModal";

const HostedCcOperationResponse = props => {
  const history = useHistory();
  const [isDisplayPaymentResponse, setDisplayPaymentResponse] = useState(true);

  function parseParams() {
    if (!props.params) {
      history.push("/home");
    }
    let temp = decodeURIComponent(props.params).split("&");

    let canceledByUser = temp[0].split("=")[1];
    let referenceId = temp[1].split("=")[1];
    let paymentNumber = temp[2].split("=")[1];
    let authCode = temp[3].split("=")[1];
    let amount = temp[4].split("=")[1];
    let paymentMethod = decodeURIComponent(temp[5].split("=")[1])
      .split("+")
      .join(" ");
    let errorMessage = temp[6]
      .split("=")[1]
      .split("+")
      .join(" ");
    let responseUrl = temp[7].split("=")[1];

    return {
      canceledByUser,
      referenceId,
      paymentNumber,
      authCode,
      amount,
      paymentMethod,
      errorMessage,
      responseUrl,
    };
  }

  const getParams = () => {
    let response = parseParams();
    if (response) {
      if (
        response.canceledByUser === "true" ||
        (!response.errorMessage && (!response.amount || response.amount === "0.0"))
      ) {
        history.push(response.responseUrl);
      }
      return response;
    }
  };

  let response = getParams();
  return (
    <PaymentResponseModal
      isDisplayPaymentResponse={isDisplayPaymentResponse}
      onPaymentResponseClose={() => {
        setDisplayPaymentResponse(false);
        history.push(response.responseUrl);
      }}
      response={response}
    />
  );
};

export default HostedCcOperationResponse;
