import React from "react";
export default ({
  mediumBlue = "#336eb4",
  darkBlue = "#114878",
  lightBlue = "#318eff",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="78.761"
    viewBox="0 0 51 78.761"
    {...props}>
    <path
      fill={mediumBlue}
      d="M46.482,73.914,27.239,17.867A2.021,2.021,0,0,0,25.346,16.5a1.925,1.925,0,0,0-1.893,1.367L4.105,73.914a2.086,2.086,0,0,0,.315,1.893,2.2,2.2,0,0,0,1.682.841H44.695a2.2,2.2,0,0,0,1.682-.841A2.931,2.931,0,0,0,46.482,73.914ZM23.243,42.578l-6.2,6.2,6.2-18.087Zm0,5.784v13.88L16.3,55.3Zm4.1,0,6.94,6.94-6.94,6.94Zm0-5.784V30.7l6.2,18.192ZM13.674,58.457l9.569,9.569v4.522H8.837Zm13.67,14.091V68.026l9.569-9.569,4.837,14.091Z"
      transform="translate(0.206 0.851)"
    />
    <g transform="translate(7.256)">
      <path
        fill={lightBlue}
        d="M15.87,21.987l2-2a9.9,9.9,0,0,1-2.629-6.835A9.687,9.687,0,0,1,18.394,5.9l-2-2a12.955,12.955,0,0,0-4,9.254A12.573,12.573,0,0,0,15.87,21.987Z"
        transform="translate(-6.616 0.201)"
      />
      <path
        fill={lightBlue}
        d="M30.821,19.994l2,2a12.854,12.854,0,0,0,3.575-8.833,13.2,13.2,0,0,0-4-9.359l-2,2a10.082,10.082,0,0,1,3.155,7.256A11.115,11.115,0,0,1,30.821,19.994Z"
        transform="translate(-5.689 0.196)"
      />
      <path
        fill={lightBlue}
        d="M34.721,24.291l2,2A18.541,18.541,0,0,0,36.3,0l-2,2a15.886,15.886,0,0,1,4.837,11.357A16.568,16.568,0,0,1,34.721,24.291Z"
        transform="translate(-5.488)"
      />
      <path
        fill={lightBlue}
        d="M14.156,24.291A15.44,15.44,0,0,1,9.844,13.46,16.072,16.072,0,0,1,14.681,2.1L12.578,0A18.945,18.945,0,0,0,6.9,13.355a18.583,18.583,0,0,0,5.153,12.829Z"
        transform="translate(-6.9)"
      />
    </g>
    <rect fill={darkBlue} width="51" height="5.363" transform="translate(0 73.398)" />
    <circle fill={lightBlue} cx="5.678" cy="5.678" r="5.678" transform="translate(19.769 7.466)" />
  </svg>
);
