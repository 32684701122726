import React from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { useIntl } from "react-intl";

import FUBProgressCircle from "../FUBProgressCircle";
import Tooltip from "../Tooltip";

const computeUnits = (baseUnitRate, unitDescription, totalQuantity, usedQuantity) => {
  let result = {
    total: null,
    used: null,
    unit: null,
  };

  result.total = totalQuantity === -1 ? totalQuantity : totalQuantity / baseUnitRate;
  result.used = usedQuantity / baseUnitRate;

  // if the unit is other than GB or MB, round the used quantity downward to its nearest integer
  // in order to not have decimals for Minutes/Seconds/KB/etc
  if (unitDescription.toUpperCase() !== "MB" && unitDescription.toUpperCase() !== "GB") {
    result.total = Math.floor(result.total);
    result.used = Math.floor(result.used);
  }

  // the unit description will be displayed only for MB and GB
  if (unitDescription.toUpperCase() === "MB" || unitDescription.toUpperCase() === "GB") {
    result.unit = unitDescription.toUpperCase();
  }

  return result;
};

const formatQuantity = quantity => {
  return Number.parseInt(quantity.toFixed(1).replace(/[.,]0$/, ""));
};

const FUBCard = ({
  fub,
  responsive = true,
  showExpirationDate = false,
  computeExpirationDateMessage,
  ...props
}) => {
  const intl = useIntl();

  const fubUnits = computeUnits(
    fub.baseUnitRate,
    fub.unitDescription,
    fub.totalQuantity,
    fub.usedQuantity
  );

  const showDetails =
    fub.participantsAccountCodes !== null && fub.participantsAccountCodes !== undefined;

  return (
    <Box
      {...props}
      bg="contentBg"
      sx={{
        boxShadow: "light",
        borderRadius: "card",
      }}>
      <Flex p="small" flexDirection="column" height="100%">
        <Flex alignSelf="flex-start">
          <FUBProgressCircle
            remaining={formatQuantity(fubUnits.total - fubUnits.used)}
            total={formatQuantity(fubUnits.total)}
            unit={fubUnits.unit}
            responsive={responsive}
          />
        </Flex>

        <Text
          mt="small"
          textAlign="center"
          verticalAlign="center"
          color="primary"
          fontSize="default">
          {fub.description}
        </Text>

        {showDetails && (
          <Tooltip
            mt="auto"
            position="bottom"
            value={
              <Box sx={{ cursor: "normal" }}>
                {fub.participantsAccountCodes.map((value, index) => (
                  <Text
                    key={index}
                    mt={index > 0 ? "small" : "0"}
                    color="textDark"
                    textAlign="center">
                    {value}
                  </Text>
                ))}
              </Box>
            }>
            <Text
              mt="small"
              textAlign="center"
              verticalAlign="center"
              color="primary"
              fontSize="default"
              sx={{ cursor: "pointer", textDecoration: "underline" }}>
              {intl.formatMessage({ id: "lbl.view_members" })}
            </Text>
          </Tooltip>
        )}

        {showExpirationDate && (
          <Flex mt="auto" justifyContent="center">
            <Text
              mt="small"
              textAlign="center"
              verticalAlign="center"
              color="secondary"
              fontSize="note">
              {computeExpirationDateMessage(fub)}
            </Text>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default FUBCard;
