import React, { useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";

const Tooltip = ({ value, position = "default", customProps, children, childrenPY, ...props }) => {
  const [mustDisplay, setMustDisplay] = useState(false);
  let properties;
  switch (position) {
    case "top":
      properties = {
        flexDirection: "column",
        alignItems: "center",
        mt: "tooltipTop",
      };
      break;
    case "top-noflex":
      properties = {
        alignItems: "center",
        mt: "tooltipTop",
      };
      break;
    case "bottom":
      properties = {
        flexDirection: "column",
        alignItems: "center",
        mt: "medium",
      };
      break;
    case "left":
      properties = {
        flexDirection: "row",
        alignItems: "center",
        ml: "tooltipLeft",
      };
      break;
    case "right":
      properties = {
        flexDirection: "row",
        alignItems: "center",
        ml: "large",
      };
      break;
    case "custom":
      let defaultMargins = { ml: "0rem", mt: "0rem" };
      let customPropsMobile =
        customProps && customProps.mobile ? customProps.mobile : defaultMargins;
      let customPropsTablet =
        customProps && customProps.tablet ? customProps.tablet : defaultMargins;
      let customPropsDesktop =
        customProps && customProps.desktop ? customProps.desktop : defaultMargins;
      properties = {
        flexDirection: "row",
        alignItems: "center",
        ml: [customPropsMobile.ml, customPropsTablet.ml, customPropsDesktop.ml],
        mt: [customPropsMobile.mt, customPropsTablet.mt, customPropsDesktop.mt],
      };
      break;
    default:
      properties = {
        flexDirection: ["column", "column", "row"],
        alignItems: ["flex-end", "flex-end", "center"],
        ml: ["0", "0", "large"],
        mt: ["large", "large", "0"],
      };
  }

  return (
    <Flex flexDirection={properties.flexDirection} alignItems={properties.alignItems} {...props}>
      <Box
        py={childrenPY}
        onTouchStart={e => {
          e.preventDefault();
          e.stopPropagation();
          setMustDisplay(!mustDisplay);
        }}
        onMouseEnter={() => {
          setMustDisplay(true);
        }}
        onMouseLeave={() => {
          setMustDisplay(false);
        }}>
        {children}
      </Box>
      <Box
        display={mustDisplay ? "inline-block" : "none"}
        width="auto"
        maxWidth="20rem"
        mb={properties.mb ? properties.mb : "0"}
        mt={properties.mt ? properties.mt : "0"}
        ml={properties.ml ? properties.ml : "0"}
        mr={properties.mr ? properties.mr : "0"}
        sx={{
          position: "absolute",
          zIndex: 111,
          borderRadius: "card",
          boxShadow: "tooltip",
          fontSize: "note",
          backgroundColor: "contentBg",
          paddingY: "0.5em",
        }}>
        <Text color="textDark" textAlign="center" mx="default">
          {value}
        </Text>
      </Box>
    </Flex>
  );
};

export default Tooltip;
