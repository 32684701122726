import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Flex, Text } from "rebass/styled-components";

import { SelfcareButton } from "../../components/base";
import { AuthContext } from "../../contexts/AuthorizationContext";
import Layout from "../../containers/Layout";

const Error = () => {
  const history = useHistory();
  const { loggedIn, logout } = useContext(AuthContext);

  return (
    <Layout displayOnlyBurgerMenu={true} disableNavMenuDefaultLocation={true}>
      <Flex justifyContent="center" alignItems="center" height="100%" my="large">
        <Flex
          bg="contentBg"
          px="large"
          py="large"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{
            boxShadow: "light",
            borderRadius: "card",
          }}>
          <Text color="primary" fontSize="primary" lineHeight={1} fontWeight={3} textAlign="center">
            <FormattedMessage id="err.error_page_generic" />
          </Text>

          <Flex mt="default" justifyContent="space-around" flexWrap="wrap">
            {loggedIn && (
              <SelfcareButton
                variant="secondarySmall"
                onClick={() => history.goBack()}
                mr="spaceBetweenButtons"
                mb="spaceBetweenButtons">
                <FormattedMessage id="lbl.btn_back" />
              </SelfcareButton>
            )}
            {loggedIn && (
              <SelfcareButton variant="secondarySmall" onClick={() => logout(history)}>
                <FormattedMessage id="lbl.log_out" />
              </SelfcareButton>
            )}
            {!loggedIn && (
              <SelfcareButton
                variant="secondarySmall"
                onClick={() => {
                  history.replace("/");
                }}>
                <FormattedMessage id="lbl.log_out" />
              </SelfcareButton>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default Error;
