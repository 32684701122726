import { Button } from "rebass/styled-components";
import styled from "styled-components";
import { variant } from "styled-system";

const SelfcareButton = styled(Button)`
  ${variant({
    variants: {
      default: {
        outline: "none !important",
        textAlign: "center",
        cursor: "pointer",
        fontFamily: "roboto, sans-serif",
        fontSize: "primary",
        fontWeight: "solid",
        color: "textLight",
        bg: "secondary",
        borderRadius: "default",
        width: "6.25rem",
        height: "inputHeightMedium",
        "&:hover": {
          opacity: "90%",
        },
        "&:disabled": {
          cursor: "default",
          opacity: "75%",
          color: "note",
        },
      },
      "default-inverted": {
        outline: "none !important",
        textAlign: "center",
        cursor: "pointer",
        fontFamily: "roboto, sans-serif",
        fontSize: "primary",
        fontWeight: "solid",
        color: "secondary",
        border: "1px solid",
        borderColor: "secondary",
        bg: "contentBg",
        borderRadius: "default",
        width: "6.25rem",
        height: "inputHeightMedium",
        "&:hover": {
          opacity: "90%",
        },
        "&:disabled": {
          cursor: "default",
          opacity: "75%",
          color: "note",
        },
      },
      primary: {
        outline: "none !important",
        cursor: "pointer",
        fontFamily: "roboto, sans-serif",
        fontSize: "primary",
        fontWeight: 3,
        color: "textLight",
        bg: "primary",
        borderRadius: "default",
        boxShadow: "light",
        width: "18.68rem",
        height: "inputHeightDefault",
        "&:disabled": {
          cursor: "default",
          opacity: "75%",
          color: "note",
        },
      },
      secondary: {
        outline: "none !important",
        cursor: "pointer",
        fontFamily: "roboto, sans-serif",
        fontSize: "primary",
        fontWeight: 3,
        color: "textLight",
        bg: "secondary",
        borderRadius: "default",
        boxShadow: "dark",
        width: "18.68rem",
        height: "inputHeightDefault",
        "&:disabled": {
          cursor: "default",
          opacity: "75%",
          color: "note",
        },
      },
      secondarySmall: {
        outline: "none !important",
        textAlign: "center",
        cursor: "pointer",
        fontFamily: "roboto, sans-serif",
        fontSize: "primary",
        fontWeight: 3,
        color: "textLight",
        bg: "secondary",
        borderRadius: "default",
        boxShadow: "dark",
        width: "10.56rem",
        height: "inputHeightDefault",
        "&:disabled": {
          cursor: "default",
          opacity: "75%",
          color: "note",
        },
      },
      secondarySmallInverted: {
        outline: "none !important",
        textAlign: "center",
        cursor: "pointer",
        fontFamily: "roboto, sans-serif",
        fontSize: "primary",
        fontWeight: 3,
        border: "1px solid",
        borderColor: "secondary",
        color: "secondary",
        bg: "textLight",
        borderRadius: "default",
        boxShadow: "dark",
        width: "10.56rem",
        height: "inputHeightDefault",
        "&:disabled": {
          cursor: "default",
          opacity: "75%",
          color: "note",
        },
      },
      tertiary: {
        outline: "none !important",
        textAlign: "center",
        cursor: "pointer",
        fontFamily: "roboto, sans-serif",
        fontSize: "primary",
        fontWeight: "solid",
        color: "textLight",
        bg: "secondary",
        borderRadius: "default",
        width: ["13rem", "9rem"],
        height: "inputHeightMedium",
        "&:hover": {
          opacity: "90%",
        },
        "&:disabled": {
          cursor: "default",
          "&:hover": {
            opacity: "100%",
          },
          bg: "inactive",
          color: "note",
        },
      },
      tertiaryWhite: {
        outline: "none !important",
        textAlign: "center",
        cursor: "pointer",
        fontFamily: "roboto, sans-serif",
        fontSize: "primary",
        fontWeight: "solid",
        color: "secondary",
        border: "1px solid",
        borderColor: "secondary",
        bg: "contentBg",
        borderRadius: "default",
        width: ["13rem", "9rem"],
        height: "inputHeightMedium",
        "&:hover": {
          opacity: "90%",
        },
        "&:disabled": {
          cursor: "default",
          "&:hover": {
            opacity: "100%",
          },
          bg: "inactive",
          color: "note",
        },
      },
      tertiaryNoWidth: {
        outline: "none !important",
        textAlign: "center",
        cursor: "pointer",
        fontFamily: "roboto, sans-serif",
        fontSize: "primary",
        fontWeight: "solid",
        color: "textLight",
        bg: "secondary",
        borderRadius: "default",
        height: "inputHeightMedium",
        "&:hover": {
          opacity: "90%",
        },
        "&:disabled": {
          cursor: "default",
          "&:hover": {
            opacity: "100%",
          },
          bg: "inactive",
          color: "note",
        },
      },
      tertiaryWhiteNoWidth: {
        outline: "none !important",
        textAlign: "center",
        cursor: "pointer",
        fontFamily: "roboto, sans-serif",
        fontSize: "primary",
        fontWeight: "solid",
        color: "secondary",
        border: "1px solid",
        borderColor: "secondary",
        bg: "contentBg",
        borderRadius: "default",
        height: "inputHeightMedium",
        "&:hover": {
          opacity: "90%",
        },
        "&:disabled": {
          cursor: "default",
          "&:hover": {
            opacity: "100%",
          },
          bg: "inactive",
          color: "note",
        },
      },
    },
  })}
`;

export default SelfcareButton;
