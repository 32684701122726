import React, { useEffect, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useGET } from "../../hooks/restAPI";
import { SelfcareAmount, SelfcareButton } from "../../components/base";
import StyledModalMessage from "../StyledModalMessage";
import LoadDocumentModal from "../../components/LoadDocumentModal";
import { getDateWithoutTimezone } from "../../common/Utilities";

const HorizontalRule = () => {
  return <Box width="100%" height="1px" bg="inactive"></Box>;
};

const InvoiceCard = ({ id, number, fromDate, toDate, dueDate, unpaidAmount, value }) => {
  const [invoiceData, doGetInvoice, resetGetError] = useGET({});
  const [documentUrl, setDocumentUrl] = useState(null);

  useEffect(() => {
    if (invoiceData.isSuccess) {
      var file = new Blob([invoiceData.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      setDocumentUrl(fileURL);
    }
  }, [invoiceData.data, invoiceData.isSuccess]);

  const getFormattedMessage = number => {
    return number != null ? number : <FormattedMessage id="lbl.not_available" />;
  };

  const getFormattedDate = date => {
    let formattedDate;
    if (date == null) {
      formattedDate = <FormattedMessage id="lbl.not_available" />;
    } else {
      formattedDate = <FormattedDate value={getDateWithoutTimezone(date)} />;
    }

    return formattedDate;
  };

  const getFormattedNumber = value => {
    let formattedValue;
    if (value == null) {
      formattedValue = <FormattedMessage id="lbl.not_available" />;
    } else {
      formattedValue = <SelfcareAmount amount={value} />;
    }

    return formattedValue;
  };

  const getInvoice = () => {
    const getInvoiceConfig = {
      route: `/invoice/${id}/image`,
      noTimeout: true,
      expectedResponse: "blob",
    };
    doGetInvoice(getInvoiceConfig);
  };

  const getUnpaidColor = () => {
    return unpaidAmount <= 0 ? "note" : "unpaidSum";
  };

  return (
    <Flex
      p="default"
      flexDirection="column"
      bg="contentBg"
      sx={{
        boxShadow: "light",
        borderRadius: "card",
      }}>
      <Text pb="default" color="primary" fontSize="secondary">
        <FormattedMessage
          id="lbl.invoice_card_invoice_number"
          values={{ invoiceNumber: getFormattedMessage(number) }}
        />
      </Text>
      <Box pb="default" fontSize="secondary">
        <Text color="primary">
          <FormattedMessage
            id="lbl.invoice_card_from_to_dates"
            values={{
              fromDate: getFormattedDate(fromDate),
              toDate: getFormattedDate(toDate),
            }}
          />
        </Text>
        <Text color="primary" fontSize="secondary" sx={{ textTransform: "capitalize" }}>
          <FormattedMessage
            id="lbl.invoice_card_due_on"
            values={{
              dueDate: getFormattedDate(dueDate),
            }}
          />
        </Text>
      </Box>
      <Flex alignItems="flex-start">
        <Text
          sx={{ textTransform: "capitalize" }}
          color="textDark"
          fontSize="amountTitle"
          pb="default">
          {getFormattedNumber(value)}
        </Text>

        <Flex color={getUnpaidColor()} ml="auto" flexDirection="column" alignItems="flex-end">
          <Text fontSize="amountTitle">{getFormattedNumber(unpaidAmount)}</Text>
          <Text fontSize="default">
            <FormattedMessage id="lbl.unpaid" />
          </Text>
        </Flex>
      </Flex>
      <HorizontalRule />
      <Text
        onClick={getInvoice}
        pt="default"
        color="secondary"
        fontSize="secondary"
        sx={{
          cursor: "pointer",
        }}>
        <FormattedMessage id="lbl.view_details" />
      </Text>

      <LoadDocumentModal
        isOpen={invoiceData.isLoading || invoiceData.isSuccess}
        onClose={() => {
          setDocumentUrl(null);
          resetGetError();
        }}
        url={documentUrl}
      />

      {invoiceData.isError && (
        <StyledModalMessage
          isOpen={invoiceData.isError}
          message={<FormattedMessage id="lbl.invoice_not_found" />}
          onRequestClose={resetGetError}
          type="error">
          <SelfcareButton variant="secondarySmall" onClick={resetGetError}>
            <FormattedMessage id="lbl.ok" />
          </SelfcareButton>
        </StyledModalMessage>
      )}
    </Flex>
  );
};

export default InvoiceCard;
