import React from "react";
import ContentLoader from "react-content-loader";

const AccountDetailsSkeleton = ({ ...props }) => (
  <ContentLoader
    {...props}
    speed={2}
    viewBox="0 0 159.8 62.5"
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">
    <rect x="10.6" width="138.5" height="19" />
    <rect x="37.2" y="21.8" width="85.5" height="19" />
    <rect y="43.5" width="159.8" height="19" />
  </ContentLoader>
);

export default AccountDetailsSkeleton;
