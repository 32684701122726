import React from "react";
export default ({
  mediumBlue = "#336eb4",
  darkBlue = "#114878",
  lightBlue = "#318eff",
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" {...props}>
    <g id="mediumBlue">
      <path
        fill={mediumBlue}
        d="M47.5,76L27.9,19c-0.4-1.1-1.6-1.6-2.7-1.2c-0.6,0.2-1,0.7-1.2,1.2L4.3,76c-0.2,0.6-0.1,1.3,0.3,1.9
		c0.4,0.6,1,0.9,1.7,0.9h39.2c1.1,0,2.1-0.9,2.1-2C47.7,76.5,47.6,76.2,47.5,76L47.5,76z M23.9,44.2l-6.4,6.3l6.4-18.5V44.2z
		 M23.9,50v14.1l-7-7L23.9,50z M28,50l7,7l-7,7L28,50z M28,44.2V32.1l6.4,18.5L28,44.2z M14.1,60.3l9.7,9.7v4.6H9.2L14.1,60.3z
		 M28,74.6V70l9.7-9.7l4.9,14.3L28,74.6z"
      />
      <path
        fill={mediumBlue}
        d="M51.5,32.9h-3.6c-0.6,0-1.1,0.5-1.1,1.1c0,0,0,0,0,0v12c0,0.6,0.5,1.1,1.1,1.1h3.6c0.2,0,0.4,0.1,0.6,0.2
		l8.1,5.5c0.5,0.3,1.2,0.2,1.5-0.3c0.1-0.2,0.2-0.4,0.2-0.6V28.1c0-0.6-0.5-1.1-1.1-1.1c-0.2,0-0.4,0.1-0.6,0.2l-8.1,5.5
		C51.9,32.8,51.7,32.9,51.5,32.9z"
      />
    </g>
    <rect id="darkBlue" y="74.6" fill={darkBlue} width="51.8" height="5.4" />
    <g id="lightBlue">
      <path
        fill={lightBlue}
        d="M16.8,22.6l2.1-2.1c-3.8-4.1-3.6-10.5,0.5-14.3l-2.1-2.1C12.1,9.1,11.9,17.4,16.8,22.6z"
      />
      <path
        fill={lightBlue}
        d="M32.9,20.6l2.1,2.1c5-5.2,4.8-13.5-0.5-18.5l-2.1,2.1C36.5,10,36.7,16.5,32.9,20.6z"
      />
      <path
        fill={lightBlue}
        d="M37.1,24.7l2.1,2.1c7.3-7.5,7.1-19.5-0.5-26.7c0,0,0,0,0,0l-2.1,2.1C43,8.2,43.2,18.3,37.1,24.7
		C37.1,24.7,37.1,24.7,37.1,24.7z"
      />
      <path
        fill={lightBlue}
        d="M14.7,24.6C8.6,18.3,8.9,8.2,15.2,2.1L13.1,0c-7.5,7.2-7.7,19.2-0.5,26.7L14.7,24.6z"
      />
      <circle fill={lightBlue} cx="25.9" cy="13.4" r="5.8" />
      <path
        fill={lightBlue}
        d="M67.3,47.9l2.3,2.3c5.5-5.7,5.2-14.8-0.5-20.3c0,0,0,0,0,0l-2.3,2.3C71.2,36.3,71.5,43.4,67.3,47.9L67.3,47.9z
		"
      />
      <path
        fill={lightBlue}
        d="M71.9,52.4l2.3,2.3c8-8.2,7.8-21.4-0.5-29.3c0,0,0,0,0,0l-2.3,2.3C78.4,34.3,78.6,45.4,71.9,52.4
		C71.9,52.4,71.9,52.4,71.9,52.4z"
      />
    </g>
  </svg>
);
