import { useEffect, useState } from "react";

export const useOverflow = aRef => {
  const [isOverflow, setOverflow] = useState(false);
  useEffect(() => {
    if (aRef && aRef.current && aRef.current.clientWidth < aRef.current.scrollWidth) {
      setOverflow(true);
    }
  }, [aRef, setOverflow]);

  return [isOverflow];
};
