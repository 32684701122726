import React, { useContext } from "react";
import { Box, Flex, Image } from "rebass/styled-components";
import CityIllustration from "../../resources/images/city_illustration.svg";
import { BrandingContext } from "../../contexts/BrandingContext";

/**
 * Layout for login and registration pages
 * @param children - an array of two elements (left and right sides of the layout)
 * @returns {*}
 * @constructor
 */
const LoginLayout = ({ children }) => {
  const { logo } = useContext(BrandingContext);
  return (
    <Box
      bg="windowBg"
      display="grid"
      height="100%"
      sx={{
        gridGap: 0,
        gridTemplateColumns: ["1fr", "1fr", "0.5fr 0.5fr"],
        gridTemplateRows: ["1fr 0.1fr", "1fr 0.1fr", "1fr"],
        gridAutoRows: "1fr",
        gridAutoColumns: "1fr",
      }}>
      <Flex bg="windowBg" py="default" justifyContent="center" alignItems="center">
        <Flex
          flexDirection="column"
          alignItems={["center", "center", "flex-start"]}
          justifyContent="center"
          width="18rem">
          <Image maxWidth="10.375rem" py="3rem" src={logo} />
          <Box pb="1rem">{children[0]}</Box>
        </Flex>
      </Flex>
      <Flex
        bg="primary"
        flexDirection="column"
        justifyContent={["flex-start", "flex-start", "center"]}
        alignItems="center"
        height={["20rem", "20rem", "auto"]}
        sx={{
          position: "relative",
        }}>
        <Flex
          width={["100%", "100%", "18.75rem"]}
          alignItems="center"
          justifyContent="center"
          pt={["large", "large", 0]}
          flexDirection="column"
          pb={["default", "default", "huge"]}
          sx={{
            position: "absolute",
            mx: [0, 0, "auto"],
            my: [0, 0, "auto"],
            zIndex: 1,
          }}>
          {children[1]}
        </Flex>
        <Image
          mt="auto"
          display={["inline", "inline", "none"]}
          maxHeight="16rem"
          src={CityIllustration}
        />
        <Image
          mt="auto"
          maxHeight="22rem"
          display={["none", "none", "inline"]}
          src={CityIllustration}
        />
      </Flex>
    </Box>
  );
};

export default LoginLayout;
