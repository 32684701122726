import React, { forwardRef } from "react";
import { Box } from "rebass/styled-components";

const SelfcareSwitch = forwardRef(({ checked, ...props }, ref) => (
  <Box
    ref={ref}
    as="button"
    type="button"
    role="switch"
    aria-checked={checked}
    {...props}
    __css={{
      m: 0,
      p: 0,
      cursor: "pointer",
      width: "3.5rem",
      height: "1.5rem",
      color: "primary",
      bg: "inactive",
      border: "1px solid",
      borderColor: "inactive",
      borderRadius: 9999,
      "&[aria-checked=true]": {
        bg: "inactive",
      },
      "&:focus": {
        outline: "none",
      },
      "&:disabled": {
        opacity: "0.75",
      },
    }}>
    <Box
      aria-hidden
      style={{
        transform: checked ? "translateX(2rem)" : "translateX(0)",
      }}
      sx={{
        mt: "-1px",
        ml: "-1px",
        width: "1.5rem",
        height: "1.5rem",
        borderRadius: 9999,
        bg: checked ? "secondary" : "#F8F8F8",
        transitionProperty: "transform",
        transitionTimingFunction: "ease-out",
        transitionDuration: "0.1s",
      }}
    />
  </Box>
));

export default SelfcareSwitch;
