import React, { useEffect } from "react";
import { Box, Flex } from "rebass/styled-components";
import { useHistory, useLocation } from "react-router-dom";

import NavigationMenuItem from "../NavigationMenuItem";

const Separator = props => (
  <Box
    {...props}
    height="100%"
    sx={{
      borderRight: 1,
      borderRightColor: "inactive",
    }}
  />
);

const NavigationSecondaryMenuMobile = ({ menuItems, isSubAccount, ...props }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let defaultLocation;
    for (let i = 0; i < menuItems.length; i++) {
      if (!defaultLocation) {
        defaultLocation = menuItems[i].location;
      }
      if (location.pathname.includes(menuItems[i].location)) {
        return;
      }
    }
    history.replace(defaultLocation);
  }, [history, location.pathname, menuItems]);

  return (
    <Box
      display={isSubAccount ? "none" : ["inline", "none", "none"]}
      width="100%"
      sx={{
        position: "sticky",
        bottom: "mobileNavigationMenuOffset",
      }}>
      <Flex {...props} bg="contentBg">
        <Flex width="100%" alignItems="center" justifyContent="center" mx="auto">
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <NavigationMenuItem
                p="default"
                width={1 / menuItems.length}
                titleLanguageID={item.title}
                isActive={location.pathname.includes(item.location)}
                textAlign="center"
                activeBorderPosition="top"
                onClick={() =>
                  !location.pathname.includes(item.location) && history.push(item.location)
                }
              />
              {index < menuItems.length - 1 && <Separator />}
            </React.Fragment>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavigationSecondaryMenuMobile;
