import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Box, Flex, Heading, Text } from "rebass/styled-components";
import { SelfcareButton } from "../../components/base";
import BillingTimeline from "../../components/BillingTimeline";
import ContactDetails from "../../components/ContactDetails";
import FUBGroup from "../../components/FUBGroup";
import MakePayment from "../../components/MakePayment";
import { SACard, SACardSkeleton } from "../../components/SACard";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { useGET } from "../../hooks/restAPI";
import { HostedCcOperationResponse } from "../../pages";
import MakePaymentModal from "../../pages/Home/MakePaymentModal";
import PaymentResponseModal from "../../pages/Home/PaymentResponseModal";
import RequestAssistance from "../RequestAssistance";
import ViewPostpaidUnbilledSummary from "../ViewPostpaidUnbilledSummary";
import { BrandingContext } from "../../contexts/BrandingContext";
import StyledModalMessage from "../StyledModalMessage";
import GetErrorDescription from "../GetErrorDescription";

const SA_INITIAL_CHUNK_SIZE = 12;

const MasterAccountLayout = ({
  accountCode,
  accountName,
  isBillable,
  setPayment,
  setInitiatePayment,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { config } = useContext(BrandingContext);

  const { accountsList, setAccountsList } = useContext(AuthContext);
  const subAccountsOfCurrentMA =
    accountsList && accountsList.find(account => account.accountCode === accountCode);

  const [displayAllServiceAgreements, setDisplayAllServiceAgreements] = useState(
    subAccountsOfCurrentMA ? subAccountsOfCurrentMA.displayAll : false
  );
  const [didLoadAll, setDidLoadAll] = useState(
    subAccountsOfCurrentMA ? subAccountsOfCurrentMA.accounts.length > SA_INITIAL_CHUNK_SIZE : false
  );
  const [isMakePayment, setMakePayment] = useState(false);
  const [isDisplayPaymentResponse, setDisplayPaymentResponse] = useState(true);

  const [billingSummaryData, doGetBillingSummary, resetBillingSummaryErr] = useGET(null);
  const [sharedPlansData, doGetSharedPlans, resetSharedPlansErr] = useGET({
    sharedBeneficiaryUnits: null,
  });
  const [accountListData, doGetAccountList, resetAccountListErr] = useGET({ accountList: null });
  const [contactsData, doGetContacts, resetContactsErr] = useGET({});

  const isError =
    billingSummaryData.isError ||
    sharedPlansData.isError ||
    accountListData.isError ||
    contactsData.isError;

  const error =
    billingSummaryData.error ||
    sharedPlansData.error ||
    accountListData.error ||
    contactsData.error;

  const tryAgain = () => {
    resetBillingSummaryErr();
    resetSharedPlansErr();
    resetAccountListErr();
    resetContactsErr();

    if (isBillable) {
      doGetBillingSummary({
        route: `/account/${accountCode}/billingSummary`,
      });
    }

    if (!subAccountsOfCurrentMA) {
      doGetAccountList({
        route: `/account/${accountCode}/accountList?end=${SA_INITIAL_CHUNK_SIZE}`,
        noTimeout: true,
      });
    }

    doGetSharedPlans({
      route: `/account/${accountCode}/plan/hierarchySharedBeneficiaryUnits`,
      noTimeout: true,
    });

    if (!isBillable) {
      doGetContacts({
        route: `/account/${accountCode}/contacts`,
      });
    }
  };

  function onMakePaymentClick() {
    setMakePayment(true);
  }

  useEffect(() => {
    if (isBillable) {
      doGetBillingSummary({
        route: `/account/${accountCode}/billingSummary`,
      });
    }
  }, [accountCode, doGetBillingSummary, isBillable]);

  useEffect(() => {
    if (!subAccountsOfCurrentMA) {
      doGetAccountList({
        route: `/account/${accountCode}/accountList?end=${SA_INITIAL_CHUNK_SIZE}`,
        noTimeout: true,
      });
    }
  }, [accountCode, doGetAccountList, subAccountsOfCurrentMA]);

  useEffect(() => {
    if (accountListData && accountListData.isSuccess) {
      const code =
        accountListData.data.accountList.length > 0
          ? accountListData.data.accountList[0].parentAccountCode
          : accountCode;
      const subAccts = accountsList && accountsList.find(account => account.accountCode === code);

      if (!subAccts) {
        setAccountsList([
          ...accountsList,
          {
            accountCode: code,
            accounts: accountListData.data.accountList,
            displayAll: displayAllServiceAgreements,
            total: accountListData.data.totalNumberOfAccounts,
          },
        ]);
      }

      if (
        subAccts &&
        JSON.stringify(subAccts.accounts) !== JSON.stringify(accountListData.data.accountList) &&
        !didLoadAll
      ) {
        setAccountsList(
          accountsList.map(item =>
            item.accountCode === code
              ? {
                  ...item,
                  accounts: subAccts.accounts.concat(accountListData.data.accountList),
                  displayAll: displayAllServiceAgreements,
                  total: subAccts.accounts.concat(accountListData.data.accountList).length,
                }
              : item
          )
        );
        setDidLoadAll(true);
      }
    }
  }, [
    accountCode,
    accountListData,
    accountsList,
    didLoadAll,
    displayAllServiceAgreements,
    setAccountsList,
  ]);

  useEffect(() => {
    doGetSharedPlans({
      route: `/account/${accountCode}/plan/hierarchySharedBeneficiaryUnits`,
      noTimeout: true,
    });
  }, [accountCode, doGetSharedPlans]);

  useEffect(() => {
    if (!isBillable) {
      doGetContacts({
        route: `/account/${accountCode}/contacts`,
      });
    }
  }, [accountCode, doGetContacts, isBillable]);

  const itemsPerRow = () => {
    const browserFontSize = parseFloat(
      getComputedStyle(document.body, null).getPropertyValue("font-size")
    );

    if (window.innerWidth <= 40 * browserFontSize) {
      return { sharedFubs: 2, serviceAgreements: 2 };
    }

    if (window.innerWidth <= 64.1 * browserFontSize) {
      return { sharedFubs: 4, serviceAgreements: 3 };
    }

    return { sharedFubs: 6, serviceAgreements: 4 };
  };

  const serviceAgreementsToRender = () => {
    return displayAllServiceAgreements
      ? subAccountsOfCurrentMA.accounts
      : subAccountsOfCurrentMA.accounts.slice(0, SA_INITIAL_CHUNK_SIZE);
  };

  const loadAllServiceAgreements = didLoadAll => {
    if (!didLoadAll) {
      const getAccountListConfig = {
        route: `/account/${accountCode}/accountList?start=${SA_INITIAL_CHUNK_SIZE}`,
        noTimeout: true,
      };
      doGetAccountList(getAccountListConfig);
    }

    setDisplayAllServiceAgreements(!displayAllServiceAgreements);
    setAccountsList(
      accountsList.map(item =>
        item.accountCode === accountCode
          ? { ...item, displayAll: !displayAllServiceAgreements }
          : item
      )
    );
  };

  const refreshContacts = () => {
    doGetContacts({
      route: `/account/${accountCode}/contacts`,
    });
  };

  if (
    billingSummaryData.isOffline ||
    sharedPlansData.isOffline ||
    accountListData.isOffline ||
    contactsData.isOffline
  ) {
    history.push("/error");
  }

  let primaryContact = { name: {}, phone: {} };
  if (contactsData.data && contactsData.data.accountContacts) {
    primaryContact = contactsData.data.accountContacts.find(element => element.primary);
  }

  const displayShowMoreButton =
    (subAccountsOfCurrentMA &&
      accountListData.data.totalNumberOfAccounts > SA_INITIAL_CHUNK_SIZE) ||
    (subAccountsOfCurrentMA && subAccountsOfCurrentMA.total > SA_INITIAL_CHUNK_SIZE);

  return (
    <>
      {isError && (
        <StyledModalMessage
          isOpen={isError}
          message={<GetErrorDescription error={error} />}
          onRequestClose={tryAgain}
          type="error">
          <SelfcareButton variant="secondarySmall" onClick={tryAgain}>
            <FormattedMessage id="lbl.Try_Again" />
          </SelfcareButton>
        </StyledModalMessage>
      )}
      {accountName && (
        <Heading
          my={["default", "default", "medium"]}
          color="textDark"
          fontSize="title"
          textAlign="left">
          {accountName}
        </Heading>
      )}
      {isBillable && billingSummaryData.data && (
        <>
          <Box
            display={["flex", "flex", "grid"]}
            pt="default"
            sx={{
              gridGap: ["0px", "0px", "large"],
              gridTemplateColumns: ["0.75fr 0.25fr"],
              gridAutoRows: "1fr",
              flexDirection: ["column", "column", "row"],
            }}>
            <BillingTimeline
              my={["0px", "default", "default"]}
              billingSummary={billingSummaryData.data}
            />
            <MakePayment my="default" onClick={onMakePaymentClick} />
          </Box>
          <Box display={["block", "block", "flex"]}>
            {config.viewPostpaidUnbilledSummary && <ViewPostpaidUnbilledSummary />}
            {config.troubleTicket && config.troubleTicket.assistance && <RequestAssistance />}
          </Box>
        </>
      )}
      {!isBillable && (
        <Box
          display="grid"
          sx={{ gridTemplateColumns: ["1fr", "repeat(2, 1fr)", "repeat(2, 1fr)"] }}>
          <ContactDetails
            isLoading={contactsData.isLoading}
            primaryContact={primaryContact}
            refreshContacts={refreshContacts}
          />
        </Box>
      )}
      <FUBGroup
        title={intl.formatMessage({ id: "lbl.section_shared_heading" })}
        fubs={sharedPlansData.data.sharedBeneficiaryUnits}
        isLoading={sharedPlansData.isLoading}
      />
      {/* Your Services section */}
      <Flex flexDirection="column">
        <Heading
          my={["default", "default", "medium"]}
          color="textDark"
          fontSize="title"
          textAlign={["center", "left", "left"]}>
          <FormattedMessage id="lbl.sa_list_heading" />
        </Heading>
        {subAccountsOfCurrentMA && subAccountsOfCurrentMA.accounts.length === 0 && (
          <Box sx={{ textAlign: ["center", "left", "left"] }}>
            <Text color="primary">
              <FormattedMessage id="lbl.no_services" />
            </Text>
            <Text color="note">
              <FormattedMessage id="lbl.no_services_subnote" />
            </Text>
          </Box>
        )}
        <Box
          sx={{
            display: "grid",
            gridGap: "large",
            gridTemplateColumns: ["repeat(2, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"],
            gridAutoRows: "1fr",
          }}>
          {subAccountsOfCurrentMA &&
            subAccountsOfCurrentMA.accounts.length > 0 &&
            serviceAgreementsToRender().map(account => (
              <SACard key={account.accountCode} account={account} />
            ))}
          {accountListData.isLoading === true &&
            [...Array(itemsPerRow().serviceAgreements).keys()].map(index => (
              <Box
                key={index}
                bg="contentBg"
                sx={{
                  boxShadow: "light",
                  borderRadius: "card",
                }}>
                <SACardSkeleton />
              </Box>
            ))}
        </Box>
        {displayShowMoreButton && (
          <SelfcareButton
            mt="large"
            alignSelf="center"
            onClick={() => loadAllServiceAgreements(didLoadAll)}
            px="0 !important"
            variant="tertiary">
            {displayAllServiceAgreements ? (
              <FormattedMessage id="lbl.show_less" />
            ) : (
              <FormattedMessage id="lbl.show_more" />
            )}
          </SelfcareButton>
        )}

        {isMakePayment && (
          <MakePaymentModal
            isMakePayment={isMakePayment}
            onMakePaymentSuccess={() => {
              setMakePayment(false);
              setInitiatePayment(true);
            }}
            onMakePaymentClose={() => {
              setMakePayment(false);
            }}
            amount={
              !billingSummaryData.data || billingSummaryData.data.balance >= 0
                ? 0
                : intl.formatNumber(Math.abs(billingSummaryData.data.balance))
            }
            setPayment={setPayment}
          />
        )}
        {window.location.search && <HostedCcOperationResponse params={window.location.search} />}
        {window.parent.history &&
          window.parent.history.state &&
          window.parent.history.state.state &&
          window.parent.history.state.state.paymentResponse && (
            <PaymentResponseModal
              isDisplayPaymentResponse={isDisplayPaymentResponse}
              onPaymentResponseClose={() => {
                setDisplayPaymentResponse(false);
                history.push(window.parent.history.state.state.paymentResponse.responseUrl);
              }}
              response={window.parent.history.state.state.paymentResponse}
            />
          )}
      </Flex>
    </>
  );
};

export default MasterAccountLayout;
