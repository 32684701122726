import { Input, Label, Select, Textarea } from "@rebass/forms/styled-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Flex, Heading, Text } from "rebass/styled-components";
import { ACCOUNT_TYPES, PAYMENT_METHOD } from "../../common/Constants";
import { SelfcareButton } from "../../components/base";
import SelfcareRadio from "../../components/base/SelfcareRadio";
import ModalPage from "../../components/ModalPage";
import SummaryRow from "../../components/SummaryRow/SummaryRow";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { useGET, usePOST } from "../../hooks/restAPI";
import { SelfcareIcon } from "../../components/icons";
import Tooltip from "../../components/Tooltip";
import PaymentMethodSkeleton from "./PaymentMethodSkeleton";
import { FormErrorMessage } from "../../components/FormErrorMessage";
import StyledModalMessage from "../../components/StyledModalMessage";
import GetErrorDescription from "../../components/GetErrorDescription";
import { BrandingContext } from "../../contexts/BrandingContext";
import PaymentResponseModal from "./PaymentResponseModal";
import { isEmpty } from "../../common/Utilities";
import ShowPassword from "../../components/ShowPassword";

const MakePaymentModal = ({
  isMakePayment,
  onMakePaymentSuccess,
  onMakePaymentClose,
  amount,
  setPayment,
}) => {
  const { accountCode, masterAccount } = useContext(AuthContext);
  const intl = useIntl();
  const { register, handleSubmit, watch, errors, control, clearError } = useForm({
    mode: "onChange",
  });

  const { config } = useContext(BrandingContext);

  const [paymentDetailsData, doGetPaymentDetails] = useGET({
    creditCardDetails: null,
    bankAccountDetails: null,
  });
  const [bankAccountTypeMandatoryData, doGetBankAccountTypeMandatory] = useGET(true);
  const [maxAllowedAmountData, doGetMaxAllowedAmount] = useGET(9999.99);
  const [bankAccountTypesData, doGetBankAccountTypes] = useGET({});
  const [bankAccountHolderNameMandatoryData, doGetBankAccountHolderNameMandatory] = useGET(true);
  const [
    allowOverrideBankNumberOnAccountLevelData,
    doGetAllowOverrideBankNumberOnAccountLevel,
  ] = useGET(false);
  const [eCheckData, doPostECheck, resetPostECheckError] = usePOST(null);

  const [bankAccountType, setBankAccountType] = useState("");
  const [selectedBankCode, setSelectedBankCode] = useState("");
  const [paymentMethod, setPaymentMethod] = useState({});
  const [eCheckDefaults, setECheckDefaults] = useState({
    bankBranch: "",
    bankAccountNumber: "",
    bankHolderName: "",
    bankRoutingNumber: "",
  });
  const [showECheckConfirmation, setShowECheckConfirmation] = useState(false);
  const bankAccountNumberRef = React.useRef();

  useEffect(() => {
    doGetPaymentDetails({
      route: `/account/${accountCode}/payment/details`,
    });

    doGetMaxAllowedAmount({
      route: `/configuration/maxAllowedAmount`,
    });

    if (config.eCheckVisible) {
      doGetBankAccountTypeMandatory({
        route: `/configuration/bankAccountTypeMandatory`,
      });

      doGetBankAccountTypes({
        route: `/configuration/bankAccountTypes`,
      });

      doGetBankAccountHolderNameMandatory({
        route: `/configuration/bankAccountHolderNameMandatory`,
      });

      doGetAllowOverrideBankNumberOnAccountLevel({
        route: `/configuration/allowOverrideBankNumberOnAccountLevel`,
      });
    }
  }, [
    accountCode,
    doGetPaymentDetails,
    doGetBankAccountTypeMandatory,
    doGetBankAccountTypes,
    config.eCheckVisible,
    doGetBankAccountHolderNameMandatory,
    doGetAllowOverrideBankNumberOnAccountLevel,
    doGetMaxAllowedAmount,
  ]);

  const isCreditCardValid = ccDetails => {
    const expYear = ccDetails.cardExpirationYear;
    const expMonth = ccDetails.cardExpirationMonth - 1;
    const daysInExpMonth = new Date(expYear, ccDetails.cardExpirationMonth, 0).getDate();

    const expDate = Date.parse(new Date(expYear, expMonth, daysInExpMonth));
    const today = Date.parse(
      new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    );

    return today <= expDate;
  };

  const hasCreditCardDetails =
    paymentDetailsData.data.creditCardDetails &&
    paymentDetailsData.data.creditCardDetails.cardNumber !== "" &&
    isCreditCardValid(paymentDetailsData.data.creditCardDetails);

  /**
   * After initial data was loaded
   */
  useEffect(() => {
    if (paymentDetailsData.isSuccess) {
      setPaymentMethod(hasCreditCardDetails ? PAYMENT_METHOD.SAVED : PAYMENT_METHOD.NEW);
      setBankAccountType(paymentDetailsData.data.bankAccountDetails.bankAccountType);
      setSelectedBankCode(paymentDetailsData.data.bankAccountDetails.bankCode);
      setECheckDefaults({
        bankBranch: paymentDetailsData.data.bankAccountDetails.bankBranch,
        bankAccountNumber: paymentDetailsData.data.bankAccountDetails.bankAccountNumber,
        bankHolderName: paymentDetailsData.data.bankAccountDetails.bankHolderName,
        bankRoutingNumber: paymentDetailsData.data.bankAccountDetails.bankRoutingNumber,
      });
    }
  }, [paymentDetailsData, hasCreditCardDetails]);

  useEffect(() => {
    if (
      bankAccountTypesData.isSuccess &&
      bankAccountTypeMandatoryData.isSuccess &&
      isEmpty(bankAccountType) &&
      bankAccountTypeMandatoryData.data
    ) {
      setBankAccountType(bankAccountTypesData.data.banks[0].code);
    }
  }, [bankAccountType, bankAccountTypeMandatoryData, bankAccountTypesData]);

  const onMakePayment = values => {
    if (paymentMethod === PAYMENT_METHOD.E_CHECK) {
      const eCheckDetails = {
        amount: values.amount,
        bankCode: selectedBankCode,
        bankRoutingNumber: allowOverrideBankNumberOnAccountLevelData.data
          ? values.bankRoutingNumber
          : eCheckDefaults.bankRoutingNumber,
        branch: eCheckDefaults.bankBranch,
        bankAccountType: bankAccountType,
        bankAccountNumber: values.bankAccountNumber
          ? values.bankAccountNumber
          : eCheckDefaults.bankAccountNumber,
        accountHolderName: values.accountHolderName
          ? values.accountHolderName
          : eCheckDefaults.bankHolderName,
        remark: values.remark,
      };

      doPostECheck({
        route: `/account/${accountCode}/eCheck`,
        body: eCheckDetails,
      });
    } else {
      let amount = values.amount;
      setPayment({ amount, card: paymentMethod });
      onMakePaymentSuccess();
    }
  };

  /**
   * display confirmation after eCheck payment
   */
  useEffect(() => {
    if (eCheckData.isSuccess) {
      setShowECheckConfirmation(true);
    }
  }, [eCheckData.isSuccess]);

  const amountValueValidation = useCallback(() => {
    let amountVal = watch("amount");
    if (!amountVal || amountVal === 0) {
      return false;
    }

    if (
      !/^([1-9]{1}\d{0,2})(,\d{3}){0,1}(\.\d{1,2})?$|^([1-9]{1})(\d{0,5})(\.\d{1,2})?$|^([0]{0,1}\.\d{1,2})$/i.test(
        amountVal
      )
    ) {
      return "err.invalid_amount";
    }
    let cleanAmount = amountVal.replace(",", "");
    if (cleanAmount > maxAllowedAmountData.data) {
      return "err.invalid_amount";
    }

    return true;
  }, [watch, maxAllowedAmountData.data]);

  return (
    <ModalPage
      isOpen={isMakePayment}
      onRequestClose={onMakePaymentClose}
      width={["95%", "40rem", "70%"]}>
      <Box
        alignItems="center"
        display={["inline", "inline", "flex"]}
        minHeight="20rem"
        justifyContent="center">
        <Box>
          <form onSubmit={handleSubmit(onMakePayment)}>
            <Box px="medium" pt="large" pb="large" mx={["2rem", "2rem", "0rem"]}>
              <Heading
                as="h1"
                fontWeight={5}
                pb="medium"
                fontSize="title"
                color="textDark"
                textAlign="left">
                <FormattedMessage id="lbl.make_payment" />
              </Heading>
              <Box display={["block", "block", "flex"]} marginBottom="medium">
                <Text
                  color="textDark"
                  fontSize="secondary"
                  marginY="small"
                  fontWeight={4}
                  width="14rem">
                  <FormattedMessage id="lbl.enter_amount" />
                </Text>
                <Box>
                  <Input
                    defaultValue={amount}
                    ref={register({
                      validate: {
                        anyValue: amountValueValidation,
                      },
                    })}
                    variant={errors.amount ? "greyInputError" : "greyInput"}
                    maxLength="10"
                    name="amount"
                    type="text"
                    inputMode="decimal"
                    maxWidth="100%"
                  />
                  {errors.amount && errors.amount.message && (
                    <FormErrorMessage>
                      <FormattedMessage id={errors.amount.message} />
                    </FormErrorMessage>
                  )}
                </Box>
              </Box>

              {hasCreditCardDetails && (
                <Box alignItems="center" display={["block", "block", "flex"]}>
                  <Label alignItems="center" width="12rem">
                    <SelfcareRadio
                      name="savedCard"
                      id="savedCard"
                      mr="small"
                      width="1.5rem"
                      height="1.5rem"
                      onChange={() => setPaymentMethod(PAYMENT_METHOD.SAVED)}
                      checked={paymentMethod === PAYMENT_METHOD.SAVED}
                    />
                    <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
                      <FormattedMessage id="lbl.use_saved_card" />
                    </Text>
                  </Label>
                  <Flex ml="larger" alignItems="center">
                    <SummaryRow
                      my={0}
                      tooltip={intl.formatMessage({ id: "tooltip.card_type_and_number" })}
                      icon="credit-card"
                      value={
                        paymentDetailsData.data.creditCardDetails.cardType +
                        " " +
                        paymentDetailsData.data.creditCardDetails.cardNumber.substring(
                          paymentDetailsData.data.creditCardDetails.cardNumber.length - 8,
                          paymentDetailsData.data.creditCardDetails.cardNumber.length
                        )
                      }
                    />
                    <Tooltip
                      position="custom"
                      customProps={{
                        mobile: { ml: "-2rem", mt: "3.25rem" },
                        tablet: { ml: "2.5rem", mt: "0rem" },
                        desktop: { ml: "-4rem", mt: "4.5rem" },
                      }}
                      value={
                        <Box sx={{ cursor: "normal" }}>
                          <Box
                            sx={{
                              marginTop: "tiny",
                              borderBottomColor: "#cccccc",
                              borderBottomWidth: "0.01rem",
                              borderBottomStyle: "solid",
                              paddingBottom: "tiny",
                            }}>
                            <Text color="textDark" textAlign="center" fontSize="default">
                              {paymentDetailsData.data.creditCardDetails.cardHolderName}
                            </Text>
                          </Box>
                          <Box
                            sx={{
                              marginBottom: "tiny",
                              paddingTop: "tiny",
                            }}>
                            <Text color="textDark" textAlign="center" fontSize="default">
                              {paymentDetailsData.data.creditCardDetails.cardExpirationMonth +
                                "/" +
                                paymentDetailsData.data.creditCardDetails.cardExpirationYear}
                            </Text>
                          </Box>
                        </Box>
                      }>
                      <Flex ml="tiny" alignItems="center">
                        <SelfcareIcon name="info" height="2rem" />
                      </Flex>
                    </Tooltip>
                  </Flex>
                </Box>
              )}
              {paymentDetailsData.isLoading && <PaymentMethodSkeleton height="2rem" />}
              <Label alignItems="center" width="12rem">
                <SelfcareRadio
                  name="newCard"
                  id="newCard"
                  mr="small"
                  width="1.5rem"
                  height="1.5rem"
                  checked={paymentMethod === PAYMENT_METHOD.NEW}
                  onChange={() => setPaymentMethod(PAYMENT_METHOD.NEW)}
                />
                <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
                  <FormattedMessage id="lbl.use_new_card" />
                </Text>
              </Label>

              {config.eCheckVisible &&
                masterAccount.accountType === ACCOUNT_TYPES.POSTPAID &&
                paymentDetailsData.isLoading && <PaymentMethodSkeleton height="2rem" />}
              {config.eCheckVisible &&
                masterAccount.accountType === ACCOUNT_TYPES.POSTPAID &&
                paymentDetailsData.isSuccess && (
                  <Label alignItems="center" width="18rem">
                    <SelfcareRadio
                      name="eCheck"
                      id="eCheck"
                      mr="small"
                      width="1.5rem"
                      height="1.5rem"
                      checked={paymentMethod === PAYMENT_METHOD.E_CHECK}
                      onChange={() => setPaymentMethod(PAYMENT_METHOD.E_CHECK)}
                    />
                    <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
                      <FormattedMessage id="lbl.use_e_check" />
                    </Text>
                  </Label>
                )}
              {config.eCheckVisible && paymentMethod === PAYMENT_METHOD.E_CHECK && (
                <>
                  <Flex flexDirection={["column", "row", "row"]}>
                    <Text
                      color="textDark"
                      fontSize="secondary"
                      marginY="small"
                      paddingLeft="large"
                      fontWeight={4}
                      width={"14rem"}>
                      <FormattedMessage id="lbl.bank_account_type" />
                    </Text>

                    <Box flex={1} paddingLeft={["large", 0, 0]}>
                      <Select
                        disabled={paymentMethod !== PAYMENT_METHOD.E_CHECK}
                        name="bankAccountType"
                        width="100%"
                        value={bankAccountType}
                        ref={register}
                        onClick={() => {
                          clearError();
                        }}
                        onChange={event => {
                          setBankAccountType(event.target.value);
                        }}
                        variant={errors.bankAccountType ? "selectError" : "select"}
                        sx={{
                          borderColor: "greyBorderInput",
                          "&:focus": {
                            outline: "none",
                          },
                        }}>
                        {!bankAccountTypeMandatoryData.data && (
                          <option key="" value="">
                            {intl.formatMessage({ id: "lbl.none_with_parenthesis" })}
                          </option>
                        )}
                        {bankAccountTypesData.isSuccess &&
                          bankAccountTypesData.data.banks &&
                          bankAccountTypesData.data.banks.map(bank => (
                            <option key={bank.code} value={bank.code}>
                              {bank.description}
                            </option>
                          ))}
                      </Select>
                    </Box>
                  </Flex>

                  <Flex flexDirection={["column", "row", "row"]}>
                    <Text
                      color="textDark"
                      fontSize="secondary"
                      marginY="small"
                      paddingLeft="large"
                      fontWeight={4}
                      width={"14rem"}>
                      <FormattedMessage id="lbl.bank_account_number" />
                    </Text>
                    <Box flex={1} paddingLeft={["large", 0, 0]}>
                      <Controller
                        name="bankAccountNumber"
                        as={
                          <Input
                            ref={bankAccountNumberRef}
                            variant={errors.bankAccountNumber ? "greyInputError" : "greyInput"}
                            style={{ paddingRight: "2.2rem" }}
                            maxLength="25"
                            type="password"
                          />
                        }
                        rules={{
                          required: true,
                          pattern: {
                            value: /^[0-9]+$/i,
                            message: "err.bank_account_number_should_contain_digits",
                          },
                        }}
                        control={control}
                        disabled={paymentMethod !== PAYMENT_METHOD.E_CHECK}
                        defaultValue={eCheckDefaults.bankAccountNumber}
                      />
                      <ShowPassword refInput={bankAccountNumberRef} mt="-1.6rem" />
                    </Box>
                  </Flex>
                  {errors.bankAccountNumber && errors.bankAccountNumber.message && (
                    <FormErrorMessage>
                      <FormattedMessage id={errors.bankAccountNumber.message} />
                    </FormErrorMessage>
                  )}

                  <Flex flexDirection={["column", "row", "row"]}>
                    <Text
                      color="textDark"
                      fontSize="secondary"
                      marginY="small"
                      paddingLeft="large"
                      fontWeight={4}
                      width={"14rem"}>
                      <FormattedMessage id="lbl.bank_routing_number" />
                    </Text>
                    <Box flex={1} paddingLeft={["large", 0, 0]}>
                      <Input
                        ref={register({
                          required: allowOverrideBankNumberOnAccountLevelData.data,
                          pattern: {
                            value: /^[0-9]{9}$/i,
                            message: "err.bank_routing_number_should_contain_9_digits",
                          },
                        })}
                        variant={errors.bankRoutingNumber ? "greyInputError" : "greyInput"}
                        disabled={!allowOverrideBankNumberOnAccountLevelData.data}
                        defaultValue={eCheckDefaults.bankRoutingNumber}
                        maxLength="9"
                        name="bankRoutingNumber"
                        type="text"
                      />
                    </Box>
                  </Flex>
                  {errors.bankRoutingNumber && errors.bankRoutingNumber.message && (
                    <FormErrorMessage>
                      <FormattedMessage id={errors.bankRoutingNumber.message} />
                    </FormErrorMessage>
                  )}

                  <Flex flexDirection={["column", "row", "row"]}>
                    <Text
                      color="textDark"
                      fontSize="secondary"
                      paddingLeft="large"
                      marginY="small"
                      fontWeight={4}
                      width={"14rem"}>
                      <FormattedMessage id="lbl.account_holder_name" />
                    </Text>
                    <Box flex={1} paddingLeft={["large", 0, 0]}>
                      <Input
                        disabled={paymentMethod !== PAYMENT_METHOD.E_CHECK}
                        defaultValue={eCheckDefaults.bankHolderName}
                        ref={register({
                          required:
                            paymentMethod === PAYMENT_METHOD.E_CHECK &&
                            bankAccountHolderNameMandatoryData.data,
                        })}
                        variant={errors.accountHolderName ? "greyInputError" : "greyInput"}
                        maxLength="80"
                        name="accountHolderName"
                        type="text"
                      />
                    </Box>
                  </Flex>

                  <Flex flexDirection={["column", "row", "row"]}>
                    <Text
                      color="textDark"
                      fontSize="secondary"
                      marginY="small"
                      paddingLeft="large"
                      fontWeight={4}
                      width={"14rem"}>
                      <FormattedMessage id="lbl.remark" />
                    </Text>
                    <Box flex={1} paddingLeft={["large", 0, 0]}>
                      <Textarea
                        ref={register}
                        disabled={paymentMethod !== PAYMENT_METHOD.E_CHECK}
                        defaultValue={""}
                        variant={errors.remark ? "greyInputError" : "greyInput"}
                        maxLength="1000"
                        height="4rem"
                        name="remark"
                      />
                    </Box>
                  </Flex>
                </>
              )}

              <Flex mt="larger" justifyContent="space-between">
                <SelfcareButton
                  disabled={eCheckData.isLoading}
                  type="submit"
                  variant="default"
                  mr="spaceBetweenButtons">
                  <FormattedMessage id="lbl.proceed" />
                </SelfcareButton>

                <SelfcareButton
                  disabled={eCheckData.isLoading}
                  variant="default-inverted"
                  onClick={onMakePaymentClose}>
                  <FormattedMessage id="lbl.cancel" />
                </SelfcareButton>
              </Flex>
            </Box>
          </form>
        </Box>
      </Box>

      {eCheckData.isError && (
        <StyledModalMessage
          isOpen={eCheckData.isError}
          message={<GetErrorDescription error={eCheckData.error} />}
          onRequestClose={resetPostECheckError}
          type="error">
          <SelfcareButton variant="secondarySmall" onClick={resetPostECheckError}>
            <FormattedMessage id="lbl.Try_Again" />
          </SelfcareButton>
        </StyledModalMessage>
      )}

      <PaymentResponseModal
        isDisplayPaymentResponse={showECheckConfirmation}
        onPaymentResponseClose={() => {
          setShowECheckConfirmation(false);
          onMakePaymentClose();
        }}
        response={{
          amount: eCheckData.data ? eCheckData.data.amount : 0,
          paymentNumber: eCheckData.data ? eCheckData.data.paymentId : -1,
          paymentMethod: intl.formatMessage({ id: "lbl.e_check" }),
        }}
        successMessageId="lbl.payment_registered"
      />
    </ModalPage>
  );
};

export default MakePaymentModal;
