import React, { useState } from "react";
import { Flex, Text } from "rebass/styled-components";
import { useIntl } from "react-intl";

import EditBankAccountDetailsModal from "./EditBankAccountDetailsModal";
import BankAccountDetailsSkeleton from "./BankAccountDetailsSkeleton";
import SummaryLayout from "../../containers/SummaryLayout";
import SummaryRow from "../../components/SummaryRow/SummaryRow";
import { maskString } from "../../common/Utilities";

const BankAccountDetails = ({ details, isLoading, onEditSuccess }) => {
  const intl = useIntl();
  const [isEdit, setEdit] = useState(false);

  if (isLoading || !details.bankAccountDetails) {
    return <SummaryLayout isLoading skeleton={BankAccountDetailsSkeleton} />;
  }

  if (
    details.bankAccountDetails.bankAccountNumber === null ||
    details.bankAccountDetails.bankAccountNumber === undefined ||
    details.bankAccountDetails.bankAccountNumber === ""
  ) {
    return (
      <SummaryLayout
        title="lbl.bank_account_information"
        showAdd={details.allowedToEditBankDetails}
        onAdd={() => setEdit(true)}>
        <Flex my="small" minHeight="2rem" alignItems="center">
          <Text textAlign="left" color="note" fontSize="secondary">
            {intl.formatMessage({ id: "lbl.no_bank_account" })}
          </Text>
        </Flex>

        {isEdit && (
          <EditBankAccountDetailsModal
            details={details}
            isOpen={isEdit}
            onClose={() => setEdit(false)}
            onEditSuccess={() => {
              setEdit(false);
              onEditSuccess();
            }}
          />
        )}
      </SummaryLayout>
    );
  }

  return (
    <SummaryLayout
      title="lbl.bank_account_information"
      showEdit={details.allowedToEditBankDetails}
      onEdit={() => setEdit(true)}>
      <SummaryRow
        tooltip={intl.formatMessage({ id: "tooltip.account_holder_name" })}
        icon="name"
        value={details.bankAccountDetails.bankHolderName}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "tooltip.bank_account_type" })}
        icon="bank-account-type"
        value={details.bankAccountDetails.bankAccountTypeDescription}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "tooltip.bank_account_number" })}
        icon="bank-account-number"
        value={maskString(details.bankAccountDetails.bankAccountNumber)}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "tooltip.bank_routing_number" })}
        icon="bank-account-routing-number"
        value={details.bankAccountDetails.bankRoutingNumber}
      />

      {isEdit && (
        <EditBankAccountDetailsModal
          details={details}
          isOpen={isEdit}
          onClose={() => setEdit(false)}
          onEditSuccess={() => {
            setEdit(false);
            onEditSuccess();
          }}
        />
      )}
    </SummaryLayout>
  );
};

export default BankAccountDetails;
