import React from "react";
import ContentLoader from "react-content-loader";

const PaymentMethodSkeleton = ({ ...props }) => (
  <ContentLoader
    {...props}
    speed={2}
    viewBox="0 0 200 32"
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">
    <rect id="Rectangle_1209-2_1_" x="0" width="200" height="32" />
  </ContentLoader>
);

export default PaymentMethodSkeleton;
