import { baseTheme, invertedTheme } from "./themes";
import logo from "../resources/images/logo.svg";
import logo_inverted from "../resources/images/logo_footer.svg";

export const Providers = {
  base: {
    theme: baseTheme,
    logo: logo,
    logoAlt: logo_inverted,
    config: {
      phoneNumber: "+972-4-993-6666",
      website: "https://www.mindcti.com",
      terms_and_conditions: "https://www.mindcti.com/terms-and-conditions/",
      limitAccessByProvider: false,
      themeSwitch: true,
      updatePackage: true,
      eCheckVisible: true,
      includeNBCallsVisible: true,
      identityTypeAndNoVisible: true,
      receiveMarketingDetailsVisible: true,
      viewPostpaidUnbilledSummary: true,
      mobilePhoneNumberVisible: true,
      troubleTicket: {
        assistance: {
          area: "SELFCARE_ASSIST",
          role: "BILLING",
          type: "SELF_AGENT",
        },
        service: {
          area: "SELFCARE_SRVC",
          role: "BILLING",
          type: "SELF_SGENT",
        },
        upgrade: {
          area: "SELFCARE_UPGRD",
          role: "BILLING",
          type: "SELF_UGENT",
        },
      },
    },
  },
  inverted: {
    theme: invertedTheme,
    logo: logo_inverted,
    logoAlt: logo,
    config: {
      phoneNumber: "+972-4-993-6666",
      website: "https://www.mindcti.com",
      terms_and_conditions: "https://www.mindcti.com/terms-and-conditions/",
      limitAccessByProvider: false,
      themeSwitch: true,
      updatePackage: true,
      eCheckVisible: true,
      includeNBCallsVisible: true,
      identityTypeAndNoVisible: true,
      receiveMarketingDetailsVisible: true,
      viewPostpaidUnbilledSummary: true,
      mobilePhoneNumberVisible: true,
      troubleTicket: {
        assistance: {
          area: "SELFCARE_ASSIST",
          role: "BILLING",
          type: "SELF_AGENT",
        },
        service: {
          area: "SELFCARE_SRVC",
          role: "BILLING",
          type: "SELF_SGENT",
        },
        upgrade: {
          area: "SELFCARE_UPGRD",
          role: "BILLING",
          type: "SELF_UGENT",
        },
      },
    },
  },
  ml: {
    theme: baseTheme,
    logo: require("../resources/images/logos/metalink_logo.png"),
    config: {
      phoneNumber: "(888) 999-8002 (Toll Free)",
      website: "https://metalink.net",
      terms_and_conditions:
        "https://www.metalink.net/images/regulatory/Internet-Access-and-Service-Agreement.pdf",
      limitAccessByProvider: true,
      themeSwitch: false,
      updatePackage: false,
      eCheckVisible: true,
      includeNBCallsVisible: false,
      identityTypeAndNoVisible: false,
      receiveMarketingDetailsVisible: false,
      viewPostpaidUnbilledSummary: false,
      mobilePhoneNumberVisible: false,
      troubleTicket: {
        assistance: {
          area: "SELFCARE_ASSIST",
          role: "BILLING",
          type: "SELF_AGENT",
        },
        service: {
          area: "SELFCARE_SRVC",
          role: "BILLING",
          type: "SELF_SGENT",
        },
        upgrade: {
          area: "SELFCARE_UPGRD",
          role: "BILLING",
          type: "SELF_UGENT",
        },
      },
    },
  },
  saa: {
    theme: baseTheme,
    logo: require("../resources/images/logos/saaLogo.png"),
    config: {
      phoneNumber: "1-888-438-8436",
      website: "https://www.saa.net",
      terms_and_conditions: "http://www.bright.net/terms/",
      limitAccessByProvider: true,
      themeSwitch: false,
      updatePackage: false,
      eCheckVisible: true,
      includeNBCallsVisible: false,
      identityTypeAndNoVisible: false,
      receiveMarketingDetailsVisible: false,
      viewPostpaidUnbilledSummary: false,
      mobilePhoneNumberVisible: false,
      troubleTicket: {
        assistance: {
          area: "SELFCARE_ASSIST",
          role: "BILLING",
          type: "SELF_AGENT",
        },
        service: {
          area: "SELFCARE_SRVC",
          role: "BILLING",
          type: "SELF_SGENT",
        },
        upgrade: {
          area: "SELFCARE_UPGRD",
          role: "BILLING",
          type: "SELF_UGENT",
        },
      },
    },
  },
  atc: {
    theme: baseTheme,
    logo: require("../resources/images/logos/ATCo_logo.png"),
    config: {
      phoneNumber: "419-395-2222",
      website: "https://www.ayersvillecomm.com",
      terms_and_conditions:
        "https://ayersvillecomm.com/wp-content/uploads/BBS-Customer-Agreement.pdf",
      limitAccessByProvider: true,
      themeSwitch: false,
      updatePackage: false,
      eCheckVisible: true,
      includeNBCallsVisible: false,
      identityTypeAndNoVisible: false,
      receiveMarketingDetailsVisible: false,
      viewPostpaidUnbilledSummary: false,
      mobilePhoneNumberVisible: false,
      troubleTicket: {
        assistance: {
          area: "SELFCARE_ASSIST",
          role: "BILLING",
          type: "SELF_AGENT",
        },
        service: {
          area: "SELFCARE_SRVC",
          role: "BILLING",
          type: "SELF_SGENT",
        },
        upgrade: {
          area: "SELFCARE_UPGRD",
          role: "BILLING",
          type: "SELF_UGENT",
        },
      },
    },
  },
};

export const defaultProvider = window._env_ ? window._env_.PROVIDER : "base";
