import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useGET } from "../../hooks/restAPI";
import { Box, Flex, Heading, Link, Text } from "rebass/styled-components";
import PackageNameSkeleton from "./PackageNameSkeleton";
import { SelfcareBox, SelfcareButton } from "../base";
import FUBGroup from "../FUBGroup";
import SASummary from "../SASummary";
import ServiceAgreementPackage from "../ServiceAgreementPackage";
import SubscriberInformation from "../SubscriberInformation";
import UsageDetailsModal from "../UsageDetailsModal";
import ViewPostpaidUnbilledSummary from "../ViewPostpaidUnbilledSummary";
import ViewUsageDetailsButton from "../ViewUsageDetailsButton";
import { getDaysUntilNextBP } from "../../common/Utilities";
import { BrandingContext } from "../../contexts/BrandingContext";
import OpenTroubleTicket from "../OpenTroubleTicket";

const ServiceAgreementPostpaid = ({
  account,
  accountIsLoading,
  beneficiaryUnits,
  beneficiaryUnitsIsLoading,
  additionalSrv,
  configuration,
  ...props
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { config } = useContext(BrandingContext);

  const [displayUsageDetailsModal, setDisplayUsageDetailsModal] = useState(false);
  const [isOpenTroubleTicketModal, setOpenTroubleTicketModal] = useState(false);
  const [hasUsageRelatedServices, getHasUsageRelatedServices] = useGET({});

  const fubExpirationMessage = fub => {
    const serviceExpirationDate = new Date(fub.serviceExpirationDate);
    let daysUntilRenew = getDaysUntilNextBP(account.billingPeriod.toDate);

    if (serviceExpirationDate.getFullYear() === 2050) {
      if (daysUntilRenew === 0) {
        return intl.formatMessage({ id: "lbl.renews_today" });
      }
      if (daysUntilRenew === 1) {
        return intl.formatMessage({ id: "lbl.renews_tomorrow" }, { days: daysUntilRenew });
      }
      return intl.formatMessage({ id: "lbl.renews_in_future" }, { days: daysUntilRenew });
    } else {
      if (daysUntilRenew === 0) {
        return intl.formatMessage({ id: "lbl.expires_today" });
      }
      if (daysUntilRenew === 1) {
        return intl.formatMessage({ id: "lbl.expires_tomorrow" }, { days: daysUntilRenew });
      }
      return intl.formatMessage({ id: "lbl.expires_in_future" }, { days: daysUntilRenew });
    }
  };

  const setUsageDetailsModalVisibility = visible => {
    setDisplayUsageDetailsModal(visible);
  };

  useEffect(() => {
    account.accountCode &&
      getHasUsageRelatedServices({
        route: `/account/${account.accountCode}/plan/hasUsageRelatedServices`,
      });
  }, [getHasUsageRelatedServices, account]);

  return (
    <Box {...props}>
      <SelfcareBox variant="grid" mb="large">
        <SASummary account={account} isLoading={accountIsLoading || !account} />
        <SubscriberInformation account={account} isLoading={accountIsLoading || !account} />
      </SelfcareBox>

      <Box display={["block", "block", "flex"]} mb="default">
        {config.viewPostpaidUnbilledSummary && (
          <ViewPostpaidUnbilledSummary saAccountCode={account.accountCode} />
        )}
        {account.accountCode &&
          hasUsageRelatedServices.isSuccess &&
          hasUsageRelatedServices.data && (
            <ViewUsageDetailsButton onClick={() => setDisplayUsageDetailsModal(true)} />
          )}
      </Box>

      {(accountIsLoading || !account) && <PackageNameSkeleton width="100%" mb="medium" />}
      {!accountIsLoading &&
        account &&
        additionalSrv &&
        !additionalSrv.isLoading &&
        additionalSrv.data &&
        additionalSrv.data.product && (
          <Flex
            flexDirection={["column", "row", "row"]}
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="flex-start"
            mb="large">
            <Heading color="textDark" fontSize="title" mt="default" mb="small" mr="default">
              {additionalSrv.data.product.description}
            </Heading>
            <Flex flexWrap={"wrap"} flexDirection="row">
              {account.accountStatus === "ACTIVE" && config.updatePackage && (
                <SelfcareButton
                  alignSelf={["center", "center", ""]}
                  variant="default"
                  sx={{ mr: "default", mt: "default" }}
                  onClick={() => {
                    history.push(`/account/${account.accountCode}/updatePackage`, {
                      additionalSrv: additionalSrv,
                      account: account,
                    });
                  }}>
                  <Text>
                    <FormattedMessage id="lbl.update" />
                  </Text>
                </SelfcareButton>
              )}
              {additionalSrv.data.product && additionalSrv.data.product.packageOverview && (
                <SelfcareButton
                  alignSelf={["center", "center", ""]}
                  variant="default"
                  sx={{ mr: "default", mt: "default" }}
                  style={{ width: "13rem" }}
                  onClick={() => {
                    document.getElementById("package-overview-link").click();
                  }}>
                  <Text>
                    <FormattedMessage id="lbl.package_overview" />
                  </Text>
                  <Link
                    display="none"
                    id="package-overview-link"
                    href={additionalSrv.data.product.packageOverview}
                    target="_blank"
                    fontSize="primary"
                    color="textLight"
                    sx={{
                      textDecoration: "none",
                    }}
                  />
                </SelfcareButton>
              )}
              {account.accountStatus === "ACTIVE" &&
                config.troubleTicket &&
                config.troubleTicket.upgrade && (
                  <>
                    <SelfcareButton
                      alignSelf={["center", "center", ""]}
                      variant="default"
                      sx={{ mr: "default", mt: "default" }}
                      style={{ width: "13rem" }}
                      onClick={() => {
                        setOpenTroubleTicketModal(true);
                      }}>
                      <Text>
                        <FormattedMessage id="lbl.request_upgrade" />
                      </Text>
                    </SelfcareButton>
                    <OpenTroubleTicket
                      config={config.troubleTicket.upgrade}
                      title={<FormattedMessage id="lbl.request_upgrade" />}
                      placeholderMessage={intl.formatMessage({
                        id: "lbl.trouble_ticket_inquiry_comments_placeholder",
                      })}
                      isOpenModal={isOpenTroubleTicketModal}
                      setOpenModal={setOpenTroubleTicketModal}
                    />
                  </>
                )}
            </Flex>
          </Flex>
        )}

      {(beneficiaryUnitsIsLoading || !beneficiaryUnits) && <FUBGroup isLoading />}
      {!beneficiaryUnitsIsLoading &&
        beneficiaryUnits &&
        account &&
        account.accountStatus === "ACTIVE" && (
          <>
            <FUBGroup
              title={intl.formatMessage({ id: "lbl.mandatory_and_optional_plans" })}
              titleSize="primary"
              fubs={beneficiaryUnits && beneficiaryUnits.filter(fub => !fub.shared)}
              showExpirationDate
              computeExpirationDateMessage={fubExpirationMessage}
            />
            <FUBGroup
              title={intl.formatMessage({ id: "lbl.shared_plans" })}
              titleSize="primary"
              fubs={beneficiaryUnits && beneficiaryUnits.filter(fub => fub.shared)}
              showExpirationDate
              computeExpirationDateMessage={fubExpirationMessage}
            />
          </>
        )}
      {account && account.accountStatus === "ACTIVE" && (
        <ServiceAgreementPackage additionalSrv={additionalSrv} configuration={configuration} />
      )}

      <UsageDetailsModal
        displayUsageDetailsModal={displayUsageDetailsModal}
        onModalClose={() => setDisplayUsageDetailsModal(false)}
        setUsageDetailsModalVisibility={setUsageDetailsModalVisibility}
      />
    </Box>
  );
};

export default ServiceAgreementPostpaid;
