import React, { useEffect } from "react";
import { Box, Flex } from "rebass/styled-components";
import { useHistory, useLocation } from "react-router-dom";

import NavigationMenuItem from "../NavigationMenuItem";

const Separator = props => (
  <Box
    {...props}
    height="100%"
    sx={{
      borderRight: 1,
      borderRightColor: "inactive",
    }}
  />
);

const NavigationMenu = ({ menuItems, disableNavMenuDefaultLocation, ...props }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!disableNavMenuDefaultLocation) {
      let defaultLocation;
      for (let i = 0; i < menuItems.length; i++) {
        if (!defaultLocation) {
          defaultLocation = menuItems[i].location;
        }
        if (location.pathname.includes(menuItems[i].location)) {
          return;
        }
      }
      history.replace(defaultLocation);
    }
  }, [disableNavMenuDefaultLocation, history, location.pathname, menuItems]);

  return (
    <Box display={["none", "inline", "inline"]} {...props}>
      <Flex
        {...props}
        bg="contentBg"
        sx={{
          boxShadow: "light",
          borderRadius: "card",
        }}>
        <Flex width="100%" height="4.875rem" alignItems="center" justifyContent="center" mx="auto">
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <NavigationMenuItem
                p="small"
                width={1 / menuItems.length}
                icon={item.icon.desktop}
                titleLanguageID={item.title}
                isActive={
                  (location.pathname === item.location && item.location === "/") ||
                  (location.pathname.startsWith(item.location) && item.location !== "/")
                }
                onClick={() => location.pathname !== item.location && history.push(item.location)}
                textAlign="center"
                items={menuItems.length}
              />
              {index < menuItems.length - 1 && <Separator />}
            </React.Fragment>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavigationMenu;
