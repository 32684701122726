import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import MasterAccountLayout from "../../components/MasterAccountLayout";
import ServiceAgreementLayout from "../../components/ServiceAgreementLayout";
import Summary from "../Summary";
import Layout from "../../containers/Layout";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { useGET } from "../../hooks/restAPI";
import HostedCcOperationRequest from "../../pages/HostedCcOperationRequest";

const saveHierarchyToLocalStorage = (newHierarchyNode, accountCode) => {
  let hierarchy = JSON.parse(window.localStorage.getItem("hierarchy"));

  if (hierarchy.some(acct => acct.code === accountCode)) {
    const currentAccount = hierarchy.find(acct => acct.code === accountCode);
    hierarchy = hierarchy.slice(0, hierarchy.indexOf(currentAccount) + 1);
  } else {
    hierarchy = [...hierarchy, newHierarchyNode];
  }

  window.localStorage.setItem("hierarchy", JSON.stringify(hierarchy));
};

const AccountBasePage = props => {
  const history = useHistory();
  const [account, setAccount] = useState(null);
  const { setCurrentAccount } = useContext(AuthContext);
  const { accountCode } = useParams();
  const [accountData, doGetAccount] = useGET(null);
  const [initiatePayment, setInitiatePayment] = useState(false);
  const [payment, setPayment] = useState({ amount: 0, card: 0 });

  useEffect(() => {
    if (props.location.state && props.location.state.account) {
      setAccount(props.location.state.account);
      setCurrentAccount(true, props.location.state.account);
      saveHierarchyToLocalStorage(
        {
          code: props.location.state.account.accountCode,
          title: props.location.state.account.accountName,
        },
        accountCode
      );
    } else {
      doGetAccount({
        route: `/account/${accountCode}`,
      });
    }
  }, [accountCode, doGetAccount, props.location.state, setCurrentAccount]);

  useEffect(() => {
    if (accountData && accountData.isSuccess) {
      setAccount(accountData.data);
      setCurrentAccount(true, accountData.data);
      saveHierarchyToLocalStorage(
        {
          code: accountData.data.accountCode,
          title: accountData.data.accountName,
        },
        accountData.data.accountCode
      );
    }
  }, [accountData, setCurrentAccount]);

  if (accountData && accountData.isOffline) {
    history.push("/error");
  }

  if (!account) {
    return null;
  }

  const isMasterAccount =
    account.masterAccount !== undefined && account.masterAccount !== null
      ? account.masterAccount
      : !account.serviceAgreement;

  return (
    <>
      {!initiatePayment && (
        <Layout disableNavMenuDefaultLocation={true} isMasterAccount={isMasterAccount}>
          {isMasterAccount && account.billable && <Summary />}
          {isMasterAccount && (
            <MasterAccountLayout
              isBillable={account.billable}
              accountCode={account.accountCode}
              accountName={account.accountName}
              setPayment={setPayment}
              setInitiatePayment={setInitiatePayment}
            />
          )}
          {!isMasterAccount && (
            <ServiceAgreementLayout
              accountName={account.accountName}
              accountType={account.accountType}
              accountCode={account.accountCode}
            />
          )}
        </Layout>
      )}
      {initiatePayment && (
        <HostedCcOperationRequest
          amount={payment.amount}
          card={payment.card}
          responseUrl={encodeURIComponent(window.location.pathname)}
        />
      )}
    </>
  );
};

export default AccountBasePage;
