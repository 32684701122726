import React, { useContext } from "react";
import { motion } from "framer-motion";
import { useIntl } from "react-intl";
import { ThemeContext } from "styled-components";
import { BaseIcon } from "../icons";

const FUBProgressCircle = ({ remaining, total, unit, size = 90, responsive = false }) => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);

  const stroke = theme.colors.secondary;
  const emptyStroke = theme.colors.inactive;
  const strokeWidth = 6;
  const emptyStrokeWidth = 4;
  const svgSize = responsive ? "100%" : size;
  const percents = total === 0 ? 0 : Math.round((remaining * 100) / total);
  const radius = 45;
  const circumference = Math.ceil(2 * Math.PI * radius);
  const fillPercents = Math.abs(Math.ceil((circumference / 100) * (percents - 100)));

  const transition = {
    duration: 1,
    delay: 0.5,
    ease: "easeIn",
  };
  const variants = {
    hidden: {
      strokeDashoffset: circumference,
      transition,
    },
    show: {
      strokeDashoffset: fillPercents,
      transition,
    },
  };

  const isUnlimited = total === -1;

  return (
    <svg
      viewBox="0 0 100 100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={svgSize}
      height={svgSize}>
      <circle
        cx="50"
        cy="50"
        r={radius}
        strokeWidth={emptyStrokeWidth}
        stroke={emptyStroke}
        strokeOpacity={0.25}
        fill="transparent"
        transform="rotate(-90 50 50)"
      />
      <motion.circle
        cx="50"
        cy="50"
        r={radius}
        strokeWidth={strokeWidth}
        stroke={stroke}
        fill="transparent"
        strokeDashoffset={fillPercents}
        strokeDasharray={circumference}
        variants={variants}
        initial="hidden"
        animate="show"
        transform="rotate(-90 50 50)"
      />
      {!isUnlimited && (
        <text x="50%" y="45%" textAnchor="middle" dy=".3em">
          <tspan x="50%" fontSize={theme.fontSizes.default} fill={stroke}>
            {remaining}
          </tspan>
          <tspan x="50%" y="65%" fontSize={theme.fontSizes.subnote} fill={theme.colors.note}>
            {intl.formatMessage({ id: "lbl.fub_out_of_total" }, { total: total, unit: unit })}
          </tspan>
        </text>
      )}
      {isUnlimited && <BaseIcon name="unlimited" x="30%" y="40%" />}
    </svg>
  );
};

export default FUBProgressCircle;
