import React from "react";
export default ({ shackle = "#346eb3", body = "#318eff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.806"
    height="25.3"
    viewBox="0 0 23.806 25.3"
    {...props}>
    <path
      fill={shackle}
      d="M5.7,10.9H4.3V7.7A7.683,7.683,0,0,1,11.9,0a7.768,7.768,0,0,1,7.7,7.7v3.2H18.2V7.7a6.25,6.25,0,1,0-12.5,0Z"
    />
    <path
      fill={body}
      d="M21.4,10.5H2.5A2.476,2.476,0,0,0,0,13v9.8a2.476,2.476,0,0,0,2.5,2.5H21.3a2.476,2.476,0,0,0,2.5-2.5V13A2.336,2.336,0,0,0,21.4,10.5ZM12.9,24.7h-2V22.8h2Zm0-4.8v.9h-2V18.1h1a2.5,2.5,0,1,0-2.5-2.5v.2H7.5v-.2a4.4,4.4,0,1,1,8.8,0A4.1,4.1,0,0,1,12.9,19.9Z"
    />
  </svg>
);
