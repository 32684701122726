import React, { useEffect, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams, useHistory } from "react-router-dom";

import Summary from "../Summary";
import AutomaticPaymentSettings from "../../components/AutomaticPaymentSettings";
import BankAccountDetails from "../../components/BankAccountDetails";
import CreditCardDetails from "../../components/CreditCardDetails";
import StyledModalMessage from "../../components/StyledModalMessage";
import GetErrorDescription from "../../components/GetErrorDescription";
import { useGET } from "../../hooks/restAPI";
import { AuthContext } from "../../contexts/AuthorizationContext";
import { SelfcareButton, SelfcareBox } from "../../components/base";
import HostedCcOperationRequest from "../HostedCcOperationRequest";

const PaymentInformation = () => {
  const history = useHistory();
  const { accountCode: masterAccountCode } = useContext(AuthContext);
  const { accountCode: accountCodePath } = useParams();
  const [isEditCard, setEditCard] = useState(false);

  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;

  const [paymentDetailsData, doGetPaymentDetails, resetGetPaymentDetailsError] = useGET({
    creditCardDetails: null,
    bankAccountDetails: null,
  });
  const [paymentSettingsData, doGetPaymentSettings, resetGetPaymentSettingsError] = useGET(null);

  useEffect(() => {
    const getPaymentDetailsConfig = {
      route: `/account/${accountCode}/payment/details`,
    };
    doGetPaymentDetails(getPaymentDetailsConfig);
  }, [accountCode, doGetPaymentDetails]);

  useEffect(() => {
    const getPaymentSettingsConfig = {
      route: `/account/${accountCode}/payment/settings`,
    };
    doGetPaymentSettings(getPaymentSettingsConfig);
  }, [accountCode, doGetPaymentSettings]);

  const refreshPaymentDetails = () => {
    const getPaymentDetailsConfig = {
      route: `/account/${accountCode}/payment/details`,
    };
    doGetPaymentDetails(getPaymentDetailsConfig);
  };

  const refreshPaymentSettings = () => {
    const getPaymentSettingsConfig = {
      route: `/account/${accountCode}/payment/settings`,
    };
    doGetPaymentSettings(getPaymentSettingsConfig);
  };

  const tryAgain = () => {
    resetGetPaymentDetailsError();
    resetGetPaymentSettingsError();

    const getPaymentDetailsConfig = {
      route: `/account/${accountCode}/payment/details`,
    };
    doGetPaymentDetails(getPaymentDetailsConfig);

    const getPaymentSettingsConfig = {
      route: `/account/${accountCode}/payment/settings`,
    };
    doGetPaymentSettings(getPaymentSettingsConfig);
  };

  const isCreditCardValid = ccDetails => {
    const expYear = ccDetails.cardExpirationYear;
    const expMonth = ccDetails.cardExpirationMonth - 1;
    const daysInExpMonth = new Date(expYear, ccDetails.cardExpirationMonth, 0).getDate();

    const expDate = Date.parse(new Date(expYear, expMonth, daysInExpMonth));
    const today = Date.parse(
      new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    );

    return today <= expDate;
  };

  if (paymentDetailsData.isOffline || paymentSettingsData.isOffline) {
    history.push("/error");
  }

  const hasCreditCardDetails =
    paymentDetailsData.data.creditCardDetails &&
    paymentDetailsData.data.creditCardDetails.cardNumber !== "" &&
    isCreditCardValid(paymentDetailsData.data.creditCardDetails);
  const hasBankAccountDetails =
    paymentDetailsData.data.bankAccountDetails &&
    paymentDetailsData.data.bankAccountDetails.bankAccountNumber !== "";
  const isError = paymentDetailsData.isError || paymentSettingsData.isError;
  const error = paymentDetailsData.isError ? paymentDetailsData.error : paymentSettingsData.error;

  return (
    <>
      {!isEditCard && (
        <Summary>
          {isError && (
            <StyledModalMessage
              isOpen={isError}
              message={<GetErrorDescription error={error} />}
              onRequestClose={tryAgain}
              type="error">
              <SelfcareButton variant="secondarySmall" onClick={tryAgain}>
                <FormattedMessage id="lbl.Try_Again" />
              </SelfcareButton>
            </StyledModalMessage>
          )}
          {!isError && (
            <SelfcareBox variant="grid">
              <CreditCardDetails
                details={paymentDetailsData.data.creditCardDetails}
                isLoading={paymentDetailsData.isLoading}
                isEditCard={isEditCard}
                setEditCard={setEditCard}
              />
              <BankAccountDetails
                details={paymentDetailsData.data}
                isLoading={paymentDetailsData.isLoading}
                onEditSuccess={refreshPaymentDetails}
              />
              <AutomaticPaymentSettings
                settings={paymentSettingsData.data}
                isLoading={paymentDetailsData.isLoading || paymentSettingsData.isLoading}
                hasCreditCardDetails={hasCreditCardDetails}
                hasBankAccountDetails={hasBankAccountDetails}
                onEditSuccess={refreshPaymentSettings}
              />
            </SelfcareBox>
          )}
        </Summary>
      )}
      {isEditCard && (
        <HostedCcOperationRequest
          amount={0}
          card={0}
          responseUrl={encodeURIComponent(window.location.pathname)}
        />
      )}
    </>
  );
};

export default PaymentInformation;
