import React from "react";
export default ({ color = "#b5b5b5", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.58"
    height="26"
    viewBox="0 0 20.58 26"
    {...props}>
    <path fill={color} d="M20.58,13l-2.7-2.17v1.09H3.25v2.16H17.88v1.09Z" />
    <path fill={color} d="M18.42,24.92H1.08V1.08H18.42V9.75H19.5V0H0V26H19.5V16.25H18.42Z" />
  </svg>
);
