import React from "react";
export default ({ coin = "#318eff", denomination = "#114878", arrows = "#346eb3", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.589"
    height="25.869"
    viewBox="0 0 25.589 25.869"
    {...props}>
    <g transform="translate(4.431 4.525)">
      <path
        fill={coin}
        d="M13.11,4.8a8.41,8.41,0,1,0,8.41,8.41A8.435,8.435,0,0,0,13.11,4.8Z"
        transform="translate(-4.7 -4.8)"
      />
      <path
        fill={denomination}
        d="M14.238,12.635c-1.4-1.121-1.775-1.775-1.775-2.616,0-.934.374-1.308,1.028-1.308s1.028.374,1.028,1.308v.561H16.48v-.374c0-1.775-.841-2.9-2.243-3.177V6h-1.5v.934c-1.4.28-2.243,1.4-2.243,3.177,0,1.5.561,2.523,2.243,3.831,1.4,1.028,1.775,1.775,1.775,2.616,0,.934-.374,1.215-1.028,1.215s-1.028-.374-1.028-1.215V15.531H10.5v.841c0,1.775.841,2.9,2.243,3.177v.934h1.5v-.934c1.4-.28,2.243-1.4,2.243-3.177C16.48,14.971,15.92,13.943,14.238,12.635Z"
        transform="translate(-5.08 -4.879)"
      />
    </g>
    <path
      fill="none"
      stroke={arrows}
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2px"
      d="M.7,5.9V9.451L4.064,8.61"
      transform="translate(-0.007 -0.347)"
    />
    <g transform="translate(0.6 0.6)">
      <path
        fill="none"
        stroke={arrows}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.2px"
        d="M25.035,12.841A12.26,12.26,0,0,0,1.3,8.543"
        transform="translate(-0.646 -0.6)"
      />
      <path
        fill="none"
        stroke={arrows}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.2px"
        d="M.6,13.9a12.26,12.26,0,0,0,23.735,4.3"
        transform="translate(-0.6 -1.472)"
      />
    </g>
    <path
      fill="none"
      stroke={arrows}
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2px"
      d="M26.364,21.451V17.9L23,18.741"
      transform="translate(-1.468 -1.134)"
    />
  </svg>
);
