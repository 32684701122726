import React, { useContext, useEffect, useState } from "react";
import { Box, Flex, Heading, Text } from "rebass/styled-components";
import { Input, Select } from "@rebass/forms/styled-components";
import { useGET, usePUT } from "../../hooks/restAPI";
import { SelfcareButton } from "../../components/base";
import ModalPage from "../../components/ModalPage";
import { FormattedMessage, useIntl } from "react-intl";
import { AuthContext } from "../../contexts/AuthorizationContext";
import StyledModalMessage from "../../components/StyledModalMessage";
import GetErrorDescription from "../../components/GetErrorDescription";
import { FormErrorMessage } from "../../components/FormErrorMessage";
import { Controller, useForm } from "react-hook-form";
import ShowPassword from "../../components/ShowPassword";
import { useParams } from "react-router-dom";
import { BrandingContext } from "../../contexts/BrandingContext";

const OWN_KEY = "OWN";

const EditAccountCPNIModal = ({ isEditCPNI, cpniDetails, onEditCPNIClose, onEditCPNISuccess }) => {
  const intl = useIntl();
  const { config } = useContext(BrandingContext);
  const { accountCode: masterAccountCode } = useContext(AuthContext);
  const { accountCode: accountCodePath } = useParams();
  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    setError,
    clearError,
    control,
  } = useForm({
    mode: "onChange",
  });

  const [receiveMarketingMaterials, setReceiveMarketingMaterials] = useState(
    cpniDetails.receiveMarketingMaterials
  );

  // array containing current CPNI codes
  const [securityQuestions, setSecurityQuestions] = useState({});

  // possible options from select (a union between system questions and already existing custom questions from account)
  const [securityQuestionOptions, setSecurityQuestionOptions] = useState([]);

  const [allQuestionsData, getAllQuestions] = useGET({});
  const [numberOfCpniSecurityTokensData, getNumberOfCpniSecurityTokens] = useGET({});
  const [doPutCPNIData, doPutCPNI, resetPutCPNIError] = usePUT({});

  useEffect(() => {
    const initializeCurrentSecurityQuestionsValues = () => {
      let temp = {};
      let index = 0;
      for (let question of cpniDetails.securityQuestions) {
        if (question.code) {
          temp[index++] = question.code;
        }
      }
      if (numberOfCpniSecurityTokensData.isSuccess && numberOfCpniSecurityTokensData.data > index) {
        let notSetQuestions = numberOfCpniSecurityTokensData.data - index;
        for (let i = 0; i < notSetQuestions; i++) {
          temp[index++] = "1";
        }
      }
      setSecurityQuestions({ ...temp });
    };

    initializeCurrentSecurityQuestionsValues();
  }, [cpniDetails.securityQuestions, numberOfCpniSecurityTokensData]);

  useEffect(() => {
    const loadInitialData = () => {
      getAllQuestions({
        route: `/securityQuestions`,
      });

      getNumberOfCpniSecurityTokens({
        route: `/configuration/numberOfCpniSecurityTokens`,
      });
    };

    loadInitialData();
  }, [getAllQuestions, getNumberOfCpniSecurityTokens]);

  useEffect(() => {
    const doAfterSuccessfulSave = () => {
      if (doPutCPNIData.isSuccess) {
        onEditCPNISuccess();
      }
    };

    doAfterSuccessfulSave();
  }, [doPutCPNIData.isSuccess, onEditCPNISuccess]);

  useEffect(() => {
    const computeSecurityQuestionsList = () => {
      let options = [];
      let allKeys = new Set();

      if (allQuestionsData.data.securityQuestions) {
        let map = allQuestionsData.data.securityQuestions.map(elem => {
          allKeys.add(elem.code);
          return (
            <option key={elem.code} value={elem.code}>
              {elem.description}
            </option>
          );
        });
        options = options.concat(map);
      }

      allKeys.add(OWN_KEY);
      options.push(
        <option key={OWN_KEY} value={OWN_KEY}>
          {intl.formatMessage({ id: "lbl.fill_your_own_question" })}
        </option>
      );

      if (cpniDetails.securityQuestions) {
        let map = cpniDetails.securityQuestions
          .filter(elem => !allKeys.has(elem.code))
          .map(elem => {
            allKeys.add(elem.code);
            return (
              <option key={elem.code} value={elem.code}>
                {elem.description}
              </option>
            );
          });
        options = options.concat(map);
      }

      return options;
    };

    const initializeSecurityQuestions = () => {
      if (allQuestionsData.isSuccess) {
        setSecurityQuestionOptions(computeSecurityQuestionsList());
      }
    };

    initializeSecurityQuestions();
  }, [allQuestionsData, intl, cpniDetails.securityQuestions]);

  const isCustomQuestion = code => {
    if (!code) {
      return false;
    }

    let isASystemQuestion = allQuestionsData.data.securityQuestions.some(
      elem => elem.code === code
    );
    return !isASystemQuestion || code === "OWN";
  };

  const getExistingCustomQuestionDescription = code => {
    let existingCustomQuestion = cpniDetails.securityQuestions.find(
      elem => elem.custom && elem.code === code
    );
    return existingCustomQuestion.description;
  };

  const onSaveCPNI = values => {
    let updatedQuestions = [];

    let addedQuestions = [];

    let deletedQuestions = [];

    for (let index = 0; index < numberOfCpniSecurityTokensData.data; index++) {
      if (!cpniDetails.securityQuestions[index] && securityQuestions["" + index]) {
        let addedQuestion = {
          code: securityQuestions["" + index],
          description:
            OWN_KEY === securityQuestions["" + index]
              ? values[`securityQuestion${index}Description`]
              : isCustomQuestion(securityQuestions["" + index])
              ? getExistingCustomQuestionDescription(securityQuestions["" + index])
              : "",
          answer: values[`securityAnswer${index}`],
          custom: isCustomQuestion(securityQuestions["" + index]),
        };
        addedQuestions = [...addedQuestions, addedQuestion];
      } else if (cpniDetails.securityQuestions[index] && cpniDetails.securityQuestions[index].id) {
        if (
          cpniDetails.securityQuestions[index].code === securityQuestions[index] &&
          cpniDetails.securityQuestions[index].answer !== values[`securityAnswer${index}`]
        ) {
          let updatedQuestion = {
            id: cpniDetails.securityQuestions[index].id,
            code: securityQuestions[index],
            description:
              OWN_KEY === securityQuestions["" + index]
                ? values[`securityQuestion${index}Description`]
                : isCustomQuestion(securityQuestions["" + index])
                ? cpniDetails.securityQuestions[index].description
                : "",
            answer: values[`securityAnswer${index}`],
            custom: isCustomQuestion(securityQuestions["" + index]),
          };

          updatedQuestions = [...updatedQuestions, updatedQuestion];
        }
        // also code was updated => delete+add must be done in order to fulfill core validation
        else {
          let deletedQuestion = {
            id: cpniDetails.securityQuestions[index].id,
            code: cpniDetails.securityQuestions[index].code,
            answer: cpniDetails.securityQuestions[index].answer,
            custom: isCustomQuestion(cpniDetails.securityQuestions[index].code),
            description: isCustomQuestion(cpniDetails.securityQuestions[index].code)
              ? cpniDetails.securityQuestions[index].description
              : "",
          };

          deletedQuestions = [...deletedQuestions, deletedQuestion];

          let addedQuestion = {
            code: securityQuestions["" + index],
            description:
              OWN_KEY === securityQuestions["" + index]
                ? values[`securityQuestion${index}Description`]
                : isCustomQuestion(securityQuestions["" + index])
                ? getExistingCustomQuestionDescription(securityQuestions["" + index])
                : "",
            answer: values[`securityAnswer${index}`],
            custom: isCustomQuestion(securityQuestions["" + index]),
          };
          addedQuestions = [...addedQuestions, addedQuestion];
        }
      }
    }

    let updatedCPNI = {
      phone: values.cpniPhone,
      email: values.cpniEmail,
      password: values.cpniNewPassword,
      receiveMarketingMaterials: receiveMarketingMaterials,
      updatedQuestions,
      addedQuestions,
      deletedQuestions,
    };

    doPutCPNI({
      route: `/account/${accountCode}/cpni`,
      expectedResponse: "none",
      body: updatedCPNI,
    });
  };

  let cpniNewPasswordRef = React.useRef();
  let cpniNewPasswordConfirmRef = React.useRef();

  const getSecurityQuestions = securityQuestions => {
    return Object.keys(securityQuestions).map((question, index) => {
      return (
        <Box key={"key" + index}>
          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.security_question" />
          </Text>
          <Box marginBottom="medium">
            <Select
              name={`securityQuestion${index}`}
              value={securityQuestions[`${index}`]}
              ref={register}
              onClick={() => {
                clearError();
              }}
              onChange={event => {
                let newSecurityQuestions = { ...securityQuestions };
                newSecurityQuestions["" + index] = event.target.value;
                setSecurityQuestions(newSecurityQuestions);

                setValue(`securityQuestion${index}Description`, "");
                setValue(`securityAnswer${index}`, "");
              }}
              variant={errors[`securityQuestion${index}`] ? "selectError" : "select"}
              sx={{
                borderColor: "greyBorderInput",
                "&:focus": {
                  outline: "none",
                },
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                paddingRight: "1.5rem",
              }}>
              {securityQuestionOptions}
            </Select>
          </Box>

          {securityQuestions[index] === OWN_KEY && (
            <Box marginBottom="medium">
              <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
                <FormattedMessage id="lbl.security_question" />
              </Text>
              <Input
                defaultValue={
                  cpniDetails.securityQuestions[index] &&
                  cpniDetails.securityQuestions[index].custom
                    ? cpniDetails.securityQuestions[index].description
                    : ""
                }
                ref={register}
                variant={
                  errors[`securityQuestion${index}Description`] ? "greyInputError" : "greyInput"
                }
                maxLength="80"
                name={`securityQuestion${index}Description`}
                my="small"
              />
            </Box>
          )}

          <Box marginBottom="medium">
            <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
              <FormattedMessage id="lbl.answer" />
            </Text>
            <Input
              ref={register({ required: true })}
              defaultValue={
                cpniDetails.securityQuestions[index]
                  ? cpniDetails.securityQuestions[index].answer
                  : ""
              }
              variant={errors[`securityAnswer${index}`] ? "greyInputError" : "greyInput"}
              maxLength="80"
              name={`securityAnswer${index}`}
            />
          </Box>
        </Box>
      );
    });
  };

  return (
    <ModalPage isOpen={isEditCPNI} onRequestClose={onEditCPNIClose}>
      <form onSubmit={handleSubmit(onSaveCPNI)}>
        <Box px="larger" py="larger">
          <Heading
            as="h3"
            fontWeight={5}
            pb="medium"
            fontSize="title"
            color="textDark"
            textAlign="left">
            <FormattedMessage id="lbl.custom_proprietary_network_information" />
          </Heading>

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.cpni_phone_number" />
          </Text>
          <Box marginBottom="medium">
            <Input
              defaultValue={cpniDetails.phoneNumber}
              ref={register({
                required: true,
                pattern: {
                  value: /^[0-9]+$/i,
                  message: "lbl.phone_number_should_contain_digits",
                },
              })}
              variant={errors.cpniPhone ? "greyInputError" : "greyInput"}
              maxLength="10"
              name="cpniPhone"
              type="text"
            />
            {errors.cpniPhone && errors.cpniPhone.message && (
              <FormErrorMessage>
                <FormattedMessage id={errors.cpniPhone.message} />
              </FormErrorMessage>
            )}
          </Box>

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.cpni_email" />
          </Text>
          <Box marginBottom="medium">
            <Input
              defaultValue={cpniDetails.email}
              variant={errors.cpniEmail ? "greyInputError" : "greyInput"}
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "err.email_address_is_invalid",
                },
              })}
              maxLength="80"
              name="cpniEmail"
              type="text"
            />
            {errors.cpniEmail && errors.cpniEmail.message && (
              <FormErrorMessage>
                <FormattedMessage id={errors.cpniEmail.message} />
              </FormErrorMessage>
            )}
          </Box>

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.new_cpni_password" />
          </Text>
          <Box marginBottom="medium">
            <Controller
              name="cpniNewPassword"
              as={
                <Input
                  ref={cpniNewPasswordRef}
                  variant={errors.cpniNewPassword ? "greyInputError" : "greyInput"}
                  style={{ paddingRight: "2.2rem" }}
                  maxLength="80"
                  type="password"
                />
              }
              rules={{
                required: true,
                validate: value => {
                  let watchCpniNewPasswordConfirm = watch("cpniNewPasswordConfirm");
                  if (watchCpniNewPasswordConfirm && watchCpniNewPasswordConfirm !== value) {
                    setError("cpniNewPasswordConfirm", "notMatch", "lbl.passwords_dont_match");
                  } else {
                    clearError("cpniNewPasswordConfirm", "notMatch");
                  }
                  return value === watchCpniNewPasswordConfirm;
                },
              }}
              control={control}
              defaultValue={cpniDetails.password}
            />
            <ShowPassword refInput={cpniNewPasswordRef} mt="-1.6rem" />
          </Box>
          {errors.cpniNewPassword && errors.cpniNewPassword.message && (
            <FormErrorMessage>
              <FormattedMessage id={errors.cpniNewPassword.message} />
            </FormErrorMessage>
          )}

          <Text color="textDark" fontSize="secondary" marginY="small" fontWeight={4}>
            <FormattedMessage id="lbl.cpni_confirm_new_password" />
          </Text>

          <Box marginBottom="medium">
            <Controller
              name="cpniNewPasswordConfirm"
              as={
                <Input
                  ref={cpniNewPasswordConfirmRef}
                  variant={errors.cpniNewPasswordConfirm ? "greyInputError" : "greyInput"}
                  style={{ paddingRight: "2.2rem" }}
                  maxLength="80"
                  type="password"
                />
              }
              rules={{
                required: true,
                validate: value => {
                  let watchCpniNewPassword = watch("cpniNewPassword");
                  if (watchCpniNewPassword && watchCpniNewPassword !== value) {
                    setError("cpniNewPassword", "notMatch");
                  } else {
                    clearError("cpniNewPassword", "notMatch");
                  }
                  return value === watchCpniNewPassword || "lbl.passwords_dont_match";
                },
              }}
              control={control}
              defaultValue={cpniDetails.password}
            />
            <ShowPassword refInput={cpniNewPasswordConfirmRef} mt="-1.6rem" />
          </Box>

          {errors.cpniNewPasswordConfirm && errors.cpniNewPasswordConfirm.message && (
            <FormErrorMessage>
              <FormattedMessage id={errors.cpniNewPasswordConfirm.message} />
            </FormErrorMessage>
          )}

          {allQuestionsData.data &&
            numberOfCpniSecurityTokensData.data &&
            securityQuestions &&
            getSecurityQuestions(securityQuestions)}

          {config.receiveMarketingDetailsVisible && (
            <Flex
              flexDirection="row"
              marginBottom="medium"
              alignItems="center"
              justifyContent="space-between">
              <Text
                color="textDark"
                fontSize="secondary"
                fontWeight={4}
                textAlign="left"
                mr={[0, "small"]}>
                <FormattedMessage id="lbl.cpni_receive_marketing_materials" />
              </Text>
              <Flex flexDirection={"row"}>
                <SelfcareButton
                  px="0 !important"
                  variant={receiveMarketingMaterials ? "tertiary" : "tertiaryWhite"}
                  onClick={event => {
                    event.preventDefault();
                    setReceiveMarketingMaterials(true);
                  }}>
                  <FormattedMessage id="lbl.yes" />
                </SelfcareButton>
                <SelfcareButton
                  px="0 !important"
                  variant={!receiveMarketingMaterials ? "tertiary" : "tertiaryWhite"}
                  onClick={event => {
                    event.preventDefault();
                    setReceiveMarketingMaterials(false);
                  }}>
                  <FormattedMessage id="lbl.no" />
                </SelfcareButton>
              </Flex>
            </Flex>
          )}

          <Box
            width="auto"
            mt="larger"
            textAlign="right"
            mb={["mobileModalExtraOffset", "huge", "huge"]}>
            <SelfcareButton type="submit" variant="default" mr="spaceBetweenButtons">
              <FormattedMessage id="lbl.save" />
            </SelfcareButton>

            <SelfcareButton variant="default-inverted" onClick={onEditCPNIClose}>
              <FormattedMessage id="lbl.cancel" />
            </SelfcareButton>
          </Box>

          {doPutCPNIData.isError && (
            <StyledModalMessage
              isOpen={doPutCPNIData.isError}
              message={<GetErrorDescription error={doPutCPNIData.error} />}
              onRequestClose={resetPutCPNIError}
              type="error">
              <SelfcareButton variant="secondarySmall" onClick={resetPutCPNIError}>
                <FormattedMessage id="lbl.Try_Again" />
              </SelfcareButton>
            </StyledModalMessage>
          )}
        </Box>
      </form>
    </ModalPage>
  );
};

export default EditAccountCPNIModal;
