import { useEffect, useReducer, useState, useContext } from "react";
import { AuthContext } from "../contexts/AuthorizationContext";
import { FacadeApi } from "../rest/FacadeRestClient";
import { retrieveAccessToken } from "../rest/AuthorizationAPI";
import AuthorizationException from "../exceptions/AuthorizationException";
import OfflineException from "../exceptions/OfflineException";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  isOffline: false,
  data: null,
  error: null,
};

const dataFetchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        isOffline: false,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
        isSuccess: true,
        isOffline: false,
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
        isSuccess: false,
        isOffline: false,
      };
    case "FETCH_ERROR_RESET":
      return {
        ...state,
        isError: false,
        error: null,
        isSuccess: false,
        isOffline: false,
      };
    case "FETCH_OFFLINE":
      return {
        ...state,
        isOffline: true,
      };
    default:
      throw new Error();
  }
};

const useFetch = (initialData = {}, customFetchParametersByHttpMethod = {}) => {
  const [fetchParameters, setFetchParameters] = useState({});
  const [state, dispatch] = useReducer(dataFetchReducer, {
    ...initialState,
    data: initialData,
  });

  const { loggedIn, logout } = useContext(AuthContext);

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      if (!fetchParameters || !Object.keys(fetchParameters).length || !fetchParameters.route) {
        return;
      }

      let mergedFetchParameters = { ...customFetchParametersByHttpMethod, ...fetchParameters };
      let tokenAuthorization;

      dispatch({ type: "FETCH_INIT" });
      try {
        if (!mergedFetchParameters.authorization && loggedIn) {
          const theToken = await retrieveAccessToken();
          tokenAuthorization = { type: "Bearer", token: theToken };
        }
        const result = await FacadeApi._fetch(
          mergedFetchParameters.route,
          mergedFetchParameters.method,
          mergedFetchParameters.body,
          mergedFetchParameters.isQuery,
          mergedFetchParameters.isForm,
          mergedFetchParameters.expectedResponse,
          mergedFetchParameters.noTimeout,
          mergedFetchParameters.abortHandle,
          mergedFetchParameters.authorization
            ? mergedFetchParameters.authorization
            : tokenAuthorization
        );

        if (!didCancel) {
          dispatch({ type: "FETCH_SUCCESS", payload: result });
        }
      } catch (error) {
        if (error instanceof AuthorizationException) {
          logout();
        }
        if (!didCancel && error instanceof OfflineException) {
          dispatch({ type: "FETCH_OFFLINE" });
        }
        if (!didCancel) {
          dispatch({ type: "FETCH_FAILURE", payload: error });
        }
      }
    };
    fetchData();

    return () => {
      didCancel = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchParameters, loggedIn]);

  const resetError = () => {
    dispatch({ type: "FETCH_ERROR_RESET" });
  };

  return [state, setFetchParameters, resetError];
};

const useGET = (initialData = {}) => {
  const [state, doGet, resetError] = useFetch(initialData, {
    method: "GET",
    isQuery: true,
    expectedResponse: "json",
    noTimeout: false,
    abortHandle: null,
  });

  return [state, doGet, resetError];
};

const usePOST = (initialData = {}) => {
  const [state, doPost, resetError] = useFetch(initialData, {
    method: "POST",
  });

  return [state, doPost, resetError];
};

const usePUT = (initialData = {}) => {
  const [state, doPut, resetError] = useFetch(initialData, {
    method: "PUT",
  });

  return [state, doPut, resetError];
};

const useDELETE = (initialData = {}) => {
  const [state, doDelete, resetError] = useFetch(initialData, {
    method: "DELETE",
  });

  return [state, doDelete, resetError];
};

export { useGET, usePOST, usePUT, useDELETE };
