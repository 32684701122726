import React from "react";

export default ({ grey = "#E6E6E6", mediumBlue = "#346EB3", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" {...props}>
    <circle id="grey" fill={grey} cx="26.5" cy="26.5" r="26.5" />
    <g id="medium_blue">
      <path
        fill={mediumBlue}
        d="M43.3,15.3l-16-4.7c-0.5-0.2-1.1-0.2-1.6,0l-16,4.7C20.8,17.7,32.3,17.7,43.3,15.3z"
      />
      <path
        fill={mediumBlue}
        d="M26.5,34.7c-5.9,0-11.9-0.7-17.7-2v8c4.9,1.1,10,1.7,15,1.9v-5c0-0.4,0.3-0.8,0.7-0.8c0,0,0,0,0,0h3.7
		c0.4,0,0.8,0.3,0.8,0.7c0,0,0,0,0,0l0,0v5c5-0.2,10.1-0.8,15-1.9v-8C38.3,34,32.4,34.6,26.5,34.7z"
      />
      <path
        fill={mediumBlue}
        d="M30.5,33.4c1.7-0.1,3.3-0.2,5-0.4v-4.3c-1.7,0.2-3.3,0.3-5,0.4V33.4z"
      />
      <path
        fill={mediumBlue}
        d="M40.1,28c-1.2,0.2-2.3,0.4-3.5,0.5v4.3c1.2-0.2,2.3-0.3,3.5-0.5V28z"
      />
      <path
        fill={mediumBlue}
        d="M22.5,29.1c-1.6-0.1-3.3-0.2-4.9-0.4V33c1.7,0.2,3.3,0.3,4.9,0.4V29.1z"
      />
      <path
        fill={mediumBlue}
        d="M29.4,29.1c-1,0-1.9,0.1-2.9,0.1s-1.9,0-2.9-0.1v4.3c1.9,0.1,3.8,0.1,5.7,0L29.4,29.1z"
      />
      <path
        fill={mediumBlue}
        d="M12.9,32.3c1.2,0.2,2.3,0.4,3.5,0.5v-4.3c-1.1-0.1-2.3-0.3-3.5-0.5L12.9,32.3z"
      />
      <path fill={mediumBlue} d="M41.3,27.8v4.3c0.9-0.2,1.8-0.3,2.8-0.5h0.1v-4.3L41.3,27.8z" />
      <path
        fill={mediumBlue}
        d="M11.8,27.8c-1-0.2-2-0.3-3-0.6v4.3h0.1c1,0.2,1.9,0.4,2.9,0.5L11.8,27.8z"
      />
      <path
        fill={mediumBlue}
        d="M22.5,23.6c-1.6-0.1-3.3-0.2-4.9-0.4v4.3c1.7,0.2,3.3,0.3,4.9,0.4V23.6z"
      />
      <path
        fill={mediumBlue}
        d="M40.1,22.5c-1.2,0.2-2.3,0.4-3.5,0.5v4.3c1.2-0.1,2.3-0.3,3.5-0.5V22.5z"
      />
      <path
        fill={mediumBlue}
        d="M30.5,27.9c1.7-0.1,3.3-0.2,5-0.4v-4.3c-1.7,0.2-3.3,0.3-5,0.4V27.9z"
      />
      <path
        fill={mediumBlue}
        d="M11.8,22.3c-1-0.2-2-0.3-3-0.5v4.3h0.1c1,0.2,1.9,0.4,2.9,0.5L11.8,22.3z"
      />
      <path
        fill={mediumBlue}
        d="M41.3,22.3v4.3c0.9-0.2,1.8-0.3,2.8-0.5h0.1v-4.3C43.2,22,42.2,22.1,41.3,22.3z"
      />
      <path
        fill={mediumBlue}
        d="M12.9,26.8c1.2,0.2,2.3,0.4,3.5,0.5V23c-1.1-0.1-2.3-0.3-3.5-0.5L12.9,26.8z"
      />
      <path
        fill={mediumBlue}
        d="M29.4,23.6c-1,0-1.9,0.1-2.9,0.1s-1.9,0-2.9-0.1V28c1.9,0.1,3.8,0.1,5.7,0L29.4,23.6z"
      />
      <path
        fill={mediumBlue}
        d="M40.1,17c-1.2,0.2-2.3,0.4-3.5,0.5v4.3c1.2-0.1,2.3-0.3,3.5-0.5V17z"
      />
      <path
        fill={mediumBlue}
        d="M30.5,22.5c1.7-0.1,3.3-0.2,5-0.4v-4.3c-1.7,0.2-3.3,0.3-5,0.4V22.5z"
      />
      <path
        fill={mediumBlue}
        d="M22.5,18.1c-1.6-0.1-3.3-0.2-4.9-0.4V22c1.7,0.2,3.3,0.3,4.9,0.4V18.1z"
      />
      <path
        fill={mediumBlue}
        d="M29.4,18.2c-1,0-1.9,0.1-2.9,0.1s-1.9,0-2.9-0.1v4.3c1.9,0.1,3.8,0.1,5.7,0L29.4,18.2z"
      />
      <path fill={mediumBlue} d="M41.3,16.8v4.3c0.9-0.2,1.8-0.3,2.8-0.5h0.1v-4.3L41.3,16.8z" />
      <path
        fill={mediumBlue}
        d="M12.9,21.4c1.2,0.2,2.3,0.4,3.5,0.5v-4.3c-1.1-0.1-2.3-0.3-3.5-0.5L12.9,21.4z"
      />
      <path
        fill={mediumBlue}
        d="M11.8,16.9c-1-0.2-2-0.4-3-0.6v4.3h0.1c1,0.2,1.9,0.4,2.9,0.5L11.8,16.9z"
      />
    </g>
  </svg>
);
