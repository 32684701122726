import React from "react";
export default ({
  dollarCorners = "#114878",
  dollarCircle = "#318eff",
  dollarBackground = "#336EB4",
  billBackground = "#e6e6e6",
  billCorner = "#dcdcdc",
  billUpperText = "#114878",
  billLowerText = "#318eff",
  arrow = "#336EB4",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="84.279"
    height="79.408"
    viewBox="0 0 84.279 79.408"
    {...props}>
    <g transform="translate(-273.361 -198.814)">
      <g transform="translate(313.909 198.814)">
        <path
          fill={billBackground}
          d="M299.822,198.814v55.239l4.368,4.6,4.37-4.6,4.372,4.6,4.372-4.6,4.372,4.6,4.371-4.6,4.372,4.6,4.373-4.6,4.38,4.6,4.38-4.6V216.076l-17.262-17.262Z"
          transform="translate(-299.822 -198.814)"
        />
        <g transform="translate(7.994 22.985)">
          <rect fill={billUpperText} width="6.936" height="2.312" />
          <g transform="translate(11.559)">
            <rect fill={billUpperText} width="16.183" height="2.312" />
          </g>
          <g transform="translate(0 4.624)">
            <rect fill={billUpperText} width="6.936" height="2.312" />
          </g>
          <g transform="translate(11.559 4.624)">
            <rect fill={billUpperText} width="16.183" height="2.312" />
          </g>
          <g transform="translate(0 9.247)">
            <rect fill={billUpperText} width="6.936" height="2.312" />
          </g>
          <g transform="translate(20.807 13.871)">
            <rect fill={billLowerText} width="6.936" height="2.312" />
          </g>
          <g transform="translate(11.559 9.247)">
            <rect fill={billUpperText} width="16.183" height="2.312" />
          </g>
        </g>
        <path
          fill={billCorner}
          d="M317.045,198.814v17.339l17.339-.055Z"
          transform="translate(-290.653 -198.814)"
        />
      </g>
      <g transform="translate(273.361 206.683)">
        <rect
          fill={dollarBackground}
          width="44.103"
          height="24.517"
          transform="translate(24.517 0) rotate(90)"
        />
        <path
          fill={dollarCorners}
          d="M285.36,203.949a6.13,6.13,0,0,0,6.129,6.129v-6.129Z"
          transform="translate(-266.973 -203.949)"
        />
        <path
          fill={dollarCorners}
          d="M279.49,203.949a6.13,6.13,0,0,1-6.129,6.129v-6.129Z"
          transform="translate(-273.361 -203.949)"
        />
        <path
          fill={dollarCorners}
          d="M285.36,234.859a6.128,6.128,0,0,1,6.129-6.128v6.128Z"
          transform="translate(-266.973 -190.756)"
        />
        <path
          fill={dollarCorners}
          d="M279.49,234.859a6.128,6.128,0,0,0-6.129-6.128v6.128Z"
          transform="translate(-273.361 -190.756)"
        />
        <path
          fill={dollarCircle}
          d="M295.318,221.939A10.359,10.359,0,1,0,284.959,232.3,10.358,10.358,0,0,0,295.318,221.939Z"
          transform="translate(-272.701 -199.887)"
        />
        <path
          fill={dollarCorners}
          d="M285.3,220.627c1.35-1.693,2.15-2.15,3.293-2.15s1.578.457,1.578,1.257-.434,1.26-1.578,1.26h-.664v2.378h.5c2.173,0,3.544-.982,3.911-2.767h1.191v-1.9h-1.191c-.388-1.762-1.761-2.748-3.911-2.748-1.83,0-3.111.708-4.735,2.767-1.327,1.692-2.15,2.15-3.293,2.15s-1.555-.5-1.555-1.3.411-1.3,1.555-1.3h1.257v-2.378h-1.1c-2.173,0-3.569,1.008-3.934,2.816h-1.168v1.9h1.168c.365,1.785,1.761,2.79,3.934,2.79C282.395,223.394,283.653,222.685,285.3,220.627Z"
          transform="translate(-272.241 -197.591)"
        />
      </g>
      <path
        fill={arrow}
        d="M320.775,252.4l-10.807-6.239v4.363H283.84V235.6h-3.751v18.681h29.879v4.364Z"
        transform="translate(3.582 19.581)"
      />
    </g>
  </svg>
);
