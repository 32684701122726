import React from "react";
export default ({ pin = "#114878", circle = "#346eb3", icon = "#318eff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.413"
    height="30.2"
    viewBox="0 0 30.413 30.2"
    {...props}>
    <g transform="translate(-0.087)">
      <path
        fill={pin}
        d="M12.1,0A8.173,8.173,0,0,0,3.9,8.2c0,6.6,8.2,16,8.2,16s8.2-9.3,8.2-16A8.3,8.3,0,0,0,12.1,0Zm0,11.7A3.691,3.691,0,0,1,8.4,8a3.7,3.7,0,0,1,7.4,0A3.757,3.757,0,0,1,12.1,11.7Z"
      />
      <path
        fill="none"
        stroke={circle}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.026px"
        d="M15.8,26.8c-1.2.1-2.4.2-3.7.2C5.7,27,.6,25.3.6,23.2c0-1.5,2.5-2.8,6.3-3.4"
      />
      <path
        fill={icon}
        d="M30.5,24.6V22.2h-2a3.506,3.506,0,0,0-.6-1.4l1.4-1.4-1.7-1.7L26.2,19a3.506,3.506,0,0,0-1.4-.6v-2H22.4v2A3.506,3.506,0,0,0,21,19l-1.4-1.4-1.7,1.7,1.4,1.4a3.506,3.506,0,0,0-.6,1.4h-2v2.4h2a3.506,3.506,0,0,0,.6,1.4l-1.4,1.4L19.6,29,21,27.6a3.506,3.506,0,0,0,1.4.6v2h2.4v-2a3.506,3.506,0,0,0,1.4-.6L27.6,29l1.7-1.7L27.9,26a3.506,3.506,0,0,0,.6-1.4Zm-6.9,2.1a3.3,3.3,0,1,1,3.3-3.3A3.4,3.4,0,0,1,23.6,26.7Z"
      />
    </g>
  </svg>
);
