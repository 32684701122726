import React from "react";
export default ({ cable = "#114878", tip = "#318eff", core = "#336eb4", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35.225"
    height="79.177"
    viewBox="0 0 35.225 79.177"
    {...props}>
    <rect fill={cable} width="3.785" height="13.354" transform="translate(15.667 65.823)" />
    <rect fill={cable} width="11.461" height="11.671" transform="translate(11.882 57.621)" />
    <rect fill={tip} width="27.654" height="27.654" transform="translate(3.785)" />
    <path
      fill={core}
      d="M35.225,56.022S17.56,66.957,0,56.022V22.9H35.225Z"
      transform="translate(0 1.179)"
    />
    <g transform="translate(7.571)">
      <rect fill={core} width="2.734" height="9.043" />
      <rect fill={core} width="2.734" height="9.043" transform="translate(4.311)" />
      <rect fill={core} width="2.734" height="9.043" transform="translate(8.727)" />
      <rect fill={core} width="2.734" height="9.043" transform="translate(13.038)" />
      <rect fill={core} width="2.734" height="9.043" transform="translate(17.349)" />
    </g>
  </svg>
);
