import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthorizationContext";

import { Box, Flex, Text } from "rebass/styled-components";
import { FormattedDate, FormattedMessage } from "react-intl";

import { SelfcareAmount } from "../base";
import { getDateWithoutTimezone } from "../../common/Utilities";

const HorizontalRule = () => {
  return <Box width="100%" height="1px" bg="inactive"></Box>;
};

const PaymentCard = ({ payment }) => {
  const { accountCode: masterAccountCode, isSubAccount } = useContext(AuthContext);
  const { accountCode: accountCodePath } = useParams();

  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;

  const history = useHistory();

  const getFormattedMessage = number => {
    return number != null ? number : <FormattedMessage id="lbl.not_available" />;
  };

  const getFormattedDate = date => {
    let formattedDate;
    if (date == null) {
      formattedDate = <FormattedMessage id="lbl.not_available" />;
    } else {
      formattedDate = <FormattedDate value={getDateWithoutTimezone(date)} />;
    }

    return formattedDate;
  };

  const getFormattedNumber = value => {
    let formattedValue;
    if (value == null) {
      formattedValue = <FormattedMessage id="lbl.not_available" />;
    } else {
      formattedValue = <SelfcareAmount amount={value} />;
    }

    return formattedValue;
  };

  const getPaymentMethod = payment => {
    if (payment.paymentMethod === 4) {
      return <FormattedMessage id="lbl.e_check" />;
    }
    return payment.description;
  };

  const route = isSubAccount
    ? `/account/${accountCode}/billing/payments/details`
    : "/billing/payments/details";

  return (
    <Flex
      p="default"
      flexDirection="column"
      bg="contentBg"
      sx={{
        boxShadow: "light",
        borderRadius: "card",
      }}>
      <Text pb="default" color="primary" fontSize="secondary">
        <FormattedMessage
          id="lbl.payment_card_payment_id"
          values={{ id: getFormattedMessage(payment.transactionNumber) }}
        />
      </Text>
      <Box pb="default">
        <Text color="primary" fontSize="secondary">
          <FormattedMessage
            id="lbl.payment_card_paid_on"
            values={{ date: getFormattedDate(payment.transactionDate) }}
          />
        </Text>
        <Text color="primary" fontSize="secondary" sx={{ textTransform: "capitalize" }}>
          <FormattedMessage
            id="lbl.payment_card_with"
            values={{ method: getFormattedMessage(getPaymentMethod(payment)) }}
          />
        </Text>
      </Box>
      <Text
        sx={{ textTransform: "capitalize" }}
        color="textDark"
        fontSize="amountTitle"
        pb="default">
        {getFormattedNumber(payment.value)}
      </Text>
      <HorizontalRule />
      <Text
        pt="default"
        color="secondary"
        fontSize="secondary"
        sx={{
          cursor: "pointer",
          "&:hover": {
            opacity: "90%",
          },
        }}
        onClick={e => history.push(route, payment)}>
        <FormattedMessage id="lbl.view_details" />
      </Text>
    </Flex>
  );
};

export default PaymentCard;
