import React from "react";
export default ({ icon = "#318eff", clock = "#114878", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="24.364"
    viewBox="0 0 27 24.364"
    {...props}>
    <path
      fill={icon}
      d="M26.942,21.822,17.474,5.348a1.783,1.783,0,0,0-3.124,0L4.787,21.822A1.849,1.849,0,0,0,6.4,24.568h19.03A1.809,1.809,0,0,0,26.942,21.822ZM17.1,22.2H14.634V19.929H17.1Zm0-4.639H14.634V7.81H17.1Z"
      transform="translate(-0.211 -0.204)"
    />
    <path
      fill="none"
      stroke={clock}
      strokeMiterlimit="10"
      strokeWidth="1.2px"
      d="M5.807,16.317A8.13,8.13,0,0,1,8.742.6a8,8,0,0,1,5.113,1.8"
      transform="translate(0 0)"
    />
    <path
      fill="none"
      stroke={clock}
      strokeMiterlimit="10"
      strokeWidth="1.2px"
      d="M8,5V8.882h2.935"
      transform="translate(-0.394 -0.234)"
    />
  </svg>
);
