import React from "react";

export default ({ grey = "#E6E6E6", mediumBlue = "#346EB3", lightBlue = "#318EFF", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" {...props}>
    <circle id="grey" fill={grey} cx="26.5" cy="26.5" r="26.5" />
    <g id="medium_blue">
      <polygon
        fill={mediumBlue}
        points="23.5,27 9.7,27 9.7,37.6 13.1,37.6 13.1,30.5 20.2,30.5 20.2,37.6 23.5,37.6 23.5,27 	"
      />
      <path
        fill={mediumBlue}
        d="M41.1,37.6V27l-8.3-7.2L24.5,27v10.6h9.4v-8.4h4.4v8.4H41.1z M31.8,33.6h-4.4v-4.4h4.4V33.6z"
      />
    </g>
    <g id="light_blue">
      <polygon fill={lightBlue} points="7.8,18.6 7.8,26 19,26 27.5,18.6 	" />
      <polygon fill={lightBlue} points="32.8,15.4 20.4,26 24.1,26 32.8,18.5 41.5,26 45.2,26 	" />
    </g>
  </svg>
);
