import React, { useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";

const NumberSpinner = ({ defaultValue, minValue = 0, maxValue = 99, onNumberChange, disabled }) => {
  const [state, setState] = useState({
    number: defaultValue,
    minValue: minValue <= -1 ? 0 : minValue,
    maxValue: maxValue === -1 ? 99 : maxValue,
  });

  const doNumberChange = number => {
    if (onNumberChange) onNumberChange(number);
  };

  const increase = () => {
    if (disabled) return;

    if (state.maxValue > state.number) {
      let number = state.number + 1;
      setState({ ...state, number: number });

      doNumberChange(number);
    }
  };

  const decrease = () => {
    if (disabled) return;

    if (state.minValue < state.number) {
      let number = state.number - 1;
      setState({ ...state, number: number });

      doNumberChange(number);
    }
  };

  return (
    <Flex
      flexDirection="row"
      width={"4.5rem"}
      height={"1.5rem"}
      sx={{ backgroundColor: "#D4D4D4", borderRadius: "card" }}>
      <Box
        flex={1}
        alignItems="flex-start"
        justifyContent="center"
        textAlign="center"
        onClick={() => decrease()}>
        <Text
          as="span"
          sx={{
            position: "relative",
            top: "-0.1875rem",
            color: "note",
            fontSize: "1.75rem",
            lineHeight: "solid",
            fontWeight: "bold",
            userSelect: "none",
            ":hover": {
              cursor: "pointer",
              opacity: "70%",
            },
          }}>
          -
        </Text>
      </Box>
      <Box
        alignItems="center"
        justifyContent="center"
        height="1.125rem"
        width="1.125rem"
        textAlign="center"
        sx={{
          marginY: "0.125rem",
          backgroundColor: "secondary",
          borderRadius: "card",
        }}>
        <Text
          as="span"
          sx={{
            position: "relative",
            top: "-0.0625rem",
            color: "contentBg",
            fontSize: "note",
            userSelect: "none",
            lineHeight: "solid",
            fontWeight: "400",
          }}>
          {state.number}
        </Text>
      </Box>
      <Box
        flex={1}
        alignItems="flex-end"
        justifyContent="center"
        textAlign="center"
        onClick={() => increase()}>
        <Text
          as="span"
          sx={{
            position: "relative",
            top: "-0.1875rem",
            color: "secondary",
            fontSize: "1.75rem",
            lineHeight: "solid",
            userSelect: "none",
            fontWeight: "bold",
            ":hover": {
              cursor: "pointer",
              opacity: "70%",
            },
          }}>
          +
        </Text>
      </Box>
    </Flex>
  );
};
export default NumberSpinner;
